import { DownloadFile } from '@main/bulk-downloader';
import { QuestionnaireFormComponentRegistry, resolveReadableValues } from '@main/dynamic-form';
import { Vq_Form_Upload_Types_Enum } from '@main/graphql/types.generated';
import { getFilteredConfig, reorderFormAnswers } from '@main/questionnaires-form';
import { safeFileName, serializeCSV, sleep } from '@main/shared/utils';
import { TFunction } from 'i18next';

import { AppDownloadFile } from '../../../bulk-downloader/app-stream-downloader';
import { QuestionnaireExporter } from '../use-questionnaire-exporters';
import {
  VendorQuestionnaireAnswerForDownloadFragment,
  VendorQuestionnaireForDownloadFragment,
} from '../vq-files-fetcher.generated';

export class FormQuestionnaireExporter implements QuestionnaireExporter {
  constructor(
    protected readonly componentRegistry: QuestionnaireFormComponentRegistry,
    protected readonly t: TFunction,
  ) {}

  canExport(vq: VendorQuestionnaireForDownloadFragment): boolean {
    return !!vq.form;
  }

  exportQuestionnaire(vq: VendorQuestionnaireForDownloadFragment): DownloadFile<AppDownloadFile>[] {
    return [this.exportAnswersFile(vq), ...this.exportUploadedFiles(vq)];
  }

  protected exportAnswersFile(
    vq: VendorQuestionnaireForDownloadFragment,
  ): DownloadFile<AppDownloadFile> {
    return { name: `Answers form.csv`, file: { content: () => this.generateAnswersCSV(vq) } };
  }

  protected exportUploadedFiles(
    vq: VendorQuestionnaireForDownloadFragment,
  ): DownloadFile<AppDownloadFile>[] {
    return (vq.form?.answers ?? []).flatMap((answer) => {
      const fieldConfig = vq.form?.config_snapshot?.find(
        (question) => question.name === answer.field_name,
      );
      const filePrefix = safeFileName(fieldConfig?.label || answer.field_name);

      return answer.form_uploads.map((upload) => ({
        name: `${filePrefix}/${safeFileName(`${upload.file.id} - ${upload.file.name ?? ''}`)}`,
        file: { fileId: upload.file.id },
      }));
    });
  }

  protected async generateAnswersCSV(vq: VendorQuestionnaireForDownloadFragment) {
    return serializeCSV(
      [
        this.t('vendors.questionnaires.export.csvHeader.question'),
        this.t('vendors.questionnaires.export.csvHeader.answer'),
        this.t('vendors.questionnaires.export.csvHeader.comment'),
        this.t('vendors.questionnaires.export.csvHeader.supportingFiles'),
      ] as const,
      await Promise.all(this.prepareAnswers(vq).map((answer) => this.exportAnswer(answer, vq))),
    );
  }

  protected prepareAnswers(vq: VendorQuestionnaireForDownloadFragment) {
    const config = vq.form?.config_snapshot ?? [];
    const answers = vq.form?.answers ?? [];
    const filteredConfig = getFilteredConfig(config, answers);

    return reorderFormAnswers(answers, filteredConfig);
  }

  protected async exportAnswer(
    answer: VendorQuestionnaireAnswerForDownloadFragment,
    vq: VendorQuestionnaireForDownloadFragment,
  ) {
    // Pause to allow main thread to process other tasks
    await sleep(16);

    const fieldConfig = vq.form?.config_snapshot?.find(
      (question) => question.name === answer.field_name,
    );

    if (!fieldConfig) {
      throw new Error(`Field config not found for answer ${answer.field_name}`);
    }

    const values = resolveReadableValues(
      this.componentRegistry,
      { ...fieldConfig, value: answer.value },
      [answer.value],
    );

    return [
      fieldConfig.label,
      values.join(', '),
      answer.comment,
      answer.form_uploads
        .filter((upload) => upload.type === Vq_Form_Upload_Types_Enum.Support)
        .map((upload) => safeFileName(`${upload.file.id} - ${upload.file.name ?? ''}`))
        .join(', '),
    ] as const;
  }
}
