import { AiAutocompleteModifier } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

export const sortedModifierMap = {
  Improve: AiAutocompleteModifier.Improve,
  Shorten: AiAutocompleteModifier.Shorten,
  Lengthen: AiAutocompleteModifier.Lengthen,
  Simplify: AiAutocompleteModifier.Simplify,
} satisfies Record<AiAutocompleteModifier, AiAutocompleteModifier>;

export function getAllSortedAiAutocompleteModifierOptions(t: TFunction<'translation'>) {
  const levels = Object.values(sortedModifierMap);
  return levels.map((value) => getSortedAiAutocompleteModifierOption(t, value));
}

export function getSortedAiAutocompleteModifierOption(
  t: TFunction<'translation'>,
  value: AiAutocompleteModifier,
) {
  return {
    value,
    label: t(`aiAutocomplete.modifiers.${value}`),
  };
}
