import dayjs from 'dayjs';

/**
 * Set the date to midnight in the user's timezone
 * and convert it to UTC in ISO format.
 */
export function dateToMidnightUTC(inputValue: string): string {
  if (!inputValue) {
    return '';
  }

  try {
    return new Date(Date.parse(`${inputValue} 00:00:00`)).toISOString();
  } catch {
    return '';
  }
}

export function toDatePart(date?: string) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function toDateTimePart(date?: string) {
  return dayjs(date).format('YYYY-MM-DDTHH:mm');
}
