/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { OrganizationUserDetailsFragmentDoc } from '../fragments/OrganizationUserDetailsFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetUserOrganizationsQueryVariables = Types.Exact<{
  user_id: Types.Scalars['uuid']['input'];
}>;

export type GetUserOrganizationsQuery = {
  __typename?: 'query_root';
  organizations: Array<{
    __typename?: 'organizations';
    id: string;
    name: string;
    parent_organization_id?: string;
    is_audit_trail_enabled: boolean;
    is_risks_module_enabled: boolean;
    is_vendors_module_enabled: boolean;
    is_vendor_questionnaire_ai_review_enabled: boolean;
    is_day_zero_ai_features_enabled: boolean;
    is_controls_module_enabled: boolean;
    is_control_ai_review_enabled: boolean;
    is_policies_module_enabled: boolean;
    is_policy_acknowledgements_enabled: boolean;
    is_client_questionnaires_enabled: boolean;
    roles: Array<{ __typename?: 'roles'; id: string; system_role?: Types.System_Roles_Enum }>;
    organization_users: Array<{
      __typename?: 'organization_users';
      user_id: string;
      disabled: boolean;
      user: {
        __typename?: 'users';
        id: string;
        displayName: string;
        avatarUrl: string;
        email?: string;
      };
      role: {
        __typename?: 'roles';
        id: string;
        name: string;
        system_role?: Types.System_Roles_Enum;
      };
    }>;
  }>;
};

export const GetUserOrganizationsDocument = `
    query GetUserOrganizations($user_id: uuid!) {
  organizations(
    order_by: {name: asc}
    where: {organization_users: {user_id: {_eq: $user_id}}}
  ) {
    id
    name
    parent_organization_id
    is_audit_trail_enabled
    is_risks_module_enabled
    is_vendors_module_enabled
    is_vendor_questionnaire_ai_review_enabled
    is_day_zero_ai_features_enabled
    is_controls_module_enabled
    is_control_ai_review_enabled
    is_policies_module_enabled
    is_policy_acknowledgements_enabled
    is_client_questionnaires_enabled
    roles {
      id
      system_role
    }
    organization_users(order_by: {user: {createdAt: asc}}) {
      ...OrganizationUserDetails
    }
  }
}
    ${OrganizationUserDetailsFragmentDoc}` as string &
  TypedDocumentNode<GetUserOrganizationsQuery, GetUserOrganizationsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetUserOrganizations: build.query<
      GetUserOrganizationsQuery,
      GetUserOrganizationsQueryVariables
    >({
      query: (variables) => ({ document: GetUserOrganizationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserOrganizationsQuery, useLazyGetUserOrganizationsQuery } = injectedRtkApi;
