import { QuestionnaireComponentKind } from '@main/dynamic-form';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import { ParseKeys } from 'i18next';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RefinementCtx, z } from 'zod';

export const fieldSchema = z.object({
  label: z
    .string()
    .trim()
    .min(1, 'form.validation.required' satisfies ParseKeys),
  validation: z.object({ required: z.boolean().optional() }).optional(),
});

export const aiReviewSchema = z.object({
  hasAiReview: z.boolean().optional(),
  aiCriteria: z.string().trim().optional(),
  aiMessage: z.string().trim().optional(),
});

export const supportingFileSchema = z.object({
  hasSupportingFile: z.boolean().optional(),
  supportingUploadId: z.string().trim().optional(),
});

export const advancedSettingsSchema = z.object({
  addFilesToDocuments: z.boolean().optional(),
});

export const baseFieldSchema = z
  .object({})
  .merge(fieldSchema)
  .merge(aiReviewSchema)
  .merge(supportingFileSchema)
  .merge(advancedSettingsSchema);

export function fieldValidationRefinement(
  data: z.infer<typeof baseFieldSchema>,
  ctx: RefinementCtx,
) {
  if (data.hasAiReview) {
    if (!data.aiCriteria) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.fields.aiCriteria.required' satisfies ParseKeys,
        path: ['aiCriteria'],
      });
    }

    if (!data.aiMessage) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.fields.aiMessage.required' satisfies ParseKeys,
        path: ['aiMessage'],
      });
    }
  }

  if (data.hasSupportingFile) {
    if (!data.supportingUploadId) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.fields.supportingFileSwitch.required' satisfies ParseKeys,
        path: ['supportingUploadId'],
      });
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormFieldData extends z.infer<typeof baseFieldSchema> {}

// Force TS to treat T as convariant generic in functions to facilitate extended types.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export interface FormFieldConfigurator<out T extends FormFieldData> {
  kind: QuestionnaireComponentKind;
  form: UseFormReturn<T>;
  useForm(config?: QuestionnaireFormFieldConfig): UseFormReturn<T>;
  renderName: FC<FormFieldConfiguratorProps<T>>;
  renderForm: FC<FormFieldConfiguratorProps<T>>;
  renderAfterForm: FC<FormFieldConfiguratorProps<T>>;
  formDataToConfig?(
    formData: T,
    config: QuestionnaireFormFieldConfig,
  ): Partial<QuestionnaireFormFieldConfig>;
}

export interface FormFieldConfiguratorProps<T extends FormFieldData> {
  config?: QuestionnaireFormFieldConfig;
  affectedConditionalFields?: QuestionnaireFormFieldConfig[];
  configurator: FormFieldConfigurator<T>;
}

const FormFieldConfiguratorContext = createContext<
  Map<QuestionnaireComponentKind, FormFieldConfigurator<FormFieldData>>
>(new Map());

export function FormFieldConfiguratorProvider({
  children,
  registry = new Map(),
}: PropsWithChildren<{
  registry?: Map<QuestionnaireComponentKind, FormFieldConfigurator<FormFieldData>>;
}>) {
  return (
    <FormFieldConfiguratorContext.Provider value={registry}>
      {children}
    </FormFieldConfiguratorContext.Provider>
  );
}

export function useRegisterFormFieldConfigurator<T extends FormFieldData>(
  kind: QuestionnaireComponentKind,
  configurator: FormFieldConfigurator<T>,
) {
  const registry = useContext(FormFieldConfiguratorContext);

  if (!registry.has(kind)) {
    registry.set(kind, configurator);
  }
}

export function useFormFieldConfigurator(config?: QuestionnaireFormFieldConfig) {
  const registry = useContext(FormFieldConfiguratorContext);

  const { configurators, configuratorEntries } = useMemo(
    () => ({
      configurators: Array.from(registry.values()),
      configuratorEntries: Array.from(registry.entries()),
    }),
    [registry],
  );
  const forms = new Map(configuratorEntries.map(([kind, c]) => [kind, c.useForm(config)]));
  const kind = config?.kind ?? QuestionnaireComponentKind.Text;
  const configurator = registry.get(kind);
  const form = forms.get(kind);

  return { configurators, forms, configurator, form };
}
