import { Box, Divider, Flex, LayoutProps, useColorModeValue } from '@chakra-ui/react';
import {
  GlobalSearchIcon,
  GlobalSearchInput,
  GlobalSearchModalProps,
  GlobalSearchProvider,
} from '@main/ui';
import { PropsWithChildren } from 'react';

import { AppGlobalSearchResult } from '../global-search/use-global-search';
import {
  EntitySearchResults,
  EntitySearchResultsProps,
  SelectedEntities,
} from './entity-search-results';

export interface EntitySearchProps extends GlobalSearchModalProps<AppGlobalSearchResult> {
  overflow?: LayoutProps['overflowY'];
  selectedEntities: AppGlobalSearchResult[];
  results: EntitySearchResultsProps;
}

export function EntitySearch(props: PropsWithChildren<EntitySearchProps>) {
  return (
    <GlobalSearchProvider {...props.provider}>
      <Flex direction="column" gap={4} overflowY={props.overflow ?? 'auto'}>
        <Box px={1.5}>
          <GlobalSearchInput
            autoSelect={false}
            fontSize="md"
            leftElement={() => <GlobalSearchIcon w={5} h={5} />}
            {...props.input}
          />
          <Box px={3}>
            <Divider borderColor={useColorModeValue('gray.200', 'gray.600')} />
          </Box>
        </Box>

        <SelectedEntities
          selection={props.selectedEntities}
          onSelectionUpdate={props.provider.onSelectionUpdate}
        />

        {props.children ?? <EntitySearchResults {...props.results} />}
      </Flex>
    </GlobalSearchProvider>
  );
}
