import { Card, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { isNonNullable, toError, useStableCallback } from '@main/shared/utils';
import {
  actionHelper,
  createColumnHelper,
  errorToast,
  Table,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { dateToMidnightUTC } from '../../utils/date';
import { POLICY_VERSION_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../user/slice';
import { EditVersionModal } from './edit-version-modal';
import {
  useDeletePolicyVersionMutation,
  useGetPolicyQuery,
  useUpdatePolicyVersionMutation,
} from './manage-policies.generated';
import { getMappedPolicy } from './slice';

type PolicyVersion = NonNullable<ReturnType<typeof getMappedPolicy>['policy_versions']>[number];

export const PolicyVersionsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const { isLoading } = useGetPolicyQuery({ id: policyId });
  const disclosure = useDisclosure();
  const [selectedVersion, setSelectedVersion] = useState<{
    versionId: string;
    versionNumber: string;
    versionDetails?: string;
  }>();

  const drawer = useDrawer();
  const openVersion = useCallback(
    (row: { original: PolicyVersion }) => {
      drawer.open({
        entity: 'policy-version',
        entityId: `${policyId}:${row.original.id}`,
      });
    },
    [drawer, policyId],
  );

  const openEditModal = useCallback(
    (row: { original: PolicyVersion }) => {
      setSelectedVersion({
        versionId: row.original.id,
        versionNumber: row.original.version_id,
        versionDetails: row.original.revision_details,
      });
      disclosure.onOpen();
    },
    [disclosure],
  );
  const columns = useVersionsTableColumns(policyId, openEditModal);
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.policyVersion').toLowerCase(),
      plural: t('entities.plural.policyVersions').toLowerCase(),
    };
  }, [t]);
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));

  return (
    <Card variant="table-styles">
      <Table
        minW="400px"
        data={policy.policy_versions ?? []}
        isLoading={isLoading}
        columns={columns}
        itemName={tableItemName}
        onRowClick={openVersion}
      />
      {selectedVersion && (
        <EditVersionModal
          isOpen={disclosure.isOpen}
          onClose={() => {
            disclosure.onClose();
            setSelectedVersion(undefined);
          }}
          {...selectedVersion}
        />
      )}
    </Card>
  );
};

const useDeleteDraftPolicyVersionAction = (policyId: string) => {
  const drawer = useDrawer();
  const { t } = useTranslation();
  const [deletePolicyVersion] = useDeletePolicyVersionMutation();
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;

  return useStableCallback(
    ({
      policyVersionId,
      status,
    }: {
      policyVersionId: string;
      status: Policy_Version_Statuses_Enum;
    }) => {
      // we don't want to delete draft policy version if it's the only one version
      if (
        !canEditPolicy ||
        !(status === Policy_Version_Statuses_Enum.Draft && policy.policy_versions.length > 1)
      ) {
        return;
      }

      return {
        icon: TrashIcon,
        label: t('buttons.delete'),
        onClick: () =>
          openDialog({
            dialogHeader: t('policies.versions.deleteAlert.header'),
            dialogContent: t('policies.versions.deleteAlert.content'),
            confirmAction: {
              children: t('buttons.delete'),
              onClick: async () => {
                try {
                  await deletePolicyVersion({ id: policyVersionId }).unwrap();
                  const currentDrawer = drawer.getCurrentData();

                  // user is deleting the version opened in the drawer
                  if (
                    currentDrawer?.entity === 'policy-version' &&
                    currentDrawer?.entityId?.includes(policyVersionId)
                  ) {
                    drawer.open({ entity: 'policy', entityId: policyId });
                  }
                } catch (error) {
                  errorToast(
                    t('errorMessages.deleteFailed', {
                      entity: t('entities.policyVersion'),
                    }),
                  );
                  datadogLogs.logger.error('Policy version delete failed', {}, toError(error));
                }
              },
            },
          }),
      };
    },
  );
};

function useVersionsTableColumns(
  policyId: string,
  openRevisionEditModal: (row: { original: PolicyVersion }) => void,
) {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const [updateVersion] = useUpdatePolicyVersionMutation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;
  const getDeleteDraftPolicyVersionAction = useDeleteDraftPolicyVersionAction(policyId);

  const onValidityDateUpdate = useCallback(
    async (policyVersion: PolicyVersion, validityDate: string) => {
      const adjustedValidityStart = dateToMidnightUTC(validityDate);

      try {
        await updateVersion({
          id: policyVersion.id,
          updatePayload: {
            validity_start: adjustedValidityStart || null,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.policyVersion') }));
        datadogLogs.logger.error('Policy version update failed', {}, toError(error));
      }
    },
    [updateVersion, t],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyVersion>();

    return [
      columnHelper.columns.tag({
        id: 'versionId',
        header: t('policies.versions.tableColumns.version'),
        accessorFn: ({ formattedVersionId }) => {
          return {
            value: formattedVersionId,
            colorScheme: 'gray',
          };
        },
      }),

      columnHelper.columns.status({
        id: 'status',
        header: t('policies.versions.tableColumns.status'),
        accessorFn: ({ status }) => {
          return {
            value: t(`policies.versions.status.${status}`),
            colorScheme: POLICY_VERSION_STATUS_COLOR[status],
          };
        },
        size: 180,
      }),

      columnHelper.columns.date({
        id: 'validityStart',
        header: t('policies.versions.tableColumns.validityStart'),
        accessorFn: ({ validity_start }) => validity_start,
        size: 160,
        edit: {
          onChange: (row, value) => onValidityDateUpdate(row.original, value),
          canEditGuard: () => !!canEditPolicy,
        },
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('policies.versions.tableColumns.createdBy'),
        accessorFn: ({ created_by }) => {
          if (!created_by) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[created_by];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),
      columnHelper.columns.actions({
        size: 70,
        menuActions: ({ row }) => {
          return [
            canEditPolicy &&
              actionHelper.menuActionItem({
                icon: PencilSquareIcon,
                label: t('policies.versions.edit.button'),
                onClick: () => openRevisionEditModal(row),
              }),
            getDeleteDraftPolicyVersionAction({
              policyVersionId: row.original.id,
              status: row.original.status,
            }),
          ].filter(isNonNullable);
        },
      }),
    ];
  }, [
    canEditPolicy,
    currentOrgUsers,
    getDeleteDraftPolicyVersionAction,
    onValidityDateUpdate,
    openRevisionEditModal,
    t,
  ]);
}
