import { ThemeTypings } from '@chakra-ui/react';
import { RiskAssessmentStatus } from '@main/graphql/client-scalars';
import { formatDate, isNonNullable, mapByKey, Severity } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { findingTypeSeverity, getHighestSeverity } from '../findings/finding-type';
import { getInherentAndResidualLevelForRisk, getMatrixLevelsMap } from '../risks/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getVendorApi } from './get-vendor.generated';
import { api as getVendorQuestionnaireApi } from './vendor-questionnaires/vendor-questionnaires.generated';
import { api as getVendorRisksApi } from './VendorRisks.generated';

const emptyArray: never[] = [];
const getVendorApiData = createSelector(
  (state: AppRootState, vendorId: string) => ({ state, vendorId }),
  ({ state, vendorId }) => {
    return getVendorApi.endpoints.GetVendor.select({
      vendor_id: vendorId,
    })(state).data?.vendors_by_pk;
  },
);

const getVendorsApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getVendorApi.endpoints.GetVendors.select({
        organization_id: orgId,
      })(state).data?.vendors || emptyArray
    );
  },
);

const getVendorRisksApiData = createSelector(
  (state: AppRootState, vendorId: string) => ({ state, vendorId }),
  ({ state, vendorId }) => {
    return (
      getVendorRisksApi.endpoints.GetVendorRisks.select({
        vendorId,
      })(state).data?.vendor_risks || emptyArray
    );
  },
);

const getVendorQuestionnaireApiData = createSelector(
  (state: AppRootState, vqId: string) => ({ state, vqId }),
  ({ state, vqId }) => {
    return getVendorQuestionnaireApi.endpoints.GetVendorQuestionnairesById.select({
      vqId,
    })(state).data?.vendor_questionnaires_by_pk;
  },
);

export type OrganizationVendor = ReturnType<typeof getMappedVendors>[number];

export const getMappedVendors = createSelector([getVendorsApiData], (vendors) =>
  vendors.map((vendor) => {
    return {
      ...vendor,
      findings: vendor.vendor_findings,
      lastReviewedDate: vendor.last_review_date,
    };
  }),
);

export const getMappedVendor = createSelector([getVendorApiData], (vendor) => {
  if (!vendor) {
    return;
  }

  return {
    ...vendor,
    lastReviewedDate: formatDate(vendor.last_review_date),
  };
});

export const getVendorTiers = createSelector([getVendorApiData], (vendor) => {
  if (!vendor?.tier) {
    return undefined;
  }

  return {
    value: vendor.tier.id,
    label: vendor.tier.name,
    colorScheme: 'purple',
  };
});

export const getVendorRiskLevels = createSelector([getVendorApiData], (vendor) => {
  if (!vendor?.risk_level) {
    return undefined;
  }

  return {
    value: vendor.risk_level.id,
    label: vendor.risk_level.name,
    colorScheme: 'purple',
  };
});

export const getVendorRisks = createSelector(
  [getVendorRisksApiData, getMatrixLevelsMap],
  (risks, matrixLevelsMap) => {
    return risks.map(({ risk, id }) => ({
      ...risk,
      vendorRiskId: id,
      internalId: risk.internal_id,
      ...getInherentAndResidualLevelForRisk(risk, matrixLevelsMap),
    }));
  },
);

export const getVendorRisksSeverity = createSelector([getVendorRisks], (risks) => {
  const isOverdue = risks.some((risk) => risk.assessment_status === RiskAssessmentStatus.OVERDUE);
  if (isOverdue) {
    return Severity.HIGH;
  }
  return Severity.LOW;
});

export const getFormattedVendorQuestionnaire = createSelector(
  [getVendorQuestionnaireApiData],
  (vq) => {
    const findingsCount = vq?.vendor_findings?.length ?? 0;
    const findingsCountWithoutResolution =
      vq?.vendor_findings?.filter((finding) => !finding.resolution).length ?? 0;

    return {
      ...vq,
      findingsCount,
      findingsCountWithoutResolution,
      highestSeverity: getHighestSeverity(vq?.vendor_findings || []),
    };
  },
);

export const getFindingsMappedWithQuestionAndAnswer = createSelector(
  [getVendorQuestionnaireApiData],
  (vendorQuestionnaire) => {
    const findings = vendorQuestionnaire?.vendor_findings ?? [];
    const configSnapshotWithQuestionIdx = vendorQuestionnaire?.form?.config_snapshot?.map(
      (field, idx) => ({ ...field, questionIdx: idx + 1 }),
    );
    const configSnapshotMap = mapByKey(configSnapshotWithQuestionIdx ?? [], 'name');
    const formAnswersMap = mapByKey(vendorQuestionnaire?.form?.answers ?? [], 'field_name');

    return (
      findings
        .map((finding) => {
          const fieldName =
            finding.vendor_questionnaire_ai_review_assessment?.vendor_questionnaire_form_answer
              .field_name ?? '';

          return {
            ...finding,
            severity: findingTypeSeverity(finding.type),
            questionFromSnapshot: configSnapshotMap[fieldName],
            answer: formAnswersMap[fieldName],
            questionnaireFormProps: {
              fields: [configSnapshotMap[fieldName]].filter(isNonNullable),
              answers: [formAnswersMap[fieldName]].filter(isNonNullable),
              uploads: vendorQuestionnaire?.questionnaire.questionnaire_uploads,
            },
          };
        })
        // Sort the findings by the questions order in the snapshot
        .sort(
          (a, b) =>
            (a?.questionFromSnapshot?.questionIdx ?? 0) -
            (b?.questionFromSnapshot?.questionIdx ?? 0),
        )
    );
  },
);

export type Option = {
  value: string;
  label: string;
  colorScheme?: ThemeTypings['colorSchemes'];
};
