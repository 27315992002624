import { Field_Entities_Enum } from '@main/graphql/types.generated';

import { useCustomFieldsTableSettings } from '../../utils/table-settings';

const defaultColumnVisibility = {
  createdBy: false,
};

export function useTasksTableSettings(tableId: string) {
  return useCustomFieldsTableSettings({
    entityName: Field_Entities_Enum.Task,
    tableId,
    defaultColumnVisibility,
  });
}
