import { ComboboxProvider, TabProvider, useDialogStore } from '@ariakit/react';
import { useModalContext } from '@chakra-ui/react';
import { isNonNullable, useStableCallback } from '@main/shared/utils';
import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useOptional } from '../hooks';

export interface GlobalSearchState {
  search: string;
  category?: string;
}

export interface GlobalSearchResult {
  id: string;
  title: ReactNode;
  category?: string;
  updatedAt?: ReactNode;
}

export interface GlobalSearchContextValue<TResult extends GlobalSearchResult = GlobalSearchResult>
  extends GlobalSearchProviderProps<TResult> {
  resultsGroups: Partial<Record<string, TResult[]>>;
  resultsTab?: TResult[];
  tabs: string[];
  tabId: string;
  isNoResults: boolean;
  hasResults: boolean;
  onLoadMore: () => void;
}

export const GlobalSearchContext = createContext<GlobalSearchContextValue | undefined>(undefined);

export function useGlobalSearchContext<TResult extends GlobalSearchResult>() {
  const context = useContext(GlobalSearchContext);

  if (!context) {
    throw new Error('useGlobalSearchContext must be used within a GlobalSearchProvider');
  }

  return context as unknown as GlobalSearchContextValue<TResult>;
}

const ALL_CATEGORY = 'All';

export interface GlobalSearchProviderProps<TResult extends GlobalSearchResult> {
  results?: TResult[];
  initialSearch?: string;
  selectedResults?: TResult[];
  categories?: string[];
  isSearching?: boolean;
  prefix?: string;
  onSearch?: (state: GlobalSearchState) => void;
  onSelect?: (result: TResult) => void;
  onSelectionUpdate?: (results: TResult[]) => void;
}

export function GlobalSearchProvider<TResult extends GlobalSearchResult>({
  children,
  ...props
}: PropsWithChildren<GlobalSearchProviderProps<TResult>>) {
  const prefix = props.prefix ?? 'global-search-';
  const modal = useOptional(useModalContext);

  const dialogStore = useDialogStore({ open: modal?.isOpen ?? true });

  const [search, setSearch] = useState(props.initialSearch ?? '');
  const [tabId, setTabId] = useState(ALL_CATEGORY);
  const tabs = useMemo(() => [ALL_CATEGORY, ...(props.categories ?? [])], [props.categories]);

  const resultsGroups = useMemo(
    () => Object.groupBy(props.results ?? [], (result) => result.category ?? ALL_CATEGORY),
    [props.results],
  );
  const resultsTab = useMemo(
    () => (tabId === ALL_CATEGORY ? props.results : resultsGroups[tabId]),
    [tabId, props.results, resultsGroups],
  );
  const isNoResults =
    resultsTab?.length === 0 || props.results?.length === 0 || (!!props.results && !resultsTab);
  const hasResults = !!resultsTab && resultsTab?.length > 0;

  const asTabId = useStableCallback((tab: string) => `${prefix}${tab}`);
  const asTab = useStableCallback((tabId: string) => tabId.replace(prefix, ''));
  const onTabChange = useStableCallback((tab?: string | null) =>
    setTabId(asTab(tab ?? ALL_CATEGORY)),
  );
  const onSearch = useStableCallback(props.onSearch);
  const onSelect = useStableCallback(props.onSelect);
  const onSelectionUpdate = useStableCallback(props.onSelectionUpdate);

  const resultsMap = useMemo(
    () => Object.fromEntries(props.results?.map((result) => [result.id, result]) ?? []),
    [props.results],
  );

  const selectedResultsMap = useMemo(
    () => Object.fromEntries(props.selectedResults?.map((result) => [result.id, result]) ?? []),
    [props.selectedResults],
  );
  const selectedValue = useMemo(
    () => props.selectedResults?.map((result) => result.id),
    [props.selectedResults],
  );

  const onSearchChange = useStableCallback((value: string) => {
    if (!resultsMap[value]) {
      setSearch(value);
    }
  });

  const onSelectedValue = useStableCallback((value: string | string[]) => {
    if (Array.isArray(value)) {
      const results = value
        .map((option) => resultsMap[option] ?? selectedResultsMap[option])
        .filter(isNonNullable);
      onSelectionUpdate(results);
    } else {
      const result = resultsMap[value];

      if (result) {
        onSelect(result);
      }
    }
  });

  const onLoadMore = useStableCallback(() => {
    onSearch?.({
      search,
      category: tabId !== ALL_CATEGORY ? tabId : undefined,
    });
  });

  const context: GlobalSearchContextValue<TResult> = useMemo(
    () => ({
      ...props,
      prefix,
      resultsGroups,
      resultsTab,
      tabs,
      tabId: asTabId(tabId),
      isNoResults,
      hasResults,
      onLoadMore,
      onSearch,
      onResultSelect: onSelect,
    }),
    [
      hasResults,
      isNoResults,
      prefix,
      props,
      resultsGroups,
      resultsTab,
      tabId,
      tabs,
      asTabId,
      onLoadMore,
      onSelect,
      onSearch,
    ],
  );

  useEffect(() => onLoadMore(), [onLoadMore, search, tabId]);

  return (
    <GlobalSearchContext.Provider value={context as unknown as GlobalSearchContextValue}>
      <ComboboxProvider
        disclosure={dialogStore}
        selectedValue={selectedValue}
        defaultSelectedValue={props.onSelectionUpdate ? [] : undefined}
        setSelectedValue={onSelectedValue}
        value={search}
        setValue={onSearchChange}
        resetValueOnHide={false}
      >
        <TabProvider selectedId={asTabId(tabId)} setSelectedId={onTabChange}>
          {children}
        </TabProvider>
      </ComboboxProvider>
    </GlobalSearchContext.Provider>
  );
}
