import { isNonNullable } from '@main/shared/utils';

import {
  AnyDynamicFormComponentRegistry,
  DynamicFormFieldConfig,
  WithDynamicFormFieldConfigKind,
} from '../field-registry';
import { BaseDynamicFormFieldConfig } from '../types';

export function resolveReadableValues<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TConfig extends BaseDynamicFormFieldConfig &
    WithDynamicFormFieldConfigKind<string> = DynamicFormFieldConfig<TRegistry>,
>(registry: TRegistry, config: TConfig, defaultValues: unknown[]): string[] {
  const component = registry.get(config.kind);

  return (
    component.resolveReadableValues?.({ config }) ??
    defaultValues.flat().filter(isNonNullable).map(String)
  );
}
