import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { LazyQueryTrigger, UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect, useRef, useState } from 'react';

import {
  AppGlobalSearchResult,
  useGlobalSearch,
  UseGlobalSearchProps,
} from '../global-search/use-global-search';
import { EntitySearchProps } from './entity-search';
import { EntitySearchResultCard } from './entity-search-result-card';

type LazyQuery<TArgs, TResponse> = UseLazyQuery<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  QueryDefinition<TArgs, any, never, TResponse, 'api'>
>;

type QueryTrigger<TArgs, TResponse> = LazyQueryTrigger<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  QueryDefinition<TArgs, any, never, TResponse, 'api'>
>;

interface UseEntitySearchProps<TArgs, TResponse> extends UseGlobalSearchProps {
  useLazyRecommendationQuery?: LazyQuery<TArgs, TResponse>;
  recommendationQueryCallback?: (
    queryTrigger: QueryTrigger<TArgs, TResponse>,
  ) => Promise<AppGlobalSearchResult[] | undefined>;
}
export function useEntitySearch<TArgs, TResponse>({
  useLazyRecommendationQuery,
  recommendationQueryCallback,
  ...props
}: UseEntitySearchProps<TArgs, TResponse>): EntitySearchProps {
  const globalSearch = useGlobalSearch(props);
  const [selectedEntities, setSelectedEntities] = useState<AppGlobalSearchResult[]>([]);
  const [recommendations, setRecommendations] = useState<AppGlobalSearchResult[]>();
  const [queryTrigger, queryState] = useLazyRecommendationQuery?.() ?? [];
  const recommendationQueryCallbackRef = useRef(recommendationQueryCallback);
  recommendationQueryCallbackRef.current = recommendationQueryCallback;

  useEffect(() => {
    setSelectedEntities([]);
    if (!globalSearch.disclosure.isOpen) {
      setRecommendations(undefined);
    }
  }, [globalSearch.disclosure.isOpen]);
  useEffect(() => {
    async function fetchAiRecommendations() {
      if (
        recommendationQueryCallbackRef.current &&
        globalSearch.disclosure.isOpen &&
        queryTrigger
      ) {
        const results = await recommendationQueryCallbackRef.current(queryTrigger);
        setRecommendations(results);
      }
    }
    fetchAiRecommendations();
  }, [globalSearch.disclosure.isOpen, queryTrigger]);

  function onSelectionUpdate(results: AppGlobalSearchResult[]) {
    setSelectedEntities(results);
  }

  const hasRecommendation = !!recommendations && !globalSearch.provider.results;

  return {
    ...globalSearch,
    provider: {
      ...globalSearch.provider,
      results:
        hasRecommendation && recommendations?.length
          ? recommendations
          : globalSearch.provider.results,
      onSelectionUpdate,
      selectedResults: selectedEntities,
      /* We don't want to preserve previous state when modal is re-opened */
      initialSearch: undefined,
    },
    results: {
      ...globalSearch.results,
      renderResult: EntitySearchResultCard,
      hasRecommendation,
      isRecommendationLoading: queryState?.isLoading,
    },
    selectedEntities,
  };
}
