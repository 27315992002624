import { HStack, IconButton, Link, Tooltip, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useUnlinkControlAndEvidenceMutation } from '@main/graphql/mutations/UnlinkControlAndEvidence.generated';
import { useUpdateEvidenceByIdMutation } from '@main/graphql/mutations/UpdateEvidenceById.generated';
import { useUpdateEvidenceVersionByIdMutation } from '@main/graphql/mutations/UpdateEvidenceVersionById.generated';
import { toError } from '@main/shared/utils';
import {
  actionHelper,
  createColumnHelper,
  errorToast,
  Table,
  useAlertDialog,
  useDownloadStorageFile,
  useDrawer,
} from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../../utils/constants';
import { dateToMidnightUTC } from '../../../utils/date';
import { CreateEvidenceVersionModal } from '../../evidence/evidence-versions';
import {
  useLazyIsUserAuthorizedToChangeEvidence,
  useLazyIsUserAuthorizedToViewVersions,
} from '../../evidence/utils';
import { CONTROL_EVIDENCE_STATUS_COLOR } from '../../shared/status-color';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { api as getControlApi } from '../get-control.generated';
import { ControlEvidence, getMappedControlEvidences } from '../slice';

export const ControlEvidenceTable = ({
  isLoading,
  controlId,
}: {
  isLoading: boolean;
  controlId: string;
}) => {
  const { t } = useTranslation();

  const data = useAppSelector((state) => getMappedControlEvidences(state, controlId));
  const columns = useEvidenceTableColumns(controlId);
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.evidence').toLowerCase(),
      plural: t('entities.evidence').toLowerCase(),
    };
  }, [t]);

  return <Table data={data} isLoading={isLoading} columns={columns} itemName={tableItemName} />;
};

function useEvidenceTableColumns(controlId: string) {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const dispatch = useAppDispatch();
  const drawer = useDrawer();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkEvidence = userRole.permissionMap?.link_controls_evidence;
  const [unlinkEvidenceFromControl] = useUnlinkControlAndEvidenceMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentEvidenceId, setCurrentEvidenceId] = useState<string | undefined>();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const checkAuthorizationToViewVersions = useLazyIsUserAuthorizedToViewVersions();
  const checkAuthorizationToChangeEvidence = useLazyIsUserAuthorizedToChangeEvidence();

  const [updateEvidenceVersion] = useUpdateEvidenceVersionByIdMutation();
  const updateEvidenceName = useUpdateEvidenceName(controlId);

  const onDelete = useCallback(
    async (controlEvidenceId: string) => {
      try {
        await unlinkEvidenceFromControl({ controlEvidenceId }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error('Evidence delete failed', {}, toError(error));
      }
    },
    [unlinkEvidenceFromControl, t],
  );

  const onValidityDateUpdate = useCallback(
    async (controlEvidence: ControlEvidence, validityDate: string) => {
      const adjustedValidityStart = dateToMidnightUTC(validityDate);

      try {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            for (const { evidence } of draft.controls_by_pk?.control_evidences ?? []) {
              if (evidence.id === controlEvidence.id) {
                evidence.evidence_versions[0] &&
                  (evidence.evidence_versions[0].validity_start = adjustedValidityStart);
              }
            }
          }),
        );

        await updateEvidenceVersion({
          id: controlEvidence.currentVersion?.id ?? '',
          updatePayload: {
            validity_start: adjustedValidityStart,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error('Evidence update failed', {}, toError(error));
      }
    },
    [dispatch, controlId, updateEvidenceVersion, t],
  );

  const onEvidenceOpen = useCallback(
    (evidenceId: string) => {
      drawer.open({ entity: 'evidence', entityId: evidenceId });
    },
    [drawer],
  );

  const downloadStorageFile = useDownloadStorageFile();

  return useMemo(() => {
    const columnHelper = createColumnHelper<ControlEvidence>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('evidences.table.columns.status'),
        accessorFn: ({ status }) => {
          return {
            value: status,
            label: t(`evidences.status.${status}`),
            colorScheme: CONTROL_EVIDENCE_STATUS_COLOR[status],
          };
        },
        size: 120,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('evidences.table.columns.name'),
        accessorFn: ({ name }) => name,
        minSize: 250,
        meta: {
          cell: { onClick: (cell) => onEvidenceOpen(cell.row.original.id) },
        },
        edit: {
          onChange: (row, name) => updateEvidenceName(row.original.id, name),
          canEditGuard: (row) => checkAuthorizationToChangeEvidence(row.original),
        },
        enableHiding: false,
      }),

      columnHelper.columns.date({
        id: 'validityStart',
        header: t('evidences.table.columns.currentValidityStart'),
        accessorFn: (row) => {
          if (checkAuthorizationToViewVersions(row)) {
            return row.currentVersion?.validityStart
              ? row.currentVersion?.validityStart
              : t('evidences.version.noVersionAttached');
          } else {
            return t('evidences.confidential.columnPlaceholder');
          }
        },
        edit: {
          onChange: (row, value) => onValidityDateUpdate(row.original, value),
          canEditGuard: (row) =>
            checkAuthorizationToChangeEvidence(row.original) &&
            !!row.original.currentVersion?.validityStart,
        },
        size: 160,
        meta: {
          cell: {
            fontSize: 'xs',
            color: 'gray.500',
          },
        },
      }),

      columnHelper.columns.avatar({
        id: 'owner',
        header: t('evidences.table.columns.owner'),
        accessorFn: ({ owner_id }) => {
          if (!owner_id) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[owner_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),

      columnHelper.columns.actions({
        size: 90,
        PrimaryAction: ({ row }) => {
          const isUserAuthorizedToReplaceVersion = checkAuthorizationToChangeEvidence(row.original);

          if (!row.original.currentVersion) {
            return;
          }

          const { fileId, url } = row.original.currentVersion;

          return (
            <HStack spacing={2}>
              {isUserAuthorizedToReplaceVersion && (
                <Tooltip label={t('evidences.tooltip.newVersionBtn')} placement="top">
                  <IconButton
                    minW={4}
                    aria-label="Plus"
                    variant="link"
                    icon={<PlusIcon />}
                    onClick={() => {
                      setCurrentEvidenceId(row.original.id);
                      onOpen();
                    }}
                  />
                </Tooltip>
              )}
              <CreateEvidenceVersionModal
                evidenceId={row.original.id}
                isOpen={row.original.id === currentEvidenceId && isOpen}
                onCreate={() => {
                  onClose();
                }}
                onClose={() => {
                  setCurrentEvidenceId(undefined);
                  onClose();
                }}
              />
              {fileId && (
                <Tooltip label={t('evidences.tooltip.downloadBtn')} placement="top">
                  <IconButton
                    minW={4}
                    aria-label="Download"
                    variant="link"
                    icon={<ArrowDownTrayIcon />}
                    onClick={() => downloadStorageFile(fileId)}
                  />
                </Tooltip>
              )}
              {url && (
                <Tooltip label={t('evidences.tooltip.linkBtn')}>
                  <Link href={url} isExternal>
                    <IconButton
                      minW={5}
                      aria-label="Link"
                      variant="link"
                      icon={<ArrowTopRightOnSquareIcon />}
                    />
                  </Link>
                </Tooltip>
              )}
            </HStack>
          );
        },

        ...(canLinkEvidence
          ? {
              menuActions: ({ row }) => [
                actionHelper.menuActionItem({
                  icon: UnlinkIcon,
                  label: t('buttons.unlink'),
                  onClick: () =>
                    openDialog({
                      dialogHeader: t('evidences.alert.unlink.header'),
                      dialogContent: t('evidences.alert.unlink.content'),
                      confirmAction: {
                        onClick: () => onDelete(row.original.controlEvidenceId),
                      },
                    }),
                }),
              ],
            }
          : {}),
      }),
    ];
  }, [
    t,
    canLinkEvidence,
    onEvidenceOpen,
    updateEvidenceName,
    checkAuthorizationToChangeEvidence,
    checkAuthorizationToViewVersions,
    onValidityDateUpdate,
    currentOrgUsers,
    currentEvidenceId,
    isOpen,
    onOpen,
    onClose,
    downloadStorageFile,
    openDialog,
    onDelete,
  ]);
}

const useUpdateEvidenceName = (controlId: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [updateEvidence] = useUpdateEvidenceByIdMutation();

  return useCallback(
    async (evidenceId: string, value: string) => {
      try {
        /* Optimistic update */
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            for (const { evidence } of draft.controls_by_pk?.control_evidences ?? []) {
              if (evidence.id === evidenceId) {
                evidence.name = value;
              }
            }
          }),
        );

        await updateEvidence({
          id: evidenceId,
          updatePayload: {
            name: value,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error('Evidence name update failed', { evidenceId }, toError(error));
      }
    },
    [controlId, dispatch, t, updateEvidence],
  );
};
