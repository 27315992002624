import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { Table, useTableFiltersQuery, useTableSearchQuery, useTableSortQuery } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { customFieldColumn } from '../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { TASKS_TABLE_FILTER_PARAM_NAME, TASKS_TABLE_SORT_PARAM_NAME } from './constants';
import { useTaskColumnHelper } from './table-columns';
import { useTasksTableSettings } from './table-settings';
import { TaskTableFragmentFragment } from './tasks.fragment.generated';
import { useGetTasksSubscription } from './tasks-subscription';

export const TasksTable = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isLoading: isTaskLoading } = useGetTasksSubscription({
    where: {
      organization_id: {
        _eq: orgId,
      },
    },
  });
  const columns = useTasksTableColumns();
  const {
    columnVisibility,
    setColumnVisibility,
    defaultColumnVisibility,
    isColumnVisibilityModified,
  } = useTasksTableSettings('tasks');
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: TASKS_TABLE_FILTER_PARAM_NAME,
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: TASKS_TABLE_SORT_PARAM_NAME });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="1000px"
      entity="task"
      data={data?.tasks ?? []}
      isLoading={isTaskLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      sorting={sorting}
      onSortingChange={setSorting}
      defaultColumnVisibility={defaultColumnVisibility}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      hasColumnVisibilityChanged={isColumnVisibilityModified}
    />
  );
};

type Task = TaskTableFragmentFragment;

function useTasksTableColumns() {
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Task,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  const taskColumnHelper = useTaskColumnHelper();

  return useMemo(() => {
    return [
      taskColumnHelper.status({
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.name({
        enableGlobalFilter: true,
        enableSorting: true,
        isEditable: true,
      }),
      taskColumnHelper.dueDate({
        enableSorting: true,
        isEditable: true,
      }),
      taskColumnHelper.completedDate({
        enableSorting: true,
      }),
      taskColumnHelper.createdBy({
        enableColumnFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.createdAt({
        enableColumnFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.owner({
        enableColumnFilter: true,
        enableSorting: true,
        isEditable: true,
      }),

      ...(fieldConfigs || []).map(customFieldColumn<Task>),

      taskColumnHelper.actions(),
    ];
  }, [taskColumnHelper, fieldConfigs]);
}
