import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { NoPermissionPlaceholder, Table } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CSVInput } from '../../../utils/export-csv';
import { useGetOrganizationControls } from '../../controls/org-controls-query';
import { useControlsTableSettings } from '../../controls/table-settings';
import { OrganizationControl, useControlsTableColumns } from '../../controls/use-table-columns';
import { serializeCustomFields } from '../../custom-fields/custom-field';
import { useGetFieldConfigsQuery } from '../../custom-fields/field-config';
import {
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../../user/slice';
import { useUpdateReportHandler } from '../use-update-report-handler';
import { ReportCardBase } from './report-card-base';

export const ControlsReportCard = ({
  reportId,
  onExportCSV,
}: {
  reportId: string;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewControls = userRole.permissionMap?.read_controls;

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);

  const controlsQuery = useGetOrganizationControls(organizationId);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Control,
  });

  const {
    columnVisibility,
    setColumnVisibility,
    defaultColumnVisibility,
    isColumnVisibilityModified,
  } = useControlsTableSettings(`reports:${reportId}:controls`);
  const columns = useControlsTableColumns({
    page: 'reports-page',
  });
  const {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } = useUpdateReportHandler({ columns });
  const [filteredRows, setFilteredRows] = useState<OrganizationControl[]>([]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.control').toLowerCase(),
      plural: t('entities.plural.controls').toLowerCase(),
    };
  }, [t]);

  const handleExportCSV = () => {
    const fieldConfigs = fieldConfigsQuery.data?.field_configs || [];
    const filteredData = filteredRows.map((row) => ({
      Name: row.name,
      Description: row.description,
      Status: row.status,
      Group: row.groups.map((item) => item.group.name),
      Category: row.categories.map((item) => item.category.name),
      Criteria: row.criterias.map((item) => item.criteria.name),
      Tags: row.custom_tags.map((item) => item.tag.name),
      Frequency: row.frequency,
      Priority: row.priority,
      Assignee: currentOrgUsersById[row?.assignee_id ?? '']?.displayName,
      'Created by': currentOrgUsersById[row?.created_by ?? '']?.displayName,
      'Created date': row.created_at,
      Programs: row.programs.map((program) => program.name),
      ...serializeCustomFields(fieldConfigs, row.field_values),
    }));

    onExportCSV(filteredData);
  };

  if (!canViewControls) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <ReportCardBase
      heading={t('entities.plural.controls')}
      subheading={t('controls.subheading.reports')}
      onExportCSV={handleExportCSV}
    >
      <Table
        id={reportId}
        minW="900px"
        entity="control"
        data={controlsQuery.controls}
        isLoading={controlsQuery.isLoading}
        columns={columns}
        itemName={tableItemName}
        pageSize={15}
        defaultColumnVisibility={defaultColumnVisibility}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={setColumnVisibility}
        hasColumnVisibilityChanged={isColumnVisibilityModified}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        onFilteredDataChange={setFilteredRows}
        sorting={sorting}
        onSortingChange={setSorting}
      />
    </ReportCardBase>
  );
};
