import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Finding_Resolution_Types_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';

const formSchema = z.object({
  comment: z.string().trim(),
});

type FormData = z.infer<typeof formSchema>;

export type ResolutionActionType = Finding_Resolution_Types_Enum | 'Edit';

interface ActionModalProps {
  actionType: ResolutionActionType;
  onClose: () => void;
  onSubmit: (data: FormData) => Promise<void>;
  defaultValues?: FormData;
}

export const FindingActionModal = ({
  actionType,
  onClose,
  onSubmit,
  defaultValues,
}: ActionModalProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues ?? { comment: '' },
  });

  const handleFormSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await onSubmit(data);
      reset();
      onClose();
      successToast(t(`findings.actionModal.successToast.${actionType}`));
    } catch (error) {
      datadogLogs.logger.error(
        'Failed to submit finding resolution reason',
        { actionType },
        toError(error),
      );
      errorToast(t(`findings.actionModal.errorToast.${actionType}`));
    }
  };

  return (
    <Modal size="xl" isOpen={true} onClose={onClose} isCentered motionPreset="slideInBottom">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`findings.actionModal.header.${actionType}`)}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalBody pb={6}>
            <FormControl isInvalid={!!errors.comment}>
              <FormLabel display="flex" gap="1">
                <Trans
                  i18nKey="findings.actionModal.reasonInputLabel"
                  components={{
                    sub: <Text color="gray.500" fontSize="xs" fontWeight="medium" />,
                  }}
                />
              </FormLabel>
              <Textarea
                {...register('comment')}
                placeholder={t('findings.actionModal.reasonInputPlaceholder')}
              />
              <FormErrorMessage>{t('findings.actionModal.reasonInputError')}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              {t('buttons.cancel')}
            </Button>
            <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
              {t(`findings.actionModal.button.${actionType}`)}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
