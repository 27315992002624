/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateEvidenceTaskMutationVariables = Types.Exact<{
  input: Types.Evidence_Tasks_Insert_Input;
}>;

export type CreateEvidenceTaskMutation = {
  __typename?: 'mutation_root';
  insert_evidence_tasks_one?: { __typename?: 'evidence_tasks'; task_id: string };
};

export type UnlinkEvidenceTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  evidenceId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkEvidenceTaskMutation = {
  __typename?: 'mutation_root';
  delete_evidence_tasks?: {
    __typename?: 'evidence_tasks_mutation_response';
    affected_rows: number;
  };
};

export const CreateEvidenceTaskDocument = `
    mutation CreateEvidenceTask($input: evidence_tasks_insert_input!) {
  insert_evidence_tasks_one(object: $input) {
    task_id
  }
}
    ` as string &
  TypedDocumentNode<CreateEvidenceTaskMutation, CreateEvidenceTaskMutationVariables>;
export const UnlinkEvidenceTaskDocument = `
    mutation UnlinkEvidenceTask($taskId: uuid!, $evidenceId: uuid!) {
  delete_evidence_tasks(
    where: {task_id: {_eq: $taskId}, evidence_id: {_eq: $evidenceId}}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<UnlinkEvidenceTaskMutation, UnlinkEvidenceTaskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateEvidenceTask: build.mutation<
      CreateEvidenceTaskMutation,
      CreateEvidenceTaskMutationVariables
    >({
      query: (variables) => ({ document: CreateEvidenceTaskDocument, variables }),
    }),
    UnlinkEvidenceTask: build.mutation<
      UnlinkEvidenceTaskMutation,
      UnlinkEvidenceTaskMutationVariables
    >({
      query: (variables) => ({ document: UnlinkEvidenceTaskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateEvidenceTaskMutation, useUnlinkEvidenceTaskMutation } = injectedRtkApi;
