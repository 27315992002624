import { Field_Entities_Enum } from '@main/graphql/types.generated';

import { useCustomFieldsTableSettings } from '../../utils/table-settings';

const defaultColumnVisibility = {
  internalId: false,
  description: false,
  type: false,
  versionId: false,
  createdAt: false,
  createdBy: false,
};

export function usePoliciesTableSettings(tableId: string) {
  return useCustomFieldsTableSettings({
    entityName: Field_Entities_Enum.Policy,
    tableId,
    defaultColumnVisibility,
  });
}
