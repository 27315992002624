/* eslint-disable react-hooks/rules-of-hooks */
import { zodResolver } from '@hookform/resolvers/zod';
import { QuestionnaireComponentKind } from '@main/dynamic-form';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
  baseFieldSchema,
  fieldValidationRefinement,
  FormFieldConfigurator,
  useRegisterFormFieldConfigurator,
} from './field-configurator';

const formSchema = baseFieldSchema.superRefine(fieldValidationRefinement);

export type FileFieldForm = z.infer<typeof formSchema>;

export class FileFieldConfigurator implements FormFieldConfigurator<FileFieldForm> {
  static instance = new FileFieldConfigurator();

  static Register() {
    useRegisterFormFieldConfigurator(
      QuestionnaireComponentKind.File,
      FileFieldConfigurator.instance,
    );

    return null;
  }

  kind = QuestionnaireComponentKind.File;
  form!: UseFormReturn<FileFieldForm>;

  useForm(config?: QuestionnaireFormFieldConfig): UseFormReturn<FileFieldForm> {
    this.form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
        ...config,
        label: config?.label ?? '',
        hasAiReview: config?.hasAiReview ?? false,
      } as FileFieldForm,
    });

    return this.form;
  }

  renderName() {
    const { t } = useTranslation();
    return t('form.fields.type.values.file');
  }

  renderForm() {
    return null;
  }

  renderAfterForm() {
    return null;
  }
}
