/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddPermissionsForRoleMutationVariables = Types.Exact<{
  role_permissions:
    | Array<Types.Role_Permissions_Insert_Input>
    | Types.Role_Permissions_Insert_Input;
}>;

export type AddPermissionsForRoleMutation = {
  __typename?: 'mutation_root';
  insert_role_permissions?: {
    __typename?: 'role_permissions_mutation_response';
    returning: Array<{ __typename?: 'role_permissions'; id: string }>;
  };
};

export const AddPermissionsForRoleDocument = `
    mutation AddPermissionsForRole($role_permissions: [role_permissions_insert_input!]!) {
  insert_role_permissions(
    objects: $role_permissions
    on_conflict: {constraint: role_permissions_role_id_permission_key, update_columns: []}
  ) {
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<AddPermissionsForRoleMutation, AddPermissionsForRoleMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddPermissionsForRole: build.mutation<
      AddPermissionsForRoleMutation,
      AddPermissionsForRoleMutationVariables
    >({
      query: (variables) => ({ document: AddPermissionsForRoleDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddPermissionsForRoleMutation } = injectedRtkApi;
