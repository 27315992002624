import { datadogLogs } from '@datadog/browser-logs';
import { CheckCircleIcon, ClockIcon, PlayCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { isNonNullable, toError, useStableCallback } from '@main/shared/utils';
import { actionHelper, errorToast, successToast } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteTask } from './delete-task';
import { useUpdateTaskByIdMutation } from './manage-tasks.generated';
import { getMappedTask } from './slice';

export const useTaskDrawerActions = (taskId: string) => {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const markAsComplete = useMarkAsCompleteAction(taskId);
  const markAsPending = useMarkAsPendingAction(taskId);
  const markAsInProgressOrPending = useLazyInProgressOrPendingToggleMenuAction()({
    taskId,
    taskStatus: task?.status,
  });
  const deleteTask = useDeleteTaskAction(taskId);

  return actionHelper.drawerToolbarActions({
    primaryAction: markAsComplete,
    secondaryActions: [markAsPending].filter(isNonNullable),
    menuActions: [markAsInProgressOrPending, deleteTask].filter(isNonNullable),
  });
};

function useMarkAsCompleteAction(taskId: string) {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const action = useLazyPendingOrCompleteToggleAction()({
    taskId,
    completedAt: task?.completed_at,
  });

  if (task?.completed_at || task?.completed_at === undefined || !action) {
    return;
  }

  return actionHelper.primaryAction({ ...action });
}

function useMarkAsPendingAction(taskId: string) {
  const task = useAppSelector((state) => getMappedTask(state, taskId));
  const action = useLazyPendingOrCompleteToggleAction()({
    taskId,
    completedAt: task?.completed_at,
  });

  if (!task?.completed_at || !action) {
    return;
  }

  return actionHelper.secondaryAction({
    ...action,
  });
}

type CompletedActionInput = {
  taskId: string;
  completedAt?: string;
};

export function useLazyPendingOrCompleteToggleAction() {
  const { t } = useTranslation();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const [updateTask, { isLoading }] = useUpdateTaskByIdMutation();
  const canEditTask = permissionMap.write_tasks;

  return useStableCallback(({ taskId, completedAt }: CompletedActionInput) => {
    if (!canEditTask) {
      return undefined;
    }

    if (completedAt) {
      return actionHelper.menuActionItem({
        isLoading,
        icon: ClockIcon,
        label: t('tasks.markAsPending'),
        onClick: async () => {
          try {
            await updateTask({
              taskId,
              updatePayload: {
                completed_at: null,
              },
            }).unwrap();

            successToast(t('tasks.toasts.pending'));
          } catch (error) {
            errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
            datadogLogs.logger.error('Task update failed', {}, toError(error));
          }
        },
      });
    }

    return actionHelper.menuActionItem({
      isLoading,
      icon: CheckCircleIcon,
      label: t('tasks.markAsComplete'),
      onClick: async () => {
        try {
          await updateTask({
            taskId,
            updatePayload: {
              completed_at: 'now()',
            },
          }).unwrap();

          successToast(t('tasks.toasts.complete'));
        } catch (error) {
          errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
          datadogLogs.logger.error('Task update failed', {}, toError(error));
        }
      },
    });
  });
}

type InProgressActionInput = {
  taskId: string;
  taskStatus?: TaskStatus;
};
export function useLazyInProgressOrPendingToggleMenuAction() {
  const { t } = useTranslation();
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const [updateTask, { isLoading }] = useUpdateTaskByIdMutation();
  const canEditTask = permissionMap.write_tasks;

  return useStableCallback(({ taskId, taskStatus }: InProgressActionInput) => {
    if (
      !canEditTask ||
      (taskStatus !== TaskStatus.PENDING && taskStatus !== TaskStatus.IN_PROGRESS)
    ) {
      return undefined;
    }

    if (taskStatus === TaskStatus.PENDING) {
      return actionHelper.menuActionItem({
        icon: PlayCircleIcon,
        isLoading,
        label: t('tasks.markAsInProgress'),
        onClick: async () => {
          try {
            await updateTask({
              taskId,
              updatePayload: { status: TaskStatus.IN_PROGRESS, completed_at: null },
            }).unwrap();

            successToast(t('tasks.toasts.inProgress'));
          } catch (error) {
            errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
            datadogLogs.logger.error('Task update failed', {}, toError(error));
          }
        },
      });
    }

    return actionHelper.menuActionItem({
      icon: ClockIcon,
      isLoading,
      label: t('tasks.markAsPending'),
      onClick: async () => {
        try {
          await updateTask({
            taskId,
            updatePayload: { status: TaskStatus.PENDING },
          }).unwrap();

          successToast(t('tasks.toasts.pending'));
        } catch (error) {
          errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
          datadogLogs.logger.error('Task update failed', {}, toError(error));
        }
      },
    });
  });
}

function useDeleteTaskAction(taskId: string) {
  const { t } = useTranslation();
  const deleteTask = useDeleteTask();

  return useMemo(() => {
    if (!deleteTask) {
      return;
    }

    return actionHelper.menuActionItem({
      icon: TrashIcon,
      label: t('buttons.delete'),
      onClick: () => {
        deleteTask(taskId);
      },
    });
  }, [deleteTask, t, taskId]);
}
