/* eslint-disable react-hooks/rules-of-hooks */
import { LinkIcon } from '@heroicons/react/24/outline';
import {
  QuestionnaireFieldFilesContext,
  QuestionnnaireForm,
  QuestionnnaireFormProps,
} from '@main/questionnaires-form';
import { FileInputProps, UploadedFile, useStatefulCallback } from '@main/ui';
import { UploadFileCardActionsFactory } from '@main/ui/cards';
import { CardAction } from '@main/ui/cards/base-card';
import { UnlinkIcon } from '@main/ui/icons';
import { useStableCallback } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'vitest';

import { useVendorDocuments } from '../use-vendor-documents';
import { VendorQuestionnaireDocumentFragment } from './vq-form.generated';

export interface VendorQuestionnaireFormProps extends QuestionnnaireFormProps {
  vendorId: string;
  documents?: VendorQuestionnaireDocumentFragment[];
  onDocumentsChange?: () => void;
}

export function VendorQuestionnaireForm({
  vendorId,
  documents,
  onDocumentsChange,
  ...form
}: VendorQuestionnaireFormProps) {
  const { onLinkDocument, onUnlinkDocument } = useVendorDocuments(
    vendorId,
    documents,
    form.answers,
  );
  const handleLinkDocument = useStableCallback(async (file: UploadedFile) => {
    await onLinkDocument(file);
    onDocumentsChange?.();
  });
  const handleUnlinkDocument = useStableCallback(async (file: UploadedFile) => {
    await onUnlinkDocument(file);
    onDocumentsChange?.();
  });

  const filesProps: FileInputProps = useMemo(
    () => ({
      actions: documents
        ? (documentFileActions(
            documents,
            handleLinkDocument,
            handleUnlinkDocument,
          ) as UploadFileCardActionsFactory)
        : undefined,
    }),
    [documents, handleLinkDocument, handleUnlinkDocument],
  );

  return (
    <QuestionnaireFieldFilesContext.Provider value={filesProps}>
      <QuestionnnaireForm {...form} />
    </QuestionnaireFieldFilesContext.Provider>
  );
}

function documentFileActions(
  documents: VendorQuestionnaireDocumentFragment[],
  onLinkDocument: (file: UploadedFile) => Promise<void>,
  onUnlinkDocument: (file: UploadedFile) => Promise<void>,
) {
  return (actions: Nullable<CardAction>[], file: UploadedFile): Nullable<CardAction>[] => {
    const { t } = useTranslation();

    const linkedDocument = useMemo(
      () => documents.find((doc) => doc.file?.id === file.id),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [file, documents],
    );
    const isLinked = !!linkedDocument?.vq_upload_id;

    const unlinkDocument = useStatefulCallback(() => onUnlinkDocument(file));
    const linkDocument = useStatefulCallback(() => onLinkDocument(file));

    return useMemo(
      () => [
        isLinked
          ? {
              label: t('vendors.questionnaires.tabs.submission.unlinkFromRecord'),
              icon: UnlinkIcon,
              onClick: unlinkDocument.callback,
              isLoading: unlinkDocument.isLoading,
              isRead: true,
            }
          : {
              label: t('vendors.questionnaires.tabs.submission.linkToRecord'),
              icon: LinkIcon,
              onClick: linkDocument.callback,
              isLoading: linkDocument.isLoading,
              isRead: true,
            },
        ...actions,
      ],
      [actions, isLinked, linkDocument, t, unlinkDocument],
    );
  };
}
