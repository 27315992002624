import {
  Alert,
  AlertIcon,
  Flex,
  Icon,
  ListItem,
  Switch,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useRemovePermissionFromRoleMutation } from '@main/graphql/mutations/RemovePermissionFromRole.generated';
import { useGetRoleByIdQuery } from '@main/graphql/queries/GetRoleById.generated';
import {
  api as getRolePermissionApi,
  useGetRolePermissionsQuery,
} from '@main/graphql/queries/GetRolePermissions.generated';
import { Permissions_Enum } from '@main/graphql/types.generated';
import { permissionDependencies, TranslatedPermission } from '@main/permissions';
import { toError } from '@main/shared/utils';
import { errorToast, GroupedTable } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { useAddPermissionsForRoleMutation } from './permissions.generated';
import { getPermissions } from './slice';

export const PermissionsTab = () => {
  return <PermissionsTable />;
};

const PermissionsTable = () => {
  const { t } = useTranslation();
  const params = useParams({ from: '/settings/organization/roles/$roleId' });
  const roleId = params.roleId;
  useGetRolePermissionsQuery({ roleId });
  const { data: role } = useGetRoleByIdQuery({ roleId });

  const permissions = useAppSelector((state) => getPermissions(state, t, roleId));
  const columns = usePermissionTableColumns({ roleId });

  return (
    <>
      {role?.roles_by_pk?.system_role && (
        <Alert status="warning" variant="left-accent" marginBottom={10}>
          <AlertIcon />
          {t('settings.organization.roles.systemRoleBanner')}
        </Alert>
      )}
      <GroupedTable
        minW="650px"
        data={permissions}
        columns={columns}
        entityName="permissions"
        groupBy="group"
      />
    </>
  );
};

const usePermissionTableColumns = ({ roleId }: { roleId: string }) => {
  const dispatch = useAppDispatch();
  const { refetch: refetchRolePermissions } = useGetRolePermissionsQuery({ roleId });
  const { data: role } = useGetRoleByIdQuery({ roleId });

  const [addPermissions] = useAddPermissionsForRoleMutation();
  const [removePermission] = useRemovePermissionFromRoleMutation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditRole = userRole.permissionMap?.write_roles;

  const disablePermission = useCallback(
    async (permissionId: string) => {
      dispatch(
        getRolePermissionApi.util.updateQueryData('GetRolePermissions', { roleId }, (draft) => {
          const updatedPermissions = draft.role_permissions.filter(
            (permission) => permission.id !== permissionId,
          );
          draft.role_permissions = updatedPermissions;
        }),
      );
      try {
        await removePermission({
          permissionId,
        }).unwrap();
        refetchRolePermissions();
      } catch (error) {
        refetchRolePermissions();
        errorToast(t('errorMessages.disableFailed', { entity: t('entities.permission') }));
        datadogLogs.logger.error(
          'Removing permission from role failed',
          { permissionId },
          toError(error),
        );
      }
    },
    [removePermission, refetchRolePermissions, dispatch, roleId],
  );

  const enablePermission = useCallback(
    async (permission: Permissions_Enum) => {
      const childrenPermissions = permissionDependencies[permission] || [];

      dispatch(
        getRolePermissionApi.util.updateQueryData('GetRolePermissions', { roleId }, (draft) => {
          draft.role_permissions.concat([
            {
              id: '',
              permission,
            },
            ...childrenPermissions.map((childPermission) => ({
              id: '',
              permission: childPermission,
            })),
          ]);
        }),
      );
      try {
        await addPermissions({
          role_permissions: [
            {
              permission,
              role_id: roleId,
            },
            ...childrenPermissions.map((childPermission) => ({
              permission: childPermission,
              role_id: roleId,
            })),
          ],
        }).unwrap();
        refetchRolePermissions();
      } catch (error) {
        refetchRolePermissions();
        errorToast(t('errorMessages.enableFailed', { entity: t('entities.permission') }));
        datadogLogs.logger.error(
          'Adding new permission to role failed',
          { roleId, permission },
          toError(error),
        );
      }
    },
    [dispatch, roleId, addPermissions, refetchRolePermissions],
  );

  return useMemo<ColumnDef<TranslatedPermission>[]>(
    () => [
      {
        id: 'name',
        accessorFn: ({ name }) => t(`permissions.names.${name}`),
        enableHiding: false,
        header: () => <Text>{t('permissions.table.columns.actions')}</Text>,
        cell: ({ row }) => {
          return (
            <Flex alignItems="center" gap="1">
              <Text>{t(`permissions.names.${row.original.name}`)}</Text>
              {row.original.hasTooltip && (
                <Tooltip
                  // we disabled this because we don't have tooltips for all permissions
                  label={t(`permissions.tooltips.${row.original.name}` as never)}
                  hasArrow
                  placement="right"
                >
                  <Icon as={InformationCircleIcon} w={4} h={4} />
                </Tooltip>
              )}
            </Flex>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        id: 'status',
        cell: ({ row }) => {
          const isSystemRole = !!role?.roles_by_pk?.system_role;
          const shouldDisableEditing = isSystemRole || !canEditRole;

          return (
            <Tooltip
              shouldWrapChildren
              hasArrow
              placement="right"
              isDisabled={shouldDisableEditing || !row.original.hasDependencies}
              label={
                <>
                  <Text>{t('permissions.disabledReasonPrefix')}</Text>
                  <UnorderedList>
                    {row.original.dependentPermissions.map((p, index) => (
                      <ListItem key={index}>
                        {t('permissions.permissionDepItem', {
                          permission: t(`permissions.names.${p}`),
                        })}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </>
              }
            >
              <Switch
                disabled={shouldDisableEditing || row.original.hasDependencies}
                isChecked={row.original.isActive}
                onChange={() =>
                  row.original.id
                    ? disablePermission(row.original.id)
                    : enablePermission(row.original.name)
                }
              ></Switch>
            </Tooltip>
          );
        },
        header: () => <Text>{t('permissions.table.columns.enabled')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'group',
        footer: (props) => props.column.id,
      },
    ],
    [canEditRole, disablePermission, enablePermission, role?.roles_by_pk?.system_role],
  );
};
