import { useNavigate, useSearch } from '@tanstack/react-router';
import { useEffect, useMemo, useRef } from 'react';

import { DrawerService } from './drawer-service';
import { DrawerCallbackData, DrawerObserver } from './types';

export function useDrawer() {
  const navigate = useNavigate();
  const observer = useRef<DrawerObserver>();

  const service = useMemo(
    () =>
      new DrawerService({
        search: (params) => navigate({ search: (oldParams) => ({ ...oldParams, ...params }) }),
        searchReplace: (params) =>
          navigate({ search: (oldParams) => ({ ...oldParams, ...params }), replace: true }),
        getDrawerObserver: (_observer) => (observer.current = _observer),
      }),
    [navigate],
  );

  const { entity, entityId } = useSearch({
    from: '' as never,
    select(search): DrawerCallbackData {
      const ns = service.getNamespace();
      return {
        entity: search[ns.entity],
        entityId: search[ns.entityId],
      };
    },
  });

  useEffect(() => {
    observer.current?.(entity ? { entity, entityId } : undefined);
  }, [entity, entityId]);

  return service;
}
