import {
  DynamicFormComponentRegistry,
  EditableTextareaField,
  OptionsField,
  QuestionnaireComponentKind,
  QuestionnaireFormComponentRegistry,
} from '@main/dynamic-form';

import { FileField } from './file';

let componentRegistry: QuestionnaireFormComponentRegistry | undefined;

/**
 * Lazily creates and returns the component registry for the questionnaire form.
 */
export function useQuestionnaireComponentRegistry() {
  if (componentRegistry === undefined) {
    componentRegistry = createComponentRegistry();
  }

  return { componentRegistry };
}

export function createComponentRegistry(): QuestionnaireFormComponentRegistry {
  return new DynamicFormComponentRegistry({
    [QuestionnaireComponentKind.Text]: EditableTextareaField,
    [QuestionnaireComponentKind.Options]: OptionsField,
    [QuestionnaireComponentKind.File]: FileField,
  });
}
