import {
  As,
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  forwardRef,
  Spacer,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { IconAction } from '../actions';
import { MenuActions } from '../actions/menu-actions';
import { BaseAction, IconActionProps, MenuActionItem } from '../actions/types';
import { useDrawer } from './use-drawer';

declare global {
  interface Window {
    navigation?: {
      canGoBack: boolean;
      canGoForward: boolean;
    };
  }
}

export type DrawerActionsProps = {
  primaryAction?: BaseAction | null;
  secondaryActions?: BaseAction[];
  iconActions?: IconActionProps[];
  menuActions?: MenuActionItem[];
};

export const DrawerActions = (props: DrawerActionsProps & { info?: string }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box
      role="toolbar"
      aria-label="Drawer actions"
      position="sticky"
      top={0}
      zIndex={1}
      bg={bgColor}
    >
      {isMobile ? <DrawerActionsMobile {...props} /> : <DrawerActionsDesktop {...props} />}
    </Box>
  );
};

const DrawerActionsMobile = (props: DrawerActionsProps) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Flex
      gap={3}
      py={3}
      pl={4}
      pr={5}
      borderBottom="1px"
      borderColor={borderColor}
      alignItems="center"
    >
      <CloseDrawerButton icon={ChevronLeftIcon} />
      <Spacer />
      <ToolbarActions {...props} />
    </Flex>
  );
};

const DrawerActionsDesktop = ({ info, ...actions }: DrawerActionsProps & { info?: string }) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const infoTextColor = useColorModeValue('gray.400', 'gray.600');

  return (
    <Flex
      height={12}
      justifyContent="space-between"
      borderBottom="1px"
      borderColor={borderColor}
      py={2}
      pl={4}
      pr={5}
    >
      <NavigationButtons />
      <Flex gap={3} alignItems="center">
        <Text fontSize="xs" color={infoTextColor}>
          {info}
        </Text>
        <ToolbarActions {...actions} />
        <CloseDrawerButton icon={XMarkIcon} />
      </Flex>
    </Flex>
  );
};

const CloseDrawerButton = ({ icon }: { icon: As }) => {
  const { t } = useTranslation('ui');
  const drawer = useDrawer();

  return (
    <IconAction tooltip={t('drawer.actions.close')} icon={icon} onClick={() => drawer.close()} />
  );
};

const NavigationButtons = () => {
  const { t } = useTranslation('ui');
  const router = useRouter();
  const canGoBack = window.navigation?.canGoBack ?? true;
  const canGoForward = window.navigation?.canGoForward ?? true;

  return (
    <Flex gap={3}>
      <IconAction
        tooltip={t('drawer.actions.back')}
        isDisabled={!canGoBack}
        icon={ArrowLeftIcon}
        onClick={() => router.history.back()}
      />
      <IconAction
        tooltip={t('drawer.actions.forward')}
        isDisabled={!canGoForward}
        icon={ArrowRightIcon}
        onClick={() => router.history.forward()}
      />
    </Flex>
  );
};

const ToolbarActions = ({
  primaryAction,
  secondaryActions,
  iconActions,
  menuActions,
}: DrawerActionsProps) => {
  return (
    <>
      <Flex gap={2} alignItems="center">
        {secondaryActions?.map((props) => (
          <ButtonAction key={props.label} {...props} variant="outline" />
        ))}
        {primaryAction && <ButtonAction {...primaryAction} colorScheme="blue" />}
      </Flex>
      {((secondaryActions && secondaryActions.length > 0) || primaryAction) && (
        <Box h={4} borderColor="gray.300">
          <Divider orientation="vertical" />
        </Box>
      )}
      {iconActions?.map((props) => <IconAction key={props.tooltip} {...props} />)}
      <MenuActions actions={menuActions} />
    </>
  );
};

type ActionButtonProps = BaseAction & ButtonProps;
const ButtonAction = forwardRef(({ label, ...props }: ActionButtonProps, ref) => {
  return (
    <Button ref={ref} aria-label={label} {...props} size="sm">
      {label}
    </Button>
  );
});
