import { FormControl, FormLabel } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  FileInputProps,
  FileUpload,
  UploadedFile,
  useDownloadStorageFile,
  useEagerMultipleFilesUpload,
} from '@main/ui';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  QuestionnaireFormFieldConfig,
  QuestionnnaireFieldWrapperProps,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
} from './form-types';

export interface QuestionnaireFieldFilesProps extends QuestionnnaireFieldWrapperProps {
  config: QuestionnaireFormFieldConfig;
  answer: QuestionnnaireFormFieldAnswer;
}

export const QuestionnaireFieldFilesContext = createContext<FileInputProps>({});

export function QuestionnaireFieldFiles(props: QuestionnaireFieldFilesProps) {
  const { t } = useTranslation();
  const filesProps = useContext(QuestionnaireFieldFilesContext);

  const isAnswerMode = props.form.mode === QuestionnnaireFormMode.Answer;
  const isCompleted =
    props.answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed;
  const isReadOnly = isCompleted || !isAnswerMode;

  const supportingFilesUpload = useEagerMultipleFilesUpload({
    files: props.answer.support_files
      ?.filter((upload) => !!upload.file?.id)
      .map((upload) => upload.file as UploadedFile),
    onFileDownload: useDownloadStorageFile(),
    onFilesUpload: isAnswerMode
      ? async ({ errors, files }) => {
          if (errors.length) {
            errorToast(
              t('errorMessages.uploadFailed', {
                entity: t('entities.vendorQuestionnaireSupportingFile'),
              }),
            );
            for (const error of errors) {
              datadogLogs.logger.error(
                'Failed uploading supporting files for vendor questionnaire answer',
                { answerId: props.answer.id },
                toError(error),
              );
            }
            return;
          }

          const support_files = [
            ...(props.answer.support_files ?? []),
            ...files.map((file) => ({ file_id: file.id })),
          ];
          const answerData: Partial<QuestionnnaireFormFieldAnswer> = {
            support_files,
          };

          await props.onAnswerChange?.(props.answer, answerData);
        }
      : undefined,
    onFileDelete: isAnswerMode
      ? async (fileId) => {
          const support_files = props.answer.support_files?.filter(
            (upload) => upload.file?.id !== fileId,
          );
          const answerData: Partial<QuestionnnaireFormFieldAnswer> = {
            support_files,
          };

          await props.onAnswerChange?.(props.answer, answerData);
        }
      : undefined,
  });

  return (
    <FormControl w="full" isReadOnly={isReadOnly}>
      <FormLabel mb={2}>{t('questionnaires.form.supportingFiles')}</FormLabel>
      <FileUpload {...supportingFilesUpload} {...filesProps}>
        <FileUpload.Dropzone />
      </FileUpload>
    </FormControl>
  );
}
