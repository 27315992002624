import { Field_Entities_Enum } from '@main/graphql/types.generated';

import { useCustomFieldsTableSettings } from '../../utils/table-settings';

const defaultColumnVisibility = {
  internalId: false,
  description: false,
  inherentRiskImpact: false,
  inherentRiskLikelihood: false,
  residualRiskImpact: false,
  residualRiskLikelihood: false,
  inherentRisk: false,
  inherentScore: false,
  category: false,
  tag: false,
  linkedVendors: false,
  lastReviewedDate: false,
  frequency: false,
  closedAt: false,
  createdAt: false,
  createdBy: false,
};

export function useRisksTableSettings(tableId: string) {
  return useCustomFieldsTableSettings({
    entityName: Field_Entities_Enum.Risk,
    tableId,
    defaultColumnVisibility,
  });
}
