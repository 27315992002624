import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { CheckIcon, ChevronUpDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useStableCallback } from '@main/shared/utils';
import {
  getHashedAvatarColor,
  Header,
  PrimaryLayout,
  PrimaryLayoutState,
  SidebarMenu,
  SidebarMenuItem,
} from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { NotificationCenter } from '../notifications/drawer';
import { TaskSidebar } from '../tasks/sidebar/task-sidebar';
import {
  getCurrentUserOrgs,
  getCurrentUserOrgsMap,
  getCurrentUserSelectedOrg,
  orgSwitchRequested,
} from '../user/slice';
import { useAuthenticatedDrawer } from './authenticated-drawer';
import { useAuthHeaderMenu, useAuthSidebarMenu } from './authenticated-menu';

type AuthenticatedLayoutProps = {
  children?: React.ReactNode;
};

export const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { menuItems, extraMenuElement } = useAuthSidebarMenu({ isCollapsed });
  const headerMenu = useAuthHeaderMenu();
  const drawerContent = useAuthenticatedDrawer();

  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const renderSidebar = useStableCallback(
    ({
      isSidebarCollapsed,
      isInDrawer,
      onSidebarClose,
    }: PrimaryLayoutState & { isInDrawer?: boolean }) => (
      <Flex h="full" direction="column">
        <Box overflowY="auto" flex={1}>
          <OrgSwitcher isSidebarCollapsed={isSidebarCollapsed} />
          <SidebarMenu
            items={menuItems}
            onClick={onSidebarClose}
            isSidebarCollapsed={isSidebarCollapsed}
          >
            {!isInDrawer && extraMenuElement}
          </SidebarMenu>
        </Box>
        <Box borderTopWidth="1px" borderTopColor={borderColor} p={3}>
          <SidebarMenuItem
            item={{
              icon: QuestionMarkCircleIcon,
              label: t('sideBar.navItems.support'),
              href: 'https://support.complyance.com/',
              target: '_blank',
            }}
            isSidebarCollapsed={isSidebarCollapsed && !isInDrawer}
          />
        </Box>
      </Flex>
    ),
  );

  const renderHeader = useStableCallback(
    ({ logo, onSidebarOpen }: PrimaryLayoutState & { logo: React.ReactNode }) => (
      <Header
        logo={logo}
        onSidebarToggle={onSidebarOpen}
        {...headerMenu}
        taskSidebar={TaskSidebar}
        notificationCenter={NotificationCenter}
      />
    ),
  );

  return (
    <PrimaryLayout
      logoLink="/dashboard"
      onSidebarToggle={setIsCollapsed}
      sidebar={renderSidebar}
      header={renderHeader}
      drawer={drawerContent}
    >
      {children}
    </PrimaryLayout>
  );
};

interface OrgSwitcherProps {
  isSidebarCollapsed: boolean;
}

const OrgSwitcher = ({ isSidebarCollapsed }: OrgSwitcherProps) => {
  const userOrg = useAppSelector(getCurrentUserSelectedOrg);
  const userOrgs = useAppSelector(getCurrentUserOrgs);
  const userOrgsMap = useAppSelector(getCurrentUserOrgsMap);
  const dispatch = useAppDispatch();
  const bgHoverColor = useColorModeValue('blue.25', 'rgba(214, 188, 250, 0.10)');

  return (
    <Box px={3} pt={3}>
      <Menu>
        <Tooltip isDisabled={!isSidebarCollapsed} label={userOrg.name} placement="right">
          <MenuButton
            p={3}
            px={isSidebarCollapsed ? 0 : 3}
            w="full"
            borderRadius="md"
            _hover={{
              bg: bgHoverColor,
            }}
            marginBottom="2"
            disabled={userOrgs.length === 1}
          >
            <OrganizationItem
              organization={userOrg}
              isMenuButton
              showChevron={userOrgs.length > 1}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          </MenuButton>
        </Tooltip>
        <MenuList p={2} shadow="lg" rounded="md" maxW="sm  ">
          {Object.values(userOrgsMap).map((orgs, orgGroupIdx) => {
            return (
              <Box key={orgGroupIdx}>
                {orgGroupIdx !== 0 && <MenuDivider />}
                {orgs.map((org) => (
                  <MenuItem
                    key={org.id}
                    rounded="md"
                    paddingLeft={org.parent_organization_id ? '6' : '3'}
                    paddingRight={3}
                    _hover={{
                      bg: bgHoverColor,
                    }}
                    _focus={{
                      bg: bgHoverColor,
                    }}
                    py={2}
                    onClick={() => {
                      dispatch(orgSwitchRequested({ orgId: org.id }));
                    }}
                  >
                    <OrganizationItem
                      organization={org}
                      isSelectedItem={org.id === userOrg?.id}
                      isSidebarCollapsed={isSidebarCollapsed}
                    />
                  </MenuItem>
                ))}
              </Box>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

type OrganizationItemProps = {
  organization: ReturnType<typeof getCurrentUserOrgs>[number];
  isSelectedItem?: boolean;
  isMenuButton?: boolean;
  showChevron?: boolean;
  isSidebarCollapsed: boolean;
};

const OrganizationItem = ({
  organization,
  isSelectedItem,
  isMenuButton,
  showChevron,
  isSidebarCollapsed,
}: OrganizationItemProps) => {
  const { t } = useTranslation();
  const orgNameTextColor = useColorModeValue('gray.700', 'gray.100');
  const mainOrgLabelTextColor = useColorModeValue('gray.400', 'gray.300');
  const isCompact = isSidebarCollapsed && isMenuButton;
  return (
    <Flex minWidth={0} grow={1} alignItems="center" justifyContent="center">
      <Avatar
        mr={isCompact ? 0 : 2}
        size="xs"
        name={organization?.name}
        {...getHashedAvatarColor(organization?.name)}
      />
      {!isCompact && (
        <Flex
          direction="column"
          grow={1}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          <Box pr={3} py={1} rounded="md" isTruncated>
            <Text
              fontSize={isMenuButton ? 'sm' : 'xs'}
              textAlign="left"
              fontWeight={isMenuButton ? 'semibold' : 'normal'}
              mb="-0.5"
              isTruncated
              color={orgNameTextColor}
            >
              {organization?.name}
            </Text>
            <Text color={mainOrgLabelTextColor} fontSize="x-small" textAlign="left">
              {organization.parent_organization_id === null
                ? t('orgSwitcher.mainOrgLabel')
                : t('orgSwitcher.subOrgLabel')}
            </Text>
          </Box>
        </Flex>
      )}
      {showChevron && !isCompact && (
        <Icon
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={ChevronUpDownIcon}
        />
      )}
      {isSelectedItem && !isCompact && (
        <Icon
          fontSize="16"
          _groupHover={{
            color: 'white',
          }}
          as={CheckIcon}
        />
      )}
    </Flex>
  );
};
