import { NAMESPACE } from '@main/shared/utils';
import { ColumnSizingState, OnChangeFn } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { useUserOrgId } from '../../utils';

export function useTableColumnSizing(tableName: string, tableId?: string, saveDebounceMs = 16) {
  const organizationId = useUserOrgId();
  const keyPostfix = organizationId
    ? `:org-id-${organizationId}:column-sizing-v2`
    : `:column-sizing`;
  const generalKey = `${NAMESPACE}${tableName}${keyPostfix}`;
  const columnSizingKey = tableId ? `${NAMESPACE}${tableName}:${tableId}${keyPostfix}` : generalKey;

  const [columnSizing, setColumnSizing] = useState(
    JSON.parse(
      localStorage.getItem(columnSizingKey) || localStorage.getItem(generalKey) || '{}',
    ) as ColumnSizingState,
  );

  const hasColumnSizingChanged = Object.keys(columnSizing).length > 0;

  const onColumnSizingChange: OnChangeFn<ColumnSizingState> = setColumnSizing;

  useEffect(() => {
    const timeout = setTimeout(
      () => localStorage.setItem(columnSizingKey, JSON.stringify(columnSizing)),
      saveDebounceMs,
    );
    return () => clearTimeout(timeout);
  }, [columnSizing, columnSizingKey, saveDebounceMs]);

  return { columnSizing, hasColumnSizingChanged, onColumnSizingChange };
}
