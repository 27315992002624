import {
  Box,
  Button,
  Flex,
  Icon,
  Skeleton,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { EmptyPlaceholder } from '@main/ui';
import { JSXElementConstructor, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { useInfiniteScroll } from '../../shared/infinite-scroll';
import { getCurrentUserId, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { filterAndSortTasks } from '../slice';
import { useCreateTask } from '../use-create-task';
import {
  TaskSidebarDataFragment as Task,
  useUpdateAllTaskStatusesByCreatorMutation,
  useUpdateAllTaskStatusesByOwnerMutation,
  useUpdateFilteredTaskStatusesByCreatorMutation,
  useUpdateFilteredTaskStatusesByOwnerMutation,
} from './manage-task-sidebar.generated';
import { SidebarFooterActions, useTaskBulkUpdateErrorHandler } from './sidebar-actions';
import { FilterCriteria, SidebarFilter } from './sidebar-filter';
import { SidebarSort, SortCriteria } from './sidebar-sort';
import { TaskSidebarItem } from './task-sidebar-item';
import {
  TASK_SIDEBAR_PAGE_SIZE,
  useGetAssignedToMeTasks,
  useGetCreatedByMeTasks,
} from './use-get-sidebar-tasks';

export const AssignedToMeTab = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const [filters, setFilters] = useState<FilterCriteria[]>(['All']);
  const [sorts, setSorts] = useState<SortCriteria>({
    property: 'created_at',
    isAscending: true,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [updateAllTasks] = useUpdateAllTaskStatusesByOwnerMutation();
  const [updateFilteredTasks] = useUpdateFilteredTaskStatusesByOwnerMutation();
  const errorHandler = useTaskBulkUpdateErrorHandler();

  const getAssignedToMeTasks = useGetAssignedToMeTasks();

  const { data, loading, loadingMore, loadMore, noMore, mutate } = useInfiniteScroll<Task>(
    (d) => getAssignedToMeTasks(d?.offset),
    {
      target: ref,
      isNoMore: (d) => !!d?.isNoMore,
    },
  );

  const { tasks } = useAppSelector((state) =>
    filterAndSortTasks(state, {
      tasks: data?.list,
      filters,
      sorts,
    }),
  );

  const markTasksCompleteHandler = async () => {
    if (!data) {
      return;
    }

    const isFilterEnabled = filters[0] !== 'All';
    if (isFilterEnabled) {
      await errorHandler(
        updateFilteredTasks({
          userId,
          filter: filters,
          completedAt: 'now()',
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => {
          if (filters.includes(item.status)) {
            return {
              ...item,
              status: TaskStatus.COMPLETED,
              completed_at: 'now()',
            };
          }
          return item;
        }),
      });
    } else {
      await errorHandler(
        updateAllTasks({
          userId,
          status: TaskStatus.COMPLETED,
          completedAt: 'now()',
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => ({
          ...item,
          status: TaskStatus.COMPLETED,
          completed_at: 'now()',
        })),
      });
    }
  };

  const markTasksPendingHandler = async () => {
    if (!data) {
      return;
    }

    const isFilterEnabled = filters[0] !== 'All';
    if (isFilterEnabled) {
      await errorHandler(
        updateFilteredTasks({
          userId,
          filter: filters,
          completedAt: null,
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => {
          if (filters.includes(item.status)) {
            return {
              ...item,
              status: TaskStatus.PENDING,
              completed_at: undefined,
            };
          }
          return item;
        }),
      });
    } else {
      await errorHandler(
        updateAllTasks({
          userId,
          status: TaskStatus.PENDING,
          completedAt: null,
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => ({
          ...item,
          status: TaskStatus.PENDING,
          completed_at: undefined,
        })),
      });
    }
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box h="full" overflow="hidden" overflowY="auto" ref={ref}>
      <Flex pt={3} px={6}>
        <SidebarFilter filters={filters} setFilters={setFilters} />
        <Spacer />
        <SidebarSort sorts={sorts} setSorts={setSorts} />
      </Flex>
      {tasks.map((task) => (
        <TaskSidebarItem
          key={task.id}
          task={task}
          hideAssignee
          onSelect={onClose}
          onToggleComplete={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              list: data.list.map((item) => {
                if (item.id === task.id) {
                  return {
                    ...item,
                    status:
                      task.status === TaskStatus.COMPLETED
                        ? TaskStatus.PENDING
                        : TaskStatus.COMPLETED,
                  };
                }
                return item;
              }),
            });
          }}
          onToggleInProgress={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              list: data.list.map((item) => {
                if (item.id === task.id) {
                  return {
                    ...item,
                    status:
                      task.status === TaskStatus.IN_PROGRESS
                        ? TaskStatus.PENDING
                        : TaskStatus.IN_PROGRESS,
                  };
                }

                return item;
              }),
            });
          }}
          onDelete={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              offset: data.offset - 1,
              list: data.list.filter((item) => item.id !== task.id),
            });
          }}
        />
      ))}
      <ScrollStates
        noMore={noMore}
        loadingMore={loadingMore}
        dataLength={data?.list.length ?? 0}
        loadMore={loadMore}
        renderEmptyState={() => (
          <EmptyState
            heading={t('tasks.sidebar.emptyState.assignedToMe.heading')}
            subheading={t('tasks.sidebar.emptyState.assignedToMe.subheading')}
          />
        )}
      />
      <SidebarFooterActions
        onCreate={onClose}
        onMarkAllAsComplete={markTasksCompleteHandler}
        onMarkAllAsPending={markTasksPendingHandler}
      />
    </Box>
  );
};

export const CreatedByMeTab = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const [filters, setFilters] = useState<FilterCriteria[]>([]);
  const [sorts, setSorts] = useState<SortCriteria>({
    property: 'created_at',
    isAscending: true,
  });

  const ref = useRef<HTMLDivElement>(null);
  const [updateAllTasks] = useUpdateAllTaskStatusesByCreatorMutation();
  const [updateFilteredTasks] = useUpdateFilteredTaskStatusesByCreatorMutation();
  const errorHandler = useTaskBulkUpdateErrorHandler();
  const { onTaskCreate, isCreatingTask } = useCreateTask();
  const getCreatedByMeTasks = useGetCreatedByMeTasks();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canWriteTasks = userRole.permissionMap?.write_tasks;

  const { data, loading, loadingMore, loadMore, noMore, mutate } = useInfiniteScroll<Task>(
    (d) => getCreatedByMeTasks(d?.offset),
    {
      target: ref,
      isNoMore: (d) => !!d?.isNoMore,
    },
  );

  const { tasks } = useAppSelector((state) =>
    filterAndSortTasks(state, {
      tasks: data?.list,
      filters,
      sorts,
    }),
  );

  const markTasksCompleteHandler = async () => {
    if (!data) {
      return;
    }

    const isFilterEnabled = filters[0] !== 'All';
    if (isFilterEnabled) {
      await errorHandler(
        updateFilteredTasks({
          userId,
          filter: filters,
          completedAt: 'now()',
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => {
          if (filters.includes(item.status)) {
            return {
              ...item,
              status: TaskStatus.COMPLETED,
              completed_at: 'now()',
            };
          }
          return item;
        }),
      });
    } else {
      await errorHandler(
        updateAllTasks({
          userId,
          status: TaskStatus.COMPLETED,
          completedAt: 'now()',
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => ({
          ...item,
          status: TaskStatus.COMPLETED,
          completed_at: 'now()',
        })),
      });
    }
  };

  const markTasksPendingHandler = async () => {
    if (!data) {
      return;
    }

    const isFilterEnabled = filters[0] !== 'All';
    if (isFilterEnabled) {
      await errorHandler(
        updateFilteredTasks({
          userId,
          filter: filters,
          completedAt: null,
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => {
          if (filters.includes(item.status)) {
            return {
              ...item,
              status: TaskStatus.PENDING,
              completed_at: undefined,
            };
          }
          return item;
        }),
      });
    } else {
      await errorHandler(
        updateAllTasks({
          userId,
          status: TaskStatus.PENDING,
          completedAt: null,
        }),
      );

      /* Update infinite scroll cache */
      mutate({
        ...data,
        list: data.list.map((item) => ({
          ...item,
          status: TaskStatus.PENDING,
          completed_at: undefined,
        })),
      });
    }
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Box h="full" overflow="hidden" overflowY="auto" ref={ref}>
      <Flex pt={3} px={6}>
        <SidebarFilter filters={filters} setFilters={setFilters} />
        <Spacer />
        <SidebarSort sorts={sorts} setSorts={setSorts} />
      </Flex>
      {tasks.map((task) => (
        <TaskSidebarItem
          key={task.id}
          task={task}
          hideAssignee
          onSelect={onClose}
          onToggleComplete={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              list: data.list.map((item) => {
                if (item.id === task.id) {
                  return {
                    ...item,
                    status:
                      task.status === TaskStatus.COMPLETED
                        ? TaskStatus.PENDING
                        : TaskStatus.COMPLETED,
                  };
                }
                return item;
              }),
            });
          }}
          onToggleInProgress={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              list: data.list.map((item) => {
                if (item.id === task.id) {
                  return {
                    ...item,
                    status:
                      task.status === TaskStatus.IN_PROGRESS
                        ? TaskStatus.PENDING
                        : TaskStatus.IN_PROGRESS,
                  };
                }

                return item;
              }),
            });
          }}
          onDelete={() => {
            if (!data) {
              return;
            }

            mutate({
              ...data,
              offset: data.offset - 1,
              list: data.list.filter((item) => item.id !== task.id),
            });
          }}
        />
      ))}
      <ScrollStates
        noMore={noMore}
        loadingMore={loadingMore}
        dataLength={data?.list.length ?? 0}
        loadMore={loadMore}
        renderEmptyState={() => (
          <Box textAlign="center">
            <EmptyState
              heading={t('tasks.sidebar.emptyState.createdByMe.heading')}
              subheading={t(
                canWriteTasks
                  ? 'tasks.sidebar.emptyState.createdByMe.subheadingCreateTask'
                  : 'tasks.sidebar.emptyState.createdByMe.subheading',
              )}
            />
            {canWriteTasks && (
              <Button
                colorScheme="blue"
                leftIcon={<Icon as={PlusIcon} />}
                onClick={async () => {
                  onClose();
                  await onTaskCreate();
                }}
                isLoading={isCreatingTask}
              >
                {t('tasks.addTask')}
              </Button>
            )}
          </Box>
        )}
      />
      <SidebarFooterActions
        onCreate={onClose}
        onMarkAllAsComplete={markTasksCompleteHandler}
        onMarkAllAsPending={markTasksPendingHandler}
      />
    </Box>
  );
};

const ScrollStates = ({
  noMore,
  loadingMore,
  loadMore,
  dataLength,
  renderEmptyState: EmptyState,
}: {
  loadingMore: boolean;
  noMore: boolean;
  loadMore: () => void;
  dataLength: number;
  renderEmptyState: JSXElementConstructor<object>;
}) => {
  const { t } = useTranslation();

  if (loadingMore) {
    return <LoadingMoreState />;
  }

  if (dataLength === 0 && noMore) {
    return <EmptyState />;
  }

  if (noMore) {
    return <NoMoreState />;
  }

  if (dataLength <= TASK_SIDEBAR_PAGE_SIZE && !noMore) {
    return (
      <Flex justifyContent="center" py={2}>
        <Button size="xs" variant="outline" rounded="base" onClick={loadMore}>
          {t('buttons.loadMore')}
        </Button>
      </Flex>
    );
  }

  return null;
};

const LoadingState = () => {
  const array = new Array(6).fill(0);
  const bottomBorderColor = useColorModeValue('gray.200', 'gray.500');

  return array.map((_, index) => (
    <Flex
      key={index}
      px={6}
      py={5}
      borderBottom="1px"
      borderColor={bottomBorderColor}
      direction="column"
      gap={2}
    >
      <Flex gap={3} alignItems="center">
        <Skeleton h={5} w={24} />
        <Skeleton h={5} w={32} />
        <Spacer />
        <Skeleton h={9} w={9} rounded="full" />
      </Flex>
      <Skeleton h={12} w="full" />
      <Flex gap={3} alignItems="center">
        <Skeleton h={3} w={24} />
        <Skeleton h={3} w={24} />
        <Skeleton h={3} w={24} />
      </Flex>
    </Flex>
  ));
};

const EmptyState = ({ heading, subheading }: { heading: string; subheading: string }) => {
  return (
    <EmptyPlaceholder py={8}>
      <EmptyPlaceholder.Icon as={XMarkIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>{heading}</EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>{subheading}</EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

const NoMoreState = () => {
  const { t } = useTranslation();

  return (
    <Text textAlign="center" fontSize="sm" color="gray.500" my={3}>
      {t('tasks.sidebar.noMoreTasks')}
    </Text>
  );
};

const LoadingMoreState = () => {
  const { t } = useTranslation();

  return (
    <Text textAlign="center" fontSize="sm" color="gray.500" my={3}>
      {t('tasks.sidebar.loadingMoreTasks')}
    </Text>
  );
};
