import { Box, Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { severityColorScheme, toError } from '@main/shared/utils';
import { errorToast, FindingCard, successToast, useAlertDialog } from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CreateFindingRiskButton } from '../findings/create-risk';
import { CreateFindingTaskButton } from '../findings/create-task';
import { FindingActionModal, ResolutionActionType } from '../findings/finding-action-modal';
import { FindingResolutionReason } from '../findings/finding-resolution-reason';
import { useDeleteManualFindingMutation } from '../findings/ManageFindings.generated';
import { FindingMenuActions } from '../findings/menu-actions';
import {
  useFindingReinstateAction,
  useFindingResolutionActions,
  useUpdateManualFindingAction,
} from '../findings/use-finding-actions';
import { TabFooter } from '../shared/tab-footer';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { ControlFindingContent } from './finding/content';
import { ControlFinding } from './finding/control-finding';
import { getControlFindings } from './slice';

export const ControlFindingsTab = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const headingColor = useColorModeValue('gray.700', 'gray.300');
  const { findings } = useAppSelector((state) => getControlFindings(state, controlId));

  return (
    <Box>
      <Flex direction="column" gap={6} paddingBottom="40px">
        <Text color={headingColor}>{t('controls.findings.description')}</Text>
        <Stack>
          {findings.map((controlFinding, index) => (
            <ControlFindingCard
              key={controlFinding.finding.id}
              index={index}
              controlFinding={controlFinding}
              controlId={controlId}
            />
          ))}
        </Stack>
      </Flex>
      <TabFooter>
        <Text fontSize="xs" color="gray.400">
          {t('controls.findings.tip')}
        </Text>
      </TabFooter>
    </Box>
  );
};

const ControlFindingCard = ({
  controlFinding,
  controlId,
  index,
}: {
  controlFinding: ControlFinding;
  controlId: string;
  index: number;
}) => {
  const { t } = useTranslation();
  const [actionType, setActionType] = useState<ResolutionActionType | null>(null);
  const resolutionActions = useFindingResolutionActions();
  const reinstateAction = useFindingReinstateAction({
    resolutionId: controlFinding.finding.resolution?.id,
  });
  const updateAction = useUpdateManualFindingAction({
    finding: controlFinding.finding.manual_finding
      ? {
          ...controlFinding.finding,
          ...controlFinding.finding.manual_finding,
          manualFindingId: controlFinding.finding.manual_finding.id,
        }
      : undefined,
  });
  const deleteAction = useDeleteManualFindingAction({
    manualFindingId: controlFinding.finding.manual_finding?.id,
  });
  const [aiCreateEntity] = useAiCreateEntityMutation();
  const { findings } = useAppSelector((state) => getControlFindings(state, controlId));
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateControl = userRole.permissionMap?.update_controls;
  const canCreateRisk = userRole.permissionMap?.write_risks;

  const isResolved = !!controlFinding.finding.resolution;
  const riskId = controlFinding.finding.risk_id;
  const taskId = controlFinding.finding.task_id;
  const resolutionType = controlFinding.finding.resolution?.type;

  return (
    <FindingCard key={controlFinding.finding.id} resolutionType={resolutionType}>
      <FindingCard.Header
        heading={t('findings.card.findingsNo', {
          number: index + 1,
          total: findings.length,
        })}
      >
        <Tag colorScheme={severityColorScheme(controlFinding.severity)} size="sm">
          {t(`findings.type.${controlFinding.finding.type}`)}
        </Tag>
      </FindingCard.Header>

      <FindingCard.Actions>
        {canCreateRisk && (
          <CreateFindingRiskButton
            riskId={riskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'risk-control-finding', findingId: controlFinding.finding.id },
              })
            }
          />
        )}
        {canUpdateControl && (
          <CreateFindingTaskButton
            taskId={taskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'task-control-finding', findingId: controlFinding.finding.id },
              })
            }
          />
        )}

        {canUpdateControl && (
          <FindingMenuActions
            actions={[
              reinstateAction,
              ...Object.values(resolutionActions).map((action) => ({
                icon: action.icon,
                label: action.label,
                onClick: () => setActionType(action.type),
                isHidden: action.type === 'Edit' ? !isResolved : isResolved,
              })),
              updateAction,
              deleteAction,
            ]}
          />
        )}
      </FindingCard.Actions>

      {actionType && (
        <FindingActionModal
          actionType={actionType}
          onClose={() => setActionType(null)}
          onSubmit={(data) =>
            resolutionActions[actionType].handler({
              comment: data.comment,
              control_finding_id: controlFinding.finding.id,
            })
          }
          defaultValues={{
            comment: controlFinding.finding.resolution?.comment ?? '',
          }}
        />
      )}

      <FindingCard.Body>
        <ControlFindingContent {...controlFinding} />
        <FindingResolutionReason
          resolutionType={resolutionType}
          comment={controlFinding.finding.resolution?.comment}
          date={controlFinding.finding.resolution?.created_at}
          user={controlFinding.finding.resolution?.created_by}
        />
      </FindingCard.Body>
    </FindingCard>
  );
};

const useDeleteManualFindingAction = ({
  manualFindingId,
}: {
  manualFindingId: string | undefined;
}) => {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const [deleteManualFindingAction] = useDeleteManualFindingMutation();

  const deleteHandler = useCallback(
    async (findingId: string) => {
      try {
        await deleteManualFindingAction({ findingId }).unwrap();
        successToast(t('successMessages.deleteSucceeded', { entity: t('entities.finding') }));
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.finding') }));
        datadogLogs.logger.error('deleting manual finding failed', {}, toError(error));
      }
    },
    [deleteManualFindingAction, t],
  );

  const action = useMemo(() => {
    if (!manualFindingId) {
      return;
    }

    return {
      icon: <TrashIcon />,
      label: t('buttons.delete'),
      onClick: () => {
        openDialog({
          dialogHeader: t('controls.findings.alert.delete.header'),
          dialogContent: t('controls.findings.alert.delete.content'),
          confirmAction: {
            children: t('controls.findings.alert.delete.confirm'),
            onClick: () => deleteHandler(manualFindingId),
          },
        });
      },
    };
  }, [deleteHandler, manualFindingId, openDialog, t]);

  return action;
};
