import { Box, Flex, Grid, GridItem, Wrap, WrapItem } from '@chakra-ui/react';
import { Table } from '@tanstack/react-table';

import {
  AddFilter,
  ColumnFilter,
  GlobalFilter,
  hasColumnFilters,
  hasGlobalFilter,
} from '../filters';
import { AddSort, ColumnSort, shouldDisplaySort } from '../sort';
import { TableSettings } from './table-settings';

export function TableMenu<TData>({
  table,
  canChangeColumnVisibility,
  hasColumnOrderChanged,
  hasColumnVisibilityChanged,
  hasColumnSizingChanged,
}: {
  table: Table<TData>;
  canChangeColumnVisibility?: boolean;
  hasColumnOrderChanged: boolean;
  hasColumnVisibilityChanged: boolean;
  hasColumnSizingChanged: boolean;
}) {
  const { columnFilters, sorting } = table.getState();

  return (
    <Grid px={6} py={3} templateColumns="auto 320px" gap={6}>
      <GridItem>
        <Wrap overflow="visible">
          {hasColumnFilters(table) && (
            <>
              {columnFilters.map(({ id }) => (
                <WrapItem key={id}>
                  <ColumnFilter table={table} columnId={id} />
                </WrapItem>
              ))}
              <WrapItem>
                <AddFilter table={table} />
              </WrapItem>
            </>
          )}
          {shouldDisplaySort(table) && (
            <>
              {sorting.map(({ id }) => (
                <WrapItem key={id}>
                  <ColumnSort table={table} columnId={id} />
                </WrapItem>
              ))}
              <WrapItem>
                <AddSort table={table} />
              </WrapItem>
            </>
          )}
        </Wrap>
      </GridItem>

      <GridItem borderColor="green.400" as={Flex} justifyContent="end" gap={3}>
        {hasGlobalFilter(table) && (
          <Box w="xs">
            <GlobalFilter table={table} />
          </Box>
        )}

        {canChangeColumnVisibility && (
          <TableSettings
            table={table}
            hasColumnOrderChanged={hasColumnOrderChanged}
            hasColumnVisibilityChanged={hasColumnVisibilityChanged}
            hasColumnSizingChanged={hasColumnSizingChanged}
          />
        )}
      </GridItem>
    </Grid>
  );
}
