import {
  Editable,
  EditablePreview,
  EditableProps,
  EditableTextarea,
  forwardRef,
  useColorModeValue,
  useFormControl,
  useMergeRefs,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import ResizeTextarea from 'react-textarea-autosize';

type ReactHookFormProps = {
  rhfProps?: UseFormRegisterReturn;
};

type EditableAutoResizeTextareaProps = ReactHookFormProps &
  EditableProps & {
    autoFocusIfEmpty?: boolean;
  };

export const EditableAutoResizeTextarea = forwardRef<EditableAutoResizeTextareaProps, 'div'>(
  (
    {
      fontSize = 'xs',
      fontWeight,
      defaultValue,
      rhfProps,
      isDisabled: _isDisabled,
      color,
      autoFocusIfEmpty,
      ...rest
    },
    ref,
  ) => {
    const [isEmpty, setIsEmpty] = useState(!defaultValue);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const textareaPreviewRef = useRef<HTMLElement>(null);
    const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
    const focusBgColor = useColorModeValue('gray.25', 'gray.800');
    const borderColor = useColorModeValue('gray.200', 'rgba(255,255,255,0.16)');
    const placeholderColor = useColorModeValue('gray.400', 'gray.500');
    const formControlProps = useFormControl({});
    const isDisabled = formControlProps.readOnly || _isDisabled;

    const { ref: rhfRef, ...rhfPropsRest } = rhfProps || {};
    const refs = useMergeRefs(textareaRef, rhfRef);

    const handlePreviewClick = () => {
      if (textareaPreviewRef.current) {
        textareaPreviewRef.current.focus();
      }
    };

    /* Handle auto focus if input is empty */
    useEffect(() => {
      if (isEmpty && autoFocusIfEmpty) {
        handlePreviewClick();
      }
    }, [isEmpty, autoFocusIfEmpty]);

    // Handle label click to focus textarea
    useEffect(() => {
      const labelElement = document.querySelector(`label[for="${formControlProps.id}"]`);
      if (labelElement) {
        labelElement.addEventListener('click', handlePreviewClick);
      }
      return () => {
        if (labelElement) {
          labelElement.removeEventListener('click', handlePreviewClick);
        }
      };
    }, [formControlProps.id]);

    return (
      <Editable
        key={defaultValue}
        ref={ref}
        selectAllOnFocus={false}
        defaultValue={defaultValue}
        fontWeight={fontWeight}
        fontSize={fontSize}
        isDisabled={isDisabled}
        {...rest}
        onBlur={() => setIsEmpty(textareaRef.current?.value ? false : true)}
        display="flex"
        alignItems="center"
        h="fit-content"
      >
        <EditablePreview
          role="button"
          px={2}
          py={2}
          width="full"
          aria-labelledby={`${formControlProps.id}-label`}
          border="1px"
          borderColor="transparent"
          _hover={isDisabled ? {} : { bg: hoverBgColor, borderColor: hoverBgColor }}
          ref={textareaPreviewRef}
          color={isEmpty ? placeholderColor : color}
          cursor={isDisabled ? 'not-allowed' : 'text'}
        />
        <EditableTextarea
          id={formControlProps.id}
          px={2}
          py={2}
          minRows={1}
          resize="none"
          border="1px"
          borderColor={borderColor}
          overflow="hidden"
          color={color}
          _focus={{
            boxShadow: 'none',
            bgColor: focusBgColor,
          }}
          as={ResizeTextarea}
          ref={refs}
          {...rhfPropsRest}
          onFocus={() =>
            textareaRef.current?.setSelectionRange(
              textareaRef.current.value?.length || 0,
              textareaRef.current.value?.length || 0,
            )
          }
        />
      </Editable>
    );
  },
);
