import { createColumnHelper, useDrawer } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import {
  getAllControlStatusOptions,
  getControlStatusOption,
} from '../controls/table-column-options';
import { getFrequencyOption, getFrequencyOptions } from '../shared/frequency';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import {
  categoryColumnId,
  InherentImpactColumnId,
  InherentLikelihoodColumnId,
  ResidualImpactColumnId,
  ResidualLikelihoodColumnId,
} from './constants';
import { OrganizationRisk } from './slice';
import {
  getAllRiskLevelOptions,
  getAllRiskStatusOptions,
  getAllTreatmentOptions,
  getRiskLevelOption,
  getRiskStatusOption,
  getTreatmentOption,
} from './table-column-options';
import { useUpdateRiskMutation } from './update-risk.generated';
import { useUpdateRiskHandler } from './use-update-risk-handler';

export function useRiskColumnHelper() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const updateRiskHandler = useUpdateRiskHandler();
  const [updateRisk] = useUpdateRiskMutation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationRisk>();

    return {
      status() {
        return columnHelper.columns.status({
          id: 'approval',
          header: t('risks.tableColumns.status'),
          getOptions: () => getAllRiskStatusOptions(t),
          accessorFn: ({ assessment_status }) => getRiskStatusOption(t, assessment_status),
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 115,
        });
      },

      internalId() {
        return columnHelper.columns.tag({
          id: 'internalId',
          header: t('risks.tableColumns.internalId'),
          accessorFn: ({ internal_id }) => ({
            value: internal_id ?? '',
            colorScheme: 'purple',
          }),
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      createdBy() {
        return columnHelper.columns.avatar({
          id: 'createdBy',
          header: t('risks.tableColumns.createdBy'),
          accessorFn: ({ created_by }) => {
            if (!created_by) {
              return COMPLYANCE_USER;
            }

            const user = currentOrgUsers[created_by];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      createdAt() {
        return columnHelper.columns.date({
          id: 'createdAt',
          header: t('risks.tableColumns.createdAt'),
          accessorFn: ({ created_at }) => created_at,
          enableColumnFilter: true,
          enableSorting: true,
          size: 160,
        });
      },

      closedAt() {
        return columnHelper.columns.date({
          id: 'closedAt',
          header: t('risks.tableColumns.closedAt'),
          accessorFn: ({ closed_at }) => closed_at,
          size: 160,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          enableSorting: true,
          enableColumnFilter: true,
        });
      },

      name(options?: { onClickOpenDrawer?: boolean }) {
        return columnHelper.columns.text({
          id: 'name',
          header: t('risks.tableColumns.name'),
          accessorFn: ({ name }) => name,
          enableGlobalFilter: true,
          enableSorting: true,
          meta: {
            cell: {
              onClick: options?.onClickOpenDrawer
                ? (cell) => drawer.open({ entity: 'risk', entityId: cell.row.original.id })
                : undefined,
            },
          },
          enableHiding: false,
        });
      },

      description() {
        return columnHelper.columns.text({
          id: 'description',
          header: t('risks.tableColumns.description'),
          accessorFn: ({ description }) => description,
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 300,
        });
      },

      inherentRiskImpact() {
        return columnHelper.columns.tag({
          id: InherentImpactColumnId,
          header: t('risks.tableColumns.inherentImpact'),
          accessorFn: ({ inherent_impact }) => defaultOption(inherent_impact?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      inherentRiskLikelihood() {
        return columnHelper.columns.tag({
          id: InherentLikelihoodColumnId,
          header: t('risks.tableColumns.inherentLikelihood'),
          accessorFn: ({ inherent_likelihood }) => defaultOption(inherent_likelihood?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      residualRiskImpact() {
        return columnHelper.columns.tag({
          id: ResidualImpactColumnId,
          header: t('risks.tableColumns.residualImpact'),
          accessorFn: ({ residual_impact }) => defaultOption(residual_impact?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      residualRiskLikelihood() {
        return columnHelper.columns.tag({
          id: ResidualLikelihoodColumnId,
          header: t('risks.tableColumns.residualLikelihood'),
          accessorFn: ({ residual_likelihood }) => defaultOption(residual_likelihood?.name),
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      linkedVendors() {
        return columnHelper.columns.tag({
          id: 'linkedVendors',
          header: t('risks.tableColumns.linkedVendors'),
          accessorFn: ({ vendor_risks }) => {
            return vendor_risks.map(({ vendor }) => ({
              value: vendor.id,
              label: vendor.name,
              colorScheme: 'purple',
            }));
          },
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
          isMulti: true,
        });
      },

      linkedControls() {
        return columnHelper.columns.status({
          id: 'linkedControls',
          header: t('risks.tableColumns.linkedControls'),
          getOptions: () => getAllControlStatusOptions(t),
          accessorFn: ({ controlStatus }) => {
            if (!controlStatus) {
              return;
            }

            return getControlStatusOption(t, controlStatus);
          },
          size: 120,
          enableColumnFilter: true,
          enableSorting: true,
        });
      },

      inherentRisk() {
        return columnHelper.columns.tag({
          id: 'inherentRisk',
          header: t('risks.tableColumns.inherentRisk'),
          getOptions: () => getAllRiskLevelOptions(t),
          accessorFn: ({ inherentRiskLevel }) => {
            if (!inherentRiskLevel) {
              return;
            }

            return getRiskLevelOption(t, inherentRiskLevel);
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 90,
        });
      },

      residualRisk() {
        return columnHelper.columns.tag({
          id: 'residualRisk',
          header: t('risks.tableColumns.residualRisk'),
          getOptions: () => getAllRiskLevelOptions(t),
          accessorFn: ({ residualRiskLevel }) => {
            if (!residualRiskLevel) {
              return;
            }

            return getRiskLevelOption(t, residualRiskLevel);
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 105,
        });
      },

      inherentScore() {
        return columnHelper.columns.number({
          id: 'inherentScore',
          header: t('risks.tableColumns.inherentScore'),
          accessorFn: ({ inherentScore }) => inherentScore,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 105,
        });
      },

      residualScore() {
        return columnHelper.columns.number({
          id: 'residualScore',
          header: t('risks.tableColumns.residualScore'),
          accessorFn: ({ residualScore }) => residualScore,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      tags() {
        return columnHelper.columns.tag({
          id: 'tag',
          header: 'Tag',
          accessorFn: ({ tags }) => {
            return tags.map(({ tag }) => ({
              value: tag.id,
              label: tag.name,
              colorScheme: 'purple',
            }));
          },
          size: 200,
          enableColumnFilter: true,
          enableSorting: true,
          isMulti: true,
        });
      },

      category() {
        return columnHelper.columns.tag({
          id: categoryColumnId,
          header: t('risks.tableColumns.category'),
          accessorFn: ({ categories }) => {
            return categories.map(({ category }) => ({
              value: category.id,
              label: category.name,
              colorScheme: 'purple',
            }));
          },
          isMulti: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 90,
        });
      },

      treatmentPlan() {
        return columnHelper.columns.tag({
          id: 'treatmentPlan',
          header: t('risks.tableColumns.treatmentPlan'),
          getOptions: () => getAllTreatmentOptions(t),
          accessorFn: ({ treatment_plan }) => getTreatmentOption(t, treatment_plan),
          enableColumnFilter: true,
          enableSorting: true,
          size: 95,
        });
      },

      owner(options?: { isEditable?: boolean }) {
        return columnHelper.columns.avatar({
          id: 'owner',
          header: t('risks.tableColumns.owner'),
          accessorFn: ({ assignee }) => {
            if (!assignee) {
              return;
            }

            return {
              id: assignee.id,
              displayName: assignee.displayName,
            };
          },
          size: 70,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canUpdateRisks
              ? {
                  options: currentOrgNonDisabledUsers.map((user) => ({
                    id: user.id,
                    displayName: user.displayName,
                  })),
                  onChange: (row, user) => {
                    return updateRiskHandler(
                      updateRisk({
                        id: row.original.id,
                        risk_input: {
                          assignee_id: user?.id ?? null,
                        },
                      }),
                    );
                  },
                }
              : undefined,
        });
      },

      lastReviewedDate() {
        return columnHelper.columns.date({
          id: 'lastReviewedDate',
          header: t('risks.tableColumns.lastReviewedDate'),
          accessorFn: ({ lastReviewedDate }) => lastReviewedDate,
          size: 160,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          enableSorting: true,
          enableColumnFilter: true,
        });
      },

      frequency() {
        return columnHelper.columns.tag({
          id: 'frequency',
          header: t('risks.tableColumns.frequency'),
          getOptions: () => getFrequencyOptions(t),
          accessorFn: ({ review_frequency }) => getFrequencyOption(t, review_frequency),
          size: 100,
          enableColumnFilter: true,
          enableSorting: true,
          edit: canUpdateRisks
            ? {
                isClearable: false,
                options: getFrequencyOptions(t),
                onChange: (row, frequency) => {
                  if (!frequency) {
                    return;
                  }
                  updateRiskHandler(
                    updateRisk({
                      id: row.original.id,
                      risk_input: {
                        review_frequency: frequency.value,
                      },
                    }),
                  );
                },
              }
            : undefined,
        });
      },
    };
  }, [
    t,
    currentOrgUsers,
    drawer,
    canUpdateRisks,
    currentOrgNonDisabledUsers,
    updateRiskHandler,
    updateRisk,
  ]);
}

function defaultOption(value?: string) {
  if (!value) return;
  return {
    value,
    colorScheme: 'purple',
  };
}
