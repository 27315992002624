import {
  Notification_Types_Enum,
  Policy_Acknowledgement_Statuses_Enum,
  Policy_Approval_Statuses_Enum,
} from '@main/graphql/types.generated';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { StatusUpdateContent } from '../notifications/notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface PolicyAssignedNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    policy: NonNullable<NotificationItem['content']['policy']>;
  };
}

export class PolicyAssignedNotificationHandler
  implements AssignedNotificationAdapter<PolicyAssignedNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;

  static canHandle(notification: NotificationItem): notification is PolicyAssignedNotification {
    return !!notification.content.policy;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: PolicyAssignedNotification): AssignedNotificationData {
    return {
      internalId: notification.content.policy.internal_id,
      entityName: notification.content.policy.name,
      content: 'notification.policy.assignedAsOwner',
    };
  }

  redirect(notification: PolicyAssignedNotification) {
    router.navigate({
      to: '/policies',
      search: {
        drawerEntity: 'policy',
        drawerEntityId: notification.content.policy.id,
      },
    });
  }
}

export interface PolicyVersionNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    policy_version: NonNullable<NotificationItem['content']['policy_version']>;
  };
}

function isPolicyVersionNotification(
  notification: NotificationItem,
): notification is PolicyVersionNotification {
  return !!notification.content.policy_version;
}

export class PolicyVersionApprovalAssignedNotificationHandler
  implements AssignedNotificationAdapter<PolicyVersionNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;

  static canHandle(notification: NotificationItem): notification is PolicyVersionNotification {
    return (
      isPolicyVersionNotification(notification) && this.getType(notification) === 'approval-request'
    );
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  protected static getType(notification: NotificationItem) {
    return 'type' in notification.content.params
      ? notification.content.params.type
      : 'approval-request';
  }

  getAssignedData(notification: PolicyVersionNotification): AssignedNotificationData {
    return {
      internalId: notification.content.policy_version.policy.internal_id,
      entityName: notification.content.policy_version.policy.name,
      content: 'notification.policy.approvalAssigned',
    };
  }

  redirect(notification: PolicyVersionNotification) {
    router.navigate({
      to: '/policies',
      search: {
        drawerEntity: 'policy-version',
        drawerEntityId: `${notification.content.policy_version.policy.id}:${notification.content.policy_version.id}`,
      },
    });
  }
}

export class PolicyVersionAcknowledgementAssignedNotificationHandler extends PolicyVersionApprovalAssignedNotificationHandler {
  static override canHandle(
    notification: NotificationItem,
  ): notification is PolicyVersionNotification {
    return (
      isPolicyVersionNotification(notification) &&
      this.getType(notification) === 'acknowledgement-request'
    );
  }

  override getAssignedData(notification: PolicyVersionNotification): AssignedNotificationData {
    return {
      ...super.getAssignedData(notification),
      content: 'notification.policy.acknowledgementAssigned',
    };
  }
}

export interface MentionedInPolicyCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      comments_policy: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['comments_policy']
      >;
    };
  };
}

export class MentionedInPolicyCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInPolicyCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInPolicyCommentNotification {
    return !!notification.content.notifications_comment?.comments_policy;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInPolicyCommentNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.comments_policy.internal_id,
      entityName: notification.content.notifications_comment.comments_policy.name,
      content: 'notification.policy.mentioned',
    };
  }

  redirect(notification: MentionedInPolicyCommentNotification) {
    router.navigate({
      to: '/policies',
      search: {
        drawerEntity: 'policy',
        drawerEntityId: notification.content.notifications_comment.comments_policy.id,
        activeTab: 'comments',
      },
    });
  }
}

export interface PolicyReviewNotification extends PolicyVersionNotification {
  content: PolicyVersionNotification['content'] & {
    params: {
      policyApprovalStatus:
        | Policy_Approval_Statuses_Enum.Expiring
        | Policy_Approval_Statuses_Enum.Overdue
        | Policy_Approval_Statuses_Enum.Approved;
      approvalReminderTo?: 'approval_creator';
    };
  };
}

export class PolicyCreatorReviewNotificationHandler
  implements PolicyVersionNotificationHandler<PolicyReviewNotification>
{
  static readonly type = Notification_Types_Enum.PolicyApprovalReminder;

  static canHandle(notification: NotificationItem): notification is PolicyReviewNotification {
    return (
      isPolicyVersionNotification(notification) &&
      'policyApprovalStatus' in notification.content.params &&
      'approvalReminderTo' in notification.content.params &&
      notification.content.params.approvalReminderTo === 'approval_creator'
    );
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<PolicyReviewNotification, this> {
    return PolicyVersionNotificationContent;
  }

  redirect(notification: PolicyReviewNotification) {
    router.navigate({
      to: '/policies',
      search: {
        drawerEntity: 'policy-version',
        drawerEntityId: `${notification.content.policy_version.policy.id}:${notification.content.policy_version.id}`,
        activeTab: 'approvals',
      },
    });
  }

  getContent(notification: PolicyReviewNotification, t: TFunction): string {
    const policyApprovalStatus = t(
      `notification.policy.policyApprovalStatus.${notification.content.params.policyApprovalStatus}`,
    );

    return t('notification.policy.creatorNeedsReview', { policyApprovalStatus });
  }
}

export class PolicyApproverReviewNotificationHandler extends PolicyCreatorReviewNotificationHandler {
  static override canHandle(
    notification: NotificationItem,
  ): notification is PolicyReviewNotification {
    return (
      !!notification.content.policy_version &&
      'policyApprovalStatus' in notification.content.params &&
      'approvalReminderTo' in notification.content.params === false
    );
  }

  override getContent(notification: PolicyReviewNotification, t: TFunction): string {
    const policyApprovalStatus = t(
      `notification.policy.policyApprovalStatus.${notification.content.params.policyApprovalStatus}`,
    );

    return t('notification.policy.approverNeedsReview', { policyApprovalStatus });
  }
}

export interface PolicyAcknowledgementReminderNotification extends PolicyVersionNotification {
  content: PolicyVersionNotification['content'] & {
    params: {
      policyAcknowledgementStatus:
        | Policy_Acknowledgement_Statuses_Enum.Expiring
        | Policy_Acknowledgement_Statuses_Enum.Overdue;
    };
  };
}

export class PolicyAcknowledgementReminderNotificationHandler
  implements PolicyVersionNotificationHandler<PolicyAcknowledgementReminderNotification>
{
  static readonly type = Notification_Types_Enum.PolicyAcknowledgementReminder;

  static canHandle(
    notification: NotificationItem,
  ): notification is PolicyAcknowledgementReminderNotification {
    return (
      isPolicyVersionNotification(notification) &&
      'policyAcknowledgementStatus' in notification.content.params
    );
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<
    PolicyAcknowledgementReminderNotification,
    this
  > {
    return PolicyVersionNotificationContent;
  }

  redirect(notification: PolicyAcknowledgementReminderNotification) {
    router.navigate({
      to: '/policies',
      search: {
        drawerEntity: 'policy-version',
        drawerEntityId: `${notification.content.policy_version.policy.id}:${notification.content.policy_version.id}`,
      },
    });
  }

  getContent(notification: PolicyAcknowledgementReminderNotification, t: TFunction): string {
    const policyAcknowledgementStatus = t(
      `notification.policy.policyAcknowledgementStatus.${notification.content.params.policyAcknowledgementStatus}`,
    );

    return t('notification.policy.needsAcknowledgement', { policyAcknowledgementStatus });
  }
}

export interface PolicyVersionNotificationHandler<T extends PolicyVersionNotification>
  extends NotificationHandler<T> {
  getContent(notification: T, t: TFunction): string;
}

function PolicyVersionNotificationContent<T extends PolicyVersionNotification>({
  notification,
  handler,
}: NotificationContentProps<T, PolicyVersionNotificationHandler<T>>) {
  const { t } = useTranslation();
  const content = handler.getContent(notification, t);

  return (
    <StatusUpdateContent
      internalId={notification.content.policy_version.policy.internal_id ?? ''}
      entityName={notification.content.policy_version.policy.name ?? ''}
      content={content}
    />
  );
}
