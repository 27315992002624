import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Servicenow]: HandlerConfigServicenow;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigServicenow {}
}

export const ServicenowConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Servicenow> =
  {
    name: Integration_Names_Enum.Servicenow,

    orgConfig: z.object({
      baseUrl: z.string().describe('Base URL'),
      apiToken: z.string().describe('API Token'),
    }),

    evidenceConfig: z.discriminatedUnion('type', [
      z.object({
        type: z.literal(EvidenceIntegrationType.IssuesExport),
        table: z.string(),
        query: z.string(),
        fields: z.array(z.string()).min(1),
      }),
    ]),
  };
