import { EditableTextareaField, FileField, OptionsField } from '../field';
import { DynamicFormComponentRegistry } from '../field-registry';

export enum QuestionnaireComponentKind {
  Text = 'editable-textarea',
  Options = 'options',
  File = 'file',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface QuestionnaireFormFieldConfigExtra {}

export type QuestionnaireFormComponentRegistry = DynamicFormComponentRegistry<
  {
    [QuestionnaireComponentKind.Text]: typeof EditableTextareaField;
    [QuestionnaireComponentKind.Options]: typeof OptionsField;
    [QuestionnaireComponentKind.File]: FileField;
  },
  QuestionnaireFormFieldConfigExtra
>;
