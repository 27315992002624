import { FilterMode, isIsEmptyOrIsNotEmpty } from '@main/shared/url-helpers';
import { rangeToDates } from '@main/ui';

export function filterModeInComparison<T>(filterMode: FilterMode, filterValues: T[]) {
  switch (filterMode) {
    case FilterMode.Includes:
      return { _in: filterValues };
    case FilterMode.Excludes:
      return { _nin: filterValues };
    default:
      return undefined;
  }
}

export function filterModeILikeComparison(filterMode: FilterMode, filterValue: string) {
  switch (filterMode) {
    case FilterMode.Includes:
      return { _ilike: `%${filterValue}%` };
    case FilterMode.Excludes:
      return { _nilike: `%${filterValue}%` };
    default:
      return undefined;
  }
}

export function negateCondition<T>(condition: T) {
  return { _not: condition };
}

export function negateConditionIf<T>(check: boolean, condition: T) {
  return check ? negateCondition(condition) : condition;
}

export function filterModeNegateCondition<T>(filterMode: FilterMode, condition: T) {
  switch (filterMode) {
    case FilterMode.Includes:
    case FilterMode.Excludes:
      return negateConditionIf(filterMode === FilterMode.Excludes, condition);
    default:
      return;
  }
}

export function filterModeIsEmptyArray<T>(filterMode: FilterMode, condition: T) {
  switch (filterMode) {
    case FilterMode.IsEmpty:
    case FilterMode.IsNotEmpty:
      return negateConditionIf(filterMode === FilterMode.IsEmpty, condition);
    default:
      return;
  }
}

export function filterModeOrNullCondition<T>(
  filterMode: FilterMode,
  getCondition: (isNullComparison?: { _is_null: true }) => T,
) {
  switch (filterMode) {
    case FilterMode.Includes:
      return getCondition();
    case FilterMode.Excludes:
      return {
        _or: [getCondition({ _is_null: true }), getCondition()],
      };
    default:
      return undefined;
  }
}

export function filterModeDateRangeComparison(filterMode: FilterMode, range: (string | null)[]) {
  const [first, second] = range;

  if (!first) {
    return;
  }

  const [firstDate, secondDate] = rangeToDates(first, second);

  const firstValue = firstDate.toISOString();
  const secondValue = secondDate.toISOString();

  switch (filterMode) {
    case FilterMode.Includes:
      return { _gte: firstValue, _lte: secondValue };
    case FilterMode.Before:
      return { _lte: secondValue };
    case FilterMode.After:
      return { _gte: firstValue };
    case FilterMode.Between:
      return second ? { _gte: firstValue, _lte: secondValue } : undefined;
    default:
      return undefined;
  }
}

export function filterModeIsEmpty<T>(
  filterMode: FilterMode,
  wrap: (condition: { _is_null: boolean }) => T,
) {
  switch (filterMode) {
    case FilterMode.IsEmpty:
    case FilterMode.IsNotEmpty:
      return wrap({ _is_null: filterMode === FilterMode.IsEmpty });
    default:
      return undefined;
  }
}

export function filterModeIsEmptyText(filterMode: FilterMode, fieldName: string) {
  if (!isIsEmptyOrIsNotEmpty(filterMode)) return;
  const isEmpty = filterMode === FilterMode.IsEmpty;
  const isEmptyQuery = {
    _or: [{ [fieldName]: { _is_null: true } }, { [fieldName]: { _eq: '' } }],
  };
  const isNotEmptyQuery = {
    _and: [
      {
        [fieldName]: { _is_null: false },
      },
      {
        _not: {
          [fieldName]: { _eq: '' },
        },
      },
    ],
  };
  return isEmpty ? isEmptyQuery : isNotEmptyQuery;
}
