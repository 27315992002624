import { Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

type ErrorPageProps = {
  h1: string;
  h2: string;
  description: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ h1, h2, description }) => {
  const { t } = useTranslation('ui');
  const router = useRouter();

  return (
    <Center w="100%" h="100%" minHeight="500px">
      <Stack direction="column" align="center" textAlign="center">
        <Heading as="h1" fontSize="130px" fontWeight="500" color="#DBD3FB">
          {h1}
        </Heading>
        <Heading
          as="h2"
          fontSize="48px"
          fontWeight="700"
          color="#4A5568"
          margin="0 !important"
          lineHeight="48px"
        >
          {h2}
        </Heading>
        <Box
          textAlign="center"
          fontSize="20px"
          color="#667085"
          fontWeight="400"
          mt="20px !important"
          mb="35px !important"
        >
          <Text>{description}</Text>
          <Text>{t('errorPages.usefulLinks')}</Text>
        </Box>
        <Stack direction="row">
          <Button
            colorScheme="blue"
            variant="outline"
            leftIcon={<ChevronLeftIcon width="18px" />}
            onClick={() => router.history.go(-1)}
          >
            {t('errorPages.goBack')}
          </Button>
        </Stack>
      </Stack>
    </Center>
  );
};

export const Page404: React.FC = () => {
  const { t } = useTranslation('ui');
  return (
    <ErrorPage
      h1={t('errorPages.404.h1')}
      h2={t('errorPages.404.h2')}
      description={t('errorPages.404.description')}
    />
  );
};

export const Page500: React.FC = () => {
  const { t } = useTranslation('ui');
  return (
    <ErrorPage
      h1={t('errorPages.500.h1')}
      h2={t('errorPages.500.h2')}
      description={t('errorPages.500.description')}
    />
  );
};

export const ErrorFallback = () => {
  const { t } = useTranslation('ui');
  return (
    <ErrorPage
      h1={t('errorPages.500.h1')}
      h2={t('errorPages.500.h2')}
      description={t('errorPages.500.description')}
    />
  );
};
