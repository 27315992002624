import { Box, Text } from '@chakra-ui/react';
import { Finding_Resolution_Types_Enum } from '@main/graphql/types.generated';
import { formatDate } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap } from '../user/slice';

export const FindingResolutionReason = ({
  resolutionType,
  user,
  date,
  comment,
}: {
  resolutionType: Finding_Resolution_Types_Enum | undefined;
  date: string | undefined;
  user: string | undefined;
  comment: string | undefined;
}) => {
  const { t } = useTranslation();
  const orgUsers = useAppSelector(getCurrentOrgUsersMap);

  if (!resolutionType || !date) {
    return null;
  }

  /* This handles migrated data where the user might not be present */
  if (!user) {
    return (
      <Box pl={2} borderLeft="2px" borderColor="gray.400" _dark={{ borderColor: 'gray.400' }}>
        <Text fontSize="xs">
          {t('findings.reason.withoutUser', {
            resolution: t(`findings.status.${resolutionType}`),
            date: formatDate(date),
          })}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      fontSize="xs"
      pl={2}
      borderLeft="2px"
      borderColor="gray.400"
      _dark={{ borderColor: 'gray.400' }}
    >
      <Text>
        {comment
          ? t('findings.reason.fullMessage', {
              resolution: t(`findings.status.${resolutionType}`),
              user: orgUsers[user]?.displayName,
              date: formatDate(date),
            })
          : t('findings.reason.withoutComment', {
              resolution: t(`findings.status.${resolutionType}`),
              user: orgUsers[user]?.displayName,
              date: formatDate(date),
            })}
      </Text>
      <Text>{comment}</Text>
    </Box>
  );
};
