import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { Vendor_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

import {
  VENDOR_APPROVAL_STATUS_COLOR,
  VENDOR_ASSESSMENT_STATUS_COLOR,
} from '../shared/status-color';

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedVendorStatus = Object.keys(VENDOR_ASSESSMENT_STATUS_COLOR) as VendorAssessmentStatus[];

export function getAllVendorStatusOptions(t: TFunction<'translation'>) {
  return orderedVendorStatus.map((value) => getVendorStatusOption(t, value));
}

export function getVendorStatusOption(t: TFunction<'translation'>, value: VendorAssessmentStatus) {
  return {
    value,
    label: t(`vendors.enum.assessmentStatus.${value}`),
    colorScheme: VENDOR_ASSESSMENT_STATUS_COLOR[value],
  };
}

export function sortedApprovalStatus(t: TFunction<'translation'>) {
  const sortedMap = {
    pending: Vendor_Approval_Statuses_Enum.Pending,
    accepted: Vendor_Approval_Statuses_Enum.Accepted,
    rejected: Vendor_Approval_Statuses_Enum.Rejected,
  } satisfies Record<Vendor_Approval_Statuses_Enum, Vendor_Approval_Statuses_Enum>;

  return sortedMap;
}

export function getAllApprovalStatusOptions(t: TFunction<'translation'>) {
  const levels = Object.values(sortedApprovalStatus(t));
  const levelOptions = levels.map((value) => getApprovalStatusOption(t, value));

  return levelOptions;
}

export function getApprovalStatusOption(
  t: TFunction<'translation'>,
  value: Vendor_Approval_Statuses_Enum,
) {
  return {
    value,
    label: t(`vendors.props.approvalStatus.enums.${value}`),
    colorScheme: VENDOR_APPROVAL_STATUS_COLOR[value],
  };
}
