import { HStack, Tag, Text } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
  DocumentMagnifyingGlassIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import {
  getQuestionnaireType,
  QuestionnaireType,
  useStableCallback,
  VendorQuestionnairesTabKeys,
} from '@main/shared/utils';
import { Drawer, getVisibleTabs, StatusTag } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../../hooks/use-org-guard';
import { CommentsTab } from '../../comments/comments-tab';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getFormattedVendorQuestionnaire } from '../slice';
import { VendorQuestionnaireDetailsTab } from './details-tab';
import { VendorFindingsTab } from './findings-tab';
import { SubmissionsTab } from './submissions-tab';
import { useVendorQuestionnaireDrawerActions } from './use-drawer-actions';
import { useGetVendorQuestionnairesByIdSubscription } from './vendor-questionnaires.subs';

export const VendorQuestionnaireDrawer = ({ vqId }: { vqId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewVq = userRole.permissionMap?.read_vendors;

  const { isLoading: isVqLoading } = useOrgGuard(
    useGetVendorQuestionnairesByIdSubscription({ vqId }),
    ({ data }) => data?.vendor_questionnaires_by_pk?.questionnaire?.organization_id,
  );
  const { id: vendorQuestionnaireIdFromApi } = useAppSelector((state) =>
    getFormattedVendorQuestionnaire(state, vqId),
  );
  const { tabs } = useVendorQuestionnaireDrawerTabs(vqId);
  const { menuActions } = useVendorQuestionnaireDrawerActions(vqId);

  return (
    <Drawer.Layout
      isLoading={isVqLoading}
      canView={canViewVq}
      isNotFound={!vendorQuestionnaireIdFromApi}
    >
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <VendorQuestionnaireDrawerHeader vqId={vqId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const VendorQuestionnaireDrawerHeader = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const vendorQuestionnaire = useAppSelector((state) =>
    getFormattedVendorQuestionnaire(state, vqId),
  );

  if (!vendorQuestionnaire.questionnaire) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.vendorQuestionnaire')} / {vendorQuestionnaire.questionnaire.internal_id}
        </Tag>
        {vendorQuestionnaire.status ? (
          <StatusTag
            size="sm"
            minW="auto"
            maxW="none"
            colorScheme={QUESTIONNAIRE_STATUSES[vendorQuestionnaire.status].colorScheme}
            data-testid="vendor-questionnaire-status"
          >
            {t(QUESTIONNAIRE_STATUSES[vendorQuestionnaire.status].value)}
          </StatusTag>
        ) : null}
      </HStack>

      <Text fontSize="lg" fontWeight="bold">
        {vendorQuestionnaire.questionnaire.name}
      </Text>
    </>
  );
};

const useVendorQuestionnaireDrawerTabs = (vqId: string) => {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const vq = useAppSelector((state) => getFormattedVendorQuestionnaire(state, vqId));
  const type = getQuestionnaireType(vq.questionnaire);
  const isSubmitted = vq?.status === Vendor_Questionnaire_Statuses_Enum.Submitted;

  const { refetch } = useGetVendorQuestionnairesByIdSubscription({ vqId });

  const refetchComments = useStableCallback(async () => {
    await refetch().unwrap();
  });

  const tabs = useMemo(
    () =>
      getVisibleTabs<VendorQuestionnairesTabKeys>({
        details: {
          label: t('questionnaires.drawer.tabs.details'),
          icon: ListBulletIcon,
          panel: <VendorQuestionnaireDetailsTab vqId={vqId} />,
          hideTabTitle: true,
        },
        submissions: {
          label: t('questionnaires.drawer.tabs.submissions'),
          icon: ClipboardDocumentListIcon,
          panel: <SubmissionsTab vqId={vqId} />,
          isHidden: !isSubmitted,
        },
        findings: {
          label: t('questionnaires.drawer.tabs.findings'),
          icon: DocumentMagnifyingGlassIcon,
          panel: <VendorFindingsTab vendorQuestionnaireId={vqId} />,
          badge: {
            count: vq.findingsCountWithoutResolution,
            severity: vq.highestSeverity,
          },
          isHidden: !(
            isSubmitted &&
            type === QuestionnaireType.Form &&
            organization.is_vendor_questionnaire_ai_review_enabled &&
            vq.findingsCount > 0
          ),
        },
        comments: {
          label: t('questionnaires.drawer.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="vendor-questionnaire"
              entityId={vqId}
              comments={vq?.comments ?? []}
              refetchComments={refetchComments}
            />
          ),
        },
      }),
    [
      isSubmitted,
      organization.is_vendor_questionnaire_ai_review_enabled,
      refetchComments,
      t,
      type,
      vq?.comments,
      vq.findingsCount,
      vq.findingsCountWithoutResolution,
      vq.highestSeverity,
      vqId,
    ],
  );

  return { tabs };
};
