/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type LinkVqDocumentToVendorMutationVariables = Types.Exact<{
  fileId: Types.Scalars['uuid']['input'];
  vqUploadId: Types.Scalars['uuid']['input'];
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type LinkVqDocumentToVendorMutation = {
  __typename?: 'mutation_root';
  insert_vendor_documents_one?: { __typename?: 'vendor_documents'; file_id: string };
};

export type LinkVqDocumentsToVendorMutationVariables = Types.Exact<{
  input: Array<Types.Vendor_Documents_Insert_Input> | Types.Vendor_Documents_Insert_Input;
}>;

export type LinkVqDocumentsToVendorMutation = {
  __typename?: 'mutation_root';
  insert_vendor_documents?: {
    __typename?: 'vendor_documents_mutation_response';
    affected_rows: number;
  };
};

export type UnlinkVqDocumentFromVendorMutationVariables = Types.Exact<{
  fileId: Types.Scalars['uuid']['input'];
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkVqDocumentFromVendorMutation = {
  __typename?: 'mutation_root';
  delete_vendor_documents_by_pk?: { __typename?: 'vendor_documents'; file_id: string };
};

export type UnlinkVqDocumentsFromVendorMutationVariables = Types.Exact<{
  input: Types.Vendor_Documents_Bool_Exp;
}>;

export type UnlinkVqDocumentsFromVendorMutation = {
  __typename?: 'mutation_root';
  delete_vendor_documents?: {
    __typename?: 'vendor_documents_mutation_response';
    affected_rows: number;
  };
};

export const LinkVqDocumentToVendorDocument = `
    mutation LinkVqDocumentToVendor($fileId: uuid!, $vqUploadId: uuid!, $vendorId: uuid!) {
  insert_vendor_documents_one(
    object: {file_id: $fileId, vendor_id: $vendorId, vq_upload_id: $vqUploadId}
  ) {
    file_id
  }
}
    ` as string &
  TypedDocumentNode<LinkVqDocumentToVendorMutation, LinkVqDocumentToVendorMutationVariables>;
export const LinkVqDocumentsToVendorDocument = `
    mutation LinkVqDocumentsToVendor($input: [vendor_documents_insert_input!]!) {
  insert_vendor_documents(objects: $input) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<LinkVqDocumentsToVendorMutation, LinkVqDocumentsToVendorMutationVariables>;
export const UnlinkVqDocumentFromVendorDocument = `
    mutation UnlinkVqDocumentFromVendor($fileId: uuid!, $vendorId: uuid!) {
  delete_vendor_documents_by_pk(file_id: $fileId, vendor_id: $vendorId) {
    file_id
  }
}
    ` as string &
  TypedDocumentNode<
    UnlinkVqDocumentFromVendorMutation,
    UnlinkVqDocumentFromVendorMutationVariables
  >;
export const UnlinkVqDocumentsFromVendorDocument = `
    mutation UnlinkVqDocumentsFromVendor($input: vendor_documents_bool_exp!) {
  delete_vendor_documents(where: $input) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UnlinkVqDocumentsFromVendorMutation,
    UnlinkVqDocumentsFromVendorMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    LinkVqDocumentToVendor: build.mutation<
      LinkVqDocumentToVendorMutation,
      LinkVqDocumentToVendorMutationVariables
    >({
      query: (variables) => ({ document: LinkVqDocumentToVendorDocument, variables }),
    }),
    LinkVqDocumentsToVendor: build.mutation<
      LinkVqDocumentsToVendorMutation,
      LinkVqDocumentsToVendorMutationVariables
    >({
      query: (variables) => ({ document: LinkVqDocumentsToVendorDocument, variables }),
    }),
    UnlinkVqDocumentFromVendor: build.mutation<
      UnlinkVqDocumentFromVendorMutation,
      UnlinkVqDocumentFromVendorMutationVariables
    >({
      query: (variables) => ({ document: UnlinkVqDocumentFromVendorDocument, variables }),
    }),
    UnlinkVqDocumentsFromVendor: build.mutation<
      UnlinkVqDocumentsFromVendorMutation,
      UnlinkVqDocumentsFromVendorMutationVariables
    >({
      query: (variables) => ({ document: UnlinkVqDocumentsFromVendorDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useLinkVqDocumentToVendorMutation,
  useLinkVqDocumentsToVendorMutation,
  useUnlinkVqDocumentFromVendorMutation,
  useUnlinkVqDocumentsFromVendorMutation,
} = injectedRtkApi;
