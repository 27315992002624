import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  ModalBody,
  ModalFooter,
  Tooltip,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDownloadStorageFile, useModal } from '@main/ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { Toggle } from '../shared/toggle';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { ExportPolicyMutationVariables, useExportPolicyMutation } from './export-policy.generated';
import { useGetPolicyQuery } from './manage-policies.generated';

const schema = z.object({
  withVersionsChapter: z.boolean(),
  withRevisionDetails: z.boolean(),
  withApprovalsChapter: z.boolean(),
  withAcknowledgementsChapter: z.boolean(),
});

type Schema = z.infer<typeof schema>;

export const ExportPolicyForm = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { is_policy_acknowledgements_enabled: isPolicyAcknowledgementsEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const defaultValues = useDefaultValues(policyId);

  const { register, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const exportPolicy = useExportPolicy();

  const onSubmit = handleSubmit(
    async ({
      withVersionsChapter,
      withRevisionDetails,
      withApprovalsChapter,
      withAcknowledgementsChapter,
    }) => {
      await exportPolicy({
        policyId,
        withVersionsChapter,
        withRevisionDetails,
        withApprovalsChapter,
        withAcknowledgementsChapter,
      });
      closeModal();
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <Flex direction="column" gap={6}>
          <FormControl
            isInvalid={!!formState.errors.withVersionsChapter}
            isDisabled={!defaultValues.withVersionsChapter}
          >
            <Toggle
              label={t('policies.exportModal.withVersionsChapterLabel')}
              {...register('withVersionsChapter')}
            >
              {!defaultValues.withVersionsChapter && (
                <Tooltip
                  label={t('policies.exportModal.versionsTooltip')}
                  placement="right"
                  hasArrow
                >
                  <Icon as={InformationCircleIcon} w={4} h={4} />
                </Tooltip>
              )}
            </Toggle>
            <FormErrorMessage>{formState.errors.withVersionsChapter?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!formState.errors.withRevisionDetails}
            isDisabled={!defaultValues.withRevisionDetails}
          >
            <Toggle
              label={t('policies.exportModal.withVersionDetailsLabel')}
              {...register('withRevisionDetails')}
            >
              {!defaultValues.withRevisionDetails && (
                <Tooltip
                  label={t('policies.exportModal.versionDetailsTooltip')}
                  placement="right"
                  hasArrow
                >
                  <Icon as={InformationCircleIcon} w={4} h={4} />
                </Tooltip>
              )}
            </Toggle>
            <FormErrorMessage>{formState.errors.withRevisionDetails?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.withApprovalsChapter}>
            <Toggle
              label={t('policies.exportModal.withApprovalsChapterLabel')}
              {...register('withApprovalsChapter')}
            />
            <FormErrorMessage>{formState.errors.withApprovalsChapter?.message}</FormErrorMessage>
          </FormControl>

          {isPolicyAcknowledgementsEnabled && (
            <FormControl
              isInvalid={!!formState.errors.withAcknowledgementsChapter}
              isDisabled={!defaultValues.withAcknowledgementsChapter}
            >
              <Toggle
                label={t('policies.exportModal.withAcknowledgementsChapterLabel')}
                {...register('withAcknowledgementsChapter')}
              >
                {!defaultValues.withAcknowledgementsChapter && (
                  <Tooltip
                    label={t('policies.exportModal.acknowledgmentTooltip')}
                    placement="right"
                    hasArrow
                  >
                    <Icon as={InformationCircleIcon} w={4} h={4} />
                  </Tooltip>
                )}
              </Toggle>
              <FormErrorMessage>
                {formState.errors.withAcknowledgementsChapter?.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </Flex>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button isDisabled={formState.isSubmitting} onClick={closeModal}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting}>
            {t('buttons.confirm')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

function useDefaultValues(policyId: string): Schema {
  const { data } = useGetPolicyQuery({ id: policyId });

  return useMemo(() => {
    const approvedVersion = data?.policies_by_pk?.policy_versions.find(
      (version) => version.status === Policy_Version_Statuses_Enum.Approved,
    );

    const hasApprovedVersion = !!approvedVersion;
    const hasAcknowledgement = !!approvedVersion?.policy_acknowledgements.length;

    return {
      withVersionsChapter: hasApprovedVersion,
      withRevisionDetails: hasApprovedVersion,
      withApprovalsChapter: true,
      withAcknowledgementsChapter: hasAcknowledgement,
    };
  }, [data]);
}

function useExportPolicy() {
  const { t } = useTranslation();

  const [exportPolicy] = useExportPolicyMutation();
  const downloadStorageFile = useDownloadStorageFile();

  return async (input: ExportPolicyMutationVariables['input']) => {
    try {
      const { export_policy } = await exportPolicy({ input }).unwrap();
      if (!export_policy) {
        throw new Error('Policy not found');
      }
      await downloadStorageFile(export_policy.fileId);
      successToast(t('policies.exportModal.successToast'));
    } catch (error) {
      errorToast(t('errorMessages.exportFailed', { entity: t('entities.policy') }));
      datadogLogs.logger.error('Policy export failed', input, toError(error));
    }
  };
}
