import { MigrationHost } from './migration-host';
import { MigrationRegistry } from './migration-registry';

export interface Migration<THost extends MigrationHost, TContext = void> {
  version: number;
  apply(host: THost, ctx: TContext): void | Promise<void>;
}

export interface Logger {
  info(...args: unknown[]): void;
}

export class MigrationManager<THost extends MigrationHost, TContext = void> {
  constructor(
    protected readonly host: THost,
    protected readonly registry: MigrationRegistry<THost, TContext>,
    protected readonly logger: Logger,
  ) {}

  async applyMigrations(ctx: TContext) {
    const currentVersion = await this.host.currentVersion();
    const versions = this.registry.getMigrations();

    await this.host.startMigration?.();

    for (const [version, migration] of versions) {
      if (version <= currentVersion) {
        continue;
      }

      this.logger.info(`Applying migration for version ${version}...`);
      await migration.apply(this.host, ctx);

      await this.host.setCurrentVersion(version);

      this.logger.info(`Applied migration for version ${version}`);
    }

    await this.host.endMigration?.();
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mockMigrationHost<TBase extends new (...args: any[]) => MigrationHost>(
  baseHost: TBase,
) {
  return class MockMigrationHost extends baseHost {
    public version = 0;
    override currentVersion() {
      return this.version;
    }
    override setCurrentVersion(version: number) {
      this.version = version;
    }
  };
}
