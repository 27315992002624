import { ThemeTypings } from '@chakra-ui/react';

export enum Severity {
  LOW,
  CONSIDERATION = 0.5,
  MEDIUM = 1,
  HIGH,
}

export function severityColorScheme(severity: Severity): ThemeTypings['colorSchemes'] {
  switch (severity) {
    case Severity.LOW:
      return 'green';
    case Severity.CONSIDERATION:
      return 'teal';
    case Severity.MEDIUM:
      return 'orange';
    case Severity.HIGH:
      return 'red';
  }
}
