import { HStack, Tag } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  CubeIcon,
  ListBulletIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { useUpdateQuestionnaireMutation } from '@main/graphql/mutations/UpdateQuestionnaire.generated';
import { useGetQuestionnaireSubscription } from '@main/graphql/subscriptions/GetQuestionnaireSubscription';
import {
  getQuestionnaireType,
  QuestionnaireTabKeys,
  QuestionnaireType,
  useStableCallback,
} from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs } from '@main/ui';
import useDebounce from 'ahooks/es/useDebounce';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { CommentsTab } from '../comments/comments-tab';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { QuestionnaireDetailsTab } from './details-tab';
import { QuestionsTab } from './questions-tab';
import { useQuestionnaireDrawerActions } from './use-drawer-actions';
import { useUpdateQuestionnaireHandler } from './use-update-questionnaire-handler';
import { QuestionnairesVendorsTab } from './vendors-tab';

export const QuestionnaireDrawer = ({ questionnaireId }: { questionnaireId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewQuestionnaire = userRole.permissionMap?.read_vendors;

  const { data: questionnaireData, isLoading: isQuestionaireLoading } = useOrgGuard(
    useGetQuestionnaireSubscription({
      id: questionnaireId,
    }),
    ({ data }) => data?.questionnaires_by_pk?.organization_id,
  );
  const isDebouncedLoading = useDebounce(isQuestionaireLoading, { wait: 150 });

  const { tabs } = useQuestionnaireDrawerTabs(questionnaireId);
  const { menuActions } = useQuestionnaireDrawerActions(questionnaireId);

  return (
    <Drawer.Layout
      isLoading={isDebouncedLoading}
      canView={canViewQuestionnaire}
      isNotFound={!questionnaireData?.questionnaires_by_pk}
    >
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <QuestionnaireDrawerHeader questionnaireId={questionnaireId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const QuestionnaireDrawerHeader = ({ questionnaireId }: { questionnaireId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  const canUpdateVendors = userRole.permissionMap?.write_vendors;
  const { id: organizationId } = useAppSelector(getCurrentUserSelectedOrg);
  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();
  const updateQuestionnaireHandler = useUpdateQuestionnaireHandler({
    questionnaireId,
    organizationId,
  });

  const { data } = useGetQuestionnaireSubscription({ id: questionnaireId });
  const questionnaire = data?.questionnaires_by_pk;

  if (!questionnaire) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.questionnaire')} / {questionnaire.internal_id}
        </Tag>
      </HStack>

      <EditableAutoResizeTextarea
        autoFocusIfEmpty
        isDisabled={!canUpdateVendors}
        ml={-2}
        fontSize="xl"
        fontWeight="bold"
        defaultValue={questionnaire.name}
        placeholder={t('questionnaires.drawer.namePlaceholder')}
        onSubmit={(value) =>
          updateQuestionnaireHandler(
            updateQuestionnaire({
              id: questionnaireId,
              updatePayload: {
                name: value,
              },
            }),
          )
        }
      />
    </>
  );
};

const useQuestionnaireDrawerTabs = (questionnaireId: string) => {
  const { t } = useTranslation();

  const { data, refetch } = useGetQuestionnaireSubscription({ id: questionnaireId });
  const questionnaire = data?.questionnaires_by_pk;

  const refetchComments = useStableCallback(async () => {
    await refetch().unwrap();
  });

  const tabs = useMemo(
    () =>
      getVisibleTabs<QuestionnaireTabKeys>({
        details: {
          label: t('questionnaires.drawer.tabs.details'),
          icon: ListBulletIcon,
          panel: <QuestionnaireDetailsTab questionnaireId={questionnaireId} />,
          hideTabTitle: true,
        },
        questions: {
          label: t('questionnaires.drawer.tabs.questions'),
          icon: QueueListIcon,
          panel: <QuestionsTab questionnaireId={questionnaireId} />,
          isHidden: getQuestionnaireType(questionnaire) !== QuestionnaireType.Form,
        },
        vendors: {
          label: t('questionnaires.drawer.tabs.vendors'),
          icon: CubeIcon,
          panel: <QuestionnairesVendorsTab questionnaireId={questionnaireId} />,
        },
        comments: {
          label: t('questionnaires.drawer.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="questionnaire"
              entityId={questionnaireId}
              comments={questionnaire?.comments ?? []}
              refetchComments={refetchComments}
            />
          ),
        },
      }),
    [questionnaire, questionnaireId, refetchComments, t],
  );

  return { tabs };
};
