import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowPathRoundedSquareIcon,
  CheckCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Finding_Resolution_Types_Enum,
  Finding_Resolutions_Insert_Input,
} from '@main/graphql/types.admin.generated';
import { CreateManualFindingInput } from '@main/graphql/types.generated';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog, useModal } from '@main/ui';
import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { FindingForm } from '../controls/finding/finding-form';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { ResolutionActionType } from './finding-action-modal';
import {
  useAddFindingResolutionMutation,
  useRemoveFindingResolutionMutation,
  useUpdateFindingResolutionCommentMutation,
} from './ManageFindings.generated';

interface ActionConfig {
  label: string;
  type: ResolutionActionType;
  icon: ReactElement;
  handler: (input: Finding_Resolutions_Insert_Input) => Promise<void>;
}

export const useFindingResolutionActions = () => {
  const { t } = useTranslation();
  const [addResolution] = useAddFindingResolutionMutation();
  const [updateResolution] = useUpdateFindingResolutionCommentMutation();

  const handleResolution = useStableCallback(async (input: Finding_Resolutions_Insert_Input) => {
    await addResolution({ input }).unwrap();
  });

  const handleResolutionUpdate = useStableCallback(
    async (input: Finding_Resolutions_Insert_Input) => {
      const findingId = input.control_finding_id ?? input.vendor_finding_id;

      try {
        await updateResolution({
          input: {
            comment: input.comment,
          },
          where: {
            _or: [
              {
                control_finding_id: {
                  _eq: findingId,
                },
              },
              {
                vendor_finding_id: {
                  _eq: findingId,
                },
              },
            ],
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
        datadogLogs.logger.error(
          'Failed to update resolution reason for the finding',
          { findingId },
          toError(error),
        );
      }
    },
  );

  return useMemo<Record<ResolutionActionType, ActionConfig>>(
    () => ({
      Resolved: {
        label: t('findings.actionModal.button.Resolved'),
        type: Finding_Resolution_Types_Enum.Resolved,
        icon: <CheckCircleIcon />,
        handler: async (input) =>
          handleResolution({ ...input, type: Finding_Resolution_Types_Enum.Resolved }),
      },
      Accepted: {
        label: t('findings.actionModal.button.Accepted'),
        type: Finding_Resolution_Types_Enum.Accepted,
        icon: <PlusCircleIcon />,
        handler: (input) =>
          handleResolution({ ...input, type: Finding_Resolution_Types_Enum.Accepted }),
      },
      Ignored: {
        label: t('findings.actionModal.button.Ignored'),
        type: Finding_Resolution_Types_Enum.Ignored,
        icon: <XCircleIcon />,
        handler: (input) =>
          handleResolution({ ...input, type: Finding_Resolution_Types_Enum.Ignored }),
      },
      Edit: {
        label: t('findings.actionModal.button.editReason'),
        type: 'Edit',
        icon: <PencilIcon />,
        handler: (input) => handleResolutionUpdate(input),
      },
    }),
    [handleResolution, handleResolutionUpdate, t],
  );
};

export const useFindingReinstateAction = ({
  resolutionId,
}: {
  resolutionId: string | undefined;
}) => {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const [removeResolution] = useRemoveFindingResolutionMutation();

  const reinstateHandler = useCallback(
    async ({ findingResolutionId }: { findingResolutionId: string }) => {
      try {
        await removeResolution({
          findingResolutionId,
        }).unwrap();
        successToast(t('findings.actionModal.successToast.reinstated'));
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
        datadogLogs.logger.error(
          'Reinstating finding failed',
          { findingResolutionId },
          toError(error),
        );
      }
    },
    [removeResolution, t],
  );

  return useMemo(() => {
    /* If finding was not marked as resolved, ignored or accepted it cannot be reinstated */
    if (!resolutionId) {
      return;
    }

    return {
      icon: <ArrowPathRoundedSquareIcon />,
      label: t('findings.actionModal.button.Reinstate'),
      onClick: () => {
        openDialog({
          dialogHeader: t('findings.actionModal.header.Reinstate'),
          dialogContent: t('findings.actionModal.reinstateContent'),
          confirmAction: {
            children: t('findings.actionModal.button.Reinstate'),
            onClick: () => {
              reinstateHandler({
                findingResolutionId: resolutionId,
              });
            },
          },
        });
      },
    };
  }, [reinstateHandler, resolutionId, openDialog, t]);
};

export const useUpdateManualFindingAction = ({
  finding,
}: {
  finding?: CreateManualFindingInput;
}) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateFinding = !!userRole.permissionMap?.update_controls;
  if (!finding || !canUpdateFinding) {
    return;
  }

  return {
    icon: <PencilIcon />,
    label: t('controls.findingModal.editFindingBtn'),
    onClick: () =>
      openModal({
        header: t('controls.findingModal.editing.heading'),
        content: <FindingForm values={finding} />,
      }),
  };
};
