import { ThemeTypings } from '@chakra-ui/react';
import { PermissionMap } from '@main/permissions';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import {
  CONTROL_STATUS_COLOR,
  EVIDENCE_STATUS_COLOR,
  POLICY_STATUS_COLOR,
  RISK_ASSESSMENT_STATUS_COLOR,
  VENDOR_ASSESSMENT_STATUS_COLOR,
} from '../shared/status-color';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { api as getTaskApi } from './get-task.generated';
import {
  TaskLinkedEntityFragment,
  TaskSidebarDataFragment,
} from './sidebar/manage-task-sidebar.generated';
import { FilterCriteria, taskFilter } from './sidebar/sidebar-filter';
import { SortCriteria, taskSort } from './sidebar/sidebar-sort';

const getTaskApiData = createSelector(
  (state: AppRootState, taskId: string) => ({ state, taskId }),
  ({ state, taskId }) => {
    return getTaskApi.endpoints.GetTask.select({
      taskId,
    })(state).data?.tasks_by_pk;
  },
);

export type TaskLinkedEntity = 'control' | 'risk' | 'vendor' | 'policy' | 'evidence';
type Status = {
  value: string;
  colorScheme: ThemeTypings['colorSchemes'];
};
export type LinkedEntityObject = Record<
  TaskLinkedEntity,
  {
    id: string;
    status: Status;
    name?: string;
    internal_id?: string;
    owner?: string;
  }[]
>;

export const getLinkedEntities = <T extends TaskLinkedEntityFragment>(
  task: T,
  permissionMap: PermissionMap,
) => {
  const linkedEntities: LinkedEntityObject = {
    control: [],
    risk: [],
    vendor: [],
    policy: [],
    evidence: [],
  };

  if (permissionMap.read_controls) {
    task?.controls.forEach(({ control }) =>
      linkedEntities.control.push({
        ...control,
        status: {
          value: control.status,
          colorScheme: CONTROL_STATUS_COLOR[control.status],
        },
        owner: control.assignee?.displayName,
      }),
    );
  }

  if (permissionMap.read_risks) {
    task?.risks.forEach(({ risk }) =>
      linkedEntities.risk.push({
        ...risk,
        status: {
          value: risk.assessment_status,
          colorScheme: RISK_ASSESSMENT_STATUS_COLOR[risk.assessment_status],
        },
        owner: risk.assignee?.displayName,
      }),
    );
  }

  if (permissionMap.read_vendors) {
    task?.vendors.forEach(({ vendor }) =>
      linkedEntities.vendor.push({
        ...vendor,
        status: {
          value: vendor.assessment_status,
          colorScheme: VENDOR_ASSESSMENT_STATUS_COLOR[vendor.assessment_status],
        },
        owner: vendor.owner?.displayName,
      }),
    );
  }

  if (permissionMap.read_policies) {
    task?.policies.forEach(({ policy }) =>
      linkedEntities.policy.push({
        ...policy,
        status: {
          value: policy.status,
          colorScheme: POLICY_STATUS_COLOR[policy.status],
        },
        owner: policy.user?.displayName,
      }),
    );
  }

  if (permissionMap.read_evidence) {
    task?.evidence.forEach(({ evidence }) =>
      linkedEntities.evidence.push({
        ...evidence,
        status: {
          value: evidence.controls_aggregate_status,
          colorScheme: EVIDENCE_STATUS_COLOR[evidence.controls_aggregate_status],
        },
        owner: evidence.user?.displayName,
      }),
    );
  }

  return linkedEntities;
};

export const getMappedTask = createSelector(
  [getTaskApiData, getCurrentUserSelectedOrgRole],
  (task, { permissionMap }) => {
    if (!task) {
      return;
    }

    const linkedEntities = getLinkedEntities(task, permissionMap);

    return {
      ...task,
      linkedEntities,
    };
  },
);

export const filterAndSortTasks = createSelector(
  [
    (
      _: AppRootState,
      props: { tasks?: TaskSidebarDataFragment[]; filters: FilterCriteria[]; sorts: SortCriteria },
    ) => props,
    getCurrentUserSelectedOrgRole,
  ],
  ({ tasks = [], filters, sorts }, { permissionMap }) => {
    const formattedTasks =
      tasks?.map((task) => ({
        ...task,
        linkedEntities: getLinkedEntities(task, permissionMap),
      })) ?? [];
    const filteredTasks = formattedTasks
      .filter((task) => taskFilter(task, filters))
      .sort((taskA, taskB) => taskSort(taskA, taskB, sorts));

    return { tasks: filteredTasks };
  },
);
