import {
  StackDivider,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  VStack,
} from '@chakra-ui/react';
import { Nullable } from '@main/shared/types';
import { FilterMode } from '@main/shared/url-helpers';
import { useScheduler } from '@main/shared/utils';
import {
  ColumnDef,
  ColumnFiltersState,
  CoreOptions,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel as originalGetSortedRowModel,
  OnChangeFn,
  PaginationState,
  Row,
  RowModel,
  RowSelectionState,
  SortDirection,
  SortingState,
  Table as TanstackTable,
  TableMeta,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isSafari } from '../../utils';
import { useDrawer } from '../drawer';
import { FilterColumnMeta, getColumnCanGlobalFilter, globalFilterFn } from './filters';
import { shouldDisplayPagination } from './pagination';
import { ColumnTableAction, TableActionHandler, TableActionType } from './table-action';
import { TableCellColumnMeta } from './table-cell';
import { TableFilterResult } from './table-filter-result';
import { shouldDisplayMenu, TableMenu } from './table-menu';
import { InternalTableMeta } from './table-meta';
import { TableEmptyFilterResult, TableEmptyState, TableLoadingState } from './table-render-states';
import { useTableColumnOrder } from './use-table-column-order';
import { useTableColumnSizing } from './use-table-column-sizing';

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData, TValue> {
    name?: string;
    header?: TableColumnHeaderProps;
    cell?: TableCellColumnMeta<TData>;
    filter?: FilterColumnMeta<TData, TValue>;
    globalFilterFn?: (value: TValue, filterValue: string) => boolean;
    action?: ColumnTableAction<TData>;
    getGlobalFilterCondition?<TFilter>(filterValue: string): Nullable<TFilter>;
    getColumnFilterCondition?<TFilter>(
      filterMode: FilterMode,
      filterValue: unknown,
    ): Nullable<TFilter>;
    getColumnSort?<TSort>(sortDirection: SortDirection): TSort;
    isMultiline?: boolean;
    sizeUnit?: 'px' | 'fr';
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface TableRegistry {}

export type TableEntity = TableRegistry extends { entities: infer E } ? E : string;

export type TableItem = {
  singular: string;
  plural: string;
};

export type TableRowReorderHandler<T> = (
  fromRow: Row<T>,
  toRow: Row<T>,
  table: TanstackTable<T>,
) => void;

export type TableProps<T> = TableContainerProps & {
  data: T[];
  id?: string;
  isLoading?: boolean;
  // not possible to use `unknown` with typed accessors
  // https://github.com/TanStack/table/issues/4241
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  defaultColumnVisibility?: VisibilityState;
  columnVisibility?: VisibilityState;
  /**
   * When `onColumnVisibilityChange` callback is provided, the table will display the UI
   * with which user can modify table column's visibility state.
   */
  onColumnVisibilityChange?: OnChangeFn<VisibilityState>;
  hasColumnVisibilityChanged?: boolean;
  pageSize?: number;
  onRowClick?: (row: Row<T>) => void;

  /**
   * `columnFilters` should be validated before passing to the table:
   * passing filters without matching columnd ids / with invalid structure will throw.
   * `isColumnFilter` / `isValidColumnFilter` predicates can be used for parsing filters
   * before passing them to the table
   */
  columnFilters?: ColumnFiltersState;
  onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
  globalFilter?: string;
  onGlobalFilterChange?: OnChangeFn<string>;
  onFilteredDataChange?: (data: T[]) => void;
  itemName: TableItem;
  entity?: TableEntity;
  activeRow?: string;
  disableActiveRow?: boolean;
  getRowId?: CoreOptions<T>['getRowId'];
  rowCount?: number;
  manualFiltering?: boolean;
  manualPagination?: boolean;
  manualSorting?: boolean;
  pagination?: PaginationState;
  onPaginationChange?: OnChangeFn<PaginationState>;
  meta?: TableMeta<T>;
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  minColumnSize?: number;
  onRowReorder?: TableRowReorderHandler<T>;
  getSortedRowModel?(table: TanstackTable<T>): () => RowModel<T>;
  renderEmptyState?: ({ itemName }: { itemName: TableItem }) => ReactNode;
};

const defaultDefaultColumnVisibility = {};

function EditableTable<T>({
  data,
  id,
  isLoading,
  columns,
  itemName,
  defaultColumnVisibility = defaultDefaultColumnVisibility,
  columnVisibility,
  onColumnVisibilityChange,
  hasColumnVisibilityChanged,
  pageSize = Number.MAX_SAFE_INTEGER,
  columnFilters,
  globalFilter,
  entity,
  activeRow,
  disableActiveRow,
  onColumnFiltersChange,
  onGlobalFilterChange,
  onRowClick,
  onFilteredDataChange,
  getRowId,
  rowCount,
  manualFiltering,
  manualPagination,
  manualSorting,
  pagination,
  onPaginationChange,
  meta,
  sorting,
  onSortingChange,
  minColumnSize = 35,
  onRowReorder,
  getSortedRowModel,
  renderEmptyState,
  ...props
}: TableProps<T>) {
  const { t } = useTranslation('ui');
  disableActiveRow = useMemo(() => disableActiveRow ?? isSafari(), [disableActiveRow]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  getRowId = useMemo(() => getRowId ?? ((row: any, i: number) => row.id ?? String(i)), [getRowId]);

  const tableName = testIdFromItemName(itemName.plural);
  const drawer = useDrawer();

  // Using row selection feature to track active row
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({
    ...(activeRow && { [activeRow]: true }),
  });
  const { schedule: scheduleHideActiveRow, cancel: cancelHideActiveRow } = useScheduler({
    fn: useCallback(() => setRowSelection({}), [setRowSelection]),
    delayMs: 1500,
  });

  const { columnSizing, hasColumnSizingChanged, onColumnSizingChange } = useTableColumnSizing(
    tableName,
    id,
  );
  const { columnOrder, hasColumnOrderChanged, onColumnOrderChange } = useTableColumnOrder(
    columns,
    tableName,
    columnVisibility,
    defaultColumnVisibility,
    id,
  );
  const hasMultilineCol = useMemo(() => columns.some((col) => col.meta?.isMultiline), [columns]);
  const internalMeta = useMemo<InternalTableMeta<T>>(
    () => ({ ...meta, hasMultilineCol }),
    [hasMultilineCol, meta],
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel ?? originalGetSortedRowModel(),
    getColumnCanGlobalFilter: getColumnCanGlobalFilter,
    globalFilterFn: globalFilterFn,
    autoResetPageIndex: false,
    rowCount,
    manualFiltering,
    manualPagination,
    manualSorting,
    initialState: {
      columnVisibility: {},
      pagination: { pageSize },
      columnPinning: { right: ['actions'] },
    },
    getRowId,
    enableMultiRowSelection: false,
    state: {
      ...(columnVisibility && { columnVisibility }),
      ...(globalFilter && { globalFilter }),
      ...(columnFilters && { columnFilters }),
      ...(sorting && { sorting }),
      ...(pagination && { pagination }),
      rowSelection: disableActiveRow ? {} : rowSelection,
      columnSizing,
      columnOrder,
    },
    meta: internalMeta,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: undefined,
      minSize: minColumnSize,
      maxSize: undefined,
    },
    ...(onPaginationChange && { onPaginationChange }),
    ...(onSortingChange && { onSortingChange }),
    ...(onGlobalFilterChange && { onGlobalFilterChange }),
    ...(onColumnFiltersChange && { onColumnFiltersChange }),
    ...(onColumnVisibilityChange && { onColumnVisibilityChange }),
    onColumnSizingChange,
    onColumnOrderChange,
  });
  const state = table.getState();
  const filteredRows = table.getFilteredRowModel().rows;

  const actionHandler: TableActionHandler<T> = useCallback(
    (action, row) => {
      switch (action.type) {
        case TableActionType.HighlightRow:
          setRowSelection({ [row.id]: true });
          break;
      }
    },
    [setRowSelection],
  );

  // reset pagination when filters or sorting change
  useEffect(() => {
    table.setPageIndex(0);
  }, [table, state.globalFilter, state.columnFilters, state.sorting]);

  useEffect(() => {
    onFilteredDataChange && onFilteredDataChange(filteredRows.map((row) => row.original));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  // Highlight row opened in drawer
  useEffect(
    () =>
      entity && !disableActiveRow
        ? drawer.onDrawer((data) => {
            if (data?.entity === entity && data.entityId) {
              if (data.entityId in rowSelection === false) {
                cancelHideActiveRow();
                setRowSelection({ [data.entityId]: true });
              }
            } else if (Object.keys(rowSelection).length) {
              scheduleHideActiveRow();
            }
          })
        : undefined,
    [entity, drawer, rowSelection, disableActiveRow, scheduleHideActiveRow, cancelHideActiveRow],
  );

  useEffect(() => {
    const pagination = table.getState().pagination;
    const startingRow = pagination.pageSize * pagination.pageIndex;
    const rowCount = table.getRowCount();

    if (rowCount && startingRow >= rowCount) {
      table.previousPage();
    }
  }, [table]);

  const renderContent = () => {
    if (isLoading) {
      return <TableLoadingState />;
    }

    if (table.options.data.length === 0) {
      return renderEmptyState ? (
        renderEmptyState({ itemName })
      ) : (
        <TableEmptyState
          itemName={itemName}
          subHeading={t('table.empty.subheading', { item: itemName.singular })}
        />
      );
    }

    if (filteredRows.length === 0) {
      return <TableEmptyFilterResult itemName={itemName} />;
    }

    return (
      <TableFilterResult
        itemName={itemName}
        table={table}
        minColumnSize={minColumnSize}
        onRowClick={onRowClick}
        actionHandler={actionHandler}
        setColumnSizing={onColumnSizingChange}
        onRowReorder={onRowReorder}
      />
    );
  };

  return (
    <TableContainer
      minW={table.options.data.length || filteredRows.length ? props.minW : 'full'}
      {...props}
      className={shouldDisplayPagination(table) ? 'with-pagination' : 'without-pagination'}
      data-testid={tableName}
      data-tableid={id}
    >
      <VStack align="normal" spacing={0} divider={<StackDivider />}>
        {shouldDisplayMenu(table) && (
          <TableMenu
            table={table}
            canChangeColumnVisibility={!!onColumnVisibilityChange}
            hasColumnOrderChanged={hasColumnOrderChanged}
            hasColumnVisibilityChanged={!!hasColumnVisibilityChanged}
            hasColumnSizingChanged={hasColumnSizingChanged}
          />
        )}
        {renderContent()}
      </VStack>
    </TableContainer>
  );
}

function testIdFromItemName(name: string) {
  return `${name.replace(/ /g, '-')}-table`;
}

// react's memo does not preserve generic component's type
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
export const Table = memo(EditableTable) as typeof EditableTable;
