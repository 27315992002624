import { Permissions_Enum } from '@main/graphql/types.generated';
import { mapByKey } from '@main/shared/utils';
import { TFunction } from 'i18next';

export type Group =
  | 'controls'
  | 'evidence'
  | 'risks'
  | 'vendors'
  | 'reports'
  | 'roles'
  | 'programs'
  | 'tasks'
  | 'policies'
  | 'trust';

export type Permission = {
  group: Group;
  name: Permissions_Enum;
  isActive: boolean;
  dependentPermissions: Permissions_Enum[];
  hasDependencies: boolean;
  id?: string;
  hasTooltip?: boolean;
};
export interface TranslatedPermission extends Omit<Permission, 'group'> {
  group: string;
}
type PermissionGroup = {
  [P in Permissions_Enum]: TranslatedPermission;
};

export type PermissionMap = Record<Permissions_Enum, true | undefined>;

const permissions = Object.values(Permissions_Enum);

type GroupedPermissionOrder = {
  group: Group;
  order: number;
  hasTooltip?: boolean;
};

export const permissionToGroupOrder = {
  write_programs: {
    group: 'programs',
    order: 1,
  },
  read_controls: {
    group: 'controls',
    order: 1,
    hasTooltip: true,
  },
  update_controls: {
    group: 'controls',
    order: 2,
  },
  create_controls: {
    group: 'controls',
    order: 3,
  },
  link_controls_evidence: {
    group: 'controls',
    order: 4,
  },
  link_controls_programs: {
    group: 'controls',
    order: 5,
  },
  link_controls_risks: {
    group: 'controls',
    order: 6,
  },
  notify_on_control_at_risk: {
    group: 'controls',
    order: 7,
    hasTooltip: true,
  },
  write_control_field_configs: {
    group: 'controls',
    order: 9,
  },
  read_evidence: {
    group: 'evidence',
    order: 1,
  },
  write_evidence: {
    group: 'evidence',
    order: 2,
  },
  read_confidential_evidence: {
    group: 'evidence',
    order: 3,
  },
  write_confidential_evidence: {
    group: 'evidence',
    order: 4,
  },
  read_risks: {
    group: 'risks',
    order: 1,
    hasTooltip: true,
  },
  write_risks: {
    group: 'risks',
    order: 2,
  },
  manage_risk_classification: {
    group: 'risks',
    order: 3,
  },
  write_risk_field_configs: {
    group: 'risks',
    order: 4,
  },
  notify_on_risk_expiring: {
    group: 'risks',
    order: 5,
    hasTooltip: true,
  },
  read_vendors: {
    group: 'vendors',
    order: 1,
    hasTooltip: true,
  },
  write_vendors: {
    group: 'vendors',
    order: 2,
  },
  write_vendor_field_configs: {
    group: 'vendors',
    order: 3,
  },
  notify_on_vendor_expiring: {
    group: 'vendors',
    order: 4,
    hasTooltip: true,
  },
  link_risks_vendors: {
    group: 'vendors',
    order: 5,
  },
  notify_on_questionnaire_overdue: {
    group: 'vendors',
    order: 6,
    hasTooltip: true,
  },
  read_reports: {
    group: 'reports',
    order: 1,
  },
  write_reports: {
    group: 'reports',
    order: 2,
  },
  write_roles: {
    group: 'roles',
    order: 1,
  },
  read_tasks: {
    group: 'tasks',
    order: 1,
  },
  own_read_tasks: {
    group: 'tasks',
    order: 2,
    hasTooltip: true,
  },
  write_tasks: {
    group: 'tasks',
    order: 3,
  },
  write_task_field_configs: {
    group: 'tasks',
    order: 4,
  },
  read_policies: {
    group: 'policies',
    order: 1,
  },
  write_policies: {
    group: 'policies',
    order: 2,
  },
  write_policy_field_configs: {
    group: 'policies',
    order: 3,
  },
  acknowledge_policies: {
    group: 'policies',
    order: 4,
  },
  read_client_q: {
    group: 'trust',
    order: 1,
  },
  write_client_q: {
    group: 'trust',
    order: 2,
  },
  manage_documents: {
    group: 'trust',
    order: 3,
  },
} satisfies Record<Permissions_Enum, GroupedPermissionOrder>;

export const permissionDependencies: Partial<Record<Permissions_Enum, Permissions_Enum[]>> = {
  write_tasks: [Permissions_Enum.ReadTasks, Permissions_Enum.OwnReadTasks],
  read_tasks: [Permissions_Enum.OwnReadTasks],
  write_policies: [Permissions_Enum.ReadPolicies],
  acknowledge_policies: [Permissions_Enum.ReadPolicies],
  write_risks: [Permissions_Enum.ReadRisks],
  notify_on_risk_expiring: [Permissions_Enum.ReadRisks],
  write_vendors: [Permissions_Enum.ReadVendors],
  notify_on_vendor_expiring: [Permissions_Enum.ReadVendors],
  notify_on_questionnaire_overdue: [Permissions_Enum.ReadVendors],
  link_risks_vendors: [Permissions_Enum.ReadRisks, Permissions_Enum.ReadVendors],
  write_reports: [Permissions_Enum.ReadReports],
  create_controls: [Permissions_Enum.UpdateControls, Permissions_Enum.ReadControls],
  update_controls: [Permissions_Enum.ReadControls],
  notify_on_control_at_risk: [Permissions_Enum.ReadControls],
  link_controls_evidence: [Permissions_Enum.ReadControls, Permissions_Enum.ReadEvidence],
  read_confidential_evidence: [Permissions_Enum.ReadEvidence],
  write_confidential_evidence: [
    Permissions_Enum.WriteEvidence,
    Permissions_Enum.ReadConfidentialEvidence,
    Permissions_Enum.ReadEvidence,
  ],
  write_evidence: [Permissions_Enum.ReadEvidence],
  write_client_q: [Permissions_Enum.ReadClientQ],
};

export const groupOrder: Group[] = [
  'controls',
  'evidence',
  'programs',
  'risks',
  'vendors',
  'reports',
  'roles',
  'tasks',
  'policies',
  'trust',
];

// Helper to determine which role enabled permissions cause the current permission to be disabled
const getDisablingPermissions = (
  permission: Permissions_Enum,
  enabledPermissionsForRole: Permissions_Enum[],
): Permissions_Enum[] => {
  return enabledPermissionsForRole.filter((enabledPerm) =>
    permissionDependencies[enabledPerm]?.includes(permission),
  );
};

export const getGroupedPermissions = (
  t: TFunction,
  rolePermissions: Array<{ id: string; permission: Permissions_Enum }>,
) => {
  const rolePermissionsMap = mapByKey(rolePermissions, 'permission');

  return permissions.reduce<PermissionGroup>((map, permission) => {
    const rolePermission = rolePermissionsMap[permission];
    const disablingPermissions = getDisablingPermissions(
      permission,
      rolePermissions.map(({ permission }) => permission),
    );

    return {
      ...map,
      [permission]: {
        ...permissionToGroupOrder[permission],
        group: t(`permissions.groups.${permissionToGroupOrder[permission].group}`),
        name: permission,
        isActive: !!rolePermission,
        id: rolePermission?.id,
        dependentPermissions: disablingPermissions,
        hasDependencies: disablingPermissions.length > 0,
      } satisfies TranslatedPermission,
    };
  }, {} as PermissionGroup);
};

export const getPermissionMap = (rolePermissions: Array<{ permission: Permissions_Enum }> = []) => {
  return rolePermissions.reduce(
    (acc, rolePermission) => ({
      ...acc,
      [rolePermission.permission]: true,
    }),
    {} as PermissionMap,
  );
};
