/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FileInfoFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/FileInfoFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorDocumentsQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorDocumentsQuery = {
  __typename?: 'query_root';
  vendors_by_pk?: {
    __typename?: 'vendors';
    documents: Array<{
      __typename?: 'vendor_documents';
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
      vq_form_upload?: {
        __typename?: 'vendor_questionnaire_form_uploads';
        form_answer: {
          __typename?: 'vendor_questionnaire_form_answers';
          field_name: string;
          form: { __typename?: 'vendor_questionnaire_forms'; vendor_questionnaire_id: string };
        };
      };
    }>;
  };
};

export type VendorDocumentFragment = {
  __typename?: 'vendor_documents';
  file?: { __typename?: 'files'; id: string; name?: string; size?: number };
  vq_form_upload?: {
    __typename?: 'vendor_questionnaire_form_uploads';
    form_answer: {
      __typename?: 'vendor_questionnaire_form_answers';
      field_name: string;
      form: { __typename?: 'vendor_questionnaire_forms'; vendor_questionnaire_id: string };
    };
  };
};

export type GetVendorDocumentsQuestionnairesQueryVariables = Types.Exact<{
  vqIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;

export type GetVendorDocumentsQuestionnairesQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    questionnaire: { __typename?: 'questionnaires'; name?: string };
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      id: string;
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        id: string;
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
      }>;
    };
  }>;
};

export type VendorDocumentsQuestionnaireFragment = {
  __typename?: 'vendor_questionnaires';
  id: string;
  questionnaire: { __typename?: 'questionnaires'; name?: string };
  form?: {
    __typename?: 'vendor_questionnaire_forms';
    id: string;
    config_snapshot?: ClientTypes.FormConfig;
    answers: Array<{
      __typename?: 'vendor_questionnaire_form_answers';
      id: string;
      field_name: string;
      status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
      value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
    }>;
  };
};

export const VendorDocumentFragmentDoc = `
    fragment VendorDocument on vendor_documents {
  file {
    ...FileInfoFragment
  }
  vq_form_upload {
    form_answer {
      field_name
      form {
        vendor_questionnaire_id
      }
    }
  }
}
    `;
export const VendorDocumentsQuestionnaireFragmentDoc = `
    fragment VendorDocumentsQuestionnaire on vendor_questionnaires {
  id
  questionnaire {
    name
  }
  form {
    id
    config_snapshot
    answers {
      id
      field_name
      status
      value
    }
  }
}
    `;
export const GetVendorDocumentsDocument = `
    query GetVendorDocuments($vendorId: uuid!) {
  vendors_by_pk(id: $vendorId) {
    documents {
      ...VendorDocument
    }
  }
}
    ${VendorDocumentFragmentDoc}
${FileInfoFragmentFragmentDoc}` as string &
  TypedDocumentNode<GetVendorDocumentsQuery, GetVendorDocumentsQueryVariables>;
export const GetVendorDocumentsQuestionnairesDocument = `
    query GetVendorDocumentsQuestionnaires($vqIds: [uuid!]!) {
  vendor_questionnaires(where: {id: {_in: $vqIds}}) {
    ...VendorDocumentsQuestionnaire
  }
}
    ${VendorDocumentsQuestionnaireFragmentDoc}` as string &
  TypedDocumentNode<
    GetVendorDocumentsQuestionnairesQuery,
    GetVendorDocumentsQuestionnairesQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorDocuments: build.query<GetVendorDocumentsQuery, GetVendorDocumentsQueryVariables>({
      query: (variables) => ({ document: GetVendorDocumentsDocument, variables }),
    }),
    GetVendorDocumentsQuestionnaires: build.query<
      GetVendorDocumentsQuestionnairesQuery,
      GetVendorDocumentsQuestionnairesQueryVariables
    >({
      query: (variables) => ({ document: GetVendorDocumentsQuestionnairesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorDocumentsQuery,
  useLazyGetVendorDocumentsQuery,
  useGetVendorDocumentsQuestionnairesQuery,
  useLazyGetVendorDocumentsQuestionnairesQuery,
} = injectedRtkApi;
