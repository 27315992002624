import { datadogLogs } from '@datadog/browser-logs';
import {
  useLinkVqDocumentToVendorMutation,
  useUnlinkVqDocumentFromVendorMutation,
} from '@main/graphql/features/VendorQuestionnaire.generated';
import { QuestionnnaireFormFieldAnswer } from '@main/questionnaires-form';
import { isAbortError, maxTextLength, toError } from '@main/shared/utils';
import { errorToast, successToast, Trans, UploadedFile, useAlertDialog } from '@main/ui';
import { useStableCallback } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { VendorQuestionnaireDocumentFragment } from './vendor-questionnaires/vq-form.generated';

export function useVendorDocuments(
  vendorId: string,
  documents?: VendorQuestionnaireDocumentFragment[],
  answers?: readonly QuestionnnaireFormFieldAnswer[],
) {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const [linkDocument] = useLinkVqDocumentToVendorMutation();
  const [unlinkDocument] = useUnlinkVqDocumentFromVendorMutation();

  const uploads = useMemo(
    () =>
      answers?.flatMap((answer) => [
        ...(answer.answer_files ?? []),
        ...(answer.support_files ?? []),
      ]) ?? [],
    [answers],
  );

  const onLinkDocument = useStableCallback(async (file: UploadedFile) => {
    const upload = uploads.find((upload) => upload.file?.id === file.id);

    if (!upload?.id) {
      return;
    }

    try {
      await linkDocument({ vendorId, fileId: file.id, vqUploadId: upload.id }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.file') }));
    } catch (e) {
      datadogLogs.logger.error(
        'Failed linking questionnaire document to vendor',
        { vendorId, file, upload },
        toError(e),
      );
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.file') }));
    }
  });

  const onUnlinkDocument = useStableCallback(async (file: UploadedFile) => {
    const linkedDocument = documents?.find((doc) => doc.file?.id === file.id);

    if (!linkedDocument) {
      return;
    }

    try {
      await openDialog({
        dialogHeader: t('vendors.unlinkFileModal.header'),
        dialogContent: (
          <Trans
            i18nKey="vendors.unlinkFileModal.content"
            values={{ file: maxTextLength(linkedDocument.file?.name ?? '') }}
          />
        ),
        confirmAction: { children: t('buttons.unlink') },
      });

      await unlinkDocument({ vendorId, fileId: file.id }).unwrap();

      successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.file') }));
    } catch (e) {
      if (isAbortError(e)) {
        return;
      }

      datadogLogs.logger.error(
        'Failed unlinking questionnaire document from vendor',
        { vendorId, file, linkedDocument },
        toError(e),
      );
      errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.file') }));
    }
  });

  return { onLinkDocument, onUnlinkDocument };
}
