import { HStack, Tag } from '@chakra-ui/react';
import {
  ArchiveBoxIcon,
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ListBulletIcon,
  PuzzlePieceIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import { useUpdateEvidenceByIdMutation } from '@main/graphql/mutations/UpdateEvidenceById.generated';
import {
  GetEvidenceByIdQuery,
  useGetEvidenceByIdQuery,
} from '@main/graphql/queries/GetEvidenceById.generated';
import { IntegrationCard, IntegrationCardProps } from '@main/integrations/frontend';
import { EvidenceTabKeys, useStableCallback } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { CommentsTab } from '../comments/comments-tab';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { EvidenceControlsTab } from './controls-tab';
import { EvidenceDetailsTab } from './details-tab';
import { getEvidenceControlsSeverity } from './slice';
import { EvidenceTasksTab } from './tasks-tab';
import { useEvidenceDrawerActions } from './use-drawer-actions';
import { useUpdateEvidenceOnDrawer } from './use-update-evidence-handler';
import { useIsUserAuthorizedToChangeEvidence } from './utils';
import { EvidenceVersionsTab } from './versions-tab';

export const EvidenceDrawer = ({ evidenceId }: { evidenceId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewEvidence = userRole.permissionMap?.read_evidence;

  const { data: evidenceData, isLoading: isEvidenceLoading } = useOrgGuard(
    useGetEvidenceByIdQuery({ evidence_id: evidenceId }, { refetchOnMountOrArgChange: true }),
    ({ data }) => data?.evidences_by_pk?.organization_id,
  );
  const { tabs } = useEvidenceDrawerTabs(evidenceId);
  const actions = useEvidenceDrawerActions(evidenceId);

  return (
    <Drawer.Layout
      isLoading={isEvidenceLoading}
      canView={canViewEvidence}
      isNotFound={!evidenceData?.evidences_by_pk}
    >
      <Drawer.Toolbar {...actions} />
      <Drawer.Tabs tabs={tabs}>
        <EvidenceDrawerHeader evidenceId={evidenceId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const EvidenceDrawerHeader = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const { data: evidenceData } = useGetEvidenceByIdQuery({
    evidence_id: evidenceId,
  });
  const evidence = evidenceData?.evidences_by_pk;
  const isUserAuthorizedToChangeEvidence = useIsUserAuthorizedToChangeEvidence(evidence);

  const [updateEvidence] = useUpdateEvidenceByIdMutation();
  const [updateEvidenceHandler] = useUpdateEvidenceOnDrawer({ evidenceId });

  if (!evidence) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.evidence')} / {evidence.internal_id}
        </Tag>
      </HStack>

      <EditableAutoResizeTextarea
        autoFocusIfEmpty
        isDisabled={!isUserAuthorizedToChangeEvidence}
        ml={-2}
        fontSize="xl"
        fontWeight="bold"
        defaultValue={evidence.name}
        placeholder={t('evidences.create.namePlaceholder')}
        onSubmit={(value) => {
          if (value === evidence.name) {
            return;
          }
          updateEvidenceHandler(
            updateEvidence({
              id: evidenceId,
              updatePayload: {
                name: value,
              },
            }),
          );
        }}
      />
    </>
  );
};

const useEvidenceDrawerTabs = (evidenceId: string) => {
  const { t } = useTranslation();
  const { data: evidenceData, refetch } = useGetEvidenceByIdQuery({ evidence_id: evidenceId });
  const evidence = evidenceData?.evidences_by_pk;
  const evidenceIntegration = getEvidenceIntegration(evidence);
  const linkedControls = useAppSelector((state) => getEvidenceControlsSeverity(state, evidenceId));
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewControls = userRole.permissionMap?.read_controls;

  const refetchComments = useStableCallback(async () => {
    await refetch().unwrap();
  });

  const tabs = useMemo(
    () =>
      getVisibleTabs<EvidenceTabKeys>({
        details: {
          label: t('evidences.tabs.details'),
          icon: ListBulletIcon,
          panel: <EvidenceDetailsTab evidenceId={evidenceId} />,
          hideTabTitle: true,
        },
        versions: {
          label: t('evidences.tabs.versions'),
          icon: ArchiveBoxIcon,
          panel: <EvidenceVersionsTab evidenceId={evidenceId} />,
        },
        controls: {
          label: t('evidences.tabs.controls'),
          icon: Square2StackIcon,
          panel: <EvidenceControlsTab evidenceId={evidenceId} />,
          badge: {
            count: linkedControls?.amount,
            severity: linkedControls?.severity,
          },
          isHidden: !canViewControls,
        },
        integrations: {
          label: t('evidences.tabs.integrations'),
          icon: PuzzlePieceIcon,
          panel: <IntegrationCard {...evidenceIntegration} />,
          isHidden: !evidenceIntegration,
        },
        comments: {
          label: t('evidences.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="evidence"
              entityId={evidenceId}
              comments={evidence?.comments ?? []}
              refetchComments={refetchComments}
            />
          ),
        },
        tasks: {
          label: t('evidences.tabs.tasks'),
          icon: ClipboardDocumentCheckIcon,
          panel: <EvidenceTasksTab evidenceId={evidenceId} />,
        },
      }),
    [
      canViewControls,
      evidence?.comments,
      evidenceId,
      evidenceIntegration,
      linkedControls?.amount,
      linkedControls?.severity,
      refetchComments,
      t,
    ],
  );

  return { tabs };
};

function getEvidenceIntegration(
  data: GetEvidenceByIdQuery['evidences_by_pk'],
): IntegrationCardProps | null {
  if (!data) {
    return null;
  }

  const integration = data.evidence_integration;
  if (!integration) {
    return null;
  }

  const disabled =
    integration.organization_integration.disabled ||
    integration.organization_integration.integration.disabled;

  return {
    name: integration.organization_integration.integration.name,
    disabled,
  };
}
