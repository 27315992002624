/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { VendorQuestionnaireDocumentFragmentDoc } from './vq-form.generated';
import { FileInfoFragmentFragmentDoc } from '../../../../../../libs/graphql/src/fragments/FileInfoFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetVendorQuestionnaireDocumentsQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnaireDocumentsQuery = {
  __typename?: 'query_root';
  vendors_by_pk?: {
    __typename?: 'vendors';
    documents: Array<{
      __typename?: 'vendor_documents';
      vq_upload_id?: string;
      file?: { __typename?: 'files'; id: string; name?: string; size?: number };
    }>;
  };
};

export const GetVendorQuestionnaireDocumentsDocument = `
    query GetVendorQuestionnaireDocuments($vendorId: uuid!) {
  vendors_by_pk(id: $vendorId) {
    documents(where: {vq_upload_id: {_is_null: false}}) {
      ...VendorQuestionnaireDocument
    }
  }
}
    ${VendorQuestionnaireDocumentFragmentDoc}
${FileInfoFragmentFragmentDoc}` as string &
  TypedDocumentNode<
    GetVendorQuestionnaireDocumentsQuery,
    GetVendorQuestionnaireDocumentsQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorQuestionnaireDocuments: build.query<
      GetVendorQuestionnaireDocumentsQuery,
      GetVendorQuestionnaireDocumentsQueryVariables
    >({
      query: (variables) => ({ document: GetVendorQuestionnaireDocumentsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorQuestionnaireDocumentsQuery,
  useLazyGetVendorQuestionnaireDocumentsQuery,
} = injectedRtkApi;
