import { Button, Flex, Icon, ModalBody, ModalFooter, Text } from '@chakra-ui/react';
import { LinkIcon } from '@heroicons/react/20/solid';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { getRouteUrl } from '@main/shared/utils';
import { successToast } from '@main/ui';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../../user/slice';
import { useUpdateEvidenceOnDrawer } from '../use-update-evidence-handler';
import { useIsUserAuthorizedToChangeOwner } from '../utils';
import { AccessListEvidence, EvidenceAccessList } from './evidence-access-list';
import { useSetEvidenceConfidentialMutation } from './evidence-access-modal.generated';

export type EvidenceAccessModalProps = {
  evidenceId: string;
};

export const EvidenceAccessModal = ({ evidenceId }: EvidenceAccessModalProps) => {
  const evidenceQuery = useGetEvidenceByIdQuery({
    evidence_id: evidenceId,
  });

  const evidence = evidenceQuery.data?.evidences_by_pk;
  const canManageAccess = useIsUserAuthorizedToChangeOwner(evidence);
  if (!evidence) {
    return null;
  }

  const isConfidential = evidence.is_confidential;

  return (
    <Fragment>
      <ModalBody pb={6}>
        {isConfidential ? (
          <ConfidentialEvidenceModalBody evidence={evidence} />
        ) : (
          <PublicEvidenceModalBody />
        )}
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <CopyLinkButton evidenceId={evidenceId} />
        {canManageAccess && (
          <ToggleConfidentialityButton evidenceId={evidenceId} isConfidential={isConfidential} />
        )}
      </ModalFooter>
    </Fragment>
  );
};

const ConfidentialEvidenceModalBody = ({ evidence }: { evidence: AccessListEvidence }) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={6}>
      <Text>{t('evidences.accessModal.confidential.body')}</Text>
      <EvidenceAccessList evidence={evidence} />
    </Flex>
  );
};

const PublicEvidenceModalBody = () => {
  const { t } = useTranslation();
  const org = useAppSelector(getCurrentUserSelectedOrg);

  return <Text>{t('evidences.accessModal.public.body', { orgName: org.name })}</Text>;
};

const CopyLinkButton = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();

  const copyLink = () => {
    const url = getRouteUrl({
      to: '/evidence',
      search: {
        drawerEntity: 'evidence',
        drawerEntityId: evidenceId,
      },
    });
    window.navigator.clipboard.writeText(url.toString());
  };

  return (
    <Button leftIcon={<Icon as={LinkIcon} />} onClick={copyLink}>
      {t('evidences.accessModal.copyLinkButton')}
    </Button>
  );
};

const ToggleConfidentialityButton = ({
  evidenceId,
  isConfidential,
}: {
  evidenceId: string;
  isConfidential: boolean;
}) => {
  const { t } = useTranslation();

  const [setEvidenceConfidential] = useSetEvidenceConfidentialMutation();
  const [updateEvidenceHandler, { isLoading }] = useUpdateEvidenceOnDrawer({ evidenceId });

  const toggleConfidentiality = async () => {
    const result = await updateEvidenceHandler(
      setEvidenceConfidential({ evidenceId, isConfidential: !isConfidential }),
    );

    if (result) {
      successToast(t(`evidences.accessModal.${translationNs(isConfidential)}.toggleSuccessMsg`));
    }
  };

  return (
    <Button
      colorScheme="blue"
      isLoading={isLoading}
      isDisabled={isLoading}
      leftIcon={<Icon as={LockClosedIcon} />}
      onClick={toggleConfidentiality}
    >
      {t(`evidences.accessModal.${translationNs(isConfidential)}.toggleButton`)}
    </Button>
  );
};

function translationNs(isConfidential: boolean) {
  return isConfidential ? 'confidential' : 'public';
}
