import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link as ChakraLink,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAuthenticationStatus, useSignOut } from '@nhost/react';
import { Link, Navigate } from '@tanstack/react-router';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getUserSSOState, samlSSOInitiated, ssoPageLoaded } from './slice';

export const SSO = () => {
  const [domain, setDomain] = useState('');
  const { authStatus, error } = useAppSelector(getUserSSOState);
  const { isAuthenticated, isLoading: isAuthenticating } = useAuthenticationStatus();
  const bg = useBreakpointValue({ base: 'transparent', md: 'bg-surface' });
  const shadow = { base: 'none', md: useColorModeValue('md', 'dark-lg') };
  const logoSrc = useColorModeValue('/logo.svg', '/logo_dark.svg');
  const linkColor = useColorModeValue('blue.600', 'blue.200');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { signOut } = useSignOut();

  useEffect(() => {
    dispatch(ssoPageLoaded());
  }, [dispatch]);

  useEffect(() => {
    if (error && isAuthenticated) {
      signOut();
    }
  }, [error, isAuthenticated, signOut]);

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!domain) {
      return;
    }

    dispatch(samlSSOInitiated({ teamDomain: domain }));
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    switch (error) {
      case 'ACCOUNT_CREATED_BUT_DISABLED':
        return (
          <Text mt="4" textAlign="center">
            {t('sso.error.ACCOUNT_CREATED_BUT_DISABLED')}
          </Text>
        );
      default:
        return (
          <Text mt="4" textAlign="center" color="red.500">
            {/* error contains SSO api erros and frontend errors, disabled typecheck so that can use translation key directly */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {t(`sso.error.${error}`)}
          </Text>
        );
    }
  };

  if (isAuthenticating || authStatus === 'VERIFYING') {
    return (
      <Center h="100vh">
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Center>
    );
  }

  if (!error && isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Stack spacing={8} mx="auto" maxW="lg" w="lg">
      <Box
        py={{ base: '0', md: '12' }}
        px={{ base: '4', md: '10' }}
        bg={bg}
        boxShadow={shadow}
        borderRadius={{ base: 'none', md: 'xl' }}
      >
        <Box position="relative" width="100%" height="14" p={3}>
          <Image
            alt="logo"
            object-fit="contain"
            sizes="100vw"
            src={logoSrc}
            decoding="async"
            width="100%"
            height="100%"
          />
        </Box>
        <Heading as="h2" size="sm" textAlign="center" mt="4">
          {t('sso.heading')}
        </Heading>

        <form onSubmit={handleOnSubmit}>
          <FormControl variant="default" mt="8">
            <FormLabel variant="default">{t('sso.domainLabel')}</FormLabel>
            <Input
              type="text"
              size="lg"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              required
            />
          </FormControl>

          <Button type="submit" width="100%" mt={6} colorScheme="blue" size="lg">
            {t('sso.submitBtn')}
          </Button>

          {renderError()}
        </form>
      </Box>
      <Box mt="8">
        <Text color="gray.500" textAlign="center">
          {t('sso.notUsingSSO')}{' '}
          <Link to="/">
            <ChakraLink as="span" color={linkColor}>
              {t('sso.goBack')}
            </ChakraLink>
          </Link>
        </Text>
      </Box>
    </Stack>
  );
};
