import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useCreateControlMutation } from '@main/graphql/mutations/CreateControl.generated';
import { useGetProgramQuery } from '@main/graphql/queries/GetProgram.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  successToast,
  Table,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetOrganizationControls } from '../controls/org-controls-query';
import { getProgramControls } from '../controls/slice';
import { useControlsTableSettings } from '../controls/table-settings';
import { useControlsTableColumns } from '../controls/use-table-columns';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkControlsToProgramMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';

export const ProgramControls = () => {
  const { t } = useTranslation();

  const drawer = useDrawer();
  const params = useParams({ from: '/programs/$programId' });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkControl = userRole.permissionMap?.link_controls_programs;

  const programId = params.programId;
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const { isLoading: isLoadingControls } = useGetOrganizationControls(organization.id);
  const controls = useAppSelector((state) => getProgramControls(state, programId));

  const { framework } = useGetProgramQuery(
    { programId },
    {
      selectFromResult: ({ data }) => ({ framework: data?.programs_by_pk?.framework }),
    },
  );

  const {
    columnVisibility,
    setColumnVisibility,
    defaultColumnVisibility,
    isColumnVisibilityModified,
  } = useControlsTableSettings(`programs:${programId}:controls`);
  const columns = useControlsTableColumns({
    page: 'program-page',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'controlsFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'controlsSort' });

  const controlSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Controls],
  });
  const [createControl] = useCreateControlMutation();
  const { linkControlHandler, isLoading: isLinkingControl } = useLinkControl({
    onClose: () => controlSearch.disclosure.onClose?.(),
  });

  const onCreateControl = async () => {
    try {
      const controlDrawer = await drawer.openLoading({ entity: 'control' });
      const response = await createControl({
        controlInput: {
          // We need only custom framework ID,
          // if current program was created from template framework we can pass undefined to framework_id
          framework_id: framework?.id,
          organization_id: organization.id,
          program_id: programId,
        },
      }).unwrap();

      if (!response.create_control?.id) {
        throw new Error('Could not get new control id from response');
      }

      controlDrawer.load({ entityId: response.create_control.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.control') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.control') }));
      datadogLogs.logger.error('Creating new control failed', {}, toError(error));
    }
  };

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.control').toLowerCase(),
      plural: t('entities.plural.controls').toLowerCase(),
    };
  }, [t]);

  return (
    <Card variant="table-styles">
      <CardHeader>
        <Box>
          <Heading size="md">{t('entities.plural.controls')}</Heading>
          <Text variant="subheading">{t('controls.subheading.programs')}</Text>
        </Box>
        {canLinkControl && (
          <Menu>
            <MenuButton as={Button} rightIcon={<Icon as={ChevronDownIcon} />} colorScheme="blue">
              {t('controls.add.button.newControl')}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onCreateControl}>
                {t('controls.add.button.newCustomControl')}
              </MenuItem>
              <MenuItem onClick={() => controlSearch.disclosure.onOpen?.()}>
                {t('controls.add.button.linkExistingControl')}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </CardHeader>

      <CardBody>
        <Table
          id={programId}
          isLoading={isLoadingControls}
          minW="900px"
          entity="control"
          data={controls}
          columns={columns}
          itemName={tableItemName}
          pageSize={15}
          defaultColumnVisibility={defaultColumnVisibility}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={setColumnVisibility}
          hasColumnVisibilityChanged={isColumnVisibilityModified}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          globalFilter={globalFilter}
          onGlobalFilterChange={setGlobalFilter}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </CardBody>

      <LinkEntityModal
        isOpen={controlSearch.disclosure.isOpen}
        onClose={() => controlSearch.disclosure.onClose?.()}
        entityName="control"
        onLinkEntity={() => linkControlHandler(controlSearch.selectedEntities)}
        isLinkingEntity={isLinkingControl}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.control').toLowerCase(),
            }),
          }}
          {...controlSearch}
        />
      </LinkEntityModal>
    </Card>
  );
};

function useLinkControl({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { programId } = useParams({ from: '/programs/$programId' });
  const [linkControlToProgram, { isLoading }] = useLinkControlsToProgramMutation();

  const linkControlHandler = async (results: AppGlobalSearchResult[]) => {
    try {
      await linkControlToProgram({
        input: results.map((result) => ({ control_id: result.id, program_id: programId })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.control') }));
      onClose();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.control') }));
      datadogLogs.logger.error(
        'Linking control(s) to program failed',
        { programId, controls: results },
        toError(error),
      );
    }
  };

  return { linkControlHandler, isLoading };
}
