/* eslint-disable react-hooks/rules-of-hooks */
import { FormControl, Icon, Stack, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { FormConfig } from '@main/graphql/client-scalars';
import { useDeleteVendorDocumentsMutation } from '@main/graphql/mutations/DeleteVendorDocuments.generated';
import { useAddVendorDocumentsMutation } from '@main/graphql/mutations/InsertVendorDocuments.generated';
import { getFilteredConfig } from '@main/questionnaires-form';
import {
  isAbortError,
  isNonNullable,
  maxTextLength,
  toError,
  useStableCallback,
} from '@main/shared/utils';
import {
  errorToast,
  FileInputProps,
  FileUpload,
  successToast,
  Trans,
  UploadedFile,
  useAlertDialog,
  useDownloadStorageFile,
  useDrawer,
  useEagerMultipleFilesUpload,
  useStatefulCallback,
} from '@main/ui';
import { UploadFileCard, UploadFileCardActionsFactory, UploadFileCardIcon } from '@main/ui/cards';
import { CardAction } from '@main/ui/cards/base-card';
import { UnlinkIcon } from '@main/ui/icons';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'vitest';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import {
  useGetVendorDocumentsQuery,
  useGetVendorDocumentsQuestionnairesQuery,
  VendorDocumentFragment,
  VendorDocumentsQuestionnaireFragment,
} from './documents-tab.generated';
import { useVendorDocuments } from './use-vendor-documents';

export const VendorDocumentsTab = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;

  const { documents, refetch } = useGetVendorDocumentsQuery(
    { vendorId },
    { selectFromResult: ({ data }) => ({ documents: data?.vendors_by_pk?.documents ?? [] }) },
  );
  const { data: vqData } = useGetVendorDocumentsQuestionnairesQuery({
    vqIds: documents
      .map((doc) => doc.vq_form_upload?.form_answer?.form?.vendor_questionnaire_id)
      .filter(isNonNullable),
  });
  const vendorQuestionnaires = useMemo(
    () => resolveVendorQuestionnaires(vqData?.vendor_questionnaires ?? []),
    [vqData?.vendor_questionnaires],
  );
  const [deleteDocument] = useDeleteVendorDocumentsMutation();
  const [uploadDocuments] = useAddVendorDocumentsMutation();

  const fileUpload = useEagerMultipleFilesUpload({
    files: documents.map((doc) => doc.file).filter(isNonNullable),
    onFileDownload: useDownloadStorageFile(),
    onFileDelete: async (fileId) => {
      try {
        await openDialog({
          dialogHeader: t('vendors.props.documents.deleteAlert.header'),
          dialogContent: (
            <Trans
              i18nKey="vendors.props.documents.deleteAlert.content"
              values={{
                name: maxTextLength(
                  documents.find((doc) => doc.file?.id === fileId)?.file?.name ?? '',
                ),
              }}
            />
          ),
          confirmAction: { children: t('buttons.delete') },
        });
        await deleteDocument({ fileId, vendorId }).unwrap();
        await refetch().unwrap();
        successToast(
          t('successMessages.deleteSucceeded', { entity: t('entities.vendorDocument') }),
        );
      } catch (error) {
        if (isAbortError(error)) {
          return;
        }

        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.vendorDocument') }));
        datadogLogs.logger.error(
          'Failed deleting vendor document',
          { vendorId, fileId },
          toError(error),
        );
      }
    },
    onFilesUpload: async ({ errors, files }) => {
      if (errors.length) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.vendorDocument') }));
        for (const error of errors) {
          datadogLogs.logger.error(
            'Failed uploading vendor document files',
            { vendorId },
            toError(error),
          );
        }
        return;
      }

      const documents = files.map((file) => ({
        file_id: file.id,
        vendor_id: vendorId,
      }));
      try {
        datadogLogs.logger.info('Uploading vendor documents', { documents });
        await uploadDocuments({ documents_input: documents }).unwrap();
        await refetch().unwrap();
      } catch (e) {
        errorToast(t('errorMessages.uploadFailed', { entity: t('entities.vendorDocument') }));
        datadogLogs.logger.error('Failed uploading vendor documents', { documents }, toError(e));
      }
    },
  });

  const { onUnlinkDocument } = useVendorDocuments(vendorId, documents);
  const handleUnlinkDocument = useStableCallback(async (file: UploadedFile) => {
    await onUnlinkDocument(file);
    await refetch().unwrap();
  });

  const filesProps: FileInputProps = useMemo(
    () => ({
      icon: documentFileIcon(documents, vendorQuestionnaires) as UploadFileCardIcon,
      actions: documentFileActions(documents, handleUnlinkDocument) as UploadFileCardActionsFactory,
    }),
    [documents, vendorQuestionnaires, handleUnlinkDocument],
  );

  return (
    <FormControl isReadOnly={!canUpdateVendor}>
      <Text fontSize="xs" mb={2}>
        {t('vendors.props.documents.subheading')}
      </Text>
      <FileUpload {...fileUpload} {...filesProps}>
        <FileUpload.Dropzone inputProps={{ 'aria-label': t('vendors.props.documents.heading') }} />
      </FileUpload>
    </FormControl>
  );
};

interface VendorDocumentsQuestionnaireResolved extends VendorDocumentsQuestionnaireFragment {
  resolvedConfig: FormConfig;
}

function resolveVendorQuestionnaires(
  questionnaires: VendorDocumentsQuestionnaireFragment[],
): VendorDocumentsQuestionnaireResolved[] {
  return questionnaires.map((questionnaire) => ({
    ...questionnaire,
    resolvedConfig: getFilteredConfig(
      questionnaire.form?.config_snapshot ?? [],
      questionnaire.form?.answers ?? [],
    ),
  }));
}

function documentFileIcon(
  documents: VendorDocumentFragment[],
  vQuestionnaires: VendorDocumentsQuestionnaireResolved[],
) {
  return (icon: ReactNode, file: UploadedFile) => {
    const { t } = useTranslation();
    const bgColor = useColorModeValue('gray.200', 'gray.600');
    const drawer = useDrawer();

    const upload = useMemo(
      () => documents.find((doc) => doc.file?.id === file.id)?.vq_form_upload,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [file, documents],
    );

    const linkInfo = useMemo(() => {
      if (!upload) {
        return;
      }

      const vQuestionnaire = vQuestionnaires.find(
        (vq) => vq.id === upload.form_answer.form.vendor_questionnaire_id,
      );

      if (!vQuestionnaire) {
        return;
      }

      const questionIdx = vQuestionnaire.resolvedConfig.findIndex(
        (field) => field.name === upload.form_answer.field_name,
      );

      if (questionIdx === -1) {
        return;
      }

      return {
        vqId: vQuestionnaire.id,
        name: vQuestionnaire.questionnaire.name ?? '',
        number: questionIdx + 1,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upload, vQuestionnaires]);

    return (
      <Stack direction="row">
        {icon}
        {linkInfo && (
          <Tooltip
            placement="top"
            hasArrow
            label={t('vendors.props.documents.questionnaireFileLinked', linkInfo)}
          >
            <UploadFileCard.Icon
              bgColor={bgColor}
              cursor="pointer"
              onClick={() =>
                drawer.open({
                  entity: 'vendor-questionnaire',
                  entityId: linkInfo.vqId,
                  activeTab: 'submissions',
                })
              }
            >
              <Icon as={ClipboardDocumentIcon} />
            </UploadFileCard.Icon>
          </Tooltip>
        )}
      </Stack>
    );
  };
}

function documentFileActions(
  documents: VendorDocumentFragment[],
  onUnlinkDocument: (file: UploadedFile) => Promise<void>,
) {
  return (actions: Nullable<CardAction>[], file: UploadedFile): Nullable<CardAction>[] => {
    const { t } = useTranslation();

    const isLinked = useMemo(
      () => !!documents.find((doc) => doc.file?.id === file.id)?.vq_form_upload,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [file, documents],
    );

    const unlinkDocument = useStatefulCallback(() => onUnlinkDocument(file));

    return useMemo(
      () => [
        ...actions.filter((action) => !isLinked || action?.label !== t('buttons.delete')),
        isLinked
          ? {
              label: t('buttons.unlink'),
              icon: UnlinkIcon,
              onClick: unlinkDocument.callback,
              isLoading: unlinkDocument.isLoading,
              isRead: true,
            }
          : undefined,
      ],
      [actions, isLinked, t, unlinkDocument],
    );
  };
}
