import { Evidences_Bool_Exp, Evidences_Order_By } from '@main/graphql/types.generated';
import { FilterMode, isIsEmptyOrIsNotEmpty } from '@main/shared/url-helpers';
import { createColumnHelper, useDrawer } from '@main/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import {
  filterModeDateRangeComparison,
  filterModeILikeComparison,
  filterModeInComparison,
  filterModeIsEmpty,
  filterModeIsEmptyArray,
  filterModeIsEmptyText,
  filterModeNegateCondition,
  filterModeOrNullCondition,
} from '../../utils/hasura-filter';
import { hasuraSortDirection } from '../../utils/hasura-sort';
import { getMappedOrgPrograms } from '../program/slice';
import { getCurrentOrgUsersMap } from '../user/slice';
import { useGetEvidenceInternalIds, useSearchEvidenceTags } from './evidence-hooks';
import { OrganizationEvidence } from './slice';
import { getAllEvidenceStatusOptions, getEvidenceStatusOption } from './table-column-options';

export function useEvidenceColumnHelper({
  forceClientSide = false,
}: {
  forceClientSide?: boolean;
} = {}) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const orgPrograms = useAppSelector(getMappedOrgPrograms);
  const searchEvidenceTags = useSearchEvidenceTags();
  const getInternalIds = useGetEvidenceInternalIds();
  const isServerPaginationFlagEnabled = useFeatureFlagEnabled('evidence-sever-pagination');
  const isServerPaginationEnabled = forceClientSide ? false : isServerPaginationFlagEnabled;

  return useMemo(() => {
    const columnHelper = createColumnHelper<
      OrganizationEvidence,
      Evidences_Bool_Exp,
      Evidences_Order_By
    >();

    return {
      status() {
        return columnHelper.columns.status({
          id: 'status',
          header: t('evidences.table.columns.status'),
          accessorFn: ({ controls_aggregate_status: status }) => {
            return getEvidenceStatusOption(t, status);
          },
          size: 100,
          getOptions: () => getAllEvidenceStatusOptions(t),
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmpty(filterMode, (condition) => ({
                controls_aggregate_status: condition,
              })) ??
              filterModeNegateCondition(filterMode, {
                controls_aggregate_status: { _in: filterValue },
              })
            );
          },
          enableSorting: true,
          getColumnSort(sortDirection) {
            return { controls_aggregate_status: hasuraSortDirection(sortDirection) };
          },
        });
      },

      internalId() {
        return columnHelper.columns.tag({
          id: 'internalId',
          header: t('evidences.table.columns.internalId'),
          accessorFn: ({ internal_id }) => ({
            value: internal_id ?? '',
            colorScheme: 'purple',
          }),
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            return { internal_id: { _ilike: `%${filterValue}%` } };
          },
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmpty(filterMode, (condition) => ({
                internal_id: condition,
              })) ??
              filterModeNegateCondition(filterMode, {
                internal_id: { _in: filterValue },
              })
            );
          },
          enableSorting: true,
          getColumnSort(sortDirection) {
            return { internal_id: hasuraSortDirection(sortDirection) };
          },
          getOptions: isServerPaginationEnabled
            ? async () => {
                const internalIds = await getInternalIds();
                return internalIds.map(({ internal_id }) => ({
                  value: internal_id ?? '',
                  colorScheme: 'purple',
                }));
              }
            : undefined,
          size: 100,
        });
      },

      name(options?: { onClickOpenDrawer?: boolean }) {
        return columnHelper.columns.text({
          id: 'name',
          header: t('evidences.table.columns.name'),
          accessorFn: ({ name }) => name,
          meta: {
            cell: {
              onClick: options?.onClickOpenDrawer
                ? (cell) => drawer.open({ entity: 'evidence', entityId: cell.row.original.id })
                : undefined,
            },
          },
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmptyText(filterMode, 'name') ??
              filterModeOrNullCondition(filterMode, (comparison) => ({
                name: comparison || filterModeILikeComparison(filterMode, filterValue),
              }))
            );
          },
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            return { name: { _ilike: `%${filterValue}%` } };
          },
          enableSorting: true,
          getColumnSort(sortDirection) {
            return { name: hasuraSortDirection(sortDirection) };
          },
          enableHiding: false,
        });
      },

      description() {
        return columnHelper.columns.text({
          id: 'description',
          header: t('evidences.table.columns.description'),
          accessorFn: ({ description }) => description,
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmptyText(filterMode, 'description') ??
              filterModeOrNullCondition(filterMode, (comparison) => ({
                description: comparison || filterModeILikeComparison(filterMode, filterValue),
              }))
            );
          },
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            return { description: { _ilike: `%${filterValue}%` } };
          },
          enableSorting: true,
          getColumnSort(sortDirection) {
            return { description: hasuraSortDirection(sortDirection) };
          },
          size: 300,
        });
      },

      programs() {
        return columnHelper.columns.tag({
          id: 'programs',
          header: t('evidences.table.columns.programs'),
          accessorFn: ({ programs }) => {
            return programs?.map((program) => ({
              value: program.id,
              label: program.name,
              colorScheme: program.colorScheme,
            }));
          },
          size: 130,
          getOptions: isServerPaginationEnabled
            ? () => {
                return orgPrograms.map((program) => ({
                  value: program.id,
                  label: program.name,
                  colorScheme: program.colorScheme,
                }));
              }
            : undefined,
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmptyArray(filterMode, {
                control_evidences: {
                  control: {
                    program_controls: {},
                  },
                },
              }) ??
              filterModeNegateCondition(filterMode, {
                control_evidences: {
                  control: {
                    program_controls: {
                      program_id: { _in: filterValue },
                    },
                  },
                },
              })
            );
          },
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            return {
              control_evidences: {
                control: {
                  program_controls: { program: { name: { _ilike: `%${filterValue}%` } } },
                },
              },
            };
          },
          enableSorting: !isServerPaginationEnabled,
          isMulti: true,
        });
      },

      tags() {
        return columnHelper.columns.tag({
          id: 'tags',
          header: 'Tag',
          accessorFn: ({ tags }) => {
            return tags.map(({ tag }) => ({
              value: tag.id,
              label: tag.name,
              colorScheme: 'purple',
            }));
          },
          size: 200,
          getOptions: isServerPaginationEnabled
            ? async () => {
                const tags = await searchEvidenceTags();
                return tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                  colorScheme: 'purple',
                }));
              }
            : undefined,
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            return (
              filterModeIsEmptyArray(filterMode, {
                tags: {},
              }) ??
              filterModeNegateCondition(filterMode, {
                tags: { tag_id: { _in: filterValue } },
              })
            );
          },
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            return { tags: { tag: { name: { _ilike: `%${filterValue}%` } } } };
          },
          enableSorting: !isServerPaginationEnabled,
          isMulti: true,
        });
      },

      controlsCount() {
        return columnHelper.columns.text({
          id: 'controlsCount',
          header: t('evidences.table.columns.numberOfControls'),
          accessorFn: ({ control_evidences }) => control_evidences.length.toString(),
          size: 95,
          enableSorting: !isServerPaginationEnabled,
        });
      },

      owner() {
        return columnHelper.columns.avatar({
          id: 'owner',
          header: t('evidences.table.columns.owner'),
          accessorFn: ({ owner_id }) => {
            if (!owner_id) {
              return COMPLYANCE_USER;
            }

            const user = currentOrgUsers[owner_id];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          size: 70,
          getOptions: isServerPaginationEnabled
            ? () => {
                const users = Object.values(currentOrgUsers).map((user) => ({
                  id: user.id,
                  displayName: user.displayName,
                }));
                return [...users, COMPLYANCE_USER];
              }
            : undefined,
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, userIds) {
            if (isIsEmptyOrIsNotEmpty(filterMode)) {
              return filterModeIsEmptyArray(filterMode, { owner_id: {} });
            }
            const complyanceIdx = userIds.indexOf(COMPLYANCE_USER.id);
            if (complyanceIdx < 0) {
              return filterModeOrNullCondition(filterMode, (comparison) => ({
                owner_id: comparison || filterModeInComparison(filterMode, userIds),
              }));
            }

            const userIdsWithoutComplyance = [
              ...userIds.slice(0, complyanceIdx),
              ...userIds.slice(complyanceIdx + 1),
            ];
            return filterModeNegateCondition(filterMode, {
              _or: [
                { owner_id: { _is_null: true } },
                { owner_id: { _in: userIdsWithoutComplyance } },
              ],
            });
          },
          enableGlobalFilter: true,
          getGlobalFilterCondition(filterValue) {
            const condition = { user: { displayName: { _ilike: `%${filterValue}%` } } };
            if (!COMPLYANCE_USER.displayName.toLowerCase().includes(filterValue.toLowerCase())) {
              return condition;
            }
            return { _or: [{ owner_id: { _is_null: true } }, condition] };
          },
          enableSorting: true,
          getColumnSort(sortDirection) {
            return { user: { displayName: hasuraSortDirection(sortDirection) } };
          },
        });
      },

      validityStart() {
        return columnHelper.columns.date({
          id: 'validityStart',
          header: t('evidences.table.columns.currentValidityStart'),
          accessorFn: ({ currentVersion }) => currentVersion.validityStart,
          enableColumnFilter: true,
          getColumnFilterCondition(filterMode, filterValue) {
            if (isIsEmptyOrIsNotEmpty(filterMode)) {
              return filterModeNegateCondition(
                filterMode === FilterMode.IsEmpty ? FilterMode.Excludes : FilterMode.Includes,
                {
                  evidence_versions: {},
                },
              );
            }
            const dateFilter = filterModeDateRangeComparison(filterMode, filterValue);
            return (
              dateFilter && {
                evidence_versions: {
                  is_current: { _eq: true },
                  validity_start: dateFilter,
                },
              }
            );
          },
          size: 150,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          enableSorting: !isServerPaginationEnabled,
        });
      },

      createdBy() {
        return columnHelper.columns.avatar({
          id: 'createdBy',
          header: t('evidences.table.columns.createdBy'),
          accessorFn: ({ created_by }) => {
            if (!created_by) {
              return COMPLYANCE_USER;
            }

            const user = currentOrgUsers[created_by];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      createdAt() {
        return columnHelper.columns.date({
          id: 'createdAt',
          header: t('evidences.table.columns.createdAt'),
          accessorFn: ({ created_at }) => created_at,
          enableColumnFilter: true,
          enableSorting: true,
          size: 160,
        });
      },
    };
  }, [
    t,
    isServerPaginationEnabled,
    getInternalIds,
    drawer,
    orgPrograms,
    searchEvidenceTags,
    currentOrgUsers,
  ]);
}
