import {
  Box,
  BoxProps,
  TableCellProps as ChakraTableCellProps,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { Promisable } from '@main/shared/types';
import { Cell, flexRender, Row } from '@tanstack/react-table';
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DragHandleIcon } from '../../icons/drag-handle';
import { whenNotSelecting } from '../../utils/selection';
import { TableAction, TableActionHandler } from './table-action';

export type TableCellColumnMeta<TData> = Omit<ChakraTableCellProps, 'onClick'> & {
  onClick?: (cell: Cell<TData, unknown>) => Promisable<void | TableAction>;
};

export interface TableCellProps<TData> {
  cell: Cell<TData, unknown>;
  row: Row<TData>;
  boxProps?: BoxProps;
  isReorderable?: boolean;
  actionHandler: TableActionHandler<TData>;
}

export function TableCell<TData>({
  cell,
  row,
  boxProps,
  isReorderable,
  actionHandler,
}: TableCellProps<TData>) {
  const { t } = useTranslation('ui');

  const columnDef = cell.column.columnDef;
  const meta = columnDef.meta;
  const cellMeta = meta?.cell;
  const cursor = cellMeta?.onClick && 'pointer';
  const action = meta?.action;
  const actionTrigger = action?.triggerOn ?? 'onClick';
  const isFirstColumn = cell.column.getIsFirstColumn();
  const colSpan =
    cell.column.getIsFirstColumn('left') || cell.column.getIsLastColumn('right') ? 2 : 1;
  const reorderTooltipDisclosure = useDisclosure();

  const onClick = useMemo(
    () => cellMeta?.onClick && whenNotSelecting(cellMeta.onClick),
    [cellMeta?.onClick],
  );

  const handleAction = useCallback(
    (event: SyntheticEvent) => {
      if (!action) {
        return;
      }

      const resultAction = action.actionFactory ? action.actionFactory(row, cell, event) : action;

      resultAction && actionHandler(resultAction, row, cell);
    },
    [action, cell, row, actionHandler],
  );

  const handleClick = useCallback(
    async (event: SyntheticEvent) => {
      if (!onClick) {
        if (actionTrigger === 'onClick') {
          handleAction(event);
        }

        return;
      }

      const resultAction = await onClick(cell);

      if (resultAction) {
        actionHandler(resultAction, row, cell);
      } else if (actionTrigger === 'onClick') {
        handleAction(event);
      }
    },
    [cell, row, actionTrigger, actionHandler, handleAction, onClick],
  );

  const handlers = useMemo(
    () => ({
      [actionTrigger]: action ? handleAction : undefined,
      onClick: onClick ? handleClick : action ? handleAction : undefined,
    }),
    [action, actionTrigger, handleAction, handleClick, onClick],
  );

  const { attributes, listeners, isDragging } = useSortable({ id: cell.row.id });

  const dragHandleStyles = useMemo(
    () => ({
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      bottom: 0,
      w: 5,
      paddingLeft: 2,
      alignItems: 'center',
      justifyContent: 'center',
      color: 'gray.500',
      cursor: 'grab',
      userSelect: 'none',
      zIndex: 1,
      opacity: 0,
      transition: 'opacity 0.2s ease-out',
      ':hover': {
        opacity: 1,
      },
    }),
    [],
  );

  useEffect(() => {
    if (isDragging) {
      reorderTooltipDisclosure.onClose();
    }
  }, [isDragging, reorderTooltipDisclosure]);

  return useMemo(
    () => (
      <Box
        aria-label={meta?.name}
        cursor={cursor}
        {...cellMeta}
        {...handlers}
        role="cell"
        className="why-table--cell"
        gridColumnEnd={`span ${colSpan}`}
        {...boxProps}
      >
        {isReorderable && isFirstColumn && (
          <Tooltip
            label={t('table.rows.dragHandleLabel')}
            openDelay={250}
            {...reorderTooltipDisclosure}
          >
            <Box
              {...attributes}
              {...listeners}
              __css={dragHandleStyles}
              aria-label={t('table.rows.dragHandleLabel')}
            >
              <DragHandleIcon color="gray.500" />
            </Box>
          </Tooltip>
        )}
        <Box w="full" display="flex">
          {flexRender(columnDef.cell, cell.getContext())}
        </Box>
      </Box>
    ),
    [
      meta?.name,
      cursor,
      cellMeta,
      handlers,
      colSpan,
      boxProps,
      isReorderable,
      isFirstColumn,
      t,
      reorderTooltipDisclosure,
      attributes,
      listeners,
      dragHandleStyles,
      columnDef.cell,
      cell,
    ],
  );
}
