import { createStandaloneToast, theme } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { getUserOrganizationsSubcriptionApi } from '@main/graphql/subscriptions/GetUserOrganizationsSubscription';
import { OAuth2AuthCodePKCE } from '@main/oauth2-auth-code-pkce';
import { promiseRaceObject, sleep, toError } from '@main/shared/utils';
import {
  isInvalidRefreshTokenError,
  loginWithRefreshToken,
} from '@main/shared/utils/login-with-refresh-token';
import { errorToast, successToast } from '@main/ui';
import { removeParameterFromWindow, SignInResponse } from '@nhost/nhost-js';
import {
  createListenerMiddleware,
  ListenerEffectAPI,
  TaskAbortError,
  TypedStartListening,
} from '@reduxjs/toolkit';
import posthog from 'posthog-js';

import { router } from '../../router';
import { AppDispatch, AppRootState } from '../../store';
import { nhost } from '../../utils/nhostClient';
import { localStorageMigrationManager } from '../../utils/storage/local-storage-migrations';
import { featureFlagsUpdated } from '../feature-flags/slice';
import {
  authenticatedUserIdReceived,
  authenticatedUserSetupFinished,
  errorOnLoadingSSOUser,
  getCurrentUserOrgs,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
  getUserSSOState,
  impersonatePageLoaded,
  orgIdFoundInQueryParam,
  orgSwitchCompleted,
  orgSwitchRequested,
  samlSSOInitiated,
  ssoPageLoaded,
  userPreferenceOrgMismatched,
  userPreferenceOrgNotFound,
  userSessionInvalidated,
  userSignedOut,
} from './slice';

export const userListenerMiddleware = createListenerMiddleware();

const { toast } = createStandaloneToast({ theme });

const startListening = userListenerMiddleware.startListening as TypedStartListening<
  AppRootState,
  AppDispatch
>;

const oauthClient = new OAuth2AuthCodePKCE({
  authorizationUrl: `${import.meta.env.VITE_NHOST_AUTH_URL}/saml`,
  tokenUrl: `${import.meta.env.VITE_NHOST_AUTH_URL}/saml/token`,
  clientId: 'dummy',
  redirectUrl: `${window.location.origin}/sso`,
  scopes: [],
  onAccessTokenExpiry(refreshAccessToken) {
    return refreshAccessToken();
  },
  onInvalidGrant(refreshAuthCodeOrRefreshToken) {
    return refreshAuthCodeOrRefreshToken();
  },
});

const initObservabilityTools = (dispatch: AppDispatch, userId: string) => {
  datadogLogs.setUser({ id: userId });
  const dContext = datadogLogs.getInternalContext();

  const user = nhost.auth.getUser();

  if (!user) {
    datadogLogs.logger.error(`unable to fetch user ${userId}`, {});
    return {};
  }

  const impersonatorId = nhost.auth.getHasuraClaim('impersonater-id');

  posthog.identify(userId, {
    email: user.email,
    userId,
    datadogSessionId: dContext?.session_id,
    appEnv: import.meta.env.VITE_APP_ENV,
    impersonatorId: impersonatorId ? impersonatorId : undefined,
  });

  const unsubFeatureFlags = posthog.onFeatureFlags((_flags, variants) => {
    dispatch(featureFlagsUpdated(variants));
  });

  return {
    unsubFeatureFlags,
  };
};

const setOrgDetailsInObservabilityTools = async ({
  getState,
  condition,
}: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  const setOrgDetailsInTools = () => {
    const userOrg = getCurrentUserSelectedOrg(getState());
    const userRole = getCurrentUserSelectedOrgRole(getState());

    if (userOrg?.id) {
      datadogLogs.setUserProperty('orgId', userOrg.id);
      posthog.setPersonProperties({
        orgId: userOrg.id,
        orgName: userOrg.name,
        orgRole: userRole,
        parentOrgId: userOrg.parent_organization_id,
      });
      posthog.group('orgId', userOrg.id);
    }
  };

  setOrgDetailsInTools();

  while (true) {
    await condition((_, currentState, prevState) => {
      const prevOrgId = getCurrentUserSelectedOrgId(prevState);
      const currentOrgId = getCurrentUserSelectedOrgId(currentState);

      return prevOrgId !== currentOrgId;
    });
    setOrgDetailsInTools();
  }
};

const userSwitchOrg = async ({ take, dispatch }: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  while (true) {
    const [orgSwitchAction] = await take(orgSwitchRequested.match);

    await router.navigate({ to: '/', replace: true });
    dispatch(orgSwitchCompleted({ orgId: orgSwitchAction.payload.orgId }));
    await router.navigate({ to: '/home', replace: true });
  }
};

const evaluateUserOrgOnLoad = ({
  getState,
  dispatch,
}: ListenerEffectAPI<AppRootState, AppDispatch>) => {
  const userSelectedOrgId = getCurrentUserSelectedOrgId(getState());
  const userOrgs = getCurrentUserOrgs(getState()) || [];

  // if explicit query param is set, it takes preference
  const urlParams = new URLSearchParams(window.location.search);
  const organizationIdFromQueryParam = urlParams.get('organizationId');
  const hasOrgFoundInQueryParamExist =
    organizationIdFromQueryParam && userOrgs.find((org) => org.id === organizationIdFromQueryParam);

  if (hasOrgFoundInQueryParamExist) {
    dispatch(orgIdFoundInQueryParam({ orgId: organizationIdFromQueryParam }));
    removeParameterFromWindow('organizationId');
    return;
  }

  const getPotentialOrgId = () => {
    let potentialOrgId: string | undefined;
    const mainOrg = userOrgs.find((org) => org.parent_organization_id === null);
    if (mainOrg?.id) {
      potentialOrgId = mainOrg.id;
    } else {
      potentialOrgId = userOrgs[0]?.id;
    }

    return potentialOrgId;
  };

  // if user has no org preference yet
  if (userOrgs.length > 0 && !userSelectedOrgId) {
    const potentialOrgId = getPotentialOrgId();
    potentialOrgId && dispatch(userPreferenceOrgNotFound({ orgId: potentialOrgId }));
  } else if (userOrgs.length > 0 && userSelectedOrgId) {
    const selectedOrg = userOrgs.find((org) => org.id === userSelectedOrgId);

    // if user preferred org is not available anymore
    if (!selectedOrg) {
      const potentialOrgId = getPotentialOrgId();
      potentialOrgId && dispatch(userPreferenceOrgNotFound({ orgId: potentialOrgId }));
    }
  }
};

startListening({
  actionCreator: authenticatedUserIdReceived,
  effect: async (userIdReceivedAction, listenerApi) => {
    const { dispatch, cancelActiveListeners, condition, fork } = listenerApi;
    cancelActiveListeners();

    const { userId } = userIdReceivedAction.payload;

    const { unsubFeatureFlags } = initObservabilityTools(dispatch, userId);

    // get user organizations, their permissions in each organization and other users in the organization
    await dispatch(
      getUserOrganizationsSubcriptionApi.endpoints.GetUserOrganizations.initiate({
        user_id: userId,
      }),
    ).unwrap();

    const setOrgDetailsInObservabilityToolsTask = fork(() =>
      setOrgDetailsInObservabilityTools(listenerApi),
    );

    const orgSwitchTask = fork(() => userSwitchOrg(listenerApi));

    evaluateUserOrgOnLoad(listenerApi);

    try {
      await Promise.race([
        localStorageMigrationManager.applyMigrations({ listenerApi }),
        sleep(5000).then(() => Promise.reject(new Error('Timeout'))),
      ]);
    } catch (error) {
      datadogLogs.logger.error('Error applying localstorage migrations', {}, toError(error));
    }

    // trigger action to indicate boostrap is finished
    dispatch(authenticatedUserSetupFinished());

    if (window.location.pathname === '/') {
      router.navigate({ to: '/home', replace: true });
    }

    try {
      // post login lifecycle management like user session invalidation on user disabled or user signed out
      const winner = await promiseRaceObject({
        userDisabledInOrg: condition((_, currentState) => !getCurrentUserSelectedOrg(currentState)),
        userSignedOut: condition(userSignedOut.match),
      });

      // clear long running tasks and cleanup
      setOrgDetailsInObservabilityToolsTask.cancel();
      orgSwitchTask.cancel();
      datadogLogs.clearUser();
      unsubFeatureFlags?.();
      posthog.reset();

      await nhost.auth.signOut();

      router.navigate({ to: '/' });
      dispatch(userSessionInvalidated());

      if (winner.key === 'userDisabledInOrg') {
        toast({
          status: 'warning',
          title: 'Cannot load the data. You are disabled in this organization.',
        });
      }
    } catch (e) {
      if (e instanceof TaskAbortError) {
        return;
      }
      datadogLogs.logger.error(`error in user listener post auth`, {}, toError(e));
    }
  },
});

startListening({
  actionCreator: userPreferenceOrgMismatched,
  effect: async () => {
    successToast('Switched organization');
  },
});

startListening({
  actionCreator: ssoPageLoaded,
  effect: async (_, { take, fork, dispatch, cancelActiveListeners, getState }) => {
    cancelActiveListeners();

    fork(async () => {
      try {
        if (getUserSSOState(getState()).error) {
          return;
        }

        const hasAuthCode = await oauthClient.isReturningFromAuthServer();

        if (!hasAuthCode) {
          return;
        }
        const tokenResult = await oauthClient.getAccessToken();

        const res = await fetch(`${import.meta.env.VITE_NHOST_AUTH_URL}/saml/identify`, {
          method: 'POST',
          body: JSON.stringify({
            access_token: tokenResult.token?.value,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const result = (await res.json()) as SignInResponse & { message: string };

        if (!res.ok) {
          throw new Error(result?.message);
        }

        await nhost.auth.refreshSession(result?.session?.refreshToken ?? undefined);
        router.navigate({ to: '/' });
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            errorOnLoadingSSOUser({
              error: e.message,
            }),
          );
        } else {
          dispatch(errorOnLoadingSSOUser());
        }
      } finally {
        oauthClient.reset();
      }
    });

    const [samlSSOInitiatedAction] = await take(samlSSOInitiated.match);
    const teamDomain = samlSSOInitiatedAction.payload.teamDomain;

    await oauthClient.fetchAuthorizationCode({
      tenant: teamDomain,
    });
  },
});

startListening({
  actionCreator: impersonatePageLoaded,
  effect: async (action, api) => {
    api.unsubscribe();

    try {
      const { token } = action.payload;

      await nhost.auth.signOut();

      await loginWithRefreshToken(token, nhost);
      router.navigate({ to: '/' });
    } catch (e) {
      if (e instanceof TaskAbortError) {
        return;
      }

      if (isInvalidRefreshTokenError(e)) {
        errorToast('Impersonation failed!, try again or log out first');

        return;
      }

      datadogLogs.logger.error(`error in user impersonation`, {}, toError(e));
      errorToast('Impersonation failed!, try again or log out first');
    } finally {
      api.subscribe();
    }
  },
});
