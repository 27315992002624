import { Text, useFormControlContext } from '@chakra-ui/react';
import { FileField as FileFieldComponent } from '@main/dynamic-form';
import { VendorQuestionnaireFormAnswerValue } from '@main/graphql/client-scalars';
import { FileUpload, useDownloadStorageFile, useEagerFileUpload } from '@main/ui';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuestionnaireFieldFilesContext } from '../field-files';

export const FileField: FileFieldComponent = ({ config }) => {
  const { t } = useTranslation();
  const { control, setError, clearErrors } = useFormContext();
  const { isDisabled, isReadOnly } = useFormControlContext();
  const filesProps = useContext(QuestionnaireFieldFilesContext);
  const onFileDownload = useDownloadStorageFile();
  const canUpload = !isDisabled && !isReadOnly;

  return (
    <Controller
      name={config.name}
      control={control}
      rules={config.validation}
      render={({ field }) => (
        <FileUpload
          // eslint-disable-next-line react-hooks/rules-of-hooks
          {...useEagerFileUpload({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            file: field.value?.file,
            onFileDownload,
            onFileUpload: canUpload
              ? async ({ file, error }) => {
                  if (error) {
                    setError(field.name, {
                      type: 'file-upload-error',
                      message: error.message || 'Unknown error',
                    });
                  } else {
                    clearErrors(field.name);
                    field.onChange({ file } satisfies VendorQuestionnaireFormAnswerValue);
                  }
                }
              : undefined,
            onFileDelete: canUpload
              ? async () => {
                  clearErrors(field.name);
                  field.onChange(null);
                }
              : undefined,
          })}
          {...filesProps}
        >
          {canUpload && <FileUpload.Dropzone inputProps={{ accept: config.accepts }} />}
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {!canUpload && !field.value?.file && (
            <Text>{t('questionnaires.questions.notAnswered')}</Text>
          )}
        </FileUpload>
      )}
    />
  );
};

FileField.resolveReadableValues = ({ config }) => {
  if (!config.value?.file) {
    return [];
  }

  return [`${config.value.file.id} - ${config.value.file.name ?? ''}`];
};

export function isAnswerFile(answer?: VendorQuestionnaireFormAnswerValue) {
  return answer != null && typeof answer === 'object' && 'file' in answer;
}
