import { Flex, FormLabel, forwardRef, Switch, SwitchProps } from '@chakra-ui/react';

interface ToggleProps extends SwitchProps {
  label: string;
}
export const Toggle = forwardRef<React.PropsWithChildren<ToggleProps>, 'input'>((props, ref) => {
  const { label, children, ...switchProps } = props;

  return (
    <Flex gap={2} alignItems="center">
      <Switch {...switchProps} ref={ref} />
      <FormLabel m={0}>{label}</FormLabel>
      {children}
    </Flex>
  );
});
