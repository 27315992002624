import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  CloseButton,
  Heading,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  useCallbackRef,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  api as GetQuestionnaireSubscriptionApi,
  useGetQuestionnaireSubscription,
} from '@main/graphql/subscriptions/GetQuestionnaireSubscription';
import { FormFieldCondition, QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import {
  createSorter,
  hashArrayIndexes,
  isNonNullable,
  maxTextLength,
  toError,
  useUpdateAllQueryData,
} from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  OverflowContainer,
  successToast,
  Table,
  TableItem,
  Trans,
  useAlertDialog,
  useTableRowReorder,
  useTableSearchQuery,
} from '@main/ui';
import { IoGitBranchOutlineIcon } from '@main/ui/icons';
import useLocalStorageState from 'ahooks/es/useLocalStorageState';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { getConditionalFields, isOptionsChanged, resetConditionsOf } from './fields/conditions';
import { EditFieldConditionModal } from './fields/field-conditions-modal';
import { FormFieldConfigModal } from './fields/field-config-modal';
import { useCreateFormFieldConfig, useUpdateFormConfig } from './fields/field-config-mutations';
import { FormFieldConfiguratorProvider } from './fields/field-configurator';
import { FileFieldConfigurator } from './fields/file-field-configurator';
import { OptionsFieldConfigurator } from './fields/options-field-configurator';
import { TextFieldConfigurator } from './fields/text-field-configurator';

export const QuestionsTab = ({ questionnaireId }: { questionnaireId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateQuetionnaire = userRole.permissionMap?.write_vendors;
  const [editingFieldName, setEditingFieldName] = useState<string>();
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({
    searchParam: 'questionSearch',
  });
  const [showAlertMessage, setShowAlertMessage] = useLocalStorageState<boolean>(
    'user:preference:questionTabAlert',
    { defaultValue: true },
  );
  const updateAllQueryData = useUpdateAllQueryData();
  const { data: questionnaireData, isLoading: isLoadingQuestionnaire } =
    useGetQuestionnaireSubscription({
      id: questionnaireId,
    });
  const formConfig = useMemo(
    () => questionnaireData?.questionnaires_by_pk?.form?.config ?? [],
    [questionnaireData?.questionnaires_by_pk?.form?.config],
  );
  const formUploads = useMemo(
    () => questionnaireData?.questionnaires_by_pk?.questionnaire_uploads ?? [],
    [questionnaireData?.questionnaires_by_pk?.questionnaire_uploads],
  );

  const editingFieldSchema = useMemo(
    () =>
      editingFieldName ? formConfig.find((config) => config.name === editingFieldName) : undefined,
    [formConfig, editingFieldName],
  );
  const { openDialog } = useAlertDialog();

  const editingFieldConditionals = useMemo(
    () => (editingFieldSchema ? getConditionalFields(editingFieldSchema, formConfig) : []),
    [editingFieldSchema, formConfig],
  );

  const createFieldConfig = useCreateFormFieldConfig();
  const updateFieldConfig = useUpdateFormConfig();

  const configModal = useDisclosure({
    onClose: () => {
      setEditingFieldName(undefined);
    },
  });
  const conditionalModal = useDisclosure({
    onClose: () => {
      setEditingFieldName(undefined);
    },
  });

  const tableItemName: TableItem = useMemo(() => {
    return {
      singular: t('entities.question').toLowerCase(),
      plural: t('entities.plural.questions').toLowerCase(),
    };
  }, [t]);

  const handleFormFieldEdit = useCallbackRef((name: string) => {
    setEditingFieldName(name);
    configModal.onOpen();
  });
  const handleFormFieldConditionEdit = useCallbackRef((field: QuestionnaireFormFieldConfig) => {
    setEditingFieldName(field.name);
    conditionalModal.onOpen();
  });

  const handleFieldConfigDelete = useCallbackRef((formId: string, elemIdx: number) => {
    const fieldConfig = formConfig[elemIdx];
    const conditionalFields = fieldConfig ? getConditionalFields(fieldConfig, formConfig) : [];

    openDialog({
      dialogHeader: t('questionnaires.questions.delete.header'),
      dialogContent:
        conditionalFields.length > 0 ? (
          <Stack>
            <Text>
              <Trans
                i18nKey="questionnaires.questions.delete.content"
                values={{ name: maxTextLength(fieldConfig?.label ?? '') }}
              />
            </Text>
            <Text>
              <Trans
                i18nKey="questionnaires.questions.delete.affectedQuestions"
                values={{ count: conditionalFields.length }}
              />
            </Text>
            <List>
              {conditionalFields?.map((field) => (
                <ListItem key={field.name}>- {field.label}</ListItem>
              ))}
            </List>
          </Stack>
        ) : (
          <Trans
            i18nKey="questionnaires.questions.delete.content"
            values={{ name: maxTextLength(fieldConfig?.label ?? '') }}
          />
        ),
      confirmAction: {
        children: t('buttons.delete'),
        onClick: async () => {
          try {
            const fieldConfig = formConfig[elemIdx];

            if (!fieldConfig) {
              return;
            }

            let newConfig = [...formConfig];
            newConfig.splice(elemIdx, 1);
            newConfig = resetConditionsOf(fieldConfig, newConfig);

            await updateFieldConfig(formId, newConfig).unwrap();
            successToast(t('successMessages.deleteSucceeded', { entity: t('entities.question') }));
          } catch (e) {
            errorToast(t('errorMessages.deleteFailed', { entity: t('entities.question') }));
            datadogLogs.logger.error('deleting question failed', { formId, elemIdx }, toError(e));
          }
        },
      },
    });
  });

  const columns = useQuestionTableColumns({
    questionnaireId,
    onFormFieldEdit: handleFormFieldEdit,
    onFormFieldConditionEdit: handleFormFieldConditionEdit,
    onFormFieldDelete: handleFieldConfigDelete,
  });

  const handleSubmit = async (newField: QuestionnaireFormFieldConfig) => {
    const formId = questionnaireData?.questionnaires_by_pk?.form_id;
    if (!formId) {
      return;
    }

    if (editingFieldSchema) {
      const fieldInEditIndex = formConfig.findIndex(
        (fieldConfig) => fieldConfig.name === editingFieldSchema.name,
      );

      if (fieldInEditIndex === undefined || fieldInEditIndex === -1) {
        return;
      }

      let newConfig = [...formConfig];
      newConfig[fieldInEditIndex] = newField;

      if (isOptionsChanged(newField, editingFieldSchema)) {
        newConfig = resetConditionsOf(newField, newConfig);
      }

      try {
        await updateFieldConfig(formId, newConfig).unwrap();
        configModal.onClose();
        successToast(t('successMessages.editSucceeded', { entity: t('entities.question') }));
      } catch (e) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.question') }));
        datadogLogs.logger.error(
          'updating question failed',
          { formId, newConfig, formConfig },
          toError(e),
        );
      }
    } else {
      try {
        await createFieldConfig(formId, newField).unwrap();
        configModal.onClose();
        successToast(t('successMessages.createSucceeded', { entity: t('entities.question') }));
      } catch (e) {
        errorToast(t('errorMessages.createFailed', { entity: t('entities.question') }));
        datadogLogs.logger.error('creating question failed', { formId, newField }, toError(e));
      }
    }
  };

  const handleConditionsSubmit = async (condition?: FormFieldCondition) => {
    if (!editingFieldSchema) {
      return;
    }

    await handleSubmit({ ...editingFieldSchema, condition });
  };

  const sorter = useMemo(() => createSorter(), []);
  const onRowsOrderUpdated = async (updatedFields: QuestionnaireFormFieldConfig[]) => {
    const formId = questionnaireData?.questionnaires_by_pk?.form_id;

    if (!formId) {
      return;
    }

    const undoUpdate = updateAllQueryData(
      GetQuestionnaireSubscriptionApi,
      'GetQuestionnaire',
      (draft) => {
        const draftFormConfig = draft.questionnaires_by_pk?.form?.config ?? [];
        const formConfigIndexes = hashArrayIndexes(
          draft.questionnaires_by_pk?.form?.config ?? [],
          'name',
        );
        updatedFields.forEach((updatedField) => {
          const idx = formConfigIndexes[updatedField.name];
          if (idx !== undefined) {
            draftFormConfig[idx] = updatedField;
          }
        });
        draftFormConfig.sort(sorter);
      },
    );

    const newConfig = formConfig
      .map((field) => updatedFields.find((row) => row.name === field.name) ?? field)
      .sort(sorter);

    try {
      await updateFieldConfig(formId, newConfig).unwrap();
    } catch (e) {
      undoUpdate();
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.question') }));
      datadogLogs.logger.error(
        'Reordering of questionnaire questions failed',
        { formId, updatedFields, newConfig, oldConfig: formConfig },
        toError(e),
      );
    }
  };

  return (
    <>
      {showAlertMessage ? (
        <Alert status="warning" variant="left-accent" marginBottom={10}>
          <AlertIcon />
          {t('questionnaires.questions.banner')}
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setShowAlertMessage(false)}
          />
        </Alert>
      ) : null}

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('entities.plural.questions')}</Heading>
            <Text variant="subheading">{t('questionnaires.questions.subheading')}</Text>
          </Box>
          {canCreateQuetionnaire && (
            <Button
              colorScheme="blue"
              leftIcon={<Icon as={PlusIcon} />}
              onClick={configModal.onOpen}
            >
              {t('questionnaires.questions.newQuestionBtn')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <Table
            isLoading={isLoadingQuestionnaire}
            minW="400px"
            data={formConfig}
            getRowId={(row) => row.name}
            columns={columns}
            itemName={tableItemName}
            pageSize={200}
            globalFilter={globalFilter}
            onGlobalFilterChange={setGlobalFilter}
            {...useTableRowReorder({ onRowsOrderUpdated })}
          />
        </CardBody>
      </Card>
      <FormFieldConfiguratorProvider>
        <TextFieldConfigurator.Register />
        <OptionsFieldConfigurator.Register />
        <FileFieldConfigurator.Register />
        <FormFieldConfigModal
          questionnaireId={questionnaireId}
          entity="question"
          key={editingFieldSchema?.name}
          editingFieldSchema={editingFieldSchema}
          isOpen={configModal.isOpen}
          affectedConditionalFields={editingFieldConditionals}
          uploads={formUploads}
          onClose={configModal.onClose}
          onSubmit={handleSubmit}
        />
      </FormFieldConfiguratorProvider>
      <EditFieldConditionModal
        {...conditionalModal}
        field={editingFieldSchema}
        formConfig={formConfig}
        affectedConditionalFields={editingFieldConditionals}
        onSubmit={handleConditionsSubmit}
      />
    </>
  );
};

function useQuestionTableColumns({
  questionnaireId,
  onFormFieldEdit,
  onFormFieldConditionEdit,
  onFormFieldDelete,
}: {
  questionnaireId: string;
  onFormFieldEdit: (id: string) => void;
  onFormFieldConditionEdit: (field: QuestionnaireFormFieldConfig) => void;
  onFormFieldDelete: (formId: string, elemIdx: number) => void;
}) {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditQuetionnaire = userRole.permissionMap?.write_vendors;
  const { data: questionnaireData } = useGetQuestionnaireSubscription({
    id: questionnaireId,
  });

  const formId = questionnaireData?.questionnaires_by_pk?.form_id;

  return useMemo(() => {
    const columnHelper = createColumnHelper<QuestionnaireFormFieldConfig>();
    return [
      columnHelper.columns.tag({
        id: 'type',
        header: t('questionnaires.questions.table.columns.type'),
        accessorFn: ({ kind }) => ({
          value: kind,
          label: t(`form.${kind}.name`),
          colorScheme: 'purple',
        }),
        size: 140,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.text({
        id: 'question',
        header: t('questionnaires.questions.table.columns.questions'),
        accessorFn: (data) => data.label,
        cell: (context) => (
          <Stack spacing={1} px={4} pt={1} w="full">
            <OverflowContainer>
              <OverflowContainer.Tooltip
                label={context.getValue()}
                hasArrow
                placement="bottom-start"
                fontSize="sm"
                openDelay={500}
              >
                <Text isTruncated>{context.getValue()}</Text>
              </OverflowContainer.Tooltip>
            </OverflowContainer>
            {context.row.original.condition && (
              <Text fontSize="xs" color="gray.400">
                {t('questionnaires.questions.table.conditionalLogicLabel')}
              </Text>
            )}
          </Stack>
        ),
        enableGlobalFilter: true,
        meta: {
          cell: {
            onClick: canEditQuetionnaire
              ? (cell) => onFormFieldEdit(cell.row.original.name)
              : undefined,
          },
        },
      }),
      ...(canEditQuetionnaire
        ? [
            columnHelper.columns.actions({
              size: 50,
              menuActions(context) {
                return [
                  {
                    icon: IoGitBranchOutlineIcon,
                    label: t('questionnaires.questions.conditionalModal.heading'),
                    onClick: () => onFormFieldConditionEdit(context.row.original),
                  },
                  {
                    icon: TrashIcon,
                    label: t('buttons.delete'),
                    onClick: () => {
                      if (!formId) {
                        return;
                      }
                      onFormFieldDelete(formId, context.row.index);
                    },
                  },
                ].filter(isNonNullable);
              },
            }),
          ]
        : []),
    ];
  }, [
    canEditQuetionnaire,
    formId,
    onFormFieldConditionEdit,
    onFormFieldDelete,
    onFormFieldEdit,
    t,
  ]);
}
