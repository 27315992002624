/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddTasksToRiskMutationVariables = Types.Exact<{
  tasks: Array<Types.Tasks_Insert_Input> | Types.Tasks_Insert_Input;
}>;

export type AddTasksToRiskMutation = {
  __typename?: 'mutation_root';
  insert_tasks?: {
    __typename?: 'tasks_mutation_response';
    returning: Array<{ __typename?: 'tasks'; id: string }>;
  };
};

export const AddTasksToRiskDocument = `
    mutation AddTasksToRisk($tasks: [tasks_insert_input!]!) {
  insert_tasks(objects: $tasks) {
    returning {
      id
    }
  }
}
    ` as string & TypedDocumentNode<AddTasksToRiskMutation, AddTasksToRiskMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddTasksToRisk: build.mutation<AddTasksToRiskMutation, AddTasksToRiskMutationVariables>({
      query: (variables) => ({ document: AddTasksToRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddTasksToRiskMutation } = injectedRtkApi;
