import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  ModalBody,
  ModalFooter,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { toError } from '@main/shared/utils';
import { AutoResizeTextarea, errorToast, successToast, useModal } from '@main/ui';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import {
  api as controlApi,
  useGetControlAiCriteriaQuery,
  useUpdateControlAiCriteriaMutation,
} from './get-control.generated';

export const AICriteriaModal = ({ controlId }: { controlId: string }) => {
  const controlAICriteriaQuery = useGetControlAiCriteriaQuery({
    controlId,
  });

  if (controlAICriteriaQuery.isLoading) {
    return (
      <Flex justifyContent="center" height="50">
        <Spinner size="lg" color="purple.500" />
      </Flex>
    );
  }

  return <AICriteriaForm controlId={controlId} />;
};

const schema = z.object({
  criterias: z.array(
    z.object({
      id: z.string().uuid().optional(),
      heading: z.string().trim().min(1),
      content: z.string().trim().min(1),
    }),
  ),
});

type Schema = z.infer<typeof schema>;

const defaultValue: Schema['criterias'][number] = {
  heading: '',
  content: '',
};

const AICriteriaForm = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const { data: criteriaData } = useGetControlAiCriteriaQuery({
    controlId,
  });
  const dispatch = useAppDispatch();
  const [updateControlAiCriteria] = useUpdateControlAiCriteriaMutation();
  const isEditing = !!criteriaData?.controls_by_pk?.control_ai_review_criteria?.length;

  const canEditAICriteria = !!userRole.permissionMap?.update_controls;
  const { register, control, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      criterias: isEditing
        ? criteriaData?.controls_by_pk?.control_ai_review_criteria
        : [defaultValue],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criterias',
    keyName: 'fieldId',
  });

  const [removedCriteriaIds, setRemovedCriteriaIds] = useState<string[]>([]);

  const handleRemove = (index: number) => {
    const criteria = fields[index];
    const criteriaId = criteria?.id;
    if (criteriaId) {
      setRemovedCriteriaIds((prev) => [...prev, criteriaId]);
    }
    remove(index);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateControlAiCriteria({
        deleteControlAiCriteriaIds: removedCriteriaIds,
        insertControlAiReviewCriteria: data.criterias.map((criteria) => ({
          control_id: controlId,
          ...criteria,
        })),
      }).unwrap();

      await dispatch(
        controlApi.endpoints.GetControlAiCriteria.initiate(
          { controlId },
          { forceRefetch: true, subscribe: false },
        ),
      ).unwrap();

      successToast(t('controls.aiCriteriaModal.successToast'));
      closeModal();
    } catch (error) {
      datadogLogs.logger.error('Failed to update control ai criteria', data, toError(error));
      errorToast(
        t('controls.aiCriteriaModal.errorToast', { entity: t('entities.clientQuestionnaire') }),
      );
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <ModalBody maxH="60vh" overflowY="auto">
        <Stack spacing={4}>
          {fields.map((field, index) => (
            <Box key={field.fieldId} p={4} borderWidth="1px" borderRadius="md">
              <Stack spacing={3}>
                <FormControl id={`heading-${index}`} isReadOnly={!canEditAICriteria}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <FormLabel>{t('controls.aiCriteriaModal.headingLabel')}</FormLabel>
                    <IconButton
                      aria-label="Remove criteria"
                      icon={<Icon w={4} h={4} as={TrashIcon} />}
                      onClick={() => handleRemove(index)}
                      colorScheme="red"
                      variant="link"
                      size="xs"
                      isDisabled={!canEditAICriteria}
                    />
                  </Flex>
                  <Input
                    placeholder={t('controls.aiCriteriaModal.headingLabelPlaceholder')}
                    {...register(`criterias.${index}.heading`)}
                  />
                </FormControl>
                <FormControl id={`content-${index}`} isReadOnly={!canEditAICriteria}>
                  <FormLabel>{t('controls.aiCriteriaModal.criteriaLabel')}</FormLabel>
                  <AutoResizeTextarea
                    placeholder={t('controls.aiCriteriaModal.criteriaLabelPlaceholder')}
                    {...register(`criterias.${index}.content`)}
                  />
                </FormControl>
              </Stack>
            </Box>
          ))}
          <Button onClick={() => append(defaultValue)} variant="outline">
            {t('controls.aiCriteriaModal.addCriteria')}
          </Button>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button isDisabled={formState.isSubmitting} onClick={closeModal}>
            {t('buttons.cancel')}
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            isLoading={formState.isSubmitting}
            isDisabled={!canEditAICriteria}
          >
            {t('buttons.confirm')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};
