import { RiskAssessmentStatus } from '@main/graphql/client-scalars';
import { Risk_Levels_Enum, Treatment_Plan_Enum } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

import { RISK_ASSESSMENT_STATUS_COLOR, RISK_LEVEL_COLOR } from '../shared/status-color';

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedRiskStatus = Object.keys(RISK_ASSESSMENT_STATUS_COLOR) as RiskAssessmentStatus[];

export function getAllRiskStatusOptions(t: TFunction<'translation'>) {
  return orderedRiskStatus.map((value) => getRiskStatusOption(t, value));
}

export function getRiskStatusOption(t: TFunction<'translation'>, value: RiskAssessmentStatus) {
  return {
    value,
    label: t(`risks.enum.assessmentStatus.${value}`),
    colorScheme: RISK_ASSESSMENT_STATUS_COLOR[value],
  };
}

export function sortedRiskLevels(t: TFunction<'translation'>) {
  const sortedMap = {
    very_low: Risk_Levels_Enum.VeryLow,
    low: Risk_Levels_Enum.Low,
    medium: Risk_Levels_Enum.Medium,
    high: Risk_Levels_Enum.High,
    very_high: Risk_Levels_Enum.VeryHigh,
  } satisfies Record<Risk_Levels_Enum, Risk_Levels_Enum>;

  return sortedMap;
}

export function getAllRiskLevelOptions(t: TFunction<'translation'>) {
  const levels = Object.values(sortedRiskLevels(t));
  return levels.map((value) => getRiskLevelOption(t, value));
}

export function getRiskLevelOption(t: TFunction<'translation'>, value: Risk_Levels_Enum) {
  return {
    value,
    label: t(`risks.enum.level.${value}`),
    colorScheme: RISK_LEVEL_COLOR[value],
  };
}

const treatmentOptions = Object.values(Treatment_Plan_Enum);

export function getAllTreatmentOptions(t: TFunction) {
  return treatmentOptions.map((value) => getTreatmentOption(t, value));
}

export function getTreatmentOption(t: TFunction, value: Treatment_Plan_Enum) {
  return {
    value,
    label: t(`risks.enum.treatment.${value}`),
    colorScheme: 'purple',
  };
}
