import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateManualFindingInput, Finding_Types_Enum } from '@main/graphql/types.generated';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, Select, successToast, useModal } from '@main/ui';
import { ParseKeys, TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useCreateManualFindingMutation } from './create-finding.generated';

type CreateFindingModalProps = {
  controlId: string;
};
type UpdateFindingModalProps = {
  finding: CreateManualFindingInput;
};
export type FindingModalProps = {
  isOpen: boolean;
  onClose(): void;
} & (CreateFindingModalProps | UpdateFindingModalProps);

const schema = z.object({
  title: z
    .string()
    .trim()
    .min(1, { message: 'controls.findingModal.fields.title.error.required' satisfies ParseKeys }),
  description: z.string().trim(),
  type: z.nativeEnum(Finding_Types_Enum),
});

type Schema = z.infer<typeof schema>;

const defaultValues: Schema = {
  title: '',
  description: '',
  type: Finding_Types_Enum.Issue,
};

const Ordered_Finding_Types = {
  [Finding_Types_Enum.Issue]: Finding_Types_Enum.Issue,
  [Finding_Types_Enum.Recommendation]: Finding_Types_Enum.Recommendation,
  [Finding_Types_Enum.Consideration]: Finding_Types_Enum.Consideration,
} satisfies Record<Finding_Types_Enum, Finding_Types_Enum>;

const getOptions = (t: TFunction<'translation'>) => {
  return Object.values(Ordered_Finding_Types).map((type) => ({
    value: type,
    label: t(`findings.type.${type}`),
  }));
};

export const FindingForm = ({
  controlId,
  values,
}: {
  controlId?: string;
  values?: CreateManualFindingInput;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const { register, control, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: values ?? defaultValues,
  });

  const createManualFinding = useCreateManualFinding();
  const editManualFinding = useEditManualFinding();

  const onSubmit = handleSubmit(async ({ title, description, type }) => {
    await (values ? editManualFinding : createManualFinding)({
      type,
      title,
      description,
      controlId,
      manualFindingId: values?.manualFindingId,
    });
    closeModal();
  });

  return (
    <form onSubmit={onSubmit}>
      <ModalBody>
        <Flex direction="column" gap={6}>
          <Controller
            control={control}
            name="type"
            render={({ field, fieldState: { error } }) => (
              <FormControl isInvalid={!!error}>
                <FormLabel display="flex" alignItems="center" gap={1}>
                  <Text>{t('controls.findingModal.fields.type.label')}:</Text>
                  <TypeTooltip />
                </FormLabel>
                <Select
                  {...field}
                  variant="outline"
                  value={{ value: field.value, label: t(`findings.type.${field.value}`) }}
                  options={getOptions(t)}
                  onChange={(option) => (option ? field.onChange(option.value) : undefined)}
                  useBasicStyles
                />
              </FormControl>
            )}
          />

          <FormControl isInvalid={!!formState.errors.title}>
            <FormLabel>{t('controls.findingModal.fields.title.label')}:</FormLabel>
            <Input
              placeholder={t('controls.findingModal.fields.title.placeholder')}
              {...register('title')}
            />
            <FormErrorMessage>{t(formState.errors.title?.message as ParseKeys)}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formState.errors.description}>
            <FormLabel>{t('controls.findingModal.fields.description.label')}:</FormLabel>
            <Textarea
              placeholder={t('controls.findingModal.fields.description.placeholder')}
              {...register('description')}
            />
            <FormErrorMessage>{formState.errors.description?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button isDisabled={formState.isSubmitting} onClick={closeModal}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting}>
            {t('buttons.confirm')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

const TypeTooltip = () => {
  const { t } = useTranslation();

  const label = t('controls.findingModal.fields.type.tooltip', {
    issueName: t('findings.type.issue'),
    issueStatus: t('controls.status.enums.Failing'),
    recommendationName: t('findings.type.recommendation'),
    recommendationStatus: t('controls.status.enums.At risk'),
    considerationName: t('findings.type.consideration'),
  });

  return (
    <Tooltip label={label} whiteSpace="pre-wrap" placement="right" hasArrow>
      <Icon as={InformationCircleIcon} w={4} h={4} />
    </Tooltip>
  );
};

function useCreateManualFinding() {
  const { t } = useTranslation();

  const [createManualFinding] = useCreateManualFindingMutation();

  return useStableCallback(async (input: CreateManualFindingInput) => {
    try {
      await createManualFinding({ input }).unwrap();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.finding') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('failed to create manual finding', input, toError(error));
    }
  });
}

function useEditManualFinding() {
  const { t } = useTranslation();

  const [editManualFinding] = useCreateManualFindingMutation();

  return useStableCallback(async (input: CreateManualFindingInput) => {
    try {
      await editManualFinding({ input }).unwrap();
      successToast(t('controls.findingModal.editing.successToast'));
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('failed to edit manual finding', input, toError(error));
    }
  });
}
