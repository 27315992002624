import {
  Box,
  Center,
  Flex,
  Icon,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Tag,
  TagCloseButton,
  Text,
  Tooltip,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  GlobalSearchNoResults,
  GlobalSearchResults,
  GlobalSearchResultsContainer,
  GlobalSearchResultsProps,
  useGlobalSearchContext,
} from '@main/ui';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { AppGlobalSearchResult } from '../global-search/use-global-search';

export interface EntitySearchResultsProps extends GlobalSearchResultsProps<AppGlobalSearchResult> {
  hasRecommendation?: boolean;
  isRecommendationLoading?: boolean;
}

export function EntitySearchResults({
  hasRecommendation,
  isRecommendationLoading,
  ...props
}: EntitySearchResultsProps) {
  const { t } = useTranslation('ui');

  return (
    <GlobalSearchResultsContainer>
      <GlobalSearchResults
        px={4}
        {...props}
        renderHeader={hasRecommendation && <AiRecommendationResultsHeader />}
      />
      <GlobalSearchNoResults>
        <VStack py="12" px="4" spacing="1">
          <Text fontWeight="semibold">{t('globalSearch.noResults.heading')}</Text>
          <Text fontSize="xs" color="gray.500">
            {t('globalSearch.noResults.description')}
          </Text>
        </VStack>
      </GlobalSearchNoResults>
      <AiRecommendationNoResults
        hasRecommendation={hasRecommendation}
        isRecommendationLoading={isRecommendationLoading}
      />
    </GlobalSearchResultsContainer>
  );
}

export interface EntitySearchResultsPopoverProps
  extends GlobalSearchResultsProps<AppGlobalSearchResult> {
  popoverDisclosure: UseDisclosureReturn;
  popoverWidth?: string;
  popoverHeight?: string;
  children?: ReactNode;
}

export function EntitySearchResultsPopover({
  popoverDisclosure,
  popoverWidth,
  popoverHeight,
  children,
  ...props
}: EntitySearchResultsPopoverProps) {
  const globalSearch = useGlobalSearchContext<AppGlobalSearchResult>();
  const canBeOpen = globalSearch.hasResults || globalSearch.isNoResults;

  return (
    <Popover
      placement="bottom"
      isOpen={canBeOpen && popoverDisclosure.isOpen}
      onClose={popoverDisclosure.onClose}
      autoFocus={false}
      returnFocusOnClose={false}
    >
      <PopoverAnchor>
        <Box w="full" h="1px"></Box>
      </PopoverAnchor>
      <PopoverContent w={popoverWidth}>
        <PopoverBody p="0" maxH={popoverHeight} overflowY="auto">
          {children ?? <EntitySearchResults {...props} />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function SelectedEntities({
  selection,
  onSelectionUpdate,
}: {
  selection: AppGlobalSearchResult[];
  onSelectionUpdate: ((updatedSelection: AppGlobalSearchResult[]) => void) | undefined;
}) {
  if (selection.length === 0 || !onSelectionUpdate) {
    return null;
  }

  const updateSelection = (selectedEntity: AppGlobalSearchResult) => {
    onSelectionUpdate(selection.filter((entity) => entity.id !== selectedEntity.id));
  };

  return (
    <Flex flexWrap="wrap" gap={2} px={4}>
      {selection.map((entity) => (
        <Tag key={entity.id} colorScheme="purple" justifyContent="space-between">
          <Text noOfLines={1}>{entity.title}</Text>
          <TagCloseButton onClick={() => updateSelection(entity)} />
        </Tag>
      ))}
    </Flex>
  );
}

function AiRecommendationNoResults({
  hasRecommendation,
  isRecommendationLoading,
}: {
  hasRecommendation: boolean | undefined;
  isRecommendationLoading: boolean | undefined;
}) {
  const { t } = useTranslation('ui');
  const globalSearch = useGlobalSearchContext();

  if (!hasRecommendation || isRecommendationLoading || globalSearch.hasResults) {
    return;
  }

  return (
    <Center py="12" px="4">
      <Text fontWeight="semibold">{t('globalSearch.aiRecommendation.noResult')}</Text>
    </Center>
  );
}

function AiRecommendationResultsHeader() {
  const { t } = useTranslation('ui');

  return (
    <Flex alignItems="center" color="gray.400" pb={3} gap={1}>
      <Text fontSize="sm" fontWeight="medium">
        {t('globalSearch.aiRecommendation.heading')}
      </Text>
      <Tooltip
        label={t('globalSearch.aiRecommendation.tooltip')}
        placement="right"
        openDelay={300}
        hasArrow
      >
        <Icon as={InformationCircleIcon} />
      </Tooltip>
    </Flex>
  );
}
