import { Client_Questionnaire_Status_Enum } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

import { CLIENT_QESTIONNAIRE_STATUSES } from './statuses';

const orderedCQStatus = Object.keys(
  CLIENT_QESTIONNAIRE_STATUSES,
) as Client_Questionnaire_Status_Enum[];

const uniqueOrderedCQStatus = orderedCQStatus.filter(
  (status, index, self) =>
    index ===
    self.findIndex(
      (s) => CLIENT_QESTIONNAIRE_STATUSES[s].value === CLIENT_QESTIONNAIRE_STATUSES[status].value,
    ),
);

export function getAllCQStatusOptions(t: TFunction<'translation'>) {
  return uniqueOrderedCQStatus.map((value) => getCQStatusOption(t, value));
}

export function getCQStatusOption(
  t: TFunction<'translation'>,
  value: Client_Questionnaire_Status_Enum,
) {
  const metaType = CLIENT_QESTIONNAIRE_STATUSES[value];
  return {
    value,
    label: t(metaType.value),
    colorScheme: metaType.colorScheme,
  };
}
