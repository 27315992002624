/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { OrganizationEvidenceFragmentDoc } from '../../../../../libs/graphql/src/fragments/OrganizationEvidenceFragment.generated';
import { EvidenceProgramFragmentDoc } from '../../../../../libs/graphql/src/fragments/EvidenceProgramFragment.generated';
import { api } from '@main/graphql/shared-api';
export type GetEvidencePaginatedQueryVariables = Types.Exact<{
  where: Types.Evidences_Bool_Exp;
  offset: Types.Scalars['Int']['input'];
  limit: Types.Scalars['Int']['input'];
  orderBy: Array<Types.Evidences_Order_By> | Types.Evidences_Order_By;
}>;

export type GetEvidencePaginatedQuery = {
  __typename?: 'query_root';
  evidences_aggregate: {
    __typename?: 'evidences_aggregate';
    aggregate?: { __typename?: 'evidences_aggregate_fields'; count: number };
  };
  evidences: Array<{
    __typename?: 'evidences';
    id: string;
    name?: string;
    updated_at: string;
    owner_id?: string;
    description?: string;
    internal_id?: string;
    controls_aggregate_status: ClientTypes.EvidenceStatus;
    created_at: string;
    is_confidential: boolean;
    created_by?: string;
    tags: Array<{
      __typename?: 'evidence_tags';
      id: string;
      tag: { __typename?: 'organization_evidence_tags'; id: string; name: string };
    }>;
    evidence_versions: Array<{
      __typename?: 'evidence_versions';
      validity_start: string;
      url?: string;
      created_by?: string;
      updated_at: string;
      evidence_version_file?: {
        __typename?: 'evidence_version_file';
        file: { __typename?: 'files'; id: string; name?: string };
      };
    }>;
    acl: Array<{ __typename?: 'evidence_acl'; id: string; user_id: string }>;
    control_evidences: Array<{
      __typename?: 'control_evidences';
      status: ClientTypes.ControlEvidenceStatus;
      control: {
        __typename?: 'controls';
        programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
      };
    }>;
  }>;
};

export type GetEvidenceInternalIdsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type GetEvidenceInternalIdsQuery = {
  __typename?: 'query_root';
  evidences: Array<{ __typename?: 'evidences'; internal_id?: string }>;
};

export const GetEvidencePaginatedDocument = `
    query GetEvidencePaginated($where: evidences_bool_exp!, $offset: Int!, $limit: Int!, $orderBy: [evidences_order_by!]!) {
  evidences_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  evidences(where: $where, offset: $offset, limit: $limit, order_by: $orderBy) {
    ...OrganizationEvidence
  }
}
    ${OrganizationEvidenceFragmentDoc}
${EvidenceProgramFragmentDoc}` as string &
  TypedDocumentNode<GetEvidencePaginatedQuery, GetEvidencePaginatedQueryVariables>;
export const GetEvidenceInternalIdsDocument = `
    query GetEvidenceInternalIds($organizationId: uuid!) {
  evidences(
    where: {organization_id: {_eq: $organizationId}}
    order_by: {internal_id: asc}
  ) {
    internal_id
  }
}
    ` as string &
  TypedDocumentNode<GetEvidenceInternalIdsQuery, GetEvidenceInternalIdsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEvidencePaginated: build.query<
      GetEvidencePaginatedQuery,
      GetEvidencePaginatedQueryVariables
    >({
      query: (variables) => ({ document: GetEvidencePaginatedDocument, variables }),
    }),
    GetEvidenceInternalIds: build.query<
      GetEvidenceInternalIdsQuery,
      GetEvidenceInternalIdsQueryVariables
    >({
      query: (variables) => ({ document: GetEvidenceInternalIdsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetEvidencePaginatedQuery,
  useLazyGetEvidencePaginatedQuery,
  useGetEvidenceInternalIdsQuery,
  useLazyGetEvidenceInternalIdsQuery,
} = injectedRtkApi;
