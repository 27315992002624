import { isNonNullable, NAMESPACE, useStableCallback } from '@main/shared/utils';
import { ColumnDef, ColumnOrderState, OnChangeFn } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import { useUserOrgId } from '../../utils';

type ColumnOrderStateLocalStorage = {
  columnOrder: ColumnOrderState;
  defaultColumnOrder: ColumnOrderState;
};

const isNotActionsColumn = ({ id }: { id?: string }) => id !== 'actions';

export function useTableColumnOrder<T>(
  columns: ColumnDef<T>[],
  tableName: string,
  columnVisibility: Record<string, boolean> = {},
  defaultColumnVisibility: Record<string, boolean>,
  tableId?: string,
  saveDebounceMs = 16,
) {
  const organizationId = useUserOrgId();
  const keyPostfix = organizationId
    ? `:org-id-${organizationId}:column-ordering-v2`
    : `:column-ordering`;
  const generalKey = `${NAMESPACE}${tableName}${keyPostfix}`;
  const columnOrderingKey = tableId
    ? `${NAMESPACE}${tableName}:${tableId}${keyPostfix}`
    : generalKey;

  const defaultColumnOrder = useMemo(
    () =>
      columns
        .filter(isNotActionsColumn)
        .sort((a, b) => {
          const aVisible = a?.id ? (defaultColumnVisibility[a?.id] ?? true) : true;
          const bVisible = b?.id ? (defaultColumnVisibility[b?.id] ?? true) : true;
          if (aVisible === bVisible) return 0;
          return aVisible ? -1 : 1;
        })
        .map((column) => column.id)
        .filter(isNonNullable),
    [defaultColumnVisibility, columns],
  );
  const initialState = useMemo(() => {
    const localStorageItem =
      localStorage.getItem(columnOrderingKey) || localStorage.getItem(generalKey);
    const localStorageParsed: unknown = localStorageItem && JSON.parse(localStorageItem);
    if (isColumnOrderState(localStorageParsed)) {
      return localStorageParsed;
    }

    return { columnOrder: defaultColumnOrder, defaultColumnOrder };
  }, [columnOrderingKey, defaultColumnOrder, generalKey]);

  const [columnOrderState, setColumnOrderState] = useState(initialState);

  const onColumnOrderChange: OnChangeFn<ColumnOrderState> = useStableCallback(
    (update: ColumnOrderState | ((prevState: ColumnOrderState) => ColumnOrderState)) => {
      if (Array.isArray(update) && update.length === 0) {
        setColumnOrderState((state) => ({ ...state, columnOrder: defaultColumnOrder }));
        return;
      }
      setColumnOrderState((state) => ({
        ...state,
        columnOrder: typeof update === 'function' ? update(state.columnOrder) : update,
      }));
    },
  );

  const columnsWithoutActions = useMemo(() => columns.filter(isNotActionsColumn), [columns]);
  useEffect(() => {
    if (columnsWithoutActions.length !== columnOrderState.columnOrder.length) {
      // This handles a couple scenarios:
      // 1. Asynchronous loading of columns
      // 2. Columns being added or removed (e.g hardcoded or custom fields)
      // In these cases, we want to sync the state, maintaining the order where possible
      const newColumns = columnsWithoutActions
        .map((column) => column.id)
        .filter(isNonNullable)
        .filter((id) => !columnOrderState.columnOrder.includes(id));
      setColumnOrderState((state) => ({
        ...state,
        columnOrder: [...state.columnOrder, ...newColumns],
      }));
    }
  }, [columnOrderState, columnOrderState.columnOrder, columns, columnsWithoutActions]);
  useEffect(() => {
    setColumnOrderState((state) => ({ ...state, defaultColumnOrder }));
  }, [defaultColumnOrder]);
  useEffect(() => {
    const timeout = setTimeout(
      () => localStorage.setItem(columnOrderingKey, JSON.stringify(columnOrderState)),
      saveDebounceMs,
    );
    return () => clearTimeout(timeout);
  }, [columnOrderState, columnOrderingKey, saveDebounceMs]);

  const visibleColumnOrderState = columnOrderState.columnOrder.filter(
    (columnId) => columnVisibility[columnId] ?? true,
  );
  const defaultVisibleColumnOrderState = columnOrderState.defaultColumnOrder.filter(
    (columnId) => defaultColumnVisibility[columnId] ?? true,
  );
  const hasColumnOrderChanged = !(
    visibleColumnOrderState.length === defaultVisibleColumnOrderState.length &&
    visibleColumnOrderState.every((id, index) => id === defaultVisibleColumnOrderState[index])
  );

  return {
    columnOrder: columnOrderState.columnOrder,
    hasColumnOrderChanged,
    onColumnOrderChange,
  };
}

function isColumnOrderState(obj: unknown): obj is ColumnOrderStateLocalStorage {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'columnOrder' in obj &&
    Array.isArray(obj.columnOrder) &&
    'defaultColumnOrder' in obj &&
    Array.isArray(obj.defaultColumnOrder)
  );
}
