import { Severity, WithState } from '@main/shared/utils';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { ElementType, ReactNode, useEffect, useMemo } from 'react';

import { ITabsService, TabsService } from './tabs-service';
import { ActiveTabNamespaceSuffix, DefaultTabNameSpace, TabId } from './types';

export interface TabBade {
  count: number | undefined;
  severity: Severity | undefined;
}
export interface TabItem<TId extends TabId = TabId> {
  id: TId;
  label: string;
  alternateLabelForDrawerHeading?: string;
  icon: ElementType;
  panel: WithState<ReactNode, TabItem<TId>>;
  badge?: TabBade;
  isDefault?: boolean;
  isHidden?: boolean;
  hideTabTitle?: boolean;
}

export interface UseTabsProps<TId extends TabId = TabId> {
  tabs?: TabItem<TId>[];
  namespace?: string;
}

export function useTabs<TId extends TabId = TabId>({
  tabs,
  namespace,
}: UseTabsProps<TId>): ITabsService<TId> {
  const tabNameSpace = namespace ? `${namespace}${ActiveTabNamespaceSuffix}` : DefaultTabNameSpace;
  const navigate = useNavigate();

  const searchParam = useSearch({
    strict: false,
    select: (search: Record<string, TId>) => search[tabNameSpace],
  });

  const tabsService = useMemo(
    () =>
      new TabsService({
        tabs: tabs?.map((tab) => tab.id) ?? [],
        defaultTab: tabs?.find((tab) => tab.isDefault)?.id,
        navigate: (tab, replace) =>
          navigate({
            search: (old) => ({ ...old, [tabNameSpace]: tab }),
            params: (old) => old,
            replace,
          }),
      }),
    [tabs, navigate, tabNameSpace],
  );

  useEffect(() => tabsService.setActiveTab(searchParam), [searchParam, tabsService]);

  return tabsService;
}
