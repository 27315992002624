import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Promisable } from '@main/shared/types';
import { useStableCallback } from '@main/shared/utils';
import {
  forwardRef,
  MouseEvent,
  ReactNode,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { animations } from '../../utils';
import { HoverButtonGroupContext } from './hover-button-group';

type GenerateOption<Modifier extends string | number> = {
  value: Modifier;
  label: string;
};
export interface AskAiButtonProps<T, Modifier extends string | number> extends ButtonProps {
  generateLabel: string;
  loadingLabel?: ReactNode;
  recommendationLabel?: ReactNode;
  cancelLabel?: ReactNode;
  acceptLabel?: ReactNode;
  acceptButton?: (onAccept: (recommendation?: T) => void, recommendation?: T) => ReactNode;
  onRecommendationRequest: (modifier?: Modifier) => Promisable<T>;
  onRecommendationAccept: (recommendation: T) => void;
  onRecommendationCancel?: () => void;
  renderRecommendation?: (recommendation: T) => ReactNode;
  generateOptions?: GenerateOption<Modifier>[];
}

export function AskAiButton<T, Modifier extends string | number>({
  generateLabel,
  loadingLabel,
  recommendationLabel,
  cancelLabel,
  acceptLabel,
  acceptButton,
  onRecommendationRequest,
  onRecommendationAccept,
  onRecommendationCancel,
  renderRecommendation,
  generateOptions,
  ...buttonProps
}: AskAiButtonProps<T, Modifier>) {
  const { t } = useTranslation('ui');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isMenuOpen, ...menuProps } = useDisclosure();
  const [recommendation, setRecommendation] = useState<T>();
  const id = useId();
  const { shortButtons, setIsVisible } = useContext(HoverButtonGroupContext);

  const buttonColor = useColorModeValue('orange.400', 'orange.300');
  const buttonBorderColor = useColorModeValue('gray.200', 'gray.600');
  const recommendationColor = useColorModeValue('gray.600', 'gray.200');
  const bgColor = useColorModeValue('white', 'gray.700');

  const icon = useMemo(() => <SparklesIcon width="12px" />, []);
  const loadingContent = useMemo(
    () => loadingLabel ?? <Box animation={`${animations.spin} infinite 2s linear`}>{icon}</Box>,
    [icon, loadingLabel],
  );

  async function onRequest(event: MouseEvent, option?: Modifier) {
    try {
      event.stopPropagation();
      setIsLoading(true);
      setRecommendation(undefined);
      setRecommendation(await onRecommendationRequest(option));
    } finally {
      setIsLoading(false);
    }
  }

  function onAccept(rec = recommendation) {
    if (!rec) {
      return;
    }

    onRecommendationAccept(rec);
    setRecommendation(undefined);
  }

  function onCancel() {
    if (!recommendation) {
      return;
    }

    onRecommendationCancel?.();
    setRecommendation(undefined);
  }

  const isVisible = isLoading || !!recommendation || isMenuOpen;
  useEffect(() => setIsVisible(id, isVisible), [id, isVisible, setIsVisible]);

  const stableRenderRecommendation = useStableCallback(renderRecommendation);
  const render = useMemo(() => stableRenderRecommendation ?? String, [stableRenderRecommendation]);
  const recommendationElement = useMemo(
    () => (recommendation !== undefined ? render(recommendation) : null),
    [recommendation, render],
  );

  const hasMenu = !!generateOptions?.length;
  const GenerateButton = forwardRef<typeof Button, ButtonProps>((props, ref) => (
    <Tooltip label={shortButtons && generateLabel} hasArrow placement="top">
      <Button
        ref={ref}
        variant="outline"
        size="xs"
        colorScheme="orange"
        fontWeight="medium"
        leftIcon={!isLoading && !shortButtons ? icon : undefined}
        color={buttonColor}
        borderColor={buttonBorderColor}
        bgColor={bgColor}
        boxShadow="sm"
        pointerEvents={isLoading || recommendation ? 'none' : undefined}
        animation={isLoading ? `${animations.breath} infinite 1.5s linear` : undefined}
        {...buttonProps}
        {...props}
        onClick={hasMenu ? props.onClick : onRequest}
      >
        {isLoading ? loadingContent : shortButtons ? icon : generateLabel}
      </Button>
    </Tooltip>
  ));

  return hasMenu && !recommendation ? (
    <Menu {...menuProps}>
      <MenuButton as={GenerateButton}>
        {isLoading ? loadingContent : shortButtons ? icon : generateLabel}
      </MenuButton>
      <MenuList fontSize="xs" lineHeight="4">
        <MenuItem onClick={onRequest}>{generateLabel}</MenuItem>
        {generateOptions.map((option) => (
          <MenuItem key={option.value} onClick={(event) => onRequest(event, option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : (
    <Popover
      isOpen={!!recommendation}
      isLazy
      placement="top-end"
      onClose={() => setRecommendation(undefined)}
    >
      <PopoverTrigger>
        <GenerateButton />
      </PopoverTrigger>
      <PopoverContent w="md">
        <PopoverBody py="4" px="3">
          <HStack alignItems="start">
            <Box textColor={buttonColor} pt="2px">
              <SparklesIcon width="16px" />
            </Box>
            <Stack flexGrow="1" spacing="4">
              <Box fontSize="xs" color={recommendationColor}>
                <Text fontSize="sm" pb="2">
                  {recommendationLabel || t('ask-ai-button.recommendationLabel')}
                </Text>
                {recommendationElement}
              </Box>
              <Stack alignItems="flex-end">
                <ButtonGroup>
                  <Button variant="outline" size="xs" colorScheme="gray" onClick={onCancel}>
                    {cancelLabel || t('ask-ai-button.cancelLabel')}
                  </Button>
                  {acceptButton?.(onAccept, recommendation) ?? (
                    <Button variant="solid" size="xs" colorScheme="blue" onClick={() => onAccept()}>
                      {acceptLabel || t('ask-ai-button.acceptLabel')}
                    </Button>
                  )}
                </ButtonGroup>
              </Stack>
            </Stack>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
