import { Box, ButtonGroup, HStack, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  CheckIcon,
  PaperClipIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { formatDate } from '@main/shared/utils';
import { StatusTag } from '@main/ui';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  QuestionnaireFormFieldConfig,
  QuestionnnaireFieldWrapperProps,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
} from './form-types';

const QUESTION_STATUSES = {
  [Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending]: {
    value: 'questionnaires.form.questionStatus.pending',
    colorScheme: 'gray',
  },
  [Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed]: {
    value: 'questionnaires.form.questionStatus.completed',
    colorScheme: 'green',
  },
} as const;

export interface QuestionnaireFieldHeaderProps extends QuestionnnaireFieldWrapperProps {
  config: QuestionnaireFormFieldConfig;
  answer: QuestionnnaireFormFieldAnswer;
  setShowComment: Dispatch<SetStateAction<boolean>>;
  setShowFiles: Dispatch<SetStateAction<boolean>>;
}

export function QuestionnaireFieldHeader(props: QuestionnaireFieldHeaderProps) {
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const { config, answer, form, visibleFields } = props;
  const isAnswerMode = form.mode === QuestionnnaireFormMode.Answer && !form.isSubmitted;
  const isCompleted = answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed;
  const totalQuestions = visibleFields.length;
  const questionIdx = useMemo(
    () => visibleFields.findIndex((field) => field.name === config.name) + 1,
    [config.name, visibleFields],
  );

  const setCompletedAnswer = async (isCompleted: boolean) => {
    const isValid = await trigger(config.name);

    if (!isValid && isCompleted) {
      return;
    }

    await props.onAnswerChange?.(answer, {
      status: isCompleted
        ? Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed
        : Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending,
    });
  };

  return (
    <HStack alignItems="start" justifyContent="space-between" spacing={4}>
      <HStack alignItems="start">
        <Box>
          <Text fontWeight="semibold" fontSize="xs">
            {t('questionnaires.form.questionNo', { number: questionIdx, total: totalQuestions })}
          </Text>
        </Box>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={QUESTION_STATUSES[props.answer.status].colorScheme}
        >
          {t(QUESTION_STATUSES[props.answer.status].value)}
        </StatusTag>
      </HStack>
      {isAnswerMode &&
        (isCompleted ? (
          <Tooltip label={t('questionnaires.form.edit')} placement="top">
            <IconButton
              variant="outline"
              size="sm"
              px={3}
              icon={<Icon as={PencilIcon} />}
              aria-label={t('questionnaires.form.edit')}
              onClick={() => setCompletedAnswer(false)}
            />
          </Tooltip>
        ) : (
          <HStack spacing={3}>
            {props.answer.updated_at && (
              <Box>
                <Text fontSize="xs" color="gray.400">
                  {t('questionnaires.form.savedOn', { date: formatDate(props.answer.updated_at) })}
                </Text>
              </Box>
            )}
            <ButtonGroup isAttached>
              <Tooltip label={t('questionnaires.form.addSupportingFiles')} placement="top">
                <IconButton
                  variant="outline"
                  size="sm"
                  px={3}
                  icon={<Icon as={PaperClipIcon} />}
                  aria-label={t('questionnaires.form.addSupportingFiles')}
                  onClick={() => props.setShowFiles((isShow) => !isShow)}
                />
              </Tooltip>
              <Tooltip label={t('questionnaires.form.addComment')} placement="top">
                <IconButton
                  variant="outline"
                  size="sm"
                  px={3}
                  icon={<Icon as={ChatBubbleLeftRightIcon} />}
                  aria-label={t('questionnaires.form.addComment')}
                  onClick={() => props.setShowComment((isShow) => !isShow)}
                />
              </Tooltip>
            </ButtonGroup>
            <Tooltip label={t('questionnaires.form.complete')} placement="top">
              <IconButton
                size="sm"
                colorScheme="blue"
                px={3}
                icon={<Icon as={CheckIcon} />}
                aria-label={t('questionnaires.form.complete')}
                onClick={() => setCompletedAnswer(true)}
              />
            </Tooltip>
          </HStack>
        ))}
    </HStack>
  );
}
