/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { EvidenceProgramFragmentDoc } from './EvidenceProgramFragment.generated';
export type OrganizationEvidenceFragment = {
  __typename?: 'evidences';
  id: string;
  name?: string;
  updated_at: string;
  owner_id?: string;
  description?: string;
  internal_id?: string;
  controls_aggregate_status: ClientTypes.EvidenceStatus;
  created_at: string;
  is_confidential: boolean;
  created_by?: string;
  tags: Array<{
    __typename?: 'evidence_tags';
    id: string;
    tag: { __typename?: 'organization_evidence_tags'; id: string; name: string };
  }>;
  evidence_versions: Array<{
    __typename?: 'evidence_versions';
    validity_start: string;
    url?: string;
    created_by?: string;
    updated_at: string;
    evidence_version_file?: {
      __typename?: 'evidence_version_file';
      file: { __typename?: 'files'; id: string; name?: string };
    };
  }>;
  acl: Array<{ __typename?: 'evidence_acl'; id: string; user_id: string }>;
  control_evidences: Array<{
    __typename?: 'control_evidences';
    status: ClientTypes.ControlEvidenceStatus;
    control: {
      __typename?: 'controls';
      programs: Array<{ __typename?: 'program_controls'; program_id: string }>;
    };
  }>;
};

export const OrganizationEvidenceFragmentDoc = `
    fragment OrganizationEvidence on evidences {
  id
  name
  updated_at
  owner_id
  description
  internal_id
  controls_aggregate_status
  created_at
  is_confidential
  created_by
  tags {
    id
    tag {
      id
      name
    }
  }
  ...EvidenceProgram
  evidence_versions(where: {is_current: {_eq: true}}) {
    validity_start
    url
    created_by
    updated_at
    evidence_version_file {
      file {
        id
        name
      }
    }
  }
  acl(order_by: {created_at: asc}) {
    id
    user_id
  }
}
    `;
