import { Flex } from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { CellContext, ColumnDef, RowData } from '@tanstack/react-table';
import { JSXElementConstructor } from 'react';
import { useTranslation } from 'react-i18next';

import { IconAction } from '../../actions/icon-action';
import { MenuActions } from '../../actions/menu-actions';
import type { MenuActionItem } from '../../actions/types';
import { CellContainer } from '../shared/cell';

export interface ActionsColumnDef<TData extends RowData, TValue = unknown>
  extends Omit<ColumnDef<TData, TValue>, 'id' | 'enableHiding' | 'enableResizing'> {
  PrimaryAction?: JSXElementConstructor<CellContext<TData, unknown>>;
  menuActions?: (context: CellContext<TData, unknown>) => MenuActionItem[];
}

export function actions<TData>() {
  return ({
    PrimaryAction,
    menuActions,
    ...columnDef
  }: ActionsColumnDef<TData>): ColumnDef<TData> => {
    return {
      ...columnDef,
      id: 'actions',
      enableResizing: false,
      enableHiding: false,

      cell: (context) => (
        <ActionsCell context={context} PrimaryAction={PrimaryAction} menuActions={menuActions} />
      ),

      meta: {
        ...columnDef.meta,
        name: 'Actions',
      },
    };
  };
}

type ActionsCellProps<T> = {
  context: CellContext<T, unknown>;
  PrimaryAction?: JSXElementConstructor<CellContext<T, unknown>>;
  menuActions?: (context: CellContext<T, unknown>) => MenuActionItem[];
};

function ActionsCell<T>({ context, PrimaryAction, menuActions }: ActionsCellProps<T>) {
  const { t } = useTranslation('ui');
  const resolvedMenuActions = menuActions?.(context) || [];

  return (
    <CellContainer
      cell={context.cell}
      justify="end"
      cursor="default"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Flex gap={1}>
        {PrimaryAction && <PrimaryAction {...context} />}
        <MenuActions
          actions={resolvedMenuActions}
          renderTrigger={(props) => (
            <IconAction
              tooltip={t('table.column.actions.moreActions')}
              tooltipPlacement="top"
              icon={EllipsisVerticalIcon}
              {...props}
            />
          )}
        />
      </Flex>
    </CellContainer>
  );
}
