import { TrashIcon } from '@heroicons/react/24/outline';
import { isNonNullable } from '@main/shared/utils';
import { actionHelper, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteVendorQuestionnaire } from './delete-vendor-questionnaire';

export const useVendorQuestionnaireDrawerActions = (vqId: string) => {
  const deleteVendorQuestionnaire = useVendorQuestionnaireDeleteAction(vqId);

  return actionHelper.drawerToolbarActions({
    menuActions: [deleteVendorQuestionnaire].filter(isNonNullable),
  });
};

function useVendorQuestionnaireDeleteAction(vqId: string) {
  const { t } = useTranslation();

  const drawer = useDrawer();
  const deleteVendorQuestionnaire = useDeleteVendorQuestionnaire();

  if (!deleteVendorQuestionnaire) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: TrashIcon,
    label: t('buttons.delete'),
    onClick: () => {
      deleteVendorQuestionnaire(vqId, () => drawer.close());
    },
  });
}
