import { MigrationHost } from './migration-host';
import { Migration } from './migration-manager';

export class MigrationRegistry<THost extends MigrationHost, TContext = void> {
  #sealed = false;
  protected readonly migrations = new Map<number, Migration<THost, TContext>>();

  addMigration(...migrations: Migration<THost, TContext>[]) {
    if (this.#sealed) {
      throw new Error('Cannot add migration after registry has been sealed');
    }

    migrations.forEach((migration) => {
      if (this.migrations.has(migration.version)) {
        throw new Error(`Cannot add migration with existing version ${migration.version}`);
      }

      this.migrations.set(migration.version, migration);
    });
  }

  getMigrations() {
    this.#sealed = true;
    return Array.from(this.migrations.entries()).sort(([v1], [v2]) => v1 - v2);
  }
}
