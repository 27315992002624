import { Box, Button, Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { CalendarDaysIcon, HashtagIcon, LinkIcon, UserIcon } from '@heroicons/react/24/outline';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { useAiAutocompleteEntityMutation } from '@main/graphql/features/AiAutocompleteEntity.generated';
import { Field_Entities_Enum, SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError, useStableCallback } from '@main/shared/utils';
import {
  AskAiButton,
  CreateTasksButton,
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableTag,
  errorToast,
  HoverButtonGroup,
  MarkdownEditor,
  successToast,
  Trans,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { CardButton, ReferenceCard } from '@main/ui/cards';
import { BarsIcon, DoubleTagsIcon, TagIcon, UnlinkIcon } from '@main/ui/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkRiskToVendorMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { getAllSortedAiAutocompleteModifierOptions } from '../shared/ai-autocomplete-modifiers';
import { getFrequencyOption, getFrequencyOptions } from '../shared/frequency';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import { RISK_LEVEL_COLOR, VENDOR_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useUnlinkRiskFromVendorMutation } from '../vendors/VendorRisks.generated';
import { useAddTasksToRiskMutation } from './add-tasks-to-risk.generated';
import { Categories } from './categories';
import { getAllTreatmentOptions, getTreatmentOption } from './table-column-options';
import { Tags } from './tags';
import { useUpdateRiskMutation } from './update-risk.generated';
import { useGetRiskDrawerData } from './use-get-risk-data';
import { useRefetchRiskQueries, useUpdateRiskHandler } from './use-update-risk-handler';

export const RiskDetailsTab = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const successToastColor = useColorModeValue('green.800', 'green.600');
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const { is_day_zero_ai_features_enabled } = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;
  const canLinkVendors = userRole.permissionMap?.link_risks_vendors;
  const canViewVendors = userRole.permissionMap?.read_vendors;
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const drawer = useDrawer();

  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Risk,
  });
  const [updateRisk] = useUpdateRiskMutation();
  const [aiAutocompleteEntity] = useAiAutocompleteEntityMutation();
  const updateRiskHandler = useUpdateRiskHandler();
  const { risk } = useGetRiskDrawerData({ riskId });

  const { openDialog } = useAlertDialog();
  const vendorSearch = useEntitySearch({ entities: [SearchEntitiesEnum.Vendors] });
  const [linkVendorToRisk, { isLoading: isLinking }] = useLinkRiskToVendorMutation();
  const [unlinkVendorFromRisk, { isLoading: isUnlinking, originalArgs: unlinkArgs }] =
    useUnlinkRiskFromVendorMutation();
  const refetchRisk = useRefetchRiskQueries();

  const linkHandler = useStableCallback(async (results: AppGlobalSearchResult[]) => {
    try {
      await linkVendorToRisk({
        input: results.map((result) => ({ risk_id: riskId, vendor_id: result.id })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.vendor') }));
      refetchRisk(riskId);
      vendorSearch.disclosure.onClose?.();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.vendor') }));
      datadogLogs.logger.error(
        `Failed linking vendor(s) to risk`,
        { riskId, vendors: results },
        toError(error),
      );
    }
  });

  const unlinkHandler = useStableCallback(async (referenceId: string) => {
    try {
      await unlinkVendorFromRisk({ vendorRiskId: referenceId }).unwrap();
      successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.vendor') }));
      await refetchRisk();
    } catch (error) {
      errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.vendor') }));
      datadogLogs.logger.error(
        'Unlinking vendor from risk',
        { riskId, referenceId },
        toError(error),
      );
    }
  });

  const assigneeValue = useMemo(() => {
    if (!risk?.assignee) {
      return;
    }

    const user = currentOrgUsers[risk.assignee.id];
    if (!user) {
      return;
    }

    return {
      id: risk.assignee.id,
      displayName: user.displayName,
    };
  }, [currentOrgUsers, risk?.assignee]);

  const updateTreatmentPlan = useStableCallback(
    (treatment_details?: string) =>
      risk?.id &&
      risk.treatment_details !== treatment_details &&
      updateRiskHandler(
        updateRisk({
          id: risk.id,
          risk_input: { treatment_details },
        }),
      ),
  );

  const tasks = useMemo(
    () =>
      [...(risk?.treatment_details?.matchAll(/^(?:\d+\.\s(?<otask>.*)|-\s(?<utask>.*))$/gm) ?? [])]
        .map((match) => match.groups?.['otask'] || match.groups?.['utask'])
        .filter((x): x is NonNullable<typeof x> => typeof x !== 'undefined' && x.trim().length > 0),
    [risk?.treatment_details],
  );
  const showCreateTasks = canCreateTask && !!tasks.length;
  const [addTasksToRiskMutation] = useAddTasksToRiskMutation();

  const onCreateTasks = useStableCallback(async (tasks: string[]) => {
    if (!risk?.id) return;
    const insertTasksData = tasks.map((task) => ({
      name: task,
      organization_id: organizationId,
      risks: { data: [{ risk_id: risk.id }] },
    }));
    try {
      await addTasksToRiskMutation({
        tasks: insertTasksData,
      }).unwrap();

      successToast(
        <Trans
          i18nKey="risks.createTasksSuccessfull"
          components={{
            a: (
              <Button
                variant="link"
                color={successToastColor}
                onClick={() =>
                  drawer.open({ entity: 'risk', entityId: risk.id, activeTab: 'tasks' })
                }
              />
            ),
          }}
        />,
      );
    } catch (error) {
      errorToast(t('risks.createTasksUnsuccessfull'));
      datadogLogs.logger.error('Failed to create tasks for risk', insertTasksData, toError(error));
    }
  });

  const [isTreatmentDetailsEditorFocused, setIsTreatmentDetailsEditorFocused] = useState(false);
  const [isDescriptionEditorFocused, setIsDescriptionEditorFocused] = useState(false);

  if (!risk) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('risks.details.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content {...HoverButtonGroup.ParentProps}>
          <EditableAutoResizeTextarea
            defaultValue={risk.description}
            placeholder={
              canUpdateRisks
                ? t('risks.details.placeholder.description')
                : t('risks.hasNoPermission', {
                    property: t('risks.details.description').toLowerCase(),
                  })
            }
            color={textColor}
            onSubmit={(value) => {
              updateRiskHandler(
                updateRisk({
                  id: riskId,
                  risk_input: {
                    description: value,
                  },
                }),
              );
              setIsDescriptionEditorFocused(false);
            }}
            onFocus={() => setIsDescriptionEditorFocused(true)}
          />
          {is_day_zero_ai_features_enabled && canUpdateRisks && (
            <HoverButtonGroup forceHide={isDescriptionEditorFocused}>
              <AskAiButton
                data-testid="autocomplete-risk-description"
                renderRecommendation={(description) => (
                  <Text data-testid="risk-description-recommendation">{description}</Text>
                )}
                onRecommendationRequest={() =>
                  aiAutocompleteEntity({ input: { entity: 'risk-description', entityId: riskId } })
                    .unwrap()
                    .then((res) => res.ai_autocomplete_entity?.autocomplete)
                }
                onRecommendationAccept={(description) =>
                  updateRiskHandler(updateRisk({ id: riskId, risk_input: { description } }))
                }
                generateLabel={
                  risk.description
                    ? t('askAiButton.regenerateLabel')
                    : t('askAiButton.generateLabel')
                }
              />
            </HoverButtonGroup>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>
      {risk.inherentRiskLevel && (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={TagIcon}
            tooltip={t('risks.details.tooltip.calculatedRiskField')}
          >
            {t('risks.details.inherentRisk')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Tag colorScheme={RISK_LEVEL_COLOR[risk.inherentRiskLevel]} w="fit-content">
              {t(`risks.enum.level.${risk.inherentRiskLevel}`)}
            </Tag>
          </DrawerProperty.Content>
        </DrawerProperty>
      )}

      {risk.inherentScore ? (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={HashtagIcon}
            tooltip={t('risks.details.tooltip.inherentScore')}
          >
            {t('risks.details.inherentScore')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs">{risk.inherentScore}</Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      {risk.residualRiskLevel && (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={TagIcon}
            tooltip={t('risks.details.tooltip.calculatedRiskField')}
          >
            {t('risks.details.residualRisk')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Tag colorScheme={RISK_LEVEL_COLOR[risk.residualRiskLevel]} w="fit-content">
              {t(`risks.enum.level.${risk.residualRiskLevel}`)}
            </Tag>
          </DrawerProperty.Content>
        </DrawerProperty>
      )}

      {risk.residualScore ? (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={HashtagIcon}
            tooltip={t('risks.details.tooltip.residualScore')}
          >
            {t('risks.details.residualScore')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs">{risk.residualScore}</Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>
          {t('risks.details.category')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <Categories riskId={riskId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>{t('risks.details.tags')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <Tags riskId={riskId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={UserIcon}>{t('risks.details.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={assigneeValue}
            placeholder={
              canUpdateRisks
                ? t('risks.details.placeholder.owner')
                : t('risks.hasNoPermission', {
                    property: t('risks.details.owner').toLowerCase(),
                  })
            }
            onChange={(option) => {
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    assignee_id: option ? option.id : null,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.details.reviewFrequency')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={risk.review_frequency && getFrequencyOption(t, risk.review_frequency)}
            options={getFrequencyOptions(t)}
            onChange={(option) => {
              if (option) {
                updateRiskHandler(
                  updateRisk({
                    id: risk.id,
                    risk_input: {
                      review_frequency: option.value,
                    },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label
          icon={CalendarDaysIcon}
          tooltip={t('risks.details.tooltip.lastReviewedDate')}
        >
          {t('risks.details.lastReviewedDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center" fontSize="xs">
          {risk.lastReviewedDate
            ? risk.lastReviewedDate
            : t('risks.hasNoPermission', {
                property: t('risks.tabs.reviews').toLowerCase(),
              })}
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>{t('risks.details.treatment')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={getTreatmentOption(t, risk.treatment_plan)}
            options={getAllTreatmentOptions(t)}
            onChange={(option) => {
              if (option) {
                updateRiskHandler(
                  updateRisk({
                    id: risk.id,
                    risk_input: {
                      treatment_plan: option.value,
                    },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('risks.details.treatmentDetails.label')}
        </DrawerProperty.Label>
        <DrawerProperty.Content {...HoverButtonGroup.ParentProps}>
          <Flex h="full" alignItems="center" gap={2}>
            <Box
              p={2}
              borderRadius="md"
              transitionProperty="common"
              transitionDuration="normal"
              border="1px"
              borderColor="transparent"
              _hover={canUpdateRisks ? { bg: hoverBgColor, cursor: 'pointer' } : undefined}
              _focusWithin={{
                bg: 'none',
                cursor: 'auto',
                borderColor: 'gray.200',
                _dark: {
                  borderColor: 'rgba(255,255,255,0.16)',
                },
              }}
              w="full"
            >
              <MarkdownEditor
                defaultValue={risk.treatment_details}
                config={{ editable: !!canUpdateRisks }}
                placeholder={
                  canUpdateRisks
                    ? t('risks.details.placeholder.treatmentPlan')
                    : t('risks.hasNoPermission', {
                        property: t('risks.details.treatmentPlan').toLowerCase(),
                      })
                }
                onBlur={(treatmentDetails) => {
                  updateTreatmentPlan(treatmentDetails);
                  setIsTreatmentDetailsEditorFocused(false);
                }}
                contentEditable={
                  <ContentEditable
                    data-testid="treatment-details"
                    className="editor-input"
                    style={{ outline: 'none', fontSize: '12px' }}
                    onFocus={() => setIsTreatmentDetailsEditorFocused(true)}
                  />
                }
              />
            </Box>
          </Flex>
          {is_day_zero_ai_features_enabled && canUpdateRisks && (
            <HoverButtonGroup forceHide={isTreatmentDetailsEditorFocused}>
              {showCreateTasks && <CreateTasksButton tasks={tasks} onCreateTasks={onCreateTasks} />}
              <AskAiButton
                data-testid="autocomplete-treatment-details"
                renderRecommendation={(recommendation) => (
                  <Box maxH="40vh" overflowY="auto">
                    <MarkdownEditor
                      config={{ editable: false }}
                      defaultValue={recommendation}
                      contentEditable={
                        <ContentEditable
                          data-testid="ai-treatment-details"
                          className="editor-input"
                        />
                      }
                    />
                  </Box>
                )}
                onRecommendationRequest={(modifier) =>
                  aiAutocompleteEntity({
                    input: { entity: 'risk-treatment-plan', entityId: risk.id, modifier },
                  })
                    .unwrap()
                    .then((res) => res?.ai_autocomplete_entity?.autocomplete)
                }
                onRecommendationAccept={updateTreatmentPlan}
                acceptLabel={risk.treatment_details && t('risks.details.treatmentDetails.replace')}
                generateLabel={
                  risk.treatment_details
                    ? t('askAiButton.regenerateLabel')
                    : t('askAiButton.generateLabel')
                }
                generateOptions={
                  risk.treatment_details ? getAllSortedAiAutocompleteModifierOptions(t) : []
                }
              />
            </HoverButtonGroup>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>

      {fieldConfigsQuery?.data?.field_configs.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={riskId}
          config={config}
          values={risk.field_values}
          onChange={() => {
            fieldConfigsQuery.refetch();
          }}
          isReadOnly={!canUpdateRisks}
        />
      ))}

      {canViewVendors && risk.references.vendors.length && (
        <DrawerProperty isReadOnly={!canLinkVendors}>
          <DrawerProperty.Label icon={LinkIcon}>
            {t('risks.details.references')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" flexDirection="column" gap={2}>
            {risk.references.vendors.map((vendor) => {
              return (
                <ReferenceCard
                  key={vendor.refId}
                  name={vendor.name}
                  owner={vendor.owner}
                  tags={[
                    {
                      label: t('entities.vendor'),
                      colorSchema: 'purple',
                    },
                    {
                      type: 'status',
                      label: t(`vendors.enum.assessmentStatus.${vendor.assessment_status}`),
                      colorSchema: VENDOR_ASSESSMENT_STATUS_COLOR[vendor.assessment_status],
                    },
                  ]}
                  actions={[
                    {
                      icon: UnlinkIcon,
                      label: t('buttons.unlink'),
                      onClick: () =>
                        openDialog({
                          dialogHeader: t('vendors.alert.unlink.header'),
                          dialogContent: t('vendors.alert.unlink.content'),
                          confirmAction: {
                            children: t('vendors.alert.unlink.confirm'),
                            onClick: () => unlinkHandler(vendor.refId),
                          },
                        }),
                      isLoading: unlinkArgs?.vendorRiskId === vendor.refId && isUnlinking,
                    },
                  ]}
                  navigate={{
                    drawerEntity: 'vendor',
                    drawerEntityId: vendor.id,
                  }}
                />
              );
            })}
            {canLinkVendors && (
              <CardButton onClick={() => vendorSearch.disclosure.onOpen?.()}>
                {t('tasks.addReference')}
              </CardButton>
            )}
          </DrawerProperty.Content>
        </DrawerProperty>
      )}

      <LinkEntityModal
        isOpen={vendorSearch.disclosure.isOpen}
        onClose={() => vendorSearch.disclosure.onClose?.()}
        entityName={t('entities.vendor')}
        onLinkEntity={() => linkHandler(vendorSearch.selectedEntities)}
        isLinkingEntity={isLinking}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.vendor').toLowerCase(),
            }),
          }}
          {...vendorSearch}
        />
      </LinkEntityModal>
    </Stack>
  );
};
