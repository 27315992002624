import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { isNonNullable } from '@main/shared/utils';
import { ReactElement } from 'react';

interface FindingMenuAction {
  label: string;
  onClick: () => void;
  icon: ReactElement;
  isHidden?: boolean;
}

export const FindingMenuActions = ({
  actions: _actions,
}: {
  actions: (FindingMenuAction | undefined)[];
}) => {
  const actions = _actions.filter(isNonNullable).filter((action) => !action.isHidden);

  if (!actions.length) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        aria-label="menu-actions"
        as={IconButton}
        size="sm"
        variant="outline"
        icon={<Icon as={EllipsisVerticalIcon} boxSize="4" />}
      />
      <MenuList>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            icon={action.icon}
            iconSpacing={2}
            onClick={action.onClick}
            fontSize="24px"
          >
            <Text fontSize="sm">{action.label}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
