import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  ParagraphNode,
} from 'lexical';
import { useCallback } from 'react';

export const useMentions = () => {
  const [editor] = useLexicalComposerContext();

  return useCallback(() => {
    const editorHasFocus = editor.getRootElement()?.contains(document.activeElement);

    if (!editorHasFocus) {
      editor.focus();
    }

    editor.update(() => {
      const mentionSymbol = '@';
      const selection = $getSelection();

      if (selection) {
        selection.insertText(mentionSymbol);
        return;
      }

      const root = $getRoot();
      let paragraph = root.getFirstChild();

      if (!$isParagraphNode(paragraph)) {
        paragraph = $createParagraphNode();
        root.append(paragraph);
      }

      (paragraph as ParagraphNode).append($createTextNode(mentionSymbol));

      return;
    });
  }, [editor]);
};
