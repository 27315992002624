import {
  Avatar,
  Badge,
  Flex,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArrowRightOnRectangleIcon,
  BellIcon,
  ChartPieIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  ClipboardIcon,
  Cog6ToothIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  FingerPrintIcon,
  HomeIcon,
  KeyIcon,
  ListBulletIcon,
  LockClosedIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  PlusSmallIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
  Square2StackIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  SwatchIcon,
  TableCellsIcon,
  TagIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useAlertsSubscription } from '@main/graphql/subscriptions/GetAlertsSubscription';
import { useGetProgramsQuerySubscription } from '@main/graphql/subscriptions/GetProgramsSubscription';
import {
  getHashedAvatarColor,
  GlobalSearchModal,
  HeaderMenuItem,
  menuItem,
  Shortcut,
  ShortcutModifier,
} from '@main/ui';
import { useUserData } from '@nhost/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGlobalSearch } from '../global-search/use-global-search';
import { CreateProgramModal } from '../program/create-program-options';
import {
  currentUserSelectedProgramSwitched,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
  userSignedOut,
} from '../user/slice';

export interface UseAuthSidebarMenuProps {
  isCollapsed?: boolean;
}

export const useAuthSidebarMenu = (props?: UseAuthSidebarMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canReadReports = userRole.permissionMap?.read_reports;
  const canViewRisks = userRole.permissionMap?.read_risks;
  const canViewVendors = userRole.permissionMap?.read_vendors;
  const canViewControls = userRole.permissionMap?.read_controls;
  const canViewEvidence = userRole.permissionMap?.read_evidence;
  const canViewTask = userRole.permissionMap?.read_tasks || userRole.permissionMap?.own_read_tasks;
  const canViewPolicy = userRole.permissionMap?.read_policies;
  const canCreateProgram = userRole.permissionMap?.write_programs;
  const canViewClientQ = userRole.permissionMap?.read_client_q;

  const isAddingNewProgramAllowed = useFeatureFlagEnabled('program-creation') && canCreateProgram;
  const userOrg = useAppSelector(getCurrentUserSelectedOrg);
  const isClientQAllowed = userOrg.is_client_questionnaires_enabled && canViewClientQ;

  const {
    is_risks_module_enabled: isRisksModuleEnabled,
    is_vendors_module_enabled: isVendorsModuleEnabled,
    is_controls_module_enabled: isControlsModuleEnabled,
    is_policies_module_enabled: isPoliciesModuleEnabled,
  } = userOrg;
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const { data: alertsData } = useAlertsSubscription({
    organizationId,
  });
  const { data: programsData } = useGetProgramsQuerySubscription({ orgId: userOrg.id });
  const updateActiveProgram = useCallback(
    (programId: string) => dispatch(currentUserSelectedProgramSwitched({ programId })),
    [dispatch],
  );
  const {
    isOpen: isCreateCustomProgramModalOpen,
    onOpen: onOpenCreateCustomProgramModal,
    onClose: onCloseCreateCustomProgramModal,
  } = useDisclosure();
  const globalSearch = useGlobalSearch();

  const alertBadgeTextColor = useColorModeValue('blue.400', 'purple.200');
  const alertBadgeBgColor = useColorModeValue('blue.100', 'purple.700');
  const numOfAlerts = alertsData?.controls_aggregate.aggregate?.count;

  const menuItems = useMemo(
    () =>
      [
        menuItem({
          label: t('sideBar.navItems.search'),
          shortcut: <Shortcut modifier={ShortcutModifier.Ctrl} keyName="K" size="sm" isDark />,
          icon: MagnifyingGlassIcon,
          onClick: globalSearch.disclosure.onOpen,
        }),
        menuItem({
          label: t('sideBar.navItems.home'),
          to: '/dashboard',
          icon: HomeIcon,
        }),
        ...(canReadReports
          ? [
              menuItem({
                label: t('sideBar.navItems.reportManagement'),
                to: '/reports',
                icon: ChartPieIcon,
              }),
            ]
          : []),
        ...(canViewTask
          ? [
              menuItem({
                label: t('sideBar.navItems.taskCenter'),
                to: '/tasks',
                icon: ClipboardDocumentCheckIcon,
              }),
            ]
          : []),
        menuItem({
          label: t('sideBar.navItems.settings'),
          to: '/settings/organization',
          icon: Cog6ToothIcon,
        }),
        ...((isControlsModuleEnabled && (canViewControls || canViewEvidence)) ||
        (isPoliciesModuleEnabled && canViewPolicy)
          ? [
              menuItem({
                label: t('sideBar.navGroups.compliance'),
                isGroup: true,
                children: [
                  ...(isControlsModuleEnabled && canViewControls
                    ? [
                        menuItem({
                          label: t('sideBar.navItems.programs'),
                          icon: SwatchIcon,
                          children: [
                            ...(programsData?.programs.map((program) =>
                              menuItem({
                                label: program.name,
                                to: '/programs/$programId',
                                params: { programId: program.id },
                                icon: RectangleStackIcon,
                                onClick: () => updateActiveProgram(program.id),
                              }),
                            ) ?? []),
                            ...(isAddingNewProgramAllowed
                              ? [
                                  menuItem({
                                    label: t('sideBar.menuButton.createNewProgram'),
                                    icon: PlusSmallIcon,
                                    onClick: () => {
                                      onOpenCreateCustomProgramModal();
                                    },
                                  }),
                                ]
                              : []),
                          ],
                        }),
                        menuItem({
                          label: t('sideBar.navItems.controls'),
                          to: '/controls',
                          icon: Square2StackIcon,
                        }),
                      ]
                    : []),
                  ...(isControlsModuleEnabled && canViewEvidence
                    ? [
                        menuItem({
                          label: t('sideBar.navItems.evidence'),
                          to: '/evidence',
                          icon: FingerPrintIcon,
                        }),
                      ]
                    : []),
                  ...(isPoliciesModuleEnabled && canViewPolicy
                    ? [
                        menuItem({
                          label: t('sideBar.navItems.policyCenter'),
                          to: '/policies',
                          icon: ShieldCheckIcon,
                        }),
                      ]
                    : []),
                  ...(isControlsModuleEnabled && canViewControls
                    ? [
                        menuItem({
                          label: (
                            <Flex justifyContent="space-between" width="full" alignItems="center">
                              {t('sideBar.navItems.alerts')}{' '}
                              {numOfAlerts && !props?.isCollapsed ? (
                                <Badge
                                  display="flex"
                                  color={alertBadgeTextColor}
                                  bg={alertBadgeBgColor}
                                  rounded="full"
                                  justifyContent="center"
                                  alignItems="center"
                                  px={2.5}
                                  py={1}
                                >
                                  {numOfAlerts}
                                </Badge>
                              ) : null}
                            </Flex>
                          ),
                          to: '/alerts',
                          icon: ExclamationTriangleIcon,
                        }),
                      ]
                    : []),
                ],
              }),
            ]
          : []),
        ...(isRisksModuleEnabled && canViewRisks
          ? [
              menuItem({
                label: t('sideBar.navGroups.risks'),
                isGroup: true,
                children: [
                  menuItem({
                    label: t('sideBar.navItems.riskDashboard'),
                    to: '/risks/dashboard',
                    icon: SquaresPlusIcon,
                  }),
                  menuItem({
                    label: t('sideBar.navItems.riskManagement'),
                    to: '/risks/',
                    icon: TableCellsIcon,
                  }),
                ],
              }),
            ]
          : []),

        ...(isVendorsModuleEnabled && canViewVendors
          ? [
              menuItem({
                label: t('sideBar.navGroups.vendors'),
                isGroup: true,
                children: [
                  menuItem({
                    label: t('sideBar.navItems.vendorManagement'),
                    to: '/vendors',
                    icon: CubeIcon,
                  }),
                  menuItem({
                    label: t('sideBar.navItems.questionnaires'),
                    to: '/questionnaires',
                    icon: ClipboardDocumentListIcon,
                  }),
                ],
              }),
            ]
          : []),
        ...(isClientQAllowed
          ? [
              menuItem({
                label: t('sideBar.navGroups.trust'),
                isGroup: true,
                children: [
                  menuItem({
                    label: t('sideBar.navItems.clientQuestionnaires'),
                    to: '/client-questionnaires',
                    icon: ClipboardIcon,
                  }),
                ],
              }),
            ]
          : []),
      ] as const,
    [
      alertBadgeBgColor,
      alertBadgeTextColor,
      canReadReports,
      canViewControls,
      canViewEvidence,
      canViewPolicy,
      canViewRisks,
      canViewTask,
      canViewVendors,
      globalSearch.disclosure.onOpen,
      isAddingNewProgramAllowed,
      isClientQAllowed,
      isControlsModuleEnabled,
      isPoliciesModuleEnabled,
      isRisksModuleEnabled,
      isVendorsModuleEnabled,
      numOfAlerts,
      onOpenCreateCustomProgramModal,
      programsData?.programs,
      props?.isCollapsed,
      t,
      updateActiveProgram,
    ],
  );

  const extraMenuElement = (
    <>
      <CreateProgramModal
        isModalOpen={isCreateCustomProgramModalOpen}
        onModalClose={onCloseCreateCustomProgramModal}
      />
      <GlobalSearchModal openOnShortcut {...globalSearch} />
    </>
  );

  return { menuItems, extraMenuElement };
};

export const useAuthHeaderMenu = () => {
  const { t } = useTranslation();
  const user = useUserData();
  const dispatch = useAppDispatch();
  const { colorMode, toggleColorMode } = useColorMode();
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const handleSignout = () => {
    dispatch(userSignedOut());
  };

  const menuContent = (
    <Avatar name={user?.displayName} size="sm" {...getHashedAvatarColor(user?.displayName)} />
  );

  const menuMainItem = (
    <Flex alignItems="center" pl="4" py="2" pr="8" mb="2">
      <Avatar name={user?.displayName} size="sm" {...getHashedAvatarColor(user?.displayName)} />
      <Flex
        ml="3"
        flexDirection="column"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Text color={textColor} fontSize="sm" fontWeight="medium" mb="-0.5">
          {user?.displayName}
        </Text>
        <Text color="gray.500" fontSize="xs">
          {user?.email}
        </Text>
      </Flex>
    </Flex>
  );

  const menuItems: HeaderMenuItem[] = [
    {
      label: t('headerMenu.navItems.settings'),
      to: '/settings/account/profile',
      icon: Cog6ToothIcon,
    },
    {
      label: t('headerMenu.navItems.darkMode'),
      showSwitch: true,
      isSwitchChecked: colorMode === 'dark',
      onSwitchChange: toggleColorMode,
      icon: MoonIcon,
    },
    {
      label: t('headerMenu.navItems.logout'),
      onClick: handleSignout,
      icon: ArrowRightOnRectangleIcon,
    },
  ];

  return { menuContent, menuMainItem, menuItems };
};

export const useAccountSettingsMenu = () => {
  const { t } = useTranslation();
  const menuItems = [
    menuItem({
      label: t('settings.account.navItems.profile'),
      to: '/settings/account/profile',
      icon: UsersIcon,
    }),
    menuItem({
      label: t('settings.account.navItems.notifications'),
      to: '/settings/account/notifications',
      icon: BellIcon,
    }),
    menuItem({
      label: t('settings.account.navItems.password'),
      to: '/settings/account/password',
      icon: KeyIcon,
    }),
    menuItem({
      label: t('settings.account.navItems.2fa'),
      to: '/settings/account/2fa',
      icon: LockClosedIcon,
    }),
  ];

  return { menuItems };
};

export const useOrganizationSettingsMenu = () => {
  const { t } = useTranslation();
  const {
    is_risks_module_enabled: isRisksModuleEnabled,
    is_vendors_module_enabled: isVendorsModuleEnabled,
    is_controls_module_enabled: isControlsModuleEnabled,
    is_policies_module_enabled: isPoliciesModuleEnabled,
  } = useAppSelector(getCurrentUserSelectedOrg);
  const userOrg = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const isManageAuditTrailAllowed =
    useFeatureFlagEnabled('manage-audit-trail') && userOrg.is_audit_trail_enabled;
  const canViewClientQ = userRole.permissionMap?.read_client_q;
  const isClientQAllowed = userOrg.is_client_questionnaires_enabled && canViewClientQ;

  const menuItems = [
    menuItem({
      label: t('settings.organization.navItems.users'),
      to: '/settings/organization/users',
      icon: UsersIcon,
    }),
    menuItem({
      label: t('settings.organization.navItems.roles'),
      to: '/settings/organization/roles',
      icon: KeyIcon,
    }),

    ...(isManageAuditTrailAllowed
      ? [
          menuItem({
            label: t('settings.organization.navItems.auditTrail'),
            to: '/settings/organization/audit-trail',
            icon: MagnifyingGlassCircleIcon,
          }),
        ]
      : []),

    ...(isControlsModuleEnabled
      ? [
          menuItem({
            label: t('settings.organization.navGroups.controls'),
            isGroup: true,
            children: [
              menuItem({
                label: t('settings.organization.navItems.controlsCustomFields'),
                to: '/settings/organization/controls/custom-fields',
                icon: ListBulletIcon,
              }),
            ],
          }),
        ]
      : []),

    ...(isPoliciesModuleEnabled
      ? [
          menuItem({
            label: t('settings.organization.navGroups.policies'),
            isGroup: true,
            children: [
              menuItem({
                label: t('settings.organization.navItems.policiesCustomFields'),
                to: '/settings/organization/policies/custom-fields',
                icon: ListBulletIcon,
              }),
            ],
          }),
        ]
      : []),

    ...(isRisksModuleEnabled
      ? [
          menuItem({
            label: t('settings.organization.navGroups.risks'),
            isGroup: true,
            children: [
              menuItem({
                label: t('settings.organization.navItems.risksClassifications'),
                to: '/settings/organization/risks/classifications',
                icon: TagIcon,
              }),
              menuItem({
                label: t('settings.organization.navItems.risksCustomFields'),
                to: '/settings/organization/risks/custom-fields',
                icon: ListBulletIcon,
              }),
              menuItem({
                label: t('settings.organization.navItems.risksMatrix'),
                to: '/settings/organization/risks/matrix',
                icon: Squares2X2Icon,
              }),
            ],
          }),
        ]
      : []),
    ...(isVendorsModuleEnabled
      ? [
          menuItem({
            label: t('settings.organization.navGroups.vendors'),
            isGroup: true,
            children: [
              menuItem({
                label: t('settings.organization.navItems.vendorsCustomFields'),
                to: '/settings/organization/vendors/custom-fields',
                icon: ListBulletIcon,
              }),
            ],
          }),
        ]
      : []),

    menuItem({
      label: t('settings.organization.navGroups.tasks'),
      isGroup: true,
      children: [
        menuItem({
          label: t('settings.organization.navItems.tasksCustomFields'),
          to: '/settings/organization/tasks/custom-fields',
          icon: ListBulletIcon,
        }),
      ],
    }),

    ...(isClientQAllowed
      ? [
          menuItem({
            label: t('sideBar.navGroups.trust'),
            isGroup: true,
            children: [
              menuItem({
                label: t('settings.organization.navItems.documents'),
                to: '/settings/organization/trust/documents',
                icon: DocumentDuplicateIcon,
              }),
            ],
          }),
        ]
      : []),
  ];

  return { menuItems };
};
