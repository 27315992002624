import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { NoPermissionPlaceholder, Table } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CSVInput } from '../../../utils/export-csv';
import { serializeCustomFields } from '../../custom-fields/custom-field';
import { customFieldColumn } from '../../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../../custom-fields/field-config';
import { GetTasksQuery, useGetTasksQuery } from '../../tasks/get-task.generated';
import { useTaskColumnHelper } from '../../tasks/table-columns';
import { useTasksTableSettings } from '../../tasks/table-settings';
import {
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../../user/slice';
import { useUpdateReportHandler } from '../use-update-report-handler';
import { ReportCardBase } from './report-card-base';

export const TasksReportCard = ({
  reportId,
  onExportCSV,
}: {
  reportId: string;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewTasks = userRole.permissionMap?.read_tasks || userRole.permissionMap?.own_read_tasks;

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Task,
  });

  const tasksQuery = useGetTasksQuery(
    {
      where: {
        organization_id: {
          _eq: organizationId,
        },
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    columnVisibility,
    setColumnVisibility,
    defaultColumnVisibility,
    isColumnVisibilityModified,
  } = useTasksTableSettings(`reports:${reportId}:tasks`);
  const columns = useTasksTableColumns();
  const {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } = useUpdateReportHandler({ columns });
  const [filteredRows, setFilteredRows] = useState<GetTasksQuery['tasks']>([]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
    };
  }, [t]);

  const handleExportCSV = () => {
    const fieldConfigs = fieldConfigsQuery.data?.field_configs || [];
    const filteredData = filteredRows.map((row) => ({
      Name: row.name,
      Description: row.description,
      Status: row.status,
      'Created by': currentOrgUsersById[row?.created_by ?? '']?.displayName,
      'Created date': row.created_at,
      'Due date': row.due_date,
      'Completed date': row.completed_at,
      Assignee: currentOrgUsersById[row?.task_owners[0]?.owner_id ?? '']?.displayName,
      ...serializeCustomFields(fieldConfigs, row.field_values),
    }));

    onExportCSV(filteredData);
  };

  if (!canViewTasks) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <ReportCardBase
      heading={t('entities.plural.tasks')}
      subheading={t('tasks.subheading.reports')}
      onExportCSV={handleExportCSV}
    >
      <Table
        id={reportId}
        minW="900px"
        entity="task"
        data={tasksQuery.data?.tasks || []}
        isLoading={tasksQuery.isLoading}
        columns={columns}
        itemName={tableItemName}
        pageSize={15}
        defaultColumnVisibility={defaultColumnVisibility}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={setColumnVisibility}
        hasColumnVisibilityChanged={isColumnVisibilityModified}
        columnFilters={columnFilters}
        onColumnFiltersChange={setColumnFilters}
        onFilteredDataChange={setFilteredRows}
        sorting={sorting}
        onSortingChange={setSorting}
      />
    </ReportCardBase>
  );
};

function useTasksTableColumns() {
  const taskColumnHelper = useTaskColumnHelper();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Task,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  return useMemo(
    () => [
      taskColumnHelper.status({
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.name({
        enableGlobalFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.dueDate({
        enableSorting: true,
      }),
      taskColumnHelper.completedDate({
        enableSorting: true,
      }),
      taskColumnHelper.createdBy({
        enableColumnFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.createdAt({
        enableColumnFilter: true,
        enableSorting: true,
      }),
      taskColumnHelper.owner({
        enableColumnFilter: true,
        enableSorting: true,
      }),
      ...(fieldConfigs || []).map(customFieldColumn<GetTasksQuery['tasks'][number]>),
    ],
    [fieldConfigs, taskColumnHelper],
  );
}
