import { Field_Entities_Enum } from '@main/graphql/types.generated';

import { useCustomFieldsTableSettings } from '../../utils/table-settings';

const defaultColumnVisibility = {
  internalId: false,
  description: false,
  questionnaireStatus: false,
  lastReviewedDate: false,
  findings: false,
  frequency: false,
  createdAt: false,
  createdBy: false,
};

export function useVendorsTableSettings(tableId: string) {
  return useCustomFieldsTableSettings({
    entityName: Field_Entities_Enum.Vendor,
    tableId,
    defaultColumnVisibility,
  });
}
