import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CreateTasksButtonProps extends ButtonProps {
  tasks?: string[];
  onCreateTasks?: (tasks: string[]) => Promise<unknown>;
}

export function CreateTasksButton({ tasks, onCreateTasks, ...props }: CreateTasksButtonProps) {
  const { t } = useTranslation('ui');
  const [isCreateTasksOpen, setCreateTasksOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<Record<string, boolean>>({});
  const [isCreateTasksLoading, setIsCreateTasksLoading] = useState(false);
  const onCloseCreateTasks = () => {
    setCreateTasksOpen(false);
    setSelectedTasks({});
  };
  const onClickCreateTasks = useCallback(async () => {
    try {
      setIsCreateTasksLoading(true);
      await onCreateTasks?.(
        Object.entries(selectedTasks)
          .filter(([, selected]) => selected)
          .map(([task]) => task.split(/^\d+-(.*)$/)[1])
          .filter((task) => typeof task !== 'undefined'),
      );
      onCloseCreateTasks();
    } finally {
      setIsCreateTasksLoading(false);
    }
    return;
  }, [onCreateTasks, selectedTasks]);

  const show = !!tasks?.length && onCreateTasks;

  const bgColor = useColorModeValue('white', 'gray.700');

  return show ? (
    <>
      <Tooltip label={t('ask-ai-button.createTasks')} hasArrow placement="top">
        <Button
          {...props}
          variant="outline"
          bgColor={bgColor}
          aria-label={t('ask-ai-button.createTasks')}
          size="xs"
          boxShadow="sm"
          fontWeight="medium"
          onClick={() => setCreateTasksOpen(true)}
        >
          <ClipboardDocumentCheckIcon />
        </Button>
      </Tooltip>
      <Modal isOpen={isCreateTasksOpen} onClose={onCloseCreateTasks} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ask-ai-button.createTasks')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={6}>{t('ask-ai-button.createTasksModalContent')}</Text>
            {tasks.map((task, i) => {
              const key = `${i}-${task}`;
              return (
                <Box key={key} borderWidth="1px" borderRadius="lg" p="4" mt="3">
                  <Checkbox
                    onChange={() =>
                      setSelectedTasks({ ...selectedTasks, [key]: !selectedTasks[key] })
                    }
                    isChecked={selectedTasks[key] ?? false}
                  >
                    {task}
                  </Checkbox>
                </Box>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Stack alignItems="flex-end">
              <ButtonGroup>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  onClick={onCloseCreateTasks}
                  disabled={isCreateTasksLoading}
                >
                  {t('ask-ai-button.cancelLabel')}
                </Button>
                <Button
                  variant="solid"
                  colorScheme="blue"
                  onClick={onClickCreateTasks}
                  isLoading={isCreateTasksLoading}
                >
                  {t('ask-ai-button.create')}
                </Button>
              </ButtonGroup>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  ) : null;
}
