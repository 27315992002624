import { datadogLogs } from '@datadog/browser-logs';
import { PermissionMap } from '@main/permissions';
import { exhaustiveCheck, toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useUnlinkControlTaskMutation } from '../controls/tasks-tab.generated';
import { useUnlinkEvidenceTaskMutation } from '../evidence/tasks-tab.generated';
import { useUnlinkPolicyTaskMutation } from '../policies/manage-tasks.generated';
import { useUnlinkRiskTaskMutation } from '../risks/tasks-tab.generated';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useUnlinkVendorTaskMutation } from '../vendors/tasks-tab.generated';
import { TaskLinkedEntity } from './slice';
import { useUnlinkTaskHandler } from './use-unlink-task-handler';

export function useUnlinkTask() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);

  const unlinkHandler = useUnlinkTaskHandler();
  const [unlinkControlTask] = useUnlinkControlTaskMutation();
  const [unlinkRiskTask] = useUnlinkRiskTaskMutation();
  const [unlinkVendorTask] = useUnlinkVendorTaskMutation();
  const [unlinkPolicyTask] = useUnlinkPolicyTaskMutation();
  const [unlinkEvidenceTask] = useUnlinkEvidenceTaskMutation();

  return useCallback(
    ({
      taskId,
      entityId,
      linkedEntity,
    }: {
      taskId: string;
      entityId: string;
      linkedEntity: TaskLinkedEntity;
    }) => {
      if (!canUnlinkTask(userRole.permissionMap)) {
        return;
      }

      const unlinkTask = async () => {
        try {
          switch (linkedEntity) {
            case 'control':
              await unlinkHandler(unlinkControlTask({ taskId, controlId: entityId }));
              break;
            case 'risk':
              await unlinkHandler(unlinkRiskTask({ taskId, riskId: entityId }));
              break;
            case 'vendor':
              await unlinkHandler(unlinkVendorTask({ taskId, vendorId: entityId }));
              break;
            case 'policy':
              await unlinkHandler(unlinkPolicyTask({ taskId, policyId: entityId }));
              break;
            case 'evidence':
              await unlinkHandler(unlinkEvidenceTask({ taskId, evidenceId: entityId }));
              break;
            default:
              exhaustiveCheck(linkedEntity);
              break;
          }
          successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.task') }));
        } catch (error) {
          errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.task') }));
          datadogLogs.logger.error(
            'Task unlink failed',
            { linkedEntity, entityId, taskId },
            toError(error),
          );
        }
      };

      return {
        icon: UnlinkIcon,
        label: t('buttons.unlink'),
        onClick: () =>
          openDialog({
            dialogHeader: t('tasks.alert.unlink.header'),
            dialogContent: t('tasks.alert.unlink.content'),
            confirmAction: {
              children: t('tasks.alert.unlink.confirm'),
              onClick: unlinkTask,
            },
          }),
      };
    },
    [
      userRole.permissionMap,
      t,
      unlinkHandler,
      unlinkControlTask,
      unlinkRiskTask,
      unlinkVendorTask,
      unlinkPolicyTask,
      unlinkEvidenceTask,
      openDialog,
    ],
  );
}

function canUnlinkTask(permissionMap: PermissionMap) {
  return permissionMap.write_tasks;
}
