import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverContent,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Nullable } from '@main/shared/types';
import { FilterMode, isIsEmptyOrIsNotEmpty } from '@main/shared/url-helpers';
import { formatDate } from '@main/shared/utils';
import { isEmpty } from '@main/shared/utils/is-empty';
import {
  AccessorFn,
  CellContext,
  ColumnDef,
  Row,
  RowData,
  SortDirection,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { rangeToDates } from '../../../utils';
import { OverflowContainer } from '../../overflow-tooltip';
import { createFilterHelper } from '../filters';
import { FilterModeSelector } from '../filters/filter-mode';
import { CellContainer } from '../shared/cell';

type EditProps<TData> = {
  onChange: (row: Row<TData>, value: string) => void;
  canEditGuard?: (row: Row<TData>) => boolean;
  min?: string;
  max?: string;
};
type DateColumnConfig<TData, TValue, TFilter, TSort> = {
  id: string;
  accessorFn: AccessorFn<TData, TValue>;
  header: string;
  dateFormat?: string;
  edit?: EditProps<TData>;
  getColumnFilterCondition?(
    filterMode: FilterMode,
    filterValue: (string | null)[],
  ): Nullable<TFilter>;
  getColumnSort?(sortDirection: SortDirection): TSort;
};

type DateColumnDef<TData extends RowData, TValue, TFilter, TSort> = ColumnDef<TData, TValue> &
  DateColumnConfig<TData, TValue, TFilter, TSort>;

const filterHelper = createFilterHelper({
  filterSchema: z.array(z.string().nullable()),
  filterFn,
});

const cachedFilterState = {
  hash: '',
  firstTime: 0,
  secondTime: 0,
  updateCache(filterValue: (string | null)[], filterMode?: FilterMode) {
    const hash = this.getHash(filterValue, filterMode);

    if (hash === this.hash) {
      return;
    }

    const [first, second] = filterValue;

    if (!first) {
      return;
    }

    const [firstDate, secondDate] = rangeToDates(first, second);

    this.firstTime = firstDate.getTime();
    this.secondTime = secondDate.getTime();
    this.hash = hash;
  },
  getHash(filterValue: (string | null)[], filterMode?: FilterMode) {
    return `${filterValue?.[0]}:${filterValue?.[1]}:${filterMode}`;
  },
};

function filterFn(
  value: string | undefined,
  filterValue: (string | null)[] | null,
  filterMode?: FilterMode,
): boolean {
  if (filterMode === FilterMode.IsEmpty) {
    return isEmpty(value);
  }

  if (filterMode === FilterMode.IsNotEmpty) {
    return !isEmpty(value);
  }

  if (!filterValue?.length || !filterValue[0]) {
    return true;
  }

  if (!value) {
    return false;
  }

  const date = new Date(value);
  cachedFilterState.updateCache(filterValue, filterMode);

  if (!filterMode || filterMode === FilterMode.Includes) {
    return (
      date.getTime() >= cachedFilterState.firstTime &&
      date.getTime() <= cachedFilterState.secondTime
    );
  }

  if (filterMode === FilterMode.Before) {
    return date.getTime() <= cachedFilterState.secondTime;
  }

  if (filterMode === FilterMode.After) {
    return date.getTime() >= cachedFilterState.firstTime;
  }

  if (filterMode === FilterMode.Between && filterValue[1]) {
    return (
      date.getTime() >= cachedFilterState.firstTime &&
      date.getTime() <= cachedFilterState.secondTime
    );
  }

  return true;
}

export function date<TData, TFilter, TSort>() {
  return ({
    dateFormat,
    edit,
    ...columnDef
  }: DateColumnDef<TData, string | undefined, TFilter, TSort>): ColumnDef<
    TData,
    string | undefined
  > => {
    return {
      enableSorting: false,
      sortDescFirst: false,
      sortingFn: (rowA, rowB, columnId) => {
        //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const valueA = rowA.getValue(columnId) as string | undefined;
        //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const valueB = rowB.getValue(columnId) as string | undefined;

        if (!valueA && !valueB) return 0;
        if (!valueA) return -1;
        if (!valueB) return 1;

        return dayjs(valueA).isBefore(dayjs(valueB)) ? -1 : 1;
      },
      enableColumnFilter: false,
      ...columnDef,
      enableGlobalFilter: false,

      cell: (context) => {
        if (edit && (!edit.canEditGuard || edit.canEditGuard(context.row))) {
          return <EditableCell context={context} {...edit} />;
        }

        const value = context.getValue();
        const formattedValue = formatDateValue(value, dateFormat);

        return (
          <CellContainer cell={context.cell} p={0} data-group>
            <OverflowContainer>
              <OverflowContainer.Tooltip
                label={formattedValue}
                hasArrow
                placement="bottom-start"
                fontSize="sm"
                openDelay={500}
              >
                <Text isTruncated p={4}>
                  {formattedValue}
                </Text>
              </OverflowContainer.Tooltip>
            </OverflowContainer>
          </CellContainer>
        );
      },

      filterFn: filterHelper.filterFn,

      meta: {
        ...columnDef.meta,
        name: columnDef.header,
        getColumnFilterCondition: columnDef.getColumnFilterCondition as never,
        getColumnSort: columnDef.getColumnSort as never,

        filter: {
          filterSchema: filterHelper.filterSchema,

          DisplayFilter: ({ column }) => {
            const { t } = useTranslation('ui');
            const mode = filterHelper.getFilterMode(column);
            const value = filterHelper.getFilterValue(column);
            const firstValue = value?.[0];
            const secondValue = value?.[1];

            return (
              <span>
                {mode === FilterMode.IsEmpty || mode === FilterMode.IsNotEmpty ? (
                  mode === FilterMode.IsEmpty ? (
                    t('table.filter.isEmpty')
                  ) : (
                    t('table.filter.isNotEmpty')
                  )
                ) : (
                  <>
                    {mode === FilterMode.Before && `${t('table.filter.before')} `}
                    {mode === FilterMode.After && `${t('table.filter.after')} `}
                    {formatDate(firstValue)}
                    {mode === FilterMode.Between && ` - ${formatDate(secondValue)}`}
                  </>
                )}
              </span>
            );
          },

          EditFilter: ({ column }) => {
            const { t } = useTranslation('ui');

            const modeOptions = useMemo(
              () => [
                { label: t('table.filter.is'), value: FilterMode.Includes },
                { label: t('table.filter.after'), value: FilterMode.After },
                { label: t('table.filter.before'), value: FilterMode.Before },
                { label: t('table.filter.between'), value: FilterMode.Between },
                { label: t('table.filter.isEmpty'), value: FilterMode.IsEmpty },
                { label: t('table.filter.isNotEmpty'), value: FilterMode.IsNotEmpty },
              ],
              [t],
            );

            const mode = filterHelper.getFilterMode(column);
            const value = filterHelper.getFilterValue(column);
            const firstValue = value?.[0] ?? null;
            const secondValue = value?.[1] ?? null;
            const firstLabel = useMemo(
              () =>
                mode === FilterMode.Includes
                  ? t('table.filter.is')
                  : mode === FilterMode.After
                    ? t('table.filter.after')
                    : mode === FilterMode.Before
                      ? t('table.filter.before')
                      : t('table.filter.after'),
              [mode, t],
            );

            const hasInput = !isIsEmptyOrIsNotEmpty(mode);

            return (
              <FormControl p={3}>
                <FormLabel fontWeight="medium" mb={hasInput ? undefined : 0}>
                  <HStack>
                    <Text>{columnDef.header}</Text>
                    <FilterModeSelector {...filterHelper} column={column} options={modeOptions} />
                  </HStack>
                </FormLabel>
                {hasInput && (
                  <HStack>
                    <Input
                      autoFocus
                      size="sm"
                      placeholder={t('table.column.date.filterPlacehoder')}
                      borderRadius="base"
                      type="date"
                      value={firstValue ?? ''}
                      max={mode === FilterMode.Between && secondValue ? secondValue : undefined}
                      aria-label={`${columnDef.header} ${firstLabel}`}
                      onChange={(event) => {
                        const first = event.target.value ?? null;
                        const second =
                          first &&
                          secondValue &&
                          new Date(first).getTime() > new Date(secondValue).getTime()
                            ? first
                            : secondValue;

                        filterHelper.setFilterValue(column, [first, second]);
                      }}
                    />
                    {mode === FilterMode.Between && (
                      <Input
                        autoFocus
                        size="sm"
                        placeholder={t('table.column.date.filterPlacehoder')}
                        borderRadius="base"
                        type="date"
                        value={secondValue ?? ''}
                        min={firstValue ?? undefined}
                        aria-label={`${columnDef.header} ${t('table.filter.before')}`}
                        onChange={(event) => {
                          const second = event.target.value ?? null;
                          const first =
                            second &&
                            firstValue &&
                            new Date(second).getTime() < new Date(firstValue).getTime()
                              ? second
                              : firstValue;

                          filterHelper.setFilterValue(column, [first, second]);
                        }}
                      />
                    )}
                  </HStack>
                )}
              </FormControl>
            );
          },
        },
      },
    };
  };
}

type EditableCellProps<TData> = EditProps<TData> & {
  context: CellContext<TData, string | undefined>;
  dateFormat?: string;
};

function EditableCell<TData>({
  context,
  onChange,
  dateFormat,
  min,
  max,
}: EditableCellProps<TData>) {
  const defaultValue = context.getValue() ?? '';
  const formattedDefaultValue = formatDateValue(defaultValue, dateFormat);
  const [value, setValue] = useState(defaultValue);
  const datepickerBgColor = useColorModeValue('gray.25', 'gray.700');
  /* Removing border width to get the exact contend width */
  const size = context.column.columnDef.size ? context.column.columnDef.size - 3 : 160;

  const onCellUpdate = () => {
    if (value !== defaultValue) {
      onChange(context.row, value);
    }
  };

  const initialFocusRef = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <OverflowContainer justify="space-between">
        <OverflowContainer.Tooltip
          label={formattedDefaultValue}
          placement="bottom-start"
          fontSize="sm"
          openDelay={500}
        >
          <Text isTruncated p={4} w="full">
            {formattedDefaultValue}
          </Text>
        </OverflowContainer.Tooltip>
        <OverflowContainer.EditTrigger onClick={onToggle} />
      </OverflowContainer>

      <Popover initialFocusRef={initialFocusRef} isOpen={isOpen} onClose={onClose}>
        <PopoverContent
          maxW="fit-content"
          rounded="none"
          bgColor={datepickerBgColor}
          _focusVisible={{
            outline: 'none',
          }}
        >
          <Input
            type="date"
            ref={initialFocusRef}
            fontSize="none"
            h="50px"
            w={`${size}px`}
            border={0}
            outline="none"
            focusBorderColor="transparent"
            rounded="sm"
            min={min}
            max={max}
            defaultValue={dayjs(value).format('YYYY-MM-DD')}
            onChange={(event) => setValue(event.target.value)}
            onBlur={onCellUpdate}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                (e.target as HTMLInputElement).blur();
              }
            }}
            /* This stops triggering click event on cell itself when clicking on the datepicker */
            onClick={(event) => event.stopPropagation()}
          />
        </PopoverContent>
      </Popover>
    </>
  );
}

function formatDateValue(value?: string | Date, format?: string) {
  if (!value) return '';
  // if the input is not a valid date, then it is used to inform the user why
  // the date is not possible to display (e.g. "Confidential" evidence)
  if (isNaN(new Date(value).getTime())) return String(value);
  return formatDate(value, format);
}
