/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { FileInfoFragmentFragmentDoc } from '../../../../../../libs/graphql/src/fragments/FileInfoFragment.generated';
export type VendorQuestionnaireDocumentFragment = {
  __typename?: 'vendor_documents';
  vq_upload_id?: string;
  file?: { __typename?: 'files'; id: string; name?: string; size?: number };
};

export const VendorQuestionnaireDocumentFragmentDoc = `
    fragment VendorQuestionnaireDocument on vendor_documents {
  file {
    ...FileInfoFragment
  }
  vq_upload_id
}
    `;
