import { MigrationHost } from './migration-host';

export class BrowserStorageMigrationHost implements MigrationHost {
  constructor(
    public readonly storage: Storage,
    protected readonly versionKey: string,
    protected readonly initialVersion = 0,
  ) {}

  currentVersion() {
    return Number(this.storage.getItem(this.versionKey) ?? this.initialVersion);
  }

  setCurrentVersion(version: number) {
    this.storage.setItem(this.versionKey, String(version));
  }
}
