import { Divider, FormControl, FormLabel, HStack, Skeleton, Text } from '@chakra-ui/react';
import { FilterMode, isIsEmptyOrIsNotEmpty } from '@main/shared/url-helpers';
import { isEmpty } from '@main/shared/utils/is-empty';
import { Column, memo, Table } from '@tanstack/react-table';
import { OnChangeValue } from 'chakra-react-select';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ComboboxProps, selectValueToArray } from '../../../combobox';
import { createFilterHelper, FilterColumnMeta } from '../../filters';
import { FilterModeSelector } from '../../filters/filter-mode';

type SelectFilterConfig<TData, TOptionBase> = {
  name: string;
  getOptions?: (
    table: Table<TData>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    column: Column<TData, any>,
  ) => TOptionBase[] | Promise<TOptionBase[]>;
  getOptionValue: (option: TOptionBase) => string;
  getOptionLabel: (option: TOptionBase) => string;

  components: {
    Edit: <TOption extends TOptionBase, IsMulti extends boolean>(
      props: Partial<ComboboxProps<TOption, IsMulti>>,
    ) => ReactElement;
  };
};

export function createSelectFilter<
  TData,
  TOptionBase,
  TOption extends TOptionBase,
  IsMulti extends boolean,
>({
  name,
  getOptions = createGetOptionsFromTableData(),
  getOptionValue,
  getOptionLabel,
  components,
}: SelectFilterConfig<TData, TOptionBase>) {
  type Value = OnChangeValue<TOption, IsMulti> | undefined;

  const filterHelper = createFilterHelper<Value, readonly string[]>({
    filterSchema: z.array(z.string()),
    filterFn: (value, filterValues, mode) => {
      if (mode === FilterMode.IsEmpty) {
        return isEmpty(value);
      }

      if (mode === FilterMode.IsNotEmpty) {
        return !isEmpty(value);
      }

      if (!filterValues?.length) {
        return true;
      }

      const values = selectValueToArray<TOption>(value).map(getOptionValue);

      if (mode === FilterMode.Excludes) {
        return filterValues.every((filterValue) => !values.includes(filterValue));
      }

      return filterValues.some((filterValue) => values.includes(filterValue));
    },
  });

  const meta: FilterColumnMeta<TData, Value> = {
    filterSchema: filterHelper.filterSchema,

    DisplayFilter: ({ table, column }) => {
      const { t } = useTranslation('ui');

      const { isLoading, getOptionsByValues } = useSelectOptions({
        table,
        getOptions: () => getOptions(table, column),
        getOptionValue,
      });
      const values = filterHelper.getFilterValue(column) || [];
      const selectedOptions = getOptionsByValues(values);

      const mode = filterHelper.getFilterMode(column);
      return (
        <span>
          {isIsEmptyOrIsNotEmpty(mode) ? (
            mode === FilterMode.IsEmpty ? (
              t('table.filter.isEmpty')
            ) : (
              t('table.filter.isNotEmpty')
            )
          ) : (
            <>
              {mode === FilterMode.Excludes && t('table.filter.notLabel')}
              {isLoading && values.length ? (
                <Skeleton
                  display="inline-block"
                  w="64px"
                  h="16px"
                  verticalAlign="middle"
                  as="span"
                />
              ) : (
                selectedOptions.map(getOptionLabel).join(', ')
              )}
            </>
          )}
        </span>
      );
    },

    EditFilter: ({ table, column }) => {
      const { t } = useTranslation('ui');

      const mode = filterHelper.getFilterMode(column);
      const isExcluding = mode === FilterMode.Excludes;
      const { options, isLoading, getOptionsByValues } = useSelectOptions({
        table,
        getOptions: () => getOptions(table, column),
        getOptionValue,
      });
      const values = filterHelper.getFilterValue(column) || [];
      const selectedOptions = getOptionsByValues(values);

      return (
        <FormControl>
          <FormLabel m={3} fontWeight="medium">
            <HStack spacing={1}>
              <Text color="gray.600" _dark={{ color: 'whiteAlpha.800' }}>
                {name}
              </Text>
              <FilterModeSelector {...filterHelper} column={column} />
            </HStack>
          </FormLabel>
          {!isIsEmptyOrIsNotEmpty(mode) && (
            <>
              <Divider opacity={1} />
              <components.Edit
                size="sm"
                variant="popover"
                placeholder={t('table.column.select.filterPlacehoder')}
                autoFocus
                menuIsOpen
                isMulti
                isLoading={isLoading}
                options={options}
                value={selectedOptions}
                aria-label={`${name} ${
                  isExcluding ? t('table.filter.excludes') : t('table.filter.includes')
                }`}
                onChange={(value) =>
                  filterHelper.setFilterValue(
                    column,
                    value.length ? value.map(getOptionValue) : null,
                  )
                }
              />
            </>
          )}
        </FormControl>
      );
    },
  };

  return {
    filterFn: filterHelper.filterFn,

    globalFilterFn: (value: Value, filterValue: string) => {
      if (!filterValue?.length) {
        return true;
      }

      const normalizedFilterValue = filterValue.toLowerCase();
      return selectValueToArray<TOption>(value).some((value) =>
        getOptionLabel(value).toLowerCase().includes(normalizedFilterValue),
      );
    },

    meta,
  };
}

function createGetOptionsFromTableData<TOptionBase>() {
  let getOptions: () => TOptionBase[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <TData,>(table: Table<TData>, column: Column<TData, any>) => {
    if (!getOptions) {
      // use `@tanstack/react-table`'s `memo` to prevent recalculating unique select options
      // until `data` passed to the table changes
      // https://github.com/TanStack/table/blob/16cd93caf5d68f89249613964f73e6e44134d5a9/packages/table-core/src/utils.ts#L131
      getOptions = memo(
        () => [table.getCoreRowModel().flatRows],
        (rows) => {
          return rows.reduce((values: TOptionBase[], row) => {
            const value = row.getValue<OnChangeValue<TOptionBase, boolean> | undefined>(column.id);
            values.push(...selectValueToArray(value));
            return values;
          }, []);
        },
        {
          key: 'selectOptionsHelper.getOptionsMap',
          debug: () => table.options.debugAll ?? table.options.debugColumns,
        },
      );
    }

    return getOptions();
  };
}

function useSelectOptions<TData, TOptionBase>({
  table,
  getOptions,
  getOptionValue,
}: {
  table: Table<TData>;
  getOptions(): TOptionBase[] | Promise<TOptionBase[]>;
  getOptionValue(option: TOptionBase): string;
}) {
  const [optionsMap, setOptionsMap] = useState<Map<string, TOptionBase> | null>(null);

  useEffect(() => {
    void (async () => {
      const options = await getOptions();
      const optionsMap = options.reduce((map, option) => {
        map.set(getOptionValue(option), option);
        return map;
      }, new Map<string, TOptionBase>());
      setOptionsMap(optionsMap);
    })();
    // refetch all select options on table data change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.options.data]);

  const options = optionsMap ? Array.from(optionsMap.values()) : [];
  const isLoading = optionsMap === null;

  const getOptionsByValues = (values: readonly string[]) => {
    return values.reduce((options: TOptionBase[], value) => {
      const option = optionsMap?.get(value);
      if (option) {
        options.push(option);
      }
      return options;
    }, []);
  };

  return {
    options,
    isLoading,
    getOptionsByValues,
  };
}
