import { Button, Icon, IconButton, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowTopRightOnSquareIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { isGraphqlError, toError } from '@main/shared/utils';
import { useDrawer, useMultiStateToast } from '@main/ui';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';

type AiCreateRiskMutationReturnType = ReturnType<ReturnType<typeof useAiCreateEntityMutation>[0]>;

export const CreateFindingRiskButton = ({
  riskId,
  onCreate,
}: {
  riskId: string | undefined;
  onCreate: () => AiCreateRiskMutationReturnType;
}) => {
  const { t } = useTranslation();
  const toast = useMultiStateToast();
  const drawer = useDrawer();
  const [isLoading, setIsLoading] = useState(false);
  const actionBtnColor = useColorModeValue('gray.500', 'gray.300');
  const toastTextColor = useColorModeValue('green.800', 'green.600');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewRisk = userRole.permissionMap?.read_risks;
  const { is_risks_module_enabled: isRisksModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const createRisk = async () => {
    toast.loading({ title: t('findings.risk.isBeingCreatedToast') });

    try {
      const { ai_create_entity } = await onCreate().unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey={
              ai_create_entity?.isGeneratedByAI
                ? 'findings.risk.createdToastAI'
                : 'findings.risk.createdToast'
            }
            values={{
              internalId: ai_create_entity?.internalId,
            }}
            components={{
              p: <Text display="inline" color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({
                      entity: 'risk',
                      entityId: ai_create_entity?.entityId ?? '',
                    });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        const findingId = onCreate().arg.originalArgs.input.findingId;
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(error[0].message, { findingId }, toError(error));
            break;
          default:
            datadogLogs.logger.error(
              'Either creating risk or linking it to the finding failed',
              { findingId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.risk') }) });
    }
  };

  if (!isRisksModuleEnabled) {
    return null;
  }

  if (canViewRisk && riskId) {
    return (
      <Tooltip label={t('findings.risk.openTooltip')} rounded="md" hasArrow placement="top">
        <Button
          size="sm"
          variant="outline"
          color={actionBtnColor}
          onClick={() =>
            drawer.open({
              entity: 'risk',
              entityId: riskId,
            })
          }
          rightIcon={<Icon as={ArrowTopRightOnSquareIcon} w={4} h={4} color={actionBtnColor} />}
        >
          {t('entities.risk')}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip label={t('findings.risk.createTooltip')} rounded="md" hasArrow placement="top">
      <IconButton
        onClick={async () => {
          setIsLoading(true);
          await createRisk();
          setIsLoading(false);
        }}
        variant="outline"
        aria-label="Create risk"
        size="sm"
        isLoading={isLoading}
        icon={<Icon as={TableCellsIcon} w={4} h={4} color={actionBtnColor} />}
      />
    </Tooltip>
  );
};
