import { Finding_Resolutions, Finding_Types_Enum } from '@main/graphql/types.generated';
import { Severity } from '@main/shared/utils';

export function findingTypeSeverity(findingType: Finding_Types_Enum): Severity {
  switch (findingType) {
    case Finding_Types_Enum.Consideration:
      return Severity.CONSIDERATION;
    case Finding_Types_Enum.Recommendation:
      return Severity.MEDIUM;
    case Finding_Types_Enum.Issue:
      return Severity.HIGH;
  }
}

export const getHighestSeverity = <
  T extends { type: Finding_Types_Enum; resolution?: Partial<Finding_Resolutions> },
>(
  findings: T[],
) => {
  return findings.reduce((highestSeverity, finding) => {
    if (finding.resolution) {
      return highestSeverity;
    }

    const severity = findingTypeSeverity(finding.type);
    if (severity > highestSeverity) {
      return severity;
    }

    return highestSeverity;
  }, Severity.LOW);
};
