import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Datadog]: HandlerConfigDatadog;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigDatadog {}
}

export const DatadogConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Datadog> = {
  name: Integration_Names_Enum.Datadog,

  orgConfig: z.object({
    baseUrl: z.string().min(1).describe('Base URL'),
    apiKey: z.string().min(1).describe('API Key'),
    appKey: z.string().min(1).describe('App Key'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.ActivePipelines),
      pipelineIds: z.array(z.string()).min(1),
    }),
  ]),
};
