import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState } from 'react';

/**
 * React hook which syncs table's global filter state to URL query search.
 * `searchParam` configures the search param name under which the state
 * will be persited in the URL.
 */
export function useTableSearchQuery({ searchParam }: { searchParam: string }) {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState(
    () => new URLSearchParams(window.location.search).get(searchParam) ?? '',
  );

  useEffect(() => {
    void navigate({
      search: (old) => ({
        ...old,
        [searchParam]: globalFilter || undefined,
      }),
      replace: true,
    });
  }, [navigate, searchParam, globalFilter]);

  return [globalFilter, setGlobalFilter] as const;
}
