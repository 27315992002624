import { encodeColumnFilters } from '@main/shared/url-helpers';
import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import * as jsurl from 'jsurl2';
import { useEffect, useMemo, useState } from 'react';

import { isColumnFilter, isValidColumnFilter } from './filters';

/**
 * React hook which syncs table's column filter state to URL query search.
 * `searchParam` configures the search param name under which the state
 * will be persited in the URL.
 */
export function useTableFiltersQuery<TData>({
  columns,
  searchParam,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  searchParam: string;
}) {
  const navigate = useNavigate();

  const [columnFilters, setColumnFilters] = useState(() =>
    decodeColumnFilters(new URLSearchParams(window.location.search).get(searchParam) ?? ''),
  );

  useEffect(() => {
    void navigate({
      search: (old) => ({
        ...old,
        [searchParam]: encodeColumnFilters(columnFilters),
      }),
      replace: true,
    });
  }, [navigate, searchParam, columnFilters]);

  const filteredColumnFilters = useMemo(
    () => columnFilters.filter((columnFilter) => isValidColumnFilter(columns, columnFilter)),
    [columns, columnFilters],
  );

  return [filteredColumnFilters, setColumnFilters] as const;
}

function decodeColumnFilters(encodedFilters: string): ColumnFiltersState {
  try {
    const json = jsurl.parse(encodedFilters);
    if (!Array.isArray(json)) {
      return [];
    }
    return json.filter(isColumnFilter);
  } catch {
    return [];
  }
}
