import {
  HStack,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  RiskImpactFragment,
  RiskLikelihoodFragment,
  useDeleteRiskImpactMutation,
  useDeleteRiskLikelihoodMutation,
  useGetRiskClassificationsQuery,
} from '@main/graphql/features/RiskClassifications.generated';
import { Promisable } from '@main/shared/types';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useTableRowReorder,
} from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../../user/slice';
import { ClassificationForm, ClassificationType } from './form';

export type Classification = RiskImpactFragment | RiskLikelihoodFragment;

type ClassificationTableProps = {
  data: Classification[];
  classificationType: ClassificationType;
  onOrderUpdated?(classifications: Classification[]): Promisable<void>;
};
export function ClassificationTable({
  data,
  classificationType,
  onOrderUpdated,
}: ClassificationTableProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setValue] = useState<Classification>();

  const openModal = (data: Classification) => {
    setValue(data);
    onOpen();
  };

  const columns = useClassificationsTableColumns({ classificationType, openModal });

  const itemName = useMemo(() => {
    return classificationType === 'impact'
      ? {
          singular: t('entities.impact').toLowerCase(),
          plural: t('entities.plural.impacts').toLowerCase(),
        }
      : {
          singular: t('entities.likelihood').toLowerCase(),
          plural: t('entities.plural.likelihoods').toLowerCase(),
        };
  }, [classificationType, t]);

  const tableRowReorder = useTableRowReorder({
    onRowsOrderUpdated: onOrderUpdated ?? (() => void 0),
  });

  return (
    <>
      <Table
        minW="500px"
        data={data}
        columns={columns}
        itemName={itemName}
        {...(onOrderUpdated ? tableRowReorder : {})}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {classificationType === 'impact'
              ? t('settings.organization.risks.classifications.impacts.createNewImpact')
              : t('settings.organization.risks.classifications.likelihoods.createNewLikelihood')}
          </ModalHeader>
          <ModalCloseButton />
          <ClassificationForm
            onClose={onClose}
            classificationType={classificationType}
            actionType="edit"
            value={value}
          />
        </ModalContent>
      </Modal>
    </>
  );
}

function useClassificationsTableColumns({
  classificationType,
  openModal,
}: {
  classificationType: ClassificationType;
  openModal: (data: Classification) => void;
}) {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [deleteImpact] = useDeleteRiskImpactMutation();
  const [deleteLikelihood] = useDeleteRiskLikelihoodMutation();
  const { refetch: refetchClassifications } = useGetRiskClassificationsQuery({ organizationId });

  const onDelete = useCallback(
    async (id: string) => {
      const translation =
        classificationType === 'impact'
          ? t('entities.impact').toLowerCase()
          : t('entities.likelihood').toLowerCase();
      try {
        if (classificationType === 'impact') {
          await deleteImpact({ id }).unwrap();
        } else {
          await deleteLikelihood({ id }).unwrap();
        }

        refetchClassifications();
        successToast(
          t('successMessages.deleteSucceeded', {
            entity: `${t('entities.risk')} ${translation}`,
          }),
        );
      } catch (error) {
        errorToast(
          t('errorMessages.deleteFailed', {
            entity: `${t('entities.risk')} ${translation}`,
          }),
        );
        datadogLogs.logger.error(`Deleting risk ${classificationType} failed`, {}, toError(error));
      }
    },
    [classificationType, deleteImpact, deleteLikelihood, refetchClassifications, t],
  );

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canManageRiskClassifications = userRole.permissionMap.manage_risk_classification;

  return useMemo(() => {
    const columnHelper = createColumnHelper<Classification>();
    return [
      columnHelper.columns.text({
        id: 'name',
        header: t('settings.organization.risks.classifications.table.cols.name'),
        accessorFn: ({ name }) => name,
        size: 220,
        enableHiding: false,
      }),
      columnHelper.columns.text({
        id: 'description',
        header: t('settings.organization.risks.classifications.table.cols.description'),
        accessorFn: ({ description }) => description,
      }),

      ...(canManageRiskClassifications
        ? [
            columnHelper.columns.actions({
              header: t('settings.organization.risks.classifications.table.cols.actions'),
              size: 80,
              PrimaryAction: ({ row }) => {
                return (
                  <HStack>
                    <IconButton
                      minW={4}
                      aria-label="Edit"
                      variant="link"
                      icon={<PencilIcon />}
                      onClick={() => openModal(row.original)}
                    />
                    <IconButton
                      minW={4}
                      aria-label="Delete"
                      variant="link"
                      icon={<TrashIcon />}
                      onClick={() =>
                        openDialog({
                          dialogHeader:
                            classificationType === 'impact'
                              ? t(
                                  'settings.organization.risks.classifications.impacts.alert.header',
                                )
                              : t(
                                  'settings.organization.risks.classifications.likelihoods.alert.header',
                                ),
                          dialogContent:
                            classificationType === 'impact'
                              ? t(
                                  'settings.organization.risks.classifications.impacts.alert.content',
                                )
                              : t(
                                  'settings.organization.risks.classifications.likelihoods.alert.content',
                                ),
                          confirmAction: {
                            children: t('settings.organization.risks.classifications.delete'),
                            onClick: () => onDelete(row.original.id),
                          },
                        })
                      }
                    />
                  </HStack>
                );
              },
            }),
          ]
        : []),
    ];
  }, [canManageRiskClassifications, classificationType, onDelete, openDialog, openModal, t]);
}
