import { ButtonGroup, VStack } from '@chakra-ui/react';
import { useStableCallback } from '@main/shared/utils';
import { Children, createContext, PropsWithChildren, useMemo, useState } from 'react';

export type HoverButtonGroupProps = PropsWithChildren<{
  spacing?: string | number;
  forceHide?: boolean;
}>;

type SetIsVisible = (id: string, isVisible: boolean) => void;
export type HoverButtonGroupContextValue = {
  shortButtons: boolean;
  setIsVisible: SetIsVisible;
};

export const HoverButtonGroupContext = createContext<HoverButtonGroupContextValue>({
  shortButtons: false,
  setIsVisible: (id, isVisible) => undefined,
});

export function HoverButtonGroup({ spacing = '1.5', children, forceHide }: HoverButtonGroupProps) {
  const [isVisibleById, setIsVisibleById] = useState<Record<string, boolean>>({});

  const isVisible = useMemo(
    () => !forceHide && !Object.values(isVisibleById).every((x) => x === false),
    [forceHide, isVisibleById],
  );
  const setIsVisible = useStableCallback<SetIsVisible>((id, isVisible) =>
    setIsVisibleById((prev) => ({ ...prev, [id]: isVisible })),
  );
  const shortButtons = Children.toArray(children).length > 1;

  const contextValue = useMemo(
    () => ({ shortButtons, setIsVisible }),
    [shortButtons, setIsVisible],
  );

  return (
    <HoverButtonGroupContext.Provider value={contextValue}>
      <VStack
        position="absolute"
        top={spacing}
        right={spacing}
        justifyContent="center"
        display={isVisible ? 'flex' : 'none'}
        _groupHover={{ display: forceHide ? 'none' : 'flex' }}
      >
        <ButtonGroup isAttached>{children}</ButtonGroup>
      </VStack>
    </HoverButtonGroupContext.Provider>
  );
}

HoverButtonGroup.ParentProps = {
  position: 'relative',
  'data-group': true,
} as const;
