import { forwardRef, Icon, IconButton, Tooltip } from '@chakra-ui/react';

import type { IconActionProps } from './types';

export const IconAction = forwardRef(
  ({ icon, tooltip, tooltipPlacement, ...props }: IconActionProps, ref) => {
    return (
      <Tooltip label={tooltip} openDelay={500} placement={tooltipPlacement}>
        <IconButton
          ref={ref}
          minW={0}
          variant="link"
          aria-label={tooltip}
          icon={<Icon as={icon} w={5} h={5} />}
          {...props}
          /* Prevent tooltip appearing when this button opens a modal dialog. */
          onFocus={(event) => event.preventDefault()}
        />
      </Tooltip>
    );
  },
);
