import { HStack, Tag } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ClockIcon,
  DocumentTextIcon,
  ListBulletIcon,
  ShieldExclamationIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import { RiskTabKeys } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs, StatusTag } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { CommentsTab } from '../comments/comments-tab';
import { RISK_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { RiskControlsTab } from './controls-tab';
import { RiskDetailsTab } from './details-tab';
import { RiskDocumentsTab } from './documents-tab';
import { useGetRiskQuery } from './get-risk.sub';
import { RiskHistoryTab } from './history-tab';
import { RiskReviewsTab } from './reviews-tab';
import { RiskLevelsTab } from './risk-levels-tab';
import { RiskTasksTab } from './tasks-tab';
import { useUpdateRiskMutation } from './update-risk.generated';
import { useRiskDrawerActions } from './use-drawer-actions';
import { useGetRiskDrawerData } from './use-get-risk-data';
import { useUpdateRiskHandler } from './use-update-risk-handler';

export const RiskDrawer = ({ riskId }: { riskId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewRisk = userRole.permissionMap?.read_risks;
  const { risk, isLoading: isRiskLoading } = useOrgGuard(
    useGetRiskDrawerData({ riskId }),
    ({ risk }) => risk?.organization_id,
  );

  const { tabs } = useRiskDrawerTabs({ riskId });
  const { menuActions } = useRiskDrawerActions(riskId);

  return (
    <Drawer.Layout isLoading={isRiskLoading} canView={canViewRisk} isNotFound={!risk}>
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <RiskDrawerHeader riskId={riskId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const RiskDrawerHeader = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;

  const [updateRisk] = useUpdateRiskMutation();
  const updateRiskHandler = useUpdateRiskHandler();
  const { risk } = useGetRiskDrawerData({ riskId });

  if (!risk) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.risk')} / {risk.internal_id}
        </Tag>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={RISK_ASSESSMENT_STATUS_COLOR[risk.assessment_status]}
          data-testid="risk-status"
        >
          {t(`risks.enum.assessmentStatus.${risk.assessment_status}`)}
        </StatusTag>
      </HStack>
      <EditableAutoResizeTextarea
        autoFocusIfEmpty
        isDisabled={!canUpdateRisks}
        ml={-2}
        fontSize="xl"
        fontWeight="bold"
        defaultValue={risk.name}
        placeholder={t('risks.details.name')}
        onSubmit={(value) =>
          updateRiskHandler(
            updateRisk({
              id: riskId,
              risk_input: {
                name: value,
              },
            }),
          )
        }
      />
    </>
  );
};

const useRiskDrawerTabs = ({ riskId }: { riskId: string }) => {
  const { is_controls_module_enabled: isControlsModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const { t } = useTranslation();
  const { comments, refetch } = useGetRiskQuery(
    { risk_id: riskId },
    {
      selectFromResult: ({ data }) => {
        return { comments: data?.risks_by_pk?.risks_comments ?? [] };
      },
    },
  );

  const refetchComments = useCallback(async () => {
    await refetch().unwrap();
  }, [refetch]);

  const tabs = useMemo(
    () =>
      getVisibleTabs<RiskTabKeys>({
        details: {
          label: t('risks.tabs.details'),
          icon: ListBulletIcon,
          panel: <RiskDetailsTab riskId={riskId} />,
          hideTabTitle: true,
        },
        riskLevel: {
          label: t('risks.tabs.riskLevel'),
          icon: ShieldExclamationIcon,
          panel: <RiskLevelsTab riskId={riskId} />,
        },
        documents: {
          label: t('risks.tabs.documents'),
          icon: DocumentTextIcon,
          panel: (
            <RiskDocumentsTab
              riskId={riskId}
              onFilesChange={async () => {
                await refetch().unwrap();
              }}
            />
          ),
        },
        controls: {
          label: t('risks.tabs.controls'),
          icon: Square2StackIcon,
          panel: <RiskControlsTab riskId={riskId} />,
          isHidden: !isControlsModuleEnabled,
        },
        reviews: {
          label: t('risks.tabs.reviews'),
          icon: ClipboardDocumentIcon,
          panel: <RiskReviewsTab riskId={riskId} />,
        },
        comments: {
          label: t('risks.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="risk"
              entityId={riskId}
              comments={comments}
              refetchComments={refetchComments}
            />
          ),
        },
        tasks: {
          label: t('risks.tabs.tasks'),
          icon: ClipboardDocumentCheckIcon,
          panel: <RiskTasksTab riskId={riskId} />,
        },
        history: {
          label: t('risks.tabs.history'),
          icon: ClockIcon,
          panel: <RiskHistoryTab riskId={riskId} />,
        },
      }),
    [comments, isControlsModuleEnabled, refetch, refetchComments, riskId, t],
  );

  return { tabs };
};
