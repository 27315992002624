import { Box, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import { QuestionnnaireFormMode } from '@main/questionnaires-form';
import { getQuestionnaireType, QuestionnaireType } from '@main/shared/utils';
import {
  FileUpload,
  useDownloadStorageFile,
  useLazyFileUpload,
  useLazyMultipleFilesUpload,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getFormattedVendorQuestionnaire } from '../slice';
import { useGetVendorQuestionnaireDocumentsQuery } from './submissions-tab.generated';
import { VendorQuestionnaireForm } from './vq-form';

export const SubmissionsTab = ({ vqId }: { vqId: string }) => {
  const vq = useAppSelector((state) => getFormattedVendorQuestionnaire(state, vqId));
  const type = getQuestionnaireType(vq.questionnaire);

  if (!vq.vendor) {
    throw new Error('Vendor not found');
  }

  const { documents, refetch } = useGetVendorQuestionnaireDocumentsQuery(
    { vendorId: vq.vendor.id },
    { selectFromResult: ({ data }) => ({ documents: data?.vendors_by_pk?.documents ?? [] }) },
  );

  return type === QuestionnaireType.Form ? (
    <VendorQuestionnaireForm
      vendorId={vq.vendor.id}
      mode={QuestionnnaireFormMode.View}
      fields={vq.form?.config_snapshot ?? []}
      answers={vq.form?.answers}
      uploads={vq.questionnaire?.questionnaire_uploads}
      documents={documents}
      onDocumentsChange={refetch}
    />
  ) : (
    <QuestionnaireFile vqId={vqId} />
  );
};

const QuestionnaireFile = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const vq = useAppSelector((state) => getFormattedVendorQuestionnaire(state, vqId));

  const supportingFiles = useMemo(() => {
    const uploads = vq?.uploads || [];
    const answerUploadId = vq?.answer_upload?.id;
    return uploads.filter((file) => file.id !== answerUploadId).map((upload) => upload.file);
  }, [vq?.uploads, vq?.answer_upload?.id]);

  const downloadStorageFile = useDownloadStorageFile();
  const answerFile = vq?.answer_upload?.file;

  const answerFileUpload = useLazyFileUpload({
    file: answerFile,
    onFileDownload: downloadStorageFile,
  });
  const supportingFileUpload = useLazyMultipleFilesUpload({
    files: supportingFiles,
    onFileDownload: downloadStorageFile,
  });
  return (
    <Stack spacing={6} pb={4}>
      <Box>
        <FormControl w="full" isReadOnly>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.answerFileLabel')}
          </FormLabel>
          <FileUpload {...answerFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      </Box>
      <Box>
        <FormControl w="full" isReadOnly>
          <FormLabel mb={2}>
            {t('vendors.questionnaires.answerFilesModal.supportingFilesLabel')}
          </FormLabel>
          <FileUpload {...supportingFileUpload.props}>
            <FileUpload.Dropzone />
          </FileUpload>
        </FormControl>
      </Box>
    </Stack>
  );
};
