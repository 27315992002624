import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ThemingProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useStableCallback } from '@main/shared/utils';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface ModalConfig {
  header: ReactNode;
  content: ReactNode;
  size?: ThemingProps<'Modal'>['size'];
}

export type ModalContextProps = {
  isModalOpen: boolean;
  openModal: (state: ModalConfig) => void;
  closeModal: () => void;
};

export const defaultContext: ModalContextProps = {
  isModalOpen: false,
  openModal: () => ({}),
  closeModal: () => ({}),
};

const defaultConfig: ModalConfig = {
  size: 'xl',
  header: undefined,
  content: undefined,
};

const ModuleContext = createContext<ModalContextProps>(defaultContext);

export const useModal = () => useContext(ModuleContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalState, setModalState] = useState<ModalConfig>(defaultConfig);
  const { header, content, ...modalProps } = modalState;

  const openModal = useStableCallback((newState: ModalConfig) => {
    setModalState((prevState) => ({ ...prevState, ...newState }));
    onOpen();
  });

  const closeModal = useStableCallback(() => {
    setModalState(defaultConfig);
    onClose();
  });

  const contextValue = useMemo(
    () => ({ isModalOpen: isOpen, openModal, closeModal }),
    [isOpen, openModal, closeModal],
  );

  const renderHeader = () => {
    if (typeof header === 'string') {
      return <ModalHeader fontSize="lg">{header}</ModalHeader>;
    }
    return header;
  };

  return (
    <ModuleContext.Provider value={contextValue}>
      {children}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        scrollBehavior="inside"
        motionPreset="slideInBottom"
        {...modalProps}
      >
        <ModalOverlay />
        <ModalContent
          /**
           * Following properties are added to add scrolling support for modal content:
           * There is a known issue in Chakra UI that prevents scrolling on modal if another Overlay element is present.
           * In our case, we may open a modal within a drawer, which prevents scrolling on modal.
           *
           * https://github.com/chakra-ui/chakra-ui/issues/7588
           * https://github.com/chakra-ui/chakra-ui/issues/8269
           */
          onWheel={(e) => {
            e.stopPropagation();
          }}
          onTouchMove={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onScroll={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <ModalCloseButton />
          {renderHeader()}
          {content}
        </ModalContent>
      </Modal>
    </ModuleContext.Provider>
  );
};
