import { DrawerActionsProps } from '../drawer/drawer-actions';
import { BaseAction, IconActionProps, MenuActionItem } from './types';

function menuActionItem<TAction extends MenuActionItem>(action: TAction): TAction {
  return action;
}

function baseAction<TAction extends BaseAction>(action: TAction): TAction {
  return action;
}

function iconAction<TAction extends IconActionProps>(action: TAction): TAction {
  return action;
}

function drawerToolbarActions<TAction extends DrawerActionsProps>(toolbar: TAction): TAction {
  return toolbar;
}

export const actionHelper = {
  drawerToolbarActions,
  iconAction,
  menuActionItem,
  /**
   * @primaryAction and @secondaryAction accept the same props but their implementation inside Drawer Actions component is different
   */
  primaryAction: baseAction,
  secondaryAction: baseAction,
};
