/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AddFindingResolutionMutationVariables = Types.Exact<{
  input: Types.Finding_Resolutions_Insert_Input;
}>;

export type AddFindingResolutionMutation = {
  __typename?: 'mutation_root';
  insert_finding_resolutions_one?: { __typename?: 'finding_resolutions'; id: string };
};

export type RemoveFindingResolutionMutationVariables = Types.Exact<{
  findingResolutionId: Types.Scalars['uuid']['input'];
}>;

export type RemoveFindingResolutionMutation = {
  __typename?: 'mutation_root';
  delete_finding_resolutions_by_pk?: { __typename?: 'finding_resolutions'; id: string };
};

export type UpdateFindingResolutionCommentMutationVariables = Types.Exact<{
  where: Types.Finding_Resolutions_Bool_Exp;
  input: Types.Finding_Resolutions_Set_Input;
}>;

export type UpdateFindingResolutionCommentMutation = {
  __typename?: 'mutation_root';
  update_finding_resolutions?: {
    __typename?: 'finding_resolutions_mutation_response';
    affected_rows: number;
  };
};

export type DeleteManualFindingMutationVariables = Types.Exact<{
  findingId: Types.Scalars['uuid']['input'];
}>;

export type DeleteManualFindingMutation = {
  __typename?: 'mutation_root';
  delete_manual_findings_by_pk?: { __typename?: 'manual_findings'; id: string };
};

export const AddFindingResolutionDocument = `
    mutation AddFindingResolution($input: finding_resolutions_insert_input!) {
  insert_finding_resolutions_one(object: $input) {
    id
  }
}
    ` as string &
  TypedDocumentNode<AddFindingResolutionMutation, AddFindingResolutionMutationVariables>;
export const RemoveFindingResolutionDocument = `
    mutation RemoveFindingResolution($findingResolutionId: uuid!) {
  delete_finding_resolutions_by_pk(id: $findingResolutionId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<RemoveFindingResolutionMutation, RemoveFindingResolutionMutationVariables>;
export const UpdateFindingResolutionCommentDocument = `
    mutation UpdateFindingResolutionComment($where: finding_resolutions_bool_exp!, $input: finding_resolutions_set_input!) {
  update_finding_resolutions(where: $where, _set: $input) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateFindingResolutionCommentMutation,
    UpdateFindingResolutionCommentMutationVariables
  >;
export const DeleteManualFindingDocument = `
    mutation DeleteManualFinding($findingId: uuid!) {
  delete_manual_findings_by_pk(id: $findingId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeleteManualFindingMutation, DeleteManualFindingMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddFindingResolution: build.mutation<
      AddFindingResolutionMutation,
      AddFindingResolutionMutationVariables
    >({
      query: (variables) => ({ document: AddFindingResolutionDocument, variables }),
    }),
    RemoveFindingResolution: build.mutation<
      RemoveFindingResolutionMutation,
      RemoveFindingResolutionMutationVariables
    >({
      query: (variables) => ({ document: RemoveFindingResolutionDocument, variables }),
    }),
    UpdateFindingResolutionComment: build.mutation<
      UpdateFindingResolutionCommentMutation,
      UpdateFindingResolutionCommentMutationVariables
    >({
      query: (variables) => ({ document: UpdateFindingResolutionCommentDocument, variables }),
    }),
    DeleteManualFinding: build.mutation<
      DeleteManualFindingMutation,
      DeleteManualFindingMutationVariables
    >({
      query: (variables) => ({ document: DeleteManualFindingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useAddFindingResolutionMutation,
  useRemoveFindingResolutionMutation,
  useUpdateFindingResolutionCommentMutation,
  useDeleteManualFindingMutation,
} = injectedRtkApi;
