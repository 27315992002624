import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ArrowUturnLeftIcon,
  Cog6ToothIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import { Column, Table, VisibilityState } from '@tanstack/react-table';
import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DragHandleIcon } from '../../../icons/drag-handle';
import { OverflowContainer } from '../../overflow-tooltip/overflow-tooltip';

const SortableColumn: FC<PropsWithChildren<{ id: string }>> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const style = {
    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,
    transition,
  };

  return (
    <FormControl ref={setNodeRef} style={style}>
      <Flex align="center" gap={2}>
        <Flex align="center" {...attributes} {...listeners}>
          <DragHandleIcon
            boxSize={3}
            color="gray.500"
            minH="0.5rem"
            minW="0.5rem"
            cursor={isDragging ? 'grabbing' : 'grab'}
          />
        </Flex>
        {children}
      </Flex>
    </FormControl>
  );
};

const SortableHeader: FC<PropsWithChildren<{ id: string }>> = ({ id, children }) => {
  const { setNodeRef, transform, transition } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      {children}
    </Box>
  );
};

const useGetTableSettingsStateFromTable = <TData,>(table: Table<TData>) => {
  const allColumns = table.getAllColumns();
  const columnOrder = table.getState().columnOrder;
  const columnVisiblity = table.getState().columnVisibility;
  const columns = useMemo(
    () => allColumns.filter((column) => column.id !== 'actions'),
    // We want column visiblity and order changes to trigger re-renders,
    // since we pull them from table APIs inside the following memos
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allColumns, columnOrder, columnVisiblity],
  );
  const visibleColumns = useMemo(
    () => columns.filter((column) => column.getIsVisible()),
    [columns],
  );
  const hiddenColumns = useMemo(
    () => columns.filter((column) => !column.getIsVisible()),
    [columns],
  );
  const columnVisibility = useMemo(
    () => ({
      visible: columns.filter((column) => column.getIsVisible()),
      hidden: columns.filter((column) => !column.getIsVisible()),
    }),
    [columns],
  );
  return useMemo(
    () => ({
      columns,
      visibleColumns,
      hiddenColumns,
      columnVisibility,
      columnOrder,
    }),
    [columnOrder, columnVisibility, columns, hiddenColumns, visibleColumns],
  );
};

const isEqual = (a: unknown, b: unknown): boolean => {
  if (a === b) return true;

  if (typeof a !== typeof b) return false;

  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    return a.every((item, index) => isEqual(item, b[index]));
  }

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if (aKeys.length !== bKeys.length) return false;

    const allMatch = aKeys.every((key) =>
      isEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key]),
    );
    return allMatch;
  }

  return false;
};

const useTableSettingsState = <TData,>(table: Table<TData>) => {
  const stateFromTable = useGetTableSettingsStateFromTable(table);
  const [prevStateFromTable, setPrevStateFromTable] = useState(stateFromTable);
  useEffect(() => {
    if (!isEqual(stateFromTable, prevStateFromTable)) {
      setPrevStateFromTable(stateFromTable);
    }
  }, [prevStateFromTable, stateFromTable]);

  const [columns, setColumns] = useState(stateFromTable.columns);
  const [columnVisibility, setColumnVisibility] = useState(stateFromTable.columnVisibility);
  const [columnOrder, setColumnOrder] = useState(
    stateFromTable.columnOrder.toSorted(sortByVisible(columnVisibility.hidden)),
  );
  useEffect(() => {
    if (!isEqual(prevStateFromTable.columns, stateFromTable.columns)) {
      setColumns(stateFromTable.columns);
    }
  }, [prevStateFromTable.columns, stateFromTable.columns]);
  useEffect(() => {
    if (!isEqual(prevStateFromTable.columnVisibility, stateFromTable.columnVisibility)) {
      setColumnVisibility(stateFromTable.columnVisibility);
    }
  }, [prevStateFromTable.columnVisibility, stateFromTable.columnVisibility]);
  useEffect(() => {
    if (
      !isEqual(prevStateFromTable.columnOrder, stateFromTable.columnOrder) ||
      !isEqual(prevStateFromTable.columnVisibility, stateFromTable.columnVisibility)
    ) {
      setColumnOrder(
        stateFromTable.columnOrder.toSorted(sortByVisible(stateFromTable.columnVisibility.hidden)),
      );
    }
  }, [
    columnVisibility.hidden,
    prevStateFromTable.columnOrder,
    prevStateFromTable.columnVisibility,
    stateFromTable.columnOrder,
    stateFromTable.columnVisibility,
  ]);

  const visibleColumnOrder = useMemo(
    () => columnOrder.filter((id) => columnVisibility.visible.some((column) => column.id === id)),
    [columnOrder, columnVisibility.visible],
  );
  const hiddenColumnOrder = useMemo(
    () => columnOrder.filter((id) => columnVisibility.hidden.some((column) => column.id === id)),
    [columnOrder, columnVisibility.hidden],
  );

  const columnOrderWithHeader = useMemo(
    () => [...visibleColumnOrder, HIDDEN_COLUMNS_HEADER_ID, ...hiddenColumnOrder],
    [hiddenColumnOrder, visibleColumnOrder],
  );

  return {
    stateFromTable,
    columns,
    setColumns,
    columnOrder,
    columnOrderWithHeader,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
  };
};

const HIDDEN_COLUMNS_HEADER_ID = 'HIDDEN_COLUMNS_HEADER';

export function TableSettings<TData>({
  table,
  hasColumnOrderChanged,
  hasColumnVisibilityChanged,
  hasColumnSizingChanged,
}: {
  table: Table<TData>;
  hasColumnOrderChanged: boolean;
  hasColumnVisibilityChanged: boolean;
  hasColumnSizingChanged: boolean;
}) {
  const { t } = useTranslation('ui');
  const {
    stateFromTable,
    columns,
    columnOrder,
    columnOrderWithHeader,
    setColumnOrder,
    columnVisibility,
    setColumnVisibility,
  } = useTableSettingsState(table);

  const popoverRef = useRef<HTMLDivElement>(null);

  const hideColumn = (column: Column<TData>) => {
    column.toggleVisibility(false);
    const newHidden = [...columnVisibility.hidden, column];
    const newColumnOrder = columnOrder.toSorted(sortByVisible(newHidden));
    table.setColumnOrder(newColumnOrder);
    popoverRef.current?.focus();
  };
  const showColumn = (column: Column<TData>) => {
    column.toggleVisibility(true);
    const newHidden = columnVisibility.hidden.filter((c) => c.id !== column.id);
    const newColumnOrder = columnOrder.toSorted(sortByVisible(newHidden));
    table.setColumnOrder(newColumnOrder);
    popoverRef.current?.focus();
  };
  const hideAll = () => {
    table.setColumnVisibility((state) =>
      columns.reduce(
        (prev, column) =>
          ({
            ...prev,
            [column.id]: column.getCanHide() ? false : prev[column.id],
          }) as VisibilityState,
        state,
      ),
    );
    popoverRef.current?.focus();
  };
  const showAll = () => {
    table.setColumnVisibility((state) =>
      columns.reduce(
        (prev, column) =>
          ({
            ...prev,
            [column.id]: true,
          }) as VisibilityState,
        state,
      ),
    );
    popoverRef.current?.focus();
  };

  const dndSensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor),
  );

  const [search, setSearch] = useState('');

  const showResetConfiguration = hasColumnOrderChanged || hasColumnVisibilityChanged;

  const label = (name: string) => (
    <FormLabel m={0} flexGrow={1} fontSize="sm" fontWeight="normal" minWidth={0}>
      <OverflowContainer>
        <OverflowContainer.Tooltip label={name}>
          <Text isTruncated>{name}</Text>
        </OverflowContainer.Tooltip>
      </OverflowContainer>
    </FormLabel>
  );

  return (
    <Box>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <Button
            aria-label={t('table.settings.buttonLabel')}
            variant="outline"
            w={12}
            color="gray.500"
            icon={<Cog6ToothIcon width="16px" />}
            as={IconButton}
          />
        </PopoverTrigger>
        <PopoverContent ref={popoverRef}>
          <PopoverCloseButton top={4} />
          <PopoverHeader borderBottom="none" pt={4} px={4}>
            <Flex direction="column" gap={4}>
              <Text fontSize="sm" fontWeight="500">
                {t('table.settings.tableConfigurationHeading')}
              </Text>
              <Input
                placeholder={t('table.settings.searchPlaceholder')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="sm"
                type="search"
              />
            </Flex>
          </PopoverHeader>
          <PopoverBody maxHeight="50vh" overflowY="auto" px={4}>
            <Flex direction="column" gap={4}>
              <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                sensors={dndSensors}
                autoScroll
              >
                <SortableContext
                  items={columnOrderWithHeader}
                  strategy={verticalListSortingStrategy}
                >
                  {stateFromTable.columnVisibility.visible.length > 0 && (
                    <>
                      <Flex justify="space-between" mb={1}>
                        <Text fontSize="xs" fontWeight="medium" color="gray.500">
                          {t('table.settings.shownInTable')}
                        </Text>
                        <Button
                          size="xs"
                          colorScheme="blue"
                          variant="link"
                          fontWeight="medium"
                          onClick={hideAll}
                        >
                          {t('table.settings.hideAll')}
                        </Button>
                      </Flex>
                      {columnVisibility.visible
                        .filter(filterColumns(search))
                        .sort(sortByIdList(columnOrder))
                        .map((column) => (
                          <SortableColumn key={column.id} id={column.id}>
                            {label(column.columnDef.meta?.name || '')}
                            <IconButton
                              variant="link"
                              size="xs"
                              icon={<EyeIcon height={20} width={20} />}
                              onClick={() => {
                                hideColumn(column);
                              }}
                              isDisabled={!column.getCanHide()}
                              aria-label={t('table.settings.hideColumn', {
                                columnName: column.columnDef.meta?.name || '',
                              })}
                            />
                          </SortableColumn>
                        ))}
                    </>
                  )}

                  {stateFromTable.columnVisibility.hidden.length > 0 && (
                    <>
                      <SortableHeader id={HIDDEN_COLUMNS_HEADER_ID}>
                        <Flex justify="space-between" mb={1} mt={2}>
                          <Text fontSize="xs" fontWeight="medium" color="gray.500">
                            {t('table.settings.hiddenInTable')}
                          </Text>
                          <Button
                            size="xs"
                            colorScheme="blue"
                            variant="link"
                            fontWeight="medium"
                            onClick={showAll}
                          >
                            {t('table.settings.showAll')}
                          </Button>
                        </Flex>
                      </SortableHeader>
                      {columnVisibility.hidden
                        .filter(filterColumns(search))
                        .sort(sortByIdList(columnOrder))
                        .map((column) => (
                          <SortableColumn key={column.id} id={column.id}>
                            {label(column.columnDef.meta?.name || '')}
                            <IconButton
                              variant="link"
                              size="xs"
                              icon={<EyeSlashIcon height={20} width={20} />}
                              onClick={() => {
                                showColumn(column);
                              }}
                              aria-label={t('table.settings.showColumn', {
                                columnName: column.columnDef.meta?.name || '',
                              })}
                            />
                          </SortableColumn>
                        ))}
                    </>
                  )}
                </SortableContext>
              </DndContext>
            </Flex>
          </PopoverBody>
          {(showResetConfiguration || hasColumnSizingChanged) && (
            <PopoverFooter px={4}>
              {showResetConfiguration && (
                <Flex align="center" gap={2}>
                  <Button
                    variant="unstyled"
                    leftIcon={<ArrowUturnLeftIcon />}
                    size="sm"
                    fontWeight="normal"
                    color="gray.500"
                    onClick={() => {
                      table.resetColumnVisibility();
                      table.resetColumnOrder();
                      popoverRef.current?.focus();
                    }}
                  >
                    {t('table.settings.resetTableConfiguration')}
                  </Button>
                </Flex>
              )}
              {hasColumnSizingChanged && (
                <Flex align="center" gap={2}>
                  <Button
                    variant="unstyled"
                    leftIcon={<ArrowUturnLeftIcon />}
                    size="sm"
                    fontWeight="normal"
                    color="gray.500"
                    onClick={() => {
                      table.resetColumnSizing();
                      popoverRef.current?.focus();
                    }}
                  >
                    {t('table.settings.resetColumnWidth')}
                  </Button>
                </Flex>
              )}
            </PopoverFooter>
          )}
        </PopoverContent>
      </Popover>
    </Box>
  );

  function handleDragOver(event: DragOverEvent) {
    setColumnVisibility((state) => {
      const currentlyVisible = state.visible.some(({ id }) => id === event.active.id);
      const targetVisible =
        event.over?.id === HIDDEN_COLUMNS_HEADER_ID
          ? !currentlyVisible
          : state.visible.some(({ id }) => id === event.over?.id);
      const column = columns.find(({ id }) => id === event.active.id);
      if (
        column &&
        currentlyVisible !== targetVisible &&
        (targetVisible === true || column.getCanHide())
      ) {
        if (targetVisible) {
          return {
            visible: [...state.visible, column],
            hidden: state.hidden.filter((c) => c.id !== column.id),
          };
        } else {
          return {
            visible: state.visible.filter((c) => c.id !== column.id),
            hidden: [...state.hidden, column],
          };
        }
      }
      return state;
    });
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      setColumnOrder((items) => {
        const oldIndex = items.indexOf(active.id.toString());
        const newIndex = items.indexOf(over.id.toString());
        const newColumnOrder = arrayMove(items, oldIndex, newIndex);
        const column = columns.find(({ id }) => id === active.id);
        const oldIsVisible = column?.getIsVisible();
        const newIsVisible =
          over.id === HIDDEN_COLUMNS_HEADER_ID
            ? !oldIsVisible
            : newIndex < columnVisibility.visible.length;
        if (oldIsVisible !== newIsVisible) {
          if (newIsVisible === false && !column?.getCanHide()) {
            return items;
          }
          table.setColumnVisibility((state) => ({
            ...state,
            [active.id]: newIsVisible,
          }));
        }
        table.setColumnOrder(newColumnOrder);
        return newColumnOrder;
      });
    } else if (over?.id && active.id === over.id) {
      // This happens when an item is moved over the threshold.
      // Local state visibility should be correct, so just propagate
      // the change to the table.
      table.setColumnVisibility({
        ...columnVisibility.visible.reduce(
          (prev, column) => ({
            ...prev,
            [column.id]: true,
          }),
          {},
        ),
        ...columnVisibility.hidden.reduce(
          (prev, column) => ({
            ...prev,
            [column.id]: false,
          }),
          {},
        ),
      });
    }
    popoverRef.current?.focus();
  }
}

function sortByIdList<T extends { id: string }>(list: string[]) {
  return (a: T, b: T) => {
    return findIdInArray(list)(a) - findIdInArray(list)(b);
  };
}

function findIdInArray(array: string[]) {
  return ({ id }: { id: string }) => {
    const foundIndex = array.indexOf(id);

    // If not found, this will be -1.  But that would sort all
    // unfound objects at the beginning of the array.
    // To place these objects at the end of the array, it needs to
    // return a number higher than the rest.  So return infinity.

    return foundIndex === -1 ? Infinity : foundIndex;
  };
}

function sortByVisible(hidden: { id: string }[]) {
  return (a: string, b: string) => {
    const isVisibleA = !hidden.some((column) => column.id === a);
    const isVisibleB = !hidden.some((column) => column.id === b);

    // If both are visible or both are hidden, maintain original order
    if (isVisibleA === isVisibleB) return 0;

    // Prioritize visible columns
    return isVisibleA ? -1 : 1;
  };
}

function filterColumns<TData>(search: string) {
  return (column: Column<TData>) =>
    column.columnDef.meta?.name?.toLowerCase().includes(search.toLowerCase());
}
