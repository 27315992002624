import { ComboboxList, TabPanel } from '@ariakit/react';
import { Box, BoxProps, Text, VStack } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteScrollY } from '../hooks';
import { GlobalSearchResult, useGlobalSearchContext } from './global-search-provider';

export function GlobalSearchResultsContainer(props: PropsWithChildren) {
  const globalSearch = useGlobalSearchContext();

  return (
    <TabPanel
      tabId={globalSearch.tabId}
      style={{ overflow: 'auto' }}
      {...useInfiniteScrollY(globalSearch.onLoadMore)}
    >
      <ComboboxList>{props.children}</ComboboxList>
    </TabPanel>
  );
}

export interface GlobalSearchResultProps<TResult extends GlobalSearchResult> {
  i: number;
  result: TResult;
}

export type GlobalSearchResultComponent<TResult extends GlobalSearchResult> = (
  props: GlobalSearchResultProps<TResult>,
) => ReactNode;

export interface GlobalSearchResultsProps<TResult extends GlobalSearchResult> extends BoxProps {
  renderResult: GlobalSearchResultComponent<TResult>;
  renderHeader?: ReactNode;
}

export function GlobalSearchResults<TResult extends GlobalSearchResult>({
  renderHeader,
  renderResult,
  ...boxProps
}: GlobalSearchResultsProps<TResult>) {
  const { t } = useTranslation('ui');
  const globalSearch = useGlobalSearchContext<TResult>();
  const ResultComponent = renderResult;

  if (!globalSearch.hasResults) {
    return;
  }

  return (
    <Box {...boxProps}>
      {renderHeader ?? (
        <Text size="sm" fontWeight="medium" color="gray.400" pb="3">
          {t('globalSearch.results')}
        </Text>
      )}
      <VStack spacing="1" align="stretch">
        {globalSearch.resultsTab?.map((result, i) => (
          <ResultComponent key={result.id} i={i} result={result} />
        ))}
      </VStack>
    </Box>
  );
}

export function GlobalSearchNoResults({ children }: { children?: ReactNode }) {
  const { t } = useTranslation('ui');
  const globalSearch = useGlobalSearchContext();

  if (!globalSearch.isNoResults) {
    return;
  }

  return (
    children ?? (
      <VStack py="14" px="4" spacing="1">
        <Text fontWeight="semibold">{t('globalSearch.noResults.heading')}</Text>
        <Text fontSize="sm">{t('globalSearch.noResults.description')}</Text>
      </VStack>
    )
  );
}
