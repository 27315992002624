import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { QuestionnnaireForm, QuestionnnaireFormMode } from '@main/questionnaires-form';
import { formatDate, severityColorScheme } from '@main/shared/utils';
import { FindingCard } from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CreateFindingRiskButton } from '../../findings/create-risk';
import { CreateFindingTaskButton } from '../../findings/create-task';
import { FindingActionModal, ResolutionActionType } from '../../findings/finding-action-modal';
import { FindingResolutionReason } from '../../findings/finding-resolution-reason';
import { FindingMenuActions } from '../../findings/menu-actions';
import {
  useFindingReinstateAction,
  useFindingResolutionActions,
} from '../../findings/use-finding-actions';
import { TabFooter } from '../../shared/tab-footer';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getFindingsMappedWithQuestionAndAnswer } from '../slice';
import { FindingQuestionnnaireFieldWrapper } from './finding-questionnaire-field-wrapper';
import { useGetVendorQuestionnairesByIdQuery } from './vendor-questionnaires.generated';

export const VendorFindingsTab = ({ vendorQuestionnaireId }: { vendorQuestionnaireId: string }) => {
  const { t } = useTranslation();
  useGetVendorQuestionnairesByIdQuery({
    vqId: vendorQuestionnaireId,
  });
  const questionnaireFindings = useAppSelector((state) =>
    getFindingsMappedWithQuestionAndAnswer(state, vendorQuestionnaireId),
  );

  return (
    <Box>
      <Flex direction="column" gap={3} paddingBottom="40px">
        <Text fontSize="xs" color="gray.400">
          {t('vendors.questionnaires.aiReview.hasReviewsTitle', {
            date: formatDate(questionnaireFindings[0]?.created_at),
          })}
        </Text>
        <Text>{t('vendors.questionnaires.aiReview.hasReviewsSubTitle')}</Text>
        <Stack>
          {questionnaireFindings.map((vendorFinding, idx) => (
            <VendorFindingCard
              key={vendorFinding.id}
              idx={idx}
              vendorFinding={vendorFinding}
              vendorQuestionnaireId={vendorQuestionnaireId}
            />
          ))}
        </Stack>
      </Flex>
      <TabFooter>
        <Text fontSize="xs" color="gray.400">
          {t('vendors.questionnaires.aiReview.footer')}
        </Text>
      </TabFooter>
    </Box>
  );
};

type VendorFinding = ReturnType<typeof getFindingsMappedWithQuestionAndAnswer>[number];

const VendorFindingCard = ({
  vendorQuestionnaireId,
  vendorFinding,
  idx,
}: {
  vendorQuestionnaireId: string;
  vendorFinding: VendorFinding;
  idx: number;
}) => {
  const { t } = useTranslation();
  const contentColor = useColorModeValue('gray.600', 'gray.300');
  const [actionType, setActionType] = useState<ResolutionActionType | null>(null);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const canLinkVendorToRisk = userRole.permissionMap?.link_risks_vendors;

  const [aiCreateEntity] = useAiCreateEntityMutation();
  const resolutionActions = useFindingResolutionActions();
  const reinstateAction = useFindingReinstateAction({
    resolutionId: vendorFinding.resolution?.id,
  });

  const questionnaireFindings = useAppSelector((state) =>
    getFindingsMappedWithQuestionAndAnswer(state, vendorQuestionnaireId),
  );
  const isResolved = !!vendorFinding.resolution;
  const riskId = vendorFinding.risk_id;
  const taskId = vendorFinding.task_id;
  const resolutionType = vendorFinding.resolution?.type;

  return (
    <FindingCard key={vendorFinding.id} resolutionType={resolutionType}>
      <FindingCard.Header
        heading={t('findings.card.findingsNo', {
          number: idx + 1,
          total: questionnaireFindings.length,
        })}
      >
        <Tag size="sm" colorScheme={severityColorScheme(vendorFinding.severity)}>
          {t(`findings.type.${vendorFinding.type}`)}
        </Tag>
      </FindingCard.Header>

      <FindingCard.Actions>
        {canLinkVendorToRisk && (
          <CreateFindingRiskButton
            riskId={riskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'risk-vendor-finding', findingId: vendorFinding.id },
              })
            }
          />
        )}
        {canUpdateVendor && (
          <CreateFindingTaskButton
            taskId={taskId}
            onCreate={() =>
              aiCreateEntity({
                input: { entity: 'task-vendor-finding', findingId: vendorFinding.id },
              })
            }
          />
        )}

        {canUpdateVendor && (
          <FindingMenuActions
            actions={[
              ...Object.values(resolutionActions).map((action) => ({
                icon: action.icon,
                label: action.label,
                onClick: () => setActionType(action.type),
                isHidden: action.type === 'Edit' ? !isResolved : isResolved,
              })),
              reinstateAction,
            ]}
          />
        )}
      </FindingCard.Actions>

      {actionType && (
        <FindingActionModal
          actionType={actionType}
          onClose={() => setActionType(null)}
          onSubmit={(data) =>
            resolutionActions[actionType].handler({
              comment: data.comment,
              vendor_finding_id: vendorFinding.id,
            })
          }
          defaultValues={{
            comment: vendorFinding.resolution?.comment ?? '',
          }}
        />
      )}

      <FindingCard.Body>
        <Text fontSize="sm" fontWeight="semibold">
          {vendorFinding.questionFromSnapshot?.aiMessage}
        </Text>
        <Text fontSize="sm" color={contentColor}>
          {vendorFinding.vendor_questionnaire_ai_review_assessment?.assessment}
        </Text>
        <Accordion allowToggle>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton padding="0" justifyContent="space-between">
                  <Flex direction="column" alignItems="flex-start">
                    <Text fontSize="xs" fontWeight="semibold">
                      {t('vendors.questionnaires.aiReview.question', {
                        questionNumber: vendorFinding.questionFromSnapshot?.questionIdx,
                      })}
                    </Text>
                    <Text fontSize="sm" color={contentColor} textAlign="start">
                      {vendorFinding.questionFromSnapshot?.label}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel paddingLeft="0" paddingTop="2">
                  {isExpanded && (
                    <QuestionnnaireForm
                      fieldWrapper={FindingQuestionnnaireFieldWrapper}
                      mode={QuestionnnaireFormMode.View}
                      {...vendorFinding.questionnaireFormProps}
                      shouldSkipFilteringFields={true}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        <FindingResolutionReason
          resolutionType={resolutionType}
          comment={vendorFinding.resolution?.comment}
          date={vendorFinding.resolution?.created_at}
          user={vendorFinding.resolution?.created_by}
        />
      </FindingCard.Body>
    </FindingCard>
  );
};
