import { datadogLogs } from '@datadog/browser-logs';
import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { isNonNullable, toError, useStableCallback } from '@main/shared/utils';
import { actionHelper, errorToast, successToast, useAlertDialog, useDrawer } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteVendor } from './delete-vendor';
import { getMappedVendor } from './slice';
import { useToggleTerminateVendorMutation } from './update-vendor.generated';
import { useRefetchVendorQueries } from './use-udpate-vendor-handler';

export const useVendorDrawerActions = (vendorId: string) => {
  const deleteVendor = useVendorDeleteAction(vendorId);
  const vendor = useAppSelector((state) => getMappedVendor(state, vendorId));
  const toggleTerminateVendor = useLazyToggleVendorTerminateAction()({
    vendorId,
    status: vendor?.assessment_status,
  });

  return actionHelper.drawerToolbarActions({
    menuActions: [toggleTerminateVendor, deleteVendor].filter(isNonNullable),
  });
};

type TerminateAction = { vendorId: string; status: VendorAssessmentStatus | undefined };
export function useLazyToggleVendorTerminateAction() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;

  const [toggleTerminatedState] = useToggleTerminateVendorMutation();
  const refetchVendors = useRefetchVendorQueries();

  const handler = useStableCallback(async ({ vendorId, status: prevState }: TerminateAction) => {
    try {
      await toggleTerminatedState({ vendorId }).unwrap();

      successToast(
        prevState !== VendorAssessmentStatus.TERMINATED
          ? t('vendors.alert.retire.successToast')
          : t('vendors.alert.reinstate.successToast'),
      );
      await refetchVendors();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.vendor') }));
      datadogLogs.logger.error(
        'Updating vendor terminated state failed',
        { vendorId },
        toError(error),
      );
    }
  });

  return useCallback(
    ({ vendorId, status }: TerminateAction) => {
      if (!canUpdateVendor) {
        return;
      }

      if (status === VendorAssessmentStatus.TERMINATED) {
        return actionHelper.menuActionItem({
          icon: CheckIcon,
          label: t('buttons.reinstate'),
          onClick: () => {
            openDialog({
              dialogHeader: t('vendors.alert.reinstate.header'),
              dialogContent: t('vendors.alert.reinstate.content'),
              confirmAction: {
                colorScheme: 'blue',
                children: t('buttons.reinstate'),
                onClick: async () => {
                  await handler({ vendorId, status });
                },
              },
            });
          },
        });
      }

      return actionHelper.menuActionItem({
        icon: XMarkIcon,
        label: t('buttons.retire'),
        onClick: () => {
          openDialog({
            dialogHeader: t('vendors.alert.retire.header'),
            dialogContent: t('vendors.alert.retire.content'),
            confirmAction: {
              colorScheme: 'blue',
              children: t('buttons.retire'),
              onClick: async () => {
                await handler({ vendorId, status });
              },
            },
          });
        },
      });
    },
    [canUpdateVendor, handler, openDialog, t],
  );
}

function useVendorDeleteAction(vendorId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const deleteVendor = useDeleteVendor();

  return useMemo(() => {
    if (!deleteVendor) {
      return null;
    }

    return actionHelper.menuActionItem({
      icon: TrashIcon,
      label: t('buttons.delete'),
      onClick: () => {
        deleteVendor(vendorId, () => drawer.close());
      },
    });
  }, [deleteVendor, drawer, t, vendorId]);
}
