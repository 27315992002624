import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useGetQuestionnaireSubscription } from '@main/graphql/subscriptions/GetQuestionnaireSubscription';
import { isNonNullable } from '@main/shared/utils';
import { actionHelper, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteQuestionnaire } from './delete-questionnaire';
import { useDuplicateQuestionnaire } from './duplicate-questionnaire';

export const useQuestionnaireDrawerActions = (questionnaireId: string) => {
  const duplicateQuestionnaireAction = useDuplicateQuestionnaireAction(questionnaireId);
  const deleteQuestionnaire = useQuestionnaireDeleteAction(questionnaireId);

  return actionHelper.drawerToolbarActions({
    menuActions: [duplicateQuestionnaireAction, deleteQuestionnaire].filter(isNonNullable),
  });
};

function useDuplicateQuestionnaireAction(questionnaireId: string) {
  const { t } = useTranslation();

  const duplicateQuestionnaire = useDuplicateQuestionnaire();
  if (!duplicateQuestionnaire) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: DocumentDuplicateIcon,
    label: t('buttons.duplicate'),
    onClick: async () => {
      duplicateQuestionnaire(questionnaireId);
    },
  });
}

function useQuestionnaireDeleteAction(questionnaireId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const deleteQuestionnaire = useDeleteQuestionnaire();

  const questionnaireSubscription = useGetQuestionnaireSubscription({
    id: questionnaireId,
  });
  const questionnaire = questionnaireSubscription.data?.questionnaires_by_pk;

  if (!questionnaire || !deleteQuestionnaire) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: TrashIcon,
    label: t('buttons.delete'),
    onClick: () => {
      deleteQuestionnaire(questionnaire, () => drawer.close());
    },
  });
}
