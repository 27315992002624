import { NAMESPACE } from '@main/shared/utils';

import { getCurrentUserOrgs } from '../../../features/user/slice';
import { localStorageMigrationRegistry } from '../local-storage';

localStorageMigrationRegistry.addMigration({
  version: 1,
  async apply(host, { listenerApi }) {
    const orgIds = getCurrentUserOrgs(listenerApi.getState()).map((org) => org.id);

    for (const key of Object.keys(host.storage)) {
      if (!isOldTableKey(key)) {
        continue;
      }

      const value = host.storage.getItem(key) ?? '{}';
      const keyParts = parseTableKey(key);

      if (!keyParts) {
        console.warn(`Skipping migration for ${key} - could not parse the key`);
        continue;
      }

      for (const orgId of orgIds) {
        host.storage.setItem(buildTableKey(keyParts, orgId), value);
      }

      host.storage.removeItem(key);
    }
  },
});

function isOldTableKey(key: string) {
  return key.endsWith('table:column-sizing') || key.endsWith('table:column-visibility');
}

function parseTableKey(key: string): [string, string] | undefined {
  let idx = key.indexOf(':column-sizing');
  if (idx === -1) {
    idx = key.indexOf(':table:column-visibility');
  }
  if (idx === -1) {
    idx = key.indexOf('-table:column-visibility');
  }
  if (idx === -1) {
    idx = key.indexOf(':column-visibility');
    return [key.slice(0, idx), 'table:column-visibility'];
  }
  if (idx === -1) {
    return;
  }

  return [key.slice(0, idx), key.slice(idx + 1)];
}

function buildTableKey([prefixKey, postfixKey]: [string, string], orgId: string) {
  return `${NAMESPACE}${prefixKey}:org-id-${orgId}:${postfixKey}-v2`;
}
