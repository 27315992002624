import {
  As,
  ComponentWithAs,
  Icon,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { forwardRef, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { IconAction } from './icon-action';
import type { MenuActionItem } from './types';

interface TriggerButtonProps extends MenuButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const MenuActions = ({
  actions,
  renderTrigger,
}: {
  actions: MenuActionItem[] | undefined;
  renderTrigger?: ComponentWithAs<As, TriggerButtonProps>;
}) => {
  const { t } = useTranslation('ui');

  if (!actions?.length) {
    return null;
  }

  return (
    <Menu computePositionOnMount strategy="fixed">
      {renderTrigger ? (
        <MenuButton
          as={forwardRef<HTMLButtonElement, TriggerButtonProps>((props, ref) =>
            renderTrigger({ ...props, ref }),
          )}
        />
      ) : (
        <MenuButton
          as={IconAction}
          tooltip={t('drawer.actions.more')}
          tooltipPlacement="bottom"
          icon={EllipsisHorizontalIcon}
        />
      )}

      <MenuList>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            fontSize="sm"
            lineHeight={5}
            iconSpacing={2}
            icon={<Icon boxSize={4} as={action.icon} />}
            onClick={action.onClick}
          >
            {action.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
