import { registerNotificationHandler } from '../notifications/registry';
import {
  EvidenceAccessGrantedNotificationHandler,
  EvidenceAssignedNotificationHandler,
  MentionedInEvidenceCommentNotificationHandler,
} from './notifications';

registerNotificationHandler(EvidenceAssignedNotificationHandler);
registerNotificationHandler(EvidenceAccessGrantedNotificationHandler);
registerNotificationHandler(MentionedInEvidenceCommentNotificationHandler);
