import { FormControl, FormLabel, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  QuestionnaireFormFieldConfig,
  QuestionnnaireFieldWrapperProps,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormMode,
} from './form-types';

export const QuestionnaireFieldCommentPrefix = 'comment:';

export interface QuestionnaireFieldCommentProps extends QuestionnnaireFieldWrapperProps {
  config: QuestionnaireFormFieldConfig;
  answer: QuestionnnaireFormFieldAnswer;
}

export function QuestionnaireFieldComment(props: QuestionnaireFieldCommentProps) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const textColor = useColorModeValue('gray.600', 'gray.300');

  const isAnswerMode = props.form.mode === QuestionnnaireFormMode.Answer;
  const isCompleted =
    props.answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed;
  const isReadOnly = isCompleted || !isAnswerMode;

  return (
    <FormControl>
      <FormLabel>{t('questionnaires.form.commentLabel')}</FormLabel>
      <Controller
        name={`${QuestionnaireFieldCommentPrefix}${props.config.name}`}
        control={control}
        defaultValue={props.answer.comment ?? ''}
        render={({ field }) =>
          isReadOnly ? (
            <Text fontSize="sm" fontWeight="medium" color={textColor}>
              {field.value}
            </Text>
          ) : (
            <Textarea {...field} placeholder={t('questionnaires.form.commentPlaceholder')} />
          )
        }
      />
    </FormControl>
  );
}
