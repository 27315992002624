import { Button, Icon, Menu, MenuButton, MenuItem, MenuList, Td } from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../../../user/slice';
import { getRiskLevelOptions, MATRIX_CELL_COLORS, MatrixItem, RISK_LEVEL_COLORS } from './utils';

export const MatrixCell = ({
  cellValue,
  onChange,
  isUpdating,
}: {
  cellValue?: MatrixItem;
  onChange: (value: MatrixItem) => void;
  isUpdating: boolean;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canManageRiskMatrix = userRole.permissionMap?.manage_risk_classification;

  if (!cellValue) {
    return null;
  }

  return (
    <Td textAlign="center" py="3px" px={1}>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={ChevronDownIcon} />}
          fontSize="xs"
          w="full"
          h="44px"
          backgroundColor={MATRIX_CELL_COLORS[cellValue.level].bgColor}
          _hover={{
            backgroundColor: MATRIX_CELL_COLORS[cellValue.level].hoverBgColor,
          }}
          colorScheme={RISK_LEVEL_COLORS[cellValue.level]}
          color="black"
          isLoading={isUpdating}
          isDisabled={!canManageRiskMatrix}
        >
          {t(`risks.enum.level.${cellValue.level}`)}
        </MenuButton>
        <MenuList>
          {getRiskLevelOptions(t).map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => onChange({ level: option.value, id: cellValue.id })}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Td>
  );
};
