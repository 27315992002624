import { Box, Button, Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import { InboxIcon } from '@heroicons/react/24/outline';
import { EmptyPlaceholder } from '@main/ui';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInfiniteScroll } from '../shared/infinite-scroll';
import {
  NotificationCenterActions,
  useArchiveAllAction,
  useMarkAllAsReadAction,
  useToggleArchiveState,
  useToggleReadState,
} from './drawer-actions';
import { GetInboxNotificationsQuery } from './get-notifications.generated';
import { NotificationItem } from './notification-item';
import {
  NOTIFICATION_PAGE_SIZE,
  useGetAllNotifications,
  useGetArchivedNotifications,
  useGetInboxNotifications,
} from './use-get-notifications';

type Notification = GetInboxNotificationsQuery['user_notifications'][number];
export const InboxTab = ({ onClose }: { onClose: () => void }) => {
  const getInboxNotifications = useGetInboxNotifications();
  const toggleReadState = useToggleReadState();
  const toggleArchiveState = useToggleArchiveState();
  const markAllAsRead = useMarkAllAsReadAction();
  const archiveAll = useArchiveAllAction();

  const ref = useRef<HTMLDivElement>(null);
  const { data, loading, loadingMore, loadMore, noMore, mutate, reloadAsync } =
    useInfiniteScroll<Notification>((d) => getInboxNotifications(d?.offset), {
      target: ref,
      isNoMore: (d) => !!d?.isNoMore,
    });

  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable((ref.current?.scrollHeight ?? 0) > (ref.current?.clientHeight ?? 0));
  }, [setIsScrollable, data]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Flex h="full" flexDirection="column">
      <Box h="full" overflow="hidden" overflowY="auto" ref={ref}>
        {data?.list?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onSelect={onClose}
            onArchive={async () => {
              const archivedAt = notification.archived_at ? null : new Date().toISOString();
              await toggleArchiveState(notification.id, archivedAt);

              mutate({
                ...data,
                offset: data.offset - 1,
                list: data.list.filter((item) => item.id !== notification.id),
              });
            }}
            onMarkAsRead={async () => {
              const readAt = notification.read_at ? null : new Date().toISOString();
              await toggleReadState(notification.id, readAt);

              mutate({
                ...data,
                list: data.list.map((item) => {
                  if (item.id === notification.id) {
                    return { ...item, read_at: readAt ? readAt : undefined };
                  }

                  return item;
                }),
              });
            }}
          />
        ))}

        <ScrollStates
          noMore={noMore}
          loadingMore={loadingMore}
          dataLength={data?.list.length ?? 0}
          loadMore={loadMore}
          isScrollable={isScrollable}
        />
      </Box>
      <NotificationCenterActions
        onOpenSettings={onClose}
        onMarkAllAsRead={async () => {
          if (!data) {
            return;
          }
          await markAllAsRead();
          mutate({
            ...data,
            list: data.list.map((item) => ({ ...item, read_at: new Date().toISOString() })),
          });
        }}
        onArchiveAll={async () => {
          await archiveAll();
          await reloadAsync();
        }}
      />
    </Flex>
  );
};

export const ArchiveTab = ({ onClose }: { onClose: () => void }) => {
  const getArchivedNotifications = useGetArchivedNotifications();
  const toggleReadState = useToggleReadState();
  const toggleArchiveState = useToggleArchiveState();
  const markAllAsRead = useMarkAllAsReadAction();

  const ref = useRef<HTMLDivElement>(null);
  const { data, loading, loadingMore, noMore, mutate, loadMore } = useInfiniteScroll<Notification>(
    (d) => getArchivedNotifications(d?.offset),
    {
      target: ref,
      isNoMore: (d) => !!d?.isNoMore,
    },
  );

  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable((ref.current?.scrollHeight ?? 0) > (ref.current?.clientHeight ?? 0));
  }, [setIsScrollable, data]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Flex h="full" flexDirection="column">
      <Box h="full" overflow="hidden" overflowY="auto" ref={ref}>
        {data?.list?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onSelect={onClose}
            onArchive={async () => {
              const archivedAt = notification.archived_at ? null : new Date().toISOString();
              await toggleArchiveState(notification.id, archivedAt);

              mutate({
                ...data,
                offset: data.offset - 1,
                list: data.list.filter((item) => item.id !== notification.id),
              });
            }}
            onMarkAsRead={async () => {
              const readAt = notification.read_at ? null : new Date().toISOString();
              await toggleReadState(notification.id, readAt);

              mutate({
                ...data,
                list: data.list.map((item) => {
                  if (item.id === notification.id) {
                    return { ...item, read_at: readAt ? readAt : undefined };
                  }

                  return item;
                }),
              });
            }}
          />
        ))}

        <ScrollStates
          noMore={noMore}
          loadingMore={loadingMore}
          dataLength={data?.list.length ?? 0}
          loadMore={loadMore}
          isScrollable={isScrollable}
        />
      </Box>
      <NotificationCenterActions
        onOpenSettings={onClose}
        onMarkAllAsRead={async () => {
          if (!data) {
            return;
          }
          await markAllAsRead();
          mutate({
            ...data,
            list: data.list.map((item) => {
              if (!item.read_at) {
                return { ...item, read_at: new Date().toISOString() };
              }
              return item;
            }),
          });
        }}
      />
    </Flex>
  );
};

export const AllNotificationsTab = ({ onClose }: { onClose: () => void }) => {
  const getAllNotifications = useGetAllNotifications();
  const toggleReadState = useToggleReadState();
  const toggleArchiveState = useToggleArchiveState();
  const markAllAsRead = useMarkAllAsReadAction();
  const archiveAll = useArchiveAllAction();

  const ref = useRef<HTMLDivElement>(null);
  const { data, loading, loadingMore, noMore, mutate, reloadAsync, loadMore } =
    useInfiniteScroll<Notification>((d) => getAllNotifications(d?.offset), {
      target: ref,
      isNoMore: (d) => !!d?.isNoMore,
    });

  const [isScrollable, setIsScrollable] = useState(false);
  useEffect(() => {
    setIsScrollable((ref.current?.scrollHeight ?? 0) > (ref.current?.clientHeight ?? 0));
  }, [setIsScrollable, data]);

  if (loading) {
    return <LoadingState />;
  }

  return (
    <Flex h="full" flexDirection="column">
      <Box h="full" overflow="hidden" overflowY="auto" ref={ref}>
        {data?.list?.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onSelect={onClose}
            onArchive={async () => {
              const archivedAt = notification.archived_at ? null : new Date().toISOString();
              await toggleArchiveState(notification.id, archivedAt);

              mutate({
                ...data,
                list: data.list.map((item) => {
                  if (item.id === notification.id) {
                    return { ...item, archived_at: archivedAt ? archivedAt : undefined };
                  }

                  return item;
                }),
              });
            }}
            onMarkAsRead={async () => {
              const readAt = notification.read_at ? null : new Date().toISOString();
              await toggleReadState(notification.id, readAt);

              mutate({
                ...data,
                list: data.list.map((item) => {
                  if (item.id === notification.id) {
                    return { ...item, read_at: readAt ? readAt : undefined };
                  }

                  return item;
                }),
              });
            }}
          />
        ))}

        <ScrollStates
          noMore={noMore}
          loadingMore={loadingMore}
          dataLength={data?.list.length ?? 0}
          loadMore={loadMore}
          isScrollable={isScrollable}
        />
      </Box>
      <NotificationCenterActions
        onOpenSettings={onClose}
        onMarkAllAsRead={async () => {
          if (!data) {
            return;
          }
          await markAllAsRead();
          mutate({
            ...data,
            list: data.list.map((item) => ({ ...item, read_at: new Date().toISOString() })),
          });
        }}
        onArchiveAll={async () => {
          await archiveAll();
          await reloadAsync();
        }}
      />
    </Flex>
  );
};

const ScrollStates = ({
  noMore,
  loadingMore,
  loadMore,
  dataLength,
  isScrollable,
}: {
  loadingMore: boolean;
  noMore: boolean;
  loadMore: () => void;
  dataLength: number;
  isScrollable: boolean;
}) => {
  const { t } = useTranslation();

  if (loadingMore) {
    return <LoadingMoreState />;
  }

  if (dataLength === 0 && noMore) {
    return <EmptyState />;
  }

  if (noMore && isScrollable) {
    return <NoMoreState />;
  }

  if (dataLength < NOTIFICATION_PAGE_SIZE && !noMore) {
    return (
      <Flex justifyContent="center" py={2}>
        <Button size="xs" variant="outline" rounded="base" onClick={loadMore}>
          {t('buttons.loadMore')}
        </Button>
      </Flex>
    );
  }

  return null;
};

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={InboxIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>{t('notification.empty.heading')}</EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('notification.empty.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
};

const NoMoreState = () => {
  const { t } = useTranslation();

  return (
    <Text textAlign="center" fontSize="sm" color="gray.500" my={3}>
      {t('notification.noMoreNotifications')}
    </Text>
  );
};

const LoadingMoreState = () => {
  const { t } = useTranslation();

  return (
    <Text textAlign="center" fontSize="sm" color="gray.500" my={3}>
      {t('notification.loadingMoreNotifications')}
    </Text>
  );
};

const LoadingState = () => {
  return (
    <Box>
      {new Array(4).fill(0).map((_, i) => (
        <Flex key={i} px={6} py={5} gap={3}>
          <SkeletonCircle w={8} h={8} />
          <Box flexGrow={1}>
            <Skeleton w="full" h={16} />
            <Skeleton w={32} h={3} mt={2} />
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
