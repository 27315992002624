import { NhostClient } from '@nhost/nhost-js';
import pRetry from 'p-retry';

import { toError } from './error';

export async function loginWithRefreshToken(refreshToken: string, nhost: NhostClient) {
  const { session, error } = await pRetry(() => nhost.auth.refreshSession(refreshToken), {
    retries: 5,
  });

  const user = session?.user;

  if (!user || error) {
    throw error ? toError(error) : new Error('No user found in session');
  }

  return user;
}

export function isInvalidRefreshTokenError(error: unknown): boolean {
  return (
    typeof error === 'object' &&
    error !== null &&
    'error' in error &&
    (error as { error: string }).error === 'invalid-refresh-token'
  );
}
