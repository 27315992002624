import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { QuestionnaireComponentKind } from '@main/dynamic-form';
import { QuestionnaireUploadFragment } from '@main/graphql/fragments/QuestionnaireUploadFragment.generated';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import { toError, useStableCallback, withDefaultPrevented } from '@main/shared/utils';
import {
  AutoResizeTextarea,
  createEnumTabsControl,
  FileInputProps,
  FileUpload,
  Select,
  useDownloadStorageFile,
  useLazyFileUpload,
} from '@main/ui';
import { ParseKeys } from 'i18next';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Toggle } from '../../shared/toggle';
import { useFieldConfigModalAssociateFileMutation } from './field-config-modal.generated';
import {
  advancedSettingsSchema,
  aiReviewSchema,
  fieldSchema,
  supportingFileSchema,
  useFormFieldConfigurator,
} from './field-configurator';

const NewFileId = '__new-file__';
const DeletedFileId = '__deleted-file__';

export const FormFieldConfigModal = ({
  questionnaireId,
  entity,
  editingFieldSchema,
  isOpen,
  affectedConditionalFields,
  uploads,
  onClose,
  onSubmit,
}: {
  questionnaireId: string;
  entity: 'question';
  editingFieldSchema?: QuestionnaireFormFieldConfig;
  isOpen: boolean;
  affectedConditionalFields?: QuestionnaireFormFieldConfig[];
  uploads: QuestionnaireUploadFragment[];
  onClose: () => void;
  onSubmit: (data: QuestionnaireFormFieldConfig) => void;
}) => {
  const { t } = useTranslation();
  const actionType = editingFieldSchema ? 'update' : 'create';

  const [fieldType, setFieldType] = useState(
    editingFieldSchema?.kind || QuestionnaireComponentKind.Text,
  );

  const schema = useMemo(
    () => ({
      ...editingFieldSchema,
      kind: fieldType,
      hasSupportingFile: !!editingFieldSchema?.supportingUploadId,
    }),
    [editingFieldSchema, fieldType],
  ) as QuestionnaireFormFieldConfig;

  const { configurator, form, configurators } = useFormFieldConfigurator(schema);

  const tabsControl = createEnumTabsControl(configurators.map((c) => c.kind));

  const configuratorProps = useMemo(
    () => ({ affectedConditionalFields, config: schema }),
    [affectedConditionalFields, schema],
  );
  const isSubmitting = form?.formState.isSubmitting;
  const isDirty = form?.formState.isDirty;

  const supportingUploadId = form?.watch('supportingUploadId') ?? schema?.supportingUploadId;
  const supportingFile = useMemo(
    () => uploads.find((upload) => upload.id === supportingUploadId)?.file,
    [supportingUploadId, uploads],
  );

  const fileUpload = useLazyFileUpload({
    onFileDownload: useDownloadStorageFile(),
    file: supportingFile,
    onFileAdd: () =>
      form?.setValue('supportingUploadId', NewFileId, {
        shouldDirty: true,
        shouldValidate: true,
      }),
    onFileDelete: () =>
      form?.setValue('supportingUploadId', DeletedFileId, {
        shouldDirty: true,
        shouldValidate: true,
      }),
  });

  const resetForm = useStableCallback(() => {
    form?.reset();
    fileUpload.clear();
  });

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  const [associateFile] = useFieldConfigModalAssociateFileMutation();

  async function uploadSupportingFile() {
    const { error, file } = await fileUpload.upload();

    if (error) {
      throw toError(error);
    }

    const { insert_questionnaire_uploads_one } = await associateFile({
      questionnaireId,
      fileId: file.id,
    }).unwrap();

    if (!insert_questionnaire_uploads_one) {
      throw new Error('Could not get upload ID from response');
    }

    return insert_questionnaire_uploads_one.id;
  }

  const hasSupportingFile = form?.watch('hasSupportingFile');
  const handleSubmit = withDefaultPrevented(async (event: FormEvent<HTMLFormElement>) => {
    const name = schema?.name ?? crypto.randomUUID();
    let uploadId = supportingUploadId;

    if (hasSupportingFile && uploadId === NewFileId) {
      try {
        uploadId = await uploadSupportingFile();

        form?.clearErrors('supportingUploadId');
        form?.setValue('supportingUploadId', uploadId, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } catch (error) {
        datadogLogs.logger.error(
          'Failed uploading supporting question file',
          { questionnaireId, fieldId: name },
          toError(error),
        );
        form?.setError('supportingUploadId', {
          type: 'uploadFailed',
          message: t('errorMessages.uploadFailed', { entity: t('entities.file') }),
        });
        return;
      }
    } else if (!hasSupportingFile || uploadId === DeletedFileId) {
      uploadId = undefined;
    }

    form?.handleSubmit((data) => {
      delete data.hasSupportingFile;
      let newConfig: Partial<QuestionnaireFormFieldConfig> = data;

      if (configurator?.formDataToConfig && schema) {
        newConfig = configurator.formDataToConfig(data, schema);
      }

      onSubmit({
        ...schema,
        name,
        kind: fieldType,
        ...newConfig,
        supportingUploadId: uploadId,
      } as QuestionnaireFormFieldConfig);
    })(event);
  });

  const fieldOptions = useMemo(
    () =>
      configurators.map((c) => ({
        label: <c.renderName {...configuratorProps} configurator={c} />,
        value: c.kind,
      })),
    [configuratorProps, configurators],
  );
  const fieldOption = useMemo(
    () => fieldOptions.find((o) => o.value === fieldType),
    [fieldOptions, fieldType],
  );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`form.entities.${entity}.${actionType}`)}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Tabs {...tabsControl(fieldType, setFieldType)}>
              <FormControl>
                <FormLabel>{t('form.fields.type.label')}:</FormLabel>
                <Select
                  useBasicStyles
                  isDisabled={actionType === 'update'}
                  options={fieldOptions}
                  value={fieldOption}
                  onChange={(value) => value && setFieldType(value.value)}
                />
              </FormControl>

              <TabPanels>
                {configurators.map((c) => (
                  <TabPanel key={c.kind} px={0}>
                    <Stack spacing={5}>
                      <FormControl isInvalid={!!c.form.formState.errors.label}>
                        <FormLabel>{t('form.fields.name.label')}:</FormLabel>
                        <AutoResizeTextarea
                          placeholder={t('form.fields.name.placeholder', { entity })}
                          {...c.form.register('label')}
                        />
                        <FormErrorMessage>
                          {t(c.form.formState.errors.label?.message as ParseKeys)}
                        </FormErrorMessage>
                      </FormControl>

                      <c.renderForm {...configuratorProps} configurator={c} />

                      <SharedFields
                        form={c.form as unknown as UseFormReturn<z.infer<typeof fieldSchema>>}
                      />
                      <AIReviewFields
                        entity={entity}
                        aiReviewForm={
                          c.form as unknown as UseFormReturn<z.infer<typeof aiReviewSchema>>
                        }
                      />
                      <SupportingFileFields
                        form={
                          c.form as unknown as UseFormReturn<z.infer<typeof supportingFileSchema>>
                        }
                        fileUpload={fileUpload.props}
                      />
                      <AdvancedSettingsFields
                        form={
                          c.form as unknown as UseFormReturn<z.infer<typeof advancedSettingsSchema>>
                        }
                      />
                    </Stack>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
            {configurator && (
              <configurator.renderAfterForm {...configuratorProps} configurator={configurator} />
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={!isDirty}
              >
                {t(`buttons.${actionType}`)}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const SharedFields = ({ form }: { form: UseFormReturn<z.infer<typeof fieldSchema>> }) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Toggle label={t('form.fields.required.label')} {...form.register('validation.required')} />
    </FormControl>
  );
};

const AIReviewFields = ({
  aiReviewForm,
  entity,
}: {
  aiReviewForm: UseFormReturn<z.infer<typeof aiReviewSchema>>;
  entity: string;
}) => {
  const { t } = useTranslation();
  const isAIReviewFeatureEnabled = useFeatureFlagEnabled('vendor-questionnaire-ai-criteria-form');

  const isAIReviewSwitchEnabled = aiReviewForm.watch('hasAiReview');

  if (!isAIReviewFeatureEnabled) {
    return null;
  }

  return (
    <>
      <FormControl>
        <Toggle label={t('form.fields.aiReviewToggle')} {...aiReviewForm.register('hasAiReview')} />
      </FormControl>

      {isAIReviewSwitchEnabled && (
        <>
          <FormControl isInvalid={!!aiReviewForm.formState.errors.aiCriteria}>
            <FormLabel>{t('form.fields.aiCriteria.label')}:</FormLabel>
            <AutoResizeTextarea
              minRows={3}
              placeholder={t('form.fields.aiCriteria.placeholder', { entity })}
              {...aiReviewForm.register('aiCriteria')}
            />
            <FormErrorMessage>
              {t(aiReviewForm.formState.errors.aiCriteria?.message as ParseKeys)}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!aiReviewForm.formState.errors.aiMessage}>
            <FormLabel>{t('form.fields.aiMessage.label')}:</FormLabel>
            <AutoResizeTextarea
              minRows={3}
              placeholder={t('form.fields.aiMessage.placeholder', { entity })}
              {...aiReviewForm.register('aiMessage')}
            />
            <FormErrorMessage>
              {t(aiReviewForm.formState.errors.aiMessage?.message as ParseKeys)}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
    </>
  );
};

function SupportingFileFields({
  form,
  fileUpload,
}: {
  form: UseFormReturn<z.infer<typeof supportingFileSchema>>;
  fileUpload: FileInputProps;
}) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <Toggle
          label={t('form.fields.supportingFileSwitch.label')}
          {...form.register('hasSupportingFile')}
        >
          <Tooltip label={t('form.fields.supportingFileSwitch.tooltip')} placement="right" hasArrow>
            <Icon as={InformationCircleIcon} />
          </Tooltip>
        </Toggle>
      </FormControl>

      {form.watch('hasSupportingFile') && (
        <FormControl isInvalid={!!form.formState.errors.supportingUploadId}>
          <FormLabel>{t('form.fields.supportingFile.label')}:</FormLabel>
          <FileUpload {...fileUpload}>
            <FileUpload.Dropzone />
          </FileUpload>
          <FormErrorMessage>
            {t(form.formState.errors.supportingUploadId?.message as ParseKeys)}
          </FormErrorMessage>
        </FormControl>
      )}
    </>
  );
}

function AdvancedSettingsFields({
  form,
}: {
  form: UseFormReturn<z.infer<typeof advancedSettingsSchema>>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <Stack spacing={1}>
        <Text fontSize="xs" fontWeight="semibold">
          {t('form.fields.advancedSettings.heading')}
        </Text>
        {/* TODO: Uncomment when linking to vendor custom fields is implemented */}
        {/* <Text fontSize="xs">{t('form.fields.advancedSettings.subheading')}</Text> */}
      </Stack>
      <FormControl>
        <Toggle
          label={t('form.fields.addFilesToDocuments.label')}
          {...form.register('addFilesToDocuments')}
        />
      </FormControl>
    </>
  );
}
