/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetAiControlRecommendationForRiskQueryVariables = Types.Exact<{
  input: Types.AiRiskControlSuggestionsInput;
}>;

export type GetAiControlRecommendationForRiskQuery = {
  __typename?: 'query_root';
  ai_control_suggestions_for_risk?: {
    __typename?: 'AiRiskControlSuggestionsOutput';
    controls?: Array<{
      __typename?: 'AiRiskControlSuggestion';
      id: string;
      name?: string;
      internal_id?: string;
    }>;
  };
};

export const GetAiControlRecommendationForRiskDocument = `
    query GetAiControlRecommendationForRisk($input: AiRiskControlSuggestionsInput!) {
  ai_control_suggestions_for_risk(input: $input) {
    controls {
      id
      name
      internal_id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    GetAiControlRecommendationForRiskQuery,
    GetAiControlRecommendationForRiskQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAiControlRecommendationForRisk: build.query<
      GetAiControlRecommendationForRiskQuery,
      GetAiControlRecommendationForRiskQueryVariables
    >({
      query: (variables) => ({ document: GetAiControlRecommendationForRiskDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAiControlRecommendationForRiskQuery,
  useLazyGetAiControlRecommendationForRiskQuery,
} = injectedRtkApi;
