/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import {
  TaskDrawerFragmentFragmentDoc,
  TaskTableFragmentFragmentDoc,
} from './tasks.fragment.generated';
import { CommentsFragmentFragmentDoc } from '../../../../../libs/graphql/src/fragments/comments.fragment.generated';
import { FieldValueFragmentDoc } from '../../../../../libs/graphql/src/fragments/FieldValueFragments.generated';
import { TaskLinkedEntityFragmentDoc } from './sidebar/manage-task-sidebar.generated';
import { api } from '@main/graphql/shared-api';
export type GetTaskQueryVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
}>;

export type GetTaskQuery = {
  __typename?: 'query_root';
  tasks_by_pk?: {
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    description?: string;
    internal_id?: string;
    completed_at?: string;
    created_at: string;
    created_by?: string;
    organization_id: string;
    task_owners: Array<{ __typename?: 'task_owners'; completed_at?: string; owner_id: string }>;
    tasks_comments: Array<{
      __typename?: 'comments';
      id: string;
      text: string;
      created_at: string;
      updated_at: string;
      user_id: string;
      comments_comment_uploads: Array<{
        __typename?: 'comment_uploads';
        id: string;
        comment_uploads_file: {
          __typename?: 'files';
          id: string;
          name?: string;
          size?: number;
          createdAt: string;
        };
      }>;
    }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
    controls: Array<{
      __typename?: 'control_tasks';
      control: {
        __typename?: 'controls';
        id: string;
        name?: string;
        internal_id?: string;
        status: ClientTypes.ControlStatus;
        assignee?: { __typename?: 'users'; displayName: string };
      };
    }>;
    risks: Array<{
      __typename?: 'risk_tasks';
      risk: {
        __typename?: 'risks';
        id: string;
        name?: string;
        internal_id?: string;
        assessment_status: ClientTypes.RiskAssessmentStatus;
        assignee?: { __typename?: 'users'; displayName: string };
      };
    }>;
    vendors: Array<{
      __typename?: 'vendor_tasks';
      vendor: {
        __typename?: 'vendors';
        id: string;
        name?: string;
        internal_id?: string;
        assessment_status: ClientTypes.VendorAssessmentStatus;
        owner?: { __typename?: 'users'; displayName: string };
      };
    }>;
    policies: Array<{
      __typename?: 'policy_tasks';
      policy: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
        status: Types.Policy_Statuses_Enum;
        user?: { __typename?: 'users'; displayName: string };
      };
    }>;
    evidence: Array<{
      __typename?: 'evidence_tasks';
      evidence: {
        __typename?: 'evidences';
        id: string;
        name?: string;
        internal_id?: string;
        controls_aggregate_status: ClientTypes.EvidenceStatus;
        user?: { __typename?: 'users'; displayName: string };
      };
    }>;
  };
};

export type GetTasksQueryVariables = Types.Exact<{
  where: Types.Tasks_Bool_Exp;
}>;

export type GetTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    description?: string;
    created_by?: string;
    created_at: string;
    completed_at?: string;
    task_owners: Array<{ __typename?: 'task_owners'; completed_at?: string; owner_id: string }>;
    field_values: Array<{
      __typename?: 'field_values';
      field_config_id: string;
      entity_name: Types.Field_Entities_Enum;
      field_type: Types.Field_Types_Enum;
      text_field_value?: {
        __typename?: 'text_field_values';
        field_value_id: string;
        value: string;
      };
      url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
      date_field_value?: {
        __typename?: 'date_field_values';
        field_value_id: string;
        value: string;
      };
      number_field_value?: {
        __typename?: 'number_field_values';
        field_value_id: string;
        value: number;
      };
      email_field_value?: {
        __typename?: 'email_field_values';
        field_value_id: string;
        value: string;
      };
      select_field_value?: {
        __typename?: 'select_field_values';
        field_value_id: string;
        select_field_option_id: string;
      };
    }>;
  }>;
};

export const GetTaskDocument = `
    query GetTask($taskId: uuid!) {
  tasks_by_pk(id: $taskId) {
    ...TaskDrawerFragment
    ...TaskLinkedEntity
  }
}
    ${TaskDrawerFragmentFragmentDoc}
${CommentsFragmentFragmentDoc}
${FieldValueFragmentDoc}
${TaskLinkedEntityFragmentDoc}` as string & TypedDocumentNode<GetTaskQuery, GetTaskQueryVariables>;
export const GetTasksDocument = `
    query GetTasks($where: tasks_bool_exp!) {
  tasks(where: $where, order_by: {internal_id: asc}) {
    ...TaskTableFragment
  }
}
    ${TaskTableFragmentFragmentDoc}
${FieldValueFragmentDoc}` as string & TypedDocumentNode<GetTasksQuery, GetTasksQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTask: build.query<GetTaskQuery, GetTaskQueryVariables>({
      query: (variables) => ({ document: GetTaskDocument, variables }),
    }),
    GetTasks: build.query<GetTasksQuery, GetTasksQueryVariables>({
      query: (variables) => ({ document: GetTasksDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTaskQuery, useLazyGetTaskQuery, useGetTasksQuery, useLazyGetTasksQuery } =
  injectedRtkApi;
