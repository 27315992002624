import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { InformationCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { isNonNullable } from '@main/shared/utils';
import { AvatarOption, EditableAvatar, getHashedAvatarColor } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentOrgNonDisabledUsers, getCurrentUserId } from '../../user/slice';
import { useUpdateEvidenceOnDrawer } from '../use-update-evidence-handler';
import { useIsUserAuthorizedToChangeOwner } from '../utils';
import {
  useGrantEvidenceAccessMutation,
  useRevokeEvidenceAccessMutation,
} from './evidence-access-modal.generated';

export type AccessListEvidence = {
  id: string;
  is_confidential: boolean;
  owner_id?: string;
  acl: { id: string; user_id: string }[];
};

export const EvidenceAccessList = ({ evidence }: { evidence: AccessListEvidence }) => {
  const { t } = useTranslation();
  const canManageAccess = useIsUserAuthorizedToChangeOwner(evidence);
  const aclEntries = useAclEntries(evidence);

  return (
    <Flex direction="column" gap={6}>
      {canManageAccess && <GrantAccessInput evidence={evidence} />}
      <Flex direction="column" gap={4}>
        <Flex align="center" gap={1}>
          <Text fontWeight="semibold">
            {t('evidences.accessModal.confidential.accessList.heading')}
          </Text>
          <Tooltip
            placement="right"
            label={t('evidences.accessModal.confidential.accessList.tooltip')}
            hasArrow
          >
            <Icon w={4} h={4} color="gray.400" as={InformationCircleIcon} />
          </Tooltip>
        </Flex>
        <Flex direction="column" gap={4}>
          {aclEntries.map(({ key, ...props }) => (
            <AccessControlEntry key={key} {...props} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const GrantAccessInput = ({ evidence }: { evidence: AccessListEvidence }) => {
  const { t } = useTranslation();
  const currentUserId = useAppSelector(getCurrentUserId);
  const grantAccessOptions = useAppSelector(getCurrentOrgNonDisabledUsers).filter((user) => {
    // exclude current user from suggestions
    if (user.id === currentUserId) return false;
    // exclude owner from suggestions
    if (user.id === evidence.owner_id) return false;
    // suggest only users, that are not part of the ACL yet
    return evidence.acl.every((entry) => entry.user_id !== user.id);
  });
  const [newUser, setNewUser] = useState<AvatarOption | null>(null);
  const [grantEvidenceAccess] = useGrantEvidenceAccessMutation();
  const [updateEvidenceHandler, { isLoading }] = useUpdateEvidenceOnDrawer({
    evidenceId: evidence.id,
  });

  const onGrantAccessClick = async () => {
    if (!newUser) {
      return;
    }

    await updateEvidenceHandler(
      grantEvidenceAccess({
        userId: newUser.id,
        evidenceId: evidence.id,
      }),
    );

    setNewUser(null);
  };

  return (
    <Flex align="center" gap={3}>
      <Box flexGrow={1}>
        <EditableAvatar
          aria-label="Grantee"
          placeholder={t('evidences.accessModal.grantAccessPlaceholder')}
          isDisabled={isLoading}
          options={grantAccessOptions.map((user) => ({
            id: user.id,
            displayName: user.displayName,
          }))}
          value={newUser}
          onChange={setNewUser}
        />
      </Box>
      <Button
        colorScheme="blue"
        isDisabled={!newUser}
        isLoading={isLoading}
        onClick={onGrantAccessClick}
      >
        {t('evidences.accessModal.grantAccessButton')}
      </Button>
    </Flex>
  );
};

type AccessControlEntryProps = {
  name: string;
  email?: string;
  isCurrentUser?: boolean;
  onDeleteClick?(): Promise<void>;
};

const AccessControlEntry = ({
  name,
  email,
  isCurrentUser,
  onDeleteClick,
}: AccessControlEntryProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Flex align="center" justify="space-between">
      <AccessControlUser name={name} email={email} isCurrentUser={isCurrentUser} />

      {onDeleteClick && (
        <IconButton
          minW={4}
          minH={4}
          variant="link"
          aria-label={t('buttons.delete')}
          icon={<TrashIcon />}
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await onDeleteClick();
            setIsLoading(false);
          }}
        />
      )}
    </Flex>
  );
};

function useAclEntries(evidence: AccessListEvidence) {
  const canManageAccess = useIsUserAuthorizedToChangeOwner(evidence);
  const currentUserId = useAppSelector(getCurrentUserId);
  const orgUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const [revokeEvidenceAccess] = useRevokeEvidenceAccessMutation();
  const [updateEvidenceHandler] = useUpdateEvidenceOnDrawer({ evidenceId: evidence.id });

  return useMemo(() => {
    const onRevokeAccessClick = async (evidenceAclId: string) => {
      await updateEvidenceHandler(revokeEvidenceAccess({ evidenceAclId }));
    };

    // collect `user id` -> `acl id` in a Map to define order and remove duplicates
    const userIdToAclIds = evidence.acl.reduce(
      (acc, entry) => {
        if (!acc.has(entry.user_id)) {
          acc.set(entry.user_id, entry.id);
        }
        return acc;
      },
      // if evidence has an owner, put it as the first Map entry
      new Map<string, string | null>(evidence.owner_id ? [[evidence.owner_id, null]] : []),
    );

    return [...userIdToAclIds.entries()]
      .map(([userId, aclId]): (AccessControlEntryProps & { key: string }) | null => {
        const user = orgUsers.find((user) => user.id === userId);
        if (!user) {
          return null;
        }
        return {
          key: user.id,
          name: user.displayName,
          email: user.email,
          isCurrentUser: user.id === currentUserId,
          onDeleteClick: canManageAccess && aclId ? () => onRevokeAccessClick(aclId) : undefined,
        };
      })
      .filter(isNonNullable);
  }, [
    canManageAccess,
    currentUserId,
    evidence.acl,
    evidence.owner_id,
    orgUsers,
    revokeEvidenceAccess,
    updateEvidenceHandler,
  ]);
}

const AccessControlUser = ({
  name,
  email,
  isCurrentUser,
}: {
  name: string;
  email?: string;
  isCurrentUser?: boolean;
}) => {
  const { t } = useTranslation();
  const normalizedName = [name, isCurrentUser && `(${t('shared.you').toLowerCase()})`]
    .filter(Boolean)
    .join(' ');
  const nameColor = useColorModeValue('gray.700', 'gray.400');

  return (
    <Flex align="center" gap={3}>
      <Avatar size="sm" name={name} {...getHashedAvatarColor(name)} />
      <Flex direction="column" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <Text color={nameColor} fontSize="xs" fontWeight="medium">
          {normalizedName}
        </Text>
        {email && (
          <Text color="gray.500" fontSize="xs" fontWeight="medium">
            {email}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
