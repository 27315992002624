import { Avatar, Box, Flex, Stack, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { CommentsFragmentFragment } from '@main/graphql/fragments/comments.fragment.generated';
import * as Types from '@main/graphql/types.generated';
import { exhaustiveCheck, formatCommentedTime, toError } from '@main/shared/utils';
import {
  CommentEditor,
  CommentSaveParams,
  EditableCommentItem,
  errorToast,
  getHashedAvatarColor,
  UploadedFile,
} from '@main/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentOrgUserMentionsData,
  getCurrentOrgUsersMap,
  getCurrentUserDetails,
  getCurrentUserId,
} from '../user/slice';
import {
  useAddCommentMutation,
  useAddCommentUploadsMutation,
  useDeleteCommentMutation,
  useDeleteCommentUploadMutation,
} from './comment-mutations.generated';

type CommentsTabProps = {
  entity:
    | 'control'
    | 'evidence'
    | 'task'
    | 'risk'
    | 'vendor'
    | 'policy'
    | 'clientq-question'
    | 'questionnaire'
    | 'vendor-questionnaire';
  entityId: string;
  comments: CommentsFragmentFragment[];
  refetchComments: () => Promise<void>;
};

export const CommentsTab = ({ entity, entityId, comments, refetchComments }: CommentsTabProps) => {
  const { t } = useTranslation();

  const userDetails = useAppSelector(getCurrentUserDetails);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentUserId = useAppSelector(getCurrentUserId);
  const userMentionsData = useAppSelector((state) => getCurrentOrgUserMentionsData(state, t));

  const [addComment] = useAddCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [uploadAttachments] = useAddCommentUploadsMutation();
  const [deleteAttachment] = useDeleteCommentUploadMutation();

  const addCommentHandler = async ({
    id,
    text,
    uploadedFiles: attachedFiles = [],
    deletedFiles = [],
  }: CommentSaveParams) => {
    const input: Types.Comments_Insert_Input = {
      id,
      text,
    };

    switch (entity) {
      case 'control':
        input.control_id = entityId;
        break;

      case 'evidence':
        input.evidence_id = entityId;
        break;

      case 'task':
        input.task_id = entityId;
        break;

      case 'risk':
        input.risk_id = entityId;
        break;

      case 'vendor':
        input.vendor_id = entityId;
        break;

      case 'policy':
        input.policy_id = entityId;
        break;

      case 'questionnaire':
        input.questionnaire_id = entityId;
        break;

      case 'vendor-questionnaire':
        input.vendor_questionnaire_id = entityId;
        break;

      case 'clientq-question':
        input.client_questionnaire_question_id = entityId;
        break;

      default:
        exhaustiveCheck(entity);
        break;
    }
    try {
      const { insert_comments_one } = await addComment({
        input,
      }).unwrap();

      if (insert_comments_one) {
        const insertedCommentId = insert_comments_one.id;
        await uploadAttachmentHandler(attachedFiles, insertedCommentId);
        await deleteAttachmentHandler(deletedFiles, insertedCommentId);
      }
      await refetchComments();
    } catch (error) {
      errorToast(t('comments.failedToCreate'));
      datadogLogs.logger.error(
        `Failed to create comment for ${entity}`,
        { entityId },
        toError(error),
      );
    }
  };

  const uploadAttachmentHandler = async (fileIds: string[], insertedCommentId: string) => {
    if (!fileIds.length) {
      return;
    }

    const uploadsList = fileIds.map<Types.Comment_Uploads_Insert_Input>((fileId) => ({
      comment_id: insertedCommentId,
      file_id: fileId,
    }));

    await uploadAttachments({ input: uploadsList }).unwrap();
  };

  const deleteAttachmentHandler = async (fileIds: string[], commentId: string) => {
    if (!fileIds.length) {
      return;
    }

    await Promise.all(fileIds.map((fileId) => deleteAttachment({ commentId, fileId })));
  };

  const deleteCommentHandler = async (commentId: string) => {
    const comment = comments.find((comment) => comment.id === commentId);

    if (!comment) {
      return;
    }

    await deleteComment({ id: commentId }).unwrap();
    const fileIds = comment.comments_comment_uploads.map(
      ({ comment_uploads_file }) => comment_uploads_file.id,
    );
    await deleteAttachmentHandler(fileIds, commentId);
    await refetchComments();
  };

  return (
    <Box>
      <Stack spacing="4">
        {comments.map((comment) => (
          <EditableCommentItem
            key={comment.id}
            id={comment.id}
            text={comment.text}
            mentionsData={userMentionsData}
            onSave={addCommentHandler}
            onDelete={async () => {
              await deleteCommentHandler(comment.id);
            }}
            attachments={comment.comments_comment_uploads.map<UploadedFile>(
              ({ comment_uploads_file, id }) => ({
                id: comment_uploads_file.id,
                name: comment_uploads_file.name,
                size: comment_uploads_file.size,
              }),
            )}
            canBeEdited={comment.user_id === currentUserId}
          >
            <Avatar
              size="sm"
              name={currentOrgUsers[`${comment.user_id}`]?.displayName}
              {...getHashedAvatarColor(currentOrgUsers[`${comment.user_id}`]?.displayName)}
            />
            <Flex align="center" gap={2} height={8}>
              <Text as="b" fontSize="sm" color="gray.600" _dark={{ color: 'gray.300' }}>
                {currentOrgUsers[`${comment.user_id}`]?.displayName}
              </Text>
              {currentOrgUsers[`${comment.user_id}`]?.disabled && (
                <Text fontSize="sm" color="gray.500">
                  {' '}
                  (disabled)
                </Text>
              )}
              <Text fontSize="xs" color="gray.300" _dark={{ color: 'gray.500' }}>
                {comment.created_at === comment.updated_at
                  ? formatCommentedTime(comment.created_at)
                  : `edited ${formatCommentedTime(comment.updated_at)}`}
              </Text>
            </Flex>
          </EditableCommentItem>
        ))}
      </Stack>

      <CommentEditor
        placeholder={t('commentInput.placeholder')}
        mentionsData={userMentionsData}
        onSave={addCommentHandler}
      >
        <Avatar
          size="sm"
          name={userDetails?.user?.displayName}
          {...getHashedAvatarColor(userDetails?.user?.displayName)}
        />
      </CommentEditor>
    </Box>
  );
};
