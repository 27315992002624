import { useEffect, useRef } from 'react';

import { getCurrentUserSelectedOrgId, userPreferenceOrgMismatched } from '../features/user/slice';
import { useAppDispatch, useAppSelector } from './redux-toolkit-hooks';

export function useOrgGuard<TData>(
  data: TData,
  orgIdGetter: (data: TData) => string | undefined,
): TData {
  const dispatch = useAppDispatch();
  const currentOrgId = useAppSelector(getCurrentUserSelectedOrgId);
  const orgId = orgIdGetter(data);
  const hasDispatchedMismatch = useRef(false);

  useEffect(() => {
    if (orgId && orgId !== currentOrgId && !hasDispatchedMismatch.current) {
      dispatch(userPreferenceOrgMismatched({ orgId }));
      hasDispatchedMismatch.current = true;
    }
  }, [currentOrgId, dispatch, orgId]);

  return data;
}
