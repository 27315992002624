import { TaskStatus } from '@main/graphql/client-scalars';
import { TFunction } from 'i18next';

import { TASK_STATUS_COLOR } from '../shared/status-color';

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedTaskStatus = Object.keys(TASK_STATUS_COLOR) as TaskStatus[];

export function getAllTaskStatusOptions(t: TFunction<'translation'>) {
  return orderedTaskStatus.map((value) => getTaskStatusOption(t, value));
}

export function getTaskStatusOption(t: TFunction<'translation'>, value: TaskStatus) {
  return {
    value,
    label: t(`tasks.enum.status.${value}`),
    colorScheme: TASK_STATUS_COLOR[value],
  };
}
