import { Box, Divider, Heading, Switch, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { Notification_Delivery_Types_Enum } from '@main/graphql/types.admin.generated';
import { toError } from '@main/shared/utils';
import { errorToast, GroupedTable, successToast } from '@main/ui';
import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getNotifications, NotificationRow } from './slice';
import {
  useDisableNotificationMutation,
  useEnableNotificationMutation,
  useGetDisabledNotificationsQuery,
} from './UserNotifications.generated';

export const Notifications = () => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  useGetDisabledNotificationsQuery({ userId });

  const notifications = useAppSelector((state) => getNotifications(state, userId));
  const columns = useNotificationsTableColumns();

  return (
    <>
      <Box py="6" px={[4, 4, 8]}>
        <Heading as="h2" size="md">
          {t('settings.account.notification.heading')}
        </Heading>
        <Text fontSize="14" color="gray.500">
          {t('settings.account.notification.subheading')}
        </Text>
      </Box>

      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <GroupedTable
          data={notifications}
          columns={columns}
          entityName="notification"
          groupBy="module"
        />
      </Box>
    </>
  );
};

const NotificationItemCell = ({
  notification,
  deliveryType,
}: {
  notification: NotificationRow;
  deliveryType: Notification_Delivery_Types_Enum;
}) => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const { refetch: refetchNotifications } = useGetDisabledNotificationsQuery({ userId });

  const [enableNotification] = useEnableNotificationMutation();
  const [disableNotification] = useDisableNotificationMutation();

  const onDisable = useCallback(async () => {
    try {
      await disableNotification({
        objects: notification.groupedTypes.map((type) => ({
          user_id: userId,
          delivery_type: deliveryType,
          type,
        })),
      }).unwrap();
      refetchNotifications();
      successToast(t('successMessages.disableSucceeded', { entity: t('entities.notification') }));
    } catch (error) {
      refetchNotifications();
      errorToast(t('errorMessages.disableFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error('Adding new permission to role failed', {}, toError(error));
    }
  }, [
    deliveryType,
    disableNotification,
    notification.groupedTypes,
    refetchNotifications,
    t,
    userId,
  ]);

  const onEnable = useCallback(async () => {
    try {
      await enableNotification({
        userId,
        deliveryType,
        notificationTypes: notification.groupedTypes,
      }).unwrap();
      refetchNotifications();
      successToast(t('successMessages.enableSucceeded', { entity: t('entities.notification') }));
    } catch (error) {
      refetchNotifications();
      errorToast(t('errorMessages.enableFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error('Adding new permission to role failed', {}, toError(error));
    }
  }, [
    deliveryType,
    enableNotification,
    notification.groupedTypes,
    refetchNotifications,
    t,
    userId,
  ]);

  return (
    <Switch
      isDisabled={notification[deliveryType].isNotAvailable}
      isChecked={
        notification[deliveryType].isNotAvailable
          ? notification[deliveryType].valueWhenNotAvailable
          : notification[deliveryType].value
      }
      onChange={() => (notification[deliveryType].value ? onDisable() : onEnable())}
    />
  );
};

const useNotificationsTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<ColumnDef<NotificationRow>[]>(
    () => [
      {
        id: 'name',
        enableHiding: false,
        accessorFn: ({ translationKey }) => t(translationKey),
        header: () => <Text>{t('notifications.tableColumns.actions')}</Text>,
        cell: ({ row }) => t(row.original.translationKey),
        footer: (props) => props.column.id,
      },
      {
        id: 'platform',
        cell: ({ row }) => (
          <NotificationItemCell
            deliveryType={Notification_Delivery_Types_Enum.Platform}
            notification={row.original}
          />
        ),
        header: () => <Text>{t('notifications.tableColumns.inPlatform')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        id: 'email',
        cell: ({ row }) => (
          <NotificationItemCell
            deliveryType={Notification_Delivery_Types_Enum.Email}
            notification={row.original}
          />
        ),
        header: () => <Text>{t('notifications.tableColumns.email')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        id: 'module',
        accessorFn: ({ module }) => t(`notifications.modules.${module}`),
        footer: (props) => props.column.id,
      },
    ],
    [t],
  );
};
