import { ControlStatus } from '@main/graphql/client-scalars';
import { TFunction } from 'i18next';

import { CONTROL_STATUS_COLOR } from '../shared/status-color';

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedControlStatus = Object.keys(CONTROL_STATUS_COLOR) as ControlStatus[];

export function getAllControlStatusOptions(t: TFunction<'translation'>) {
  return orderedControlStatus.map((value) => getControlStatusOption(t, value));
}

export function getControlStatusOption(t: TFunction<'translation'>, value: ControlStatus) {
  return {
    value,
    label: t(`controls.status.enums.${value}`),
    colorScheme: CONTROL_STATUS_COLOR[value],
  };
}
