import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { Global } from '@emotion/react';
import {
  AtSymbolIcon,
  CheckCircleIcon,
  PaperClipIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { toError } from '@main/shared/utils';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionButtons,
  FloatingActionButtons,
  FloatingButtonsContainer,
} from '../floating-action-buttons';
import { MentionedObject, MentionNode } from '../lexical/nodes/MentionNode';
import defaultTheme from '../lexical/themes/default';
import defaultStyles from '../lexical/themes/default/styles';
import { errorToast } from '../toast';
import { FileUpload, UploadedFile } from '../upload-file';
import { CommentPlugins } from './comment-plugins';
import { useFileUploads } from './hooks/use-file-uploads';
import { useGetCommentState } from './hooks/use-get-comment-state';
import { useIsCommentEmpty } from './hooks/use-is-comment-empty';
import { useMentions } from './hooks/use-mentions';

export type CommentSaveParams = {
  id?: string;
  text: string;
  uploadedFiles?: string[];
  deletedFiles?: string[];
};

export type CommentItemProps = {
  id: string;
  text: string;
  canBeEdited?: boolean;
  onDelete: () => Promise<void>;
  onSave: (data: CommentSaveParams) => Promise<void>;
  mentionsData?: MentionedObject[];
  attachments?: UploadedFile[];
  placeholder?: string;
  children: ReactNode;
};

export const EditableCommentItem: React.FC<CommentItemProps> = (props) => {
  const initialState = useGetCommentState(props.text);

  return (
    <LexicalComposer
      initialConfig={{
        editable: false,
        namespace: `comment-${props.id || 'input'}`,
        onError(error: unknown) {
          console.error(error);
        },
        nodes: [MentionNode],
        theme: defaultTheme,
        editorState: initialState,
      }}
    >
      <Global styles={defaultStyles} />
      <CommentLexical {...props} />
    </LexicalComposer>
  );
};

const CommentLexical: React.FC<CommentItemProps> = ({
  id,
  text,
  canBeEdited = false,
  onSave,
  onDelete,
  mentionsData = [],
  attachments = [],
  placeholder = 'Add a comment...',
  children,
}) => {
  const { t } = useTranslation('ui');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const initialState = useGetCommentState(text);

  const [editor] = useLexicalComposerContext();
  const [isEditing, setIsEditing] = useState(false);
  const isCommentEmpty = useIsCommentEmpty(text);
  const mentionUsers = useMentions();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const { filesUpload, clearStagedFiles, undoDeletedFiles } = useFileUploads({
    uploadedAttachments: attachments,
  });

  const onAttachIconClicked = () => fileInputRef.current?.click();

  const toggleEditMode = ({ enable }: { enable: boolean }) => {
    setIsEditing(enable);
    editor.update(() => {
      editor.setEditable(enable);
      if (editor.isEditable()) {
        editor.focus();
      }
    });
  };

  const saveEditedComment = () => {
    editor.update(async () => {
      const { errors, files } = await filesUpload.upload();

      if (errors.length > 0) {
        errorToast(t('comments.toast.uploadFailed'));
        datadogLogs.logger.error('Creating new control failed', {}, toError(errors[0]));
        return;
      }

      const uploadFileIds = files.map((file) => file.id);

      const editorState = JSON.stringify(editor.toJSON());
      await onSave({
        id,
        text: editorState,
        uploadedFiles: uploadFileIds,
        deletedFiles: filesUpload.deletedFiles.map((file) => file.id),
      });

      clearStagedFiles();
      filesUpload.clear();
    });
    toggleEditMode({ enable: false });
  };

  const cancelEditedComment = async () => {
    toggleEditMode({ enable: false });

    const editorState = editor.parseEditorState(initialState);
    editor.setEditorState(editorState);
    clearStagedFiles();
    undoDeletedFiles();
  };

  const deleteComment = () => {
    onDeleteModalOpen();
  };

  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const bgColor = useColorModeValue('#FCFCFD', 'gray.800');
  return (
    <Flex
      {...(isEditing && { border: '1px', borderColor: borderColor, rounded: 8, p: 4, bg: bgColor })}
    >
      <FloatingButtonsContainer position="relative" w="full">
        <Flex gap={3}>{children}</Flex>
        <Flex direction="column" pl="44px">
          <CommentPlugins
            onSubmit={saveEditedComment}
            mentionsList={mentionsData}
            placeholder={placeholder}
            {...(isCommentEmpty && !isEditing && { display: 'none' })}
          />
          {canBeEdited &&
            (isEditing ? (
              <ActionButtons
                position="absolute"
                top={0}
                right={0}
                buttons={[
                  {
                    'aria-label': 'Attach file',
                    tooltip: t('comments.actions.attach'),
                    icon: PaperClipIcon,
                    onClick: onAttachIconClicked,
                  },
                  {
                    'aria-label': 'Mention user',
                    tooltip: t('comments.actions.mention'),
                    icon: AtSymbolIcon,
                    onClick: mentionUsers,
                  },
                  {
                    'aria-label': 'Cancel edit',
                    tooltip: t('comments.actions.cancel'),
                    icon: XCircleIcon,
                    onClick: cancelEditedComment,
                  },
                  {
                    'aria-label': 'Update comment',
                    tooltip: t('comments.actions.update'),
                    icon: CheckCircleIcon,
                    onClick: saveEditedComment,
                  },
                ]}
              />
            ) : (
              <FloatingActionButtons
                position="absolute"
                top={0}
                right={0}
                buttons={[
                  {
                    'aria-label': 'Edit comment',
                    tooltip: t('comments.actions.edit'),
                    icon: PencilSquareIcon,
                    onClick: () => toggleEditMode({ enable: true }),
                  },
                  {
                    'aria-label': 'Delete comment',
                    tooltip: t('comments.actions.delete'),
                    icon: TrashIcon,
                    onClick: deleteComment,
                  },
                ]}
              />
            ))}

          <FormControl isReadOnly={!isEditing}>
            <FileUpload {...filesUpload.props}>
              <FileUpload.Input display="none" ref={fileInputRef} />
            </FileUpload>
          </FormControl>

          <Modal onClose={onDeleteModalClose} isOpen={isDeleteModalOpen} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize="lg">{t('comments.deleteModal.title')}</ModalHeader>
              <ModalBody>{t('comments.deleteModal.content')}</ModalBody>
              <ModalFooter>
                <Button colorScheme="gray" onClick={onDeleteModalClose}>
                  {t('comments.actions.cancel')}
                </Button>
                <Button
                  colorScheme="red"
                  ml="16px"
                  onClick={async () => {
                    await onDelete();
                    onDeleteModalClose();
                  }}
                >
                  {t('comments.actions.delete')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      </FloatingButtonsContainer>
    </Flex>
  );
};
