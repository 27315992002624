import { FormControl, FormLabel, HStack, Input, Text } from '@chakra-ui/react';
import { Nullable } from '@main/shared/types';
import { FilterMode, isIsEmptyOrIsNotEmpty } from '@main/shared/url-helpers';
import { isEmpty } from '@main/shared/utils/is-empty';
import { ColumnDef, Row, SortDirection } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { OverflowContainer } from '../../overflow-tooltip';
import { createFilterHelper } from '../filters';
import { FilterModeSelector } from '../filters/filter-mode';
import { CellContainer } from '../shared/cell';

type EditProps<TData> = {
  onChange: (row: Row<TData>, value: number) => void;
  canEditGuard?: (row: Row<TData>) => boolean;
  defaultIsEditing?: (row: Row<TData>) => boolean;
};
type NumberColumnConfig<TData, TFilter, TSort> = {
  header: string;
  edit?: EditProps<TData>;
  getGlobalFilterCondition?(filterValue: string): Nullable<TFilter>;
  getColumnFilterCondition?(filterMode: FilterMode, filterValue: string): Nullable<TFilter>;
  getColumnSort?(sortDirection: SortDirection): TSort;
};

const filterHelper = createFilterHelper({
  filterSchema: z.string(),
  filterFn,
});

function filterFn(
  value: number | undefined,
  filterValue: string | null,
  filterMode?: FilterMode,
): boolean {
  if (isIsEmptyOrIsNotEmpty(filterMode)) {
    if (filterMode === FilterMode.IsEmpty) {
      return isEmpty(value);
    }
    if (filterMode === FilterMode.IsNotEmpty) {
      return !isEmpty(value);
    }
  }

  const filterValueAsNumber = Number(filterValue);
  if (isNaN(filterValueAsNumber)) {
    return false;
  }

  if (!filterValueAsNumber) {
    return true;
  }

  if (typeof value !== 'number') {
    return false;
  }

  if (!filterMode || filterMode === FilterMode.Includes) {
    return value === filterValueAsNumber;
  }

  if (filterMode === FilterMode.NotEquals) {
    return value !== filterValueAsNumber;
  }

  if (filterMode === FilterMode.GreaterThan) {
    return value > filterValueAsNumber;
  }

  if (filterMode === FilterMode.LessThan) {
    return value < filterValueAsNumber;
  }

  if (filterMode === FilterMode.GreaterThanOrEqual) {
    return value >= filterValueAsNumber;
  }

  if (filterMode === FilterMode.LessThanOrEqual) {
    return value <= filterValueAsNumber;
  }

  return false;
}

export function number<TData, TFilter, TSort>() {
  return ({
    edit,
    ...columnDef
  }: ColumnDef<TData, number | undefined> & NumberColumnConfig<TData, TFilter, TSort>): ColumnDef<
    TData,
    number | undefined
  > => {
    return {
      enableGlobalFilter: false,
      enableColumnFilter: false,
      enableSorting: false,
      sortDescFirst: false,

      ...columnDef,

      cell: (context) => {
        const value = context.getValue();
        const formattedValue = value?.toString();

        return (
          <CellContainer cell={context.cell} p={0} data-group>
            <OverflowContainer>
              <OverflowContainer.Tooltip
                label={value}
                hasArrow
                placement="bottom-start"
                fontSize="sm"
                openDelay={500}
              >
                {columnDef.cell && typeof columnDef.cell === 'function' ? (
                  columnDef.cell(context)
                ) : (
                  <Text isTruncated p={4}>
                    {formattedValue}
                  </Text>
                )}
              </OverflowContainer.Tooltip>
            </OverflowContainer>
          </CellContainer>
        );
      },

      filterFn: filterHelper.filterFn,

      meta: {
        ...columnDef.meta,
        name: columnDef.header,
        globalFilterFn: filterFn,
        // Tanstack table filters are untyped. Type safety is enforced via filter/column helpers
        getGlobalFilterCondition: columnDef.getGlobalFilterCondition as never,
        getColumnFilterCondition: columnDef.getColumnFilterCondition as never,
        getColumnSort: columnDef.getColumnSort as never,

        filter: {
          filterSchema: filterHelper.filterSchema,

          DisplayFilter: ({ column }) => {
            const { t } = useTranslation('ui');
            const mode = filterHelper.getFilterMode(column);
            const filterValue = filterHelper.getFilterValue(column);

            return (
              <span>
                {mode === FilterMode.Includes && `${t('table.filter.equals')} `}
                {mode === FilterMode.NotEquals && `${t('table.filter.notEquals')} `}
                {mode === FilterMode.GreaterThan && `${t('table.filter.greaterThan')} `}
                {mode === FilterMode.LessThan && `${t('table.filter.lessThan')} `}
                {mode === FilterMode.GreaterThanOrEqual &&
                  `${t('table.filter.greaterThanOrEqual')} `}
                {mode === FilterMode.LessThanOrEqual && `${t('table.filter.lessThanOrEqual')} `}
                {mode === FilterMode.IsEmpty && t('table.filter.isEmpty')}
                {mode === FilterMode.IsNotEmpty && t('table.filter.isNotEmpty')}
                {filterValue}
              </span>
            );
          },

          EditFilter: ({ column }) => {
            const { t } = useTranslation('ui');

            const filterValue = filterHelper.getFilterValue(column);
            const mode = filterHelper.getFilterMode(column);
            const modeOptions = useMemo(
              () => [
                { label: t('table.filter.equals'), value: FilterMode.Includes },
                { label: t('table.filter.notEquals'), value: FilterMode.NotEquals },
                { label: t('table.filter.greaterThan'), value: FilterMode.GreaterThan },
                { label: t('table.filter.lessThan'), value: FilterMode.LessThan },
                {
                  label: t('table.filter.greaterThanOrEqual'),
                  value: FilterMode.GreaterThanOrEqual,
                },
                { label: t('table.filter.lessThanOrEqual'), value: FilterMode.LessThanOrEqual },
                { label: t('table.filter.isEmpty'), value: FilterMode.IsEmpty },
                { label: t('table.filter.isNotEmpty'), value: FilterMode.IsNotEmpty },
              ],
              [t],
            );

            const label = useMemo(
              () => modeOptions.find((option) => option.value === mode)?.label ?? '',
              [mode, modeOptions],
            );
            const hasInput = !isIsEmptyOrIsNotEmpty(mode);
            return (
              <FormControl p={3}>
                <FormLabel fontWeight="medium" mb={hasInput ? undefined : 0}>
                  <HStack spacing={1}>
                    <Text color="gray.600" _dark={{ color: 'whiteAlpha.800' }}>
                      {columnDef.header}
                    </Text>
                    <FilterModeSelector {...filterHelper} column={column} options={modeOptions} />
                  </HStack>
                </FormLabel>
                {!isIsEmptyOrIsNotEmpty(mode) && (
                  <Input
                    autoFocus
                    size="sm"
                    type="number"
                    placeholder={t('table.column.text.filterPlacehoder')}
                    borderRadius="base"
                    value={filterValue || ''}
                    aria-label={`${columnDef.header} ${label}`}
                    onChange={(event) =>
                      filterHelper.setFilterValue(column, event.target.value || null)
                    }
                  />
                )}
              </FormControl>
            );
          },
        },
      },
    };
  };
}
