import { FormControlProps } from '@chakra-ui/react';
import { createSorter, useStableCallback } from '@main/shared/utils';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  FormHTMLAttributes,
  ForwardedRef,
  forwardRef,
  memo,
  ReactNode,
  RefAttributes,
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { DynamicField, DynamicFieldWrapper } from './dynamic-field';
import { AnyDynamicFormComponentRegistry, DynamicFormFieldConfig } from './field-registry';

export interface DynamicFormProps<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
> {
  componentRegistry: TRegistry;
  fields: readonly DynamicFormFieldConfig<TRegistry>[];
  onSubmit?: SubmitHandler<FieldValues>;
  form?: Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;
  useForm?: UseFormProps;
  children?: ReactNode;
  fieldControl?: FormControlProps;
  fieldWrapper?: DynamicFieldWrapper<TRegistry, TWrapperProps>;
  fieldWrapperProps?: TWrapperProps;
  fieldSortDir?: 'asc' | 'desc';
  vScrollRef?: RefObject<HTMLDivElement>;
  vScrollOffset?: number;
  vScrollSize?: number;
}

export interface DynamicFormRef {
  form: UseFormReturn;
  formRef: RefObject<HTMLFormElement>;
}

function _DynamicForm<
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
>(props: DynamicFormProps<TRegistry, TWrapperProps>, ref: ForwardedRef<DynamicFormRef>) {
  const form = useForm(props.useForm);
  const formRef = useRef<HTMLFormElement>(null);

  const fieldsSorter = useMemo(
    () => (props.fieldSortDir ? createSorter(props.fieldSortDir) : undefined),
    [props.fieldSortDir],
  );
  const fields = useMemo(
    () => (fieldsSorter ? [...props.fields].sort(fieldsSorter) : props.fields),
    [props.fields, fieldsSorter],
  );

  useImperativeHandle(ref, () => ({ form, formRef }), [form, formRef]);

  const onSubmit = props.onSubmit && form.handleSubmit(props.onSubmit);

  const itemRender = useStableCallback((item: DynamicFormFieldConfig<TRegistry>) => (
    <DynamicField
      key={item.name}
      config={item}
      wrapper={props.fieldWrapper}
      wrapperProps={props.fieldWrapperProps}
      formControl={props.fieldControl}
      componentRegistry={props.componentRegistry}
    />
  ));

  const vScrollEnabled = !!props.vScrollRef;
  const virtualizer = useVirtualizer({
    enabled: vScrollEnabled,
    count: fields.length,
    scrollMargin: props.vScrollOffset,
    getItemKey: (idx) => fields[idx]?.name ?? idx,
    getScrollElement: () => props.vScrollRef?.current ?? null,
    estimateSize: () => props.vScrollSize ?? 50,
  });

  const vItems = virtualizer.getVirtualItems();
  const vItemsRender = useStableCallback(() => (
    <div
      style={{
        height: virtualizer.getTotalSize(),
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          willChange: 'transform',
          transform: `translateY(${(vItems[0]?.start ?? 0) - virtualizer.options.scrollMargin}px)`,
        }}
      >
        {vItems.map((vItem) => (
          <div key={vItem.key} data-index={vItem.index} ref={virtualizer.measureElement}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {itemRender(fields[vItem.index]!)}
          </div>
        ))}
      </div>
    </div>
  ));

  // HACK: Required to do initial rendering of items in prod mode
  // See https://github.com/TanStack/virtual/issues/699
  useEffect(() => virtualizer.measure(), [virtualizer]);

  const itemsRender = useStableCallback(() => fields.map(itemRender));

  const Items = vScrollEnabled ? vItemsRender : itemsRender;

  return (
    <FormProvider {...form}>
      <form {...props.form} onSubmit={onSubmit} ref={formRef}>
        <Items />
        {props.children}
      </form>
    </FormProvider>
  );
}

export const DynamicForm = memo(forwardRef(_DynamicForm)) as <
  TRegistry extends AnyDynamicFormComponentRegistry,
  TWrapperProps extends object = object,
>(
  props: DynamicFormProps<TRegistry, TWrapperProps> & RefAttributes<DynamicFormRef>,
) => ReactNode;
