import { datadogLogs } from '@datadog/browser-logs';
import { BrowserStorageMigrationHost, MigrationManager } from '@main/shared/storage';

import { localStorageMigrationRegistry } from '../local-storage';

import.meta.glob<void>('./*.ts', { eager: true });

export const localStorageMigrationManager = new MigrationManager(
  new BrowserStorageMigrationHost(localStorage, 'cply::version'),
  localStorageMigrationRegistry,
  datadogLogs.logger,
);
