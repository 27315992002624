import { SerializedEditor } from 'lexical';
import { useMemo } from 'react';

interface SerializedNode {
  children?: SerializedNode[];
  text?: string;
  type?: string;
}

export const useIsCommentEmpty = (commentState: string) => {
  return useMemo(() => {
    try {
      // Parse the serialized editor state
      const state = JSON.parse(commentState) as SerializedEditor;
      const root = state?.editorState?.root;

      // If there is no root or no children, the comment is empty
      if (!root || !Array.isArray(root.children) || root.children.length === 0) {
        return true;
      }

      // Check every child node in the root
      const isEmpty = root.children.every((node: SerializedNode) => {
        // If node has children (like a paragraph node), check each child's text content
        if (Array.isArray(node.children) && node.children.length > 0) {
          return node.children.every((child: SerializedNode) => {
            return !child.text || child.text.trim() === '';
          });
        }
        // For nodes that may have text directly
        return !node.text || node.text.trim() === '';
      });

      return isEmpty;
    } catch {
      return true;
    }
  }, [commentState]);
};
