import {
  BookmarkIcon,
  BookmarkSlashIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { isNonNullable } from '@main/shared/utils';
import { actionHelper, DrawerActionsProps, successToast, useDrawer, useModal } from '@main/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useUpdateControlOnDrawerHandler } from '../program/use-update-control-handler';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { AICriteriaModal } from './ai-criteria-form';
import { useDeleteControl } from './delete-control';
import { useDuplicateControl } from './duplicate-control';
import { FindingForm } from './finding/finding-form';
import { getMappedControl } from './slice';

export const useControlDrawerActions = (controlId: string): DrawerActionsProps => {
  const duplicateControlAction = useDuplicateControlAction(controlId);
  const addFindingAction = useAddFindingAction(controlId);
  const setControlRelevancyAction = useSetControlRelevancyAction(controlId);
  const deleteControlAction = useDeleteControlAction(controlId);
  const editAICriteriaAction = useEditAICriteriaAction(controlId);

  return actionHelper.drawerToolbarActions({
    menuActions: [
      duplicateControlAction,
      addFindingAction,
      setControlRelevancyAction,
      editAICriteriaAction,
      deleteControlAction,
    ].filter(isNonNullable),
  });
};

function useEditAICriteriaAction(controlId: string) {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const isAIReviewFormEnabled = useFeatureFlagEnabled('control-ai-review-form');
  const { is_control_ai_review_enabled } = useAppSelector(getCurrentUserSelectedOrg);

  if (!(is_control_ai_review_enabled && isAIReviewFormEnabled)) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: SparklesIcon,
    label: t('controls.aiCriteriaModal.editAICriteriaBtn'),
    onClick: () => {
      openModal({
        size: '2xl',
        header: t('controls.aiCriteriaModal.heading'),
        content: <AICriteriaModal controlId={controlId} />,
      });
    },
  });
}

function useDuplicateControlAction(controlId: string) {
  const { t } = useTranslation();

  const duplicateControl = useDuplicateControl();
  if (!duplicateControl) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: DocumentDuplicateIcon,
    label: t('buttons.duplicate'),
    onClick: async () => {
      duplicateControl(controlId);
    },
  });
}

function useAddFindingAction(controlId: string) {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateFinding = !!userRole.permissionMap?.update_controls;
  if (!canCreateFinding) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: DocumentMagnifyingGlassIcon,
    label: t('controls.findingModal.addFindingBtn'),
    onClick: () =>
      openModal({
        header: t('controls.findingModal.creating.heading'),
        content: <FindingForm controlId={controlId} />,
      }),
  });
}

function useSetControlRelevancyAction(controlId: string) {
  const { t } = useTranslation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canSetControlRelevancy = !!userRole.permissionMap?.update_controls;

  const control = useAppSelector((state) => getMappedControl(state, controlId));

  const updateControlHandler = useUpdateControlOnDrawerHandler();
  const [updateControlById] = useUpdateControlByIdMutation();

  if (!canSetControlRelevancy || !control) {
    return null;
  }

  const isRelevant = !control.irrelevant;
  const tKey = isRelevant ? 'relevant' : 'irrelevant';

  return actionHelper.menuActionItem({
    icon: isRelevant ? BookmarkSlashIcon : BookmarkIcon,
    label: t(`controls.relevancy.${tKey}.actionLabel`),
    onClick: async () => {
      const result = await updateControlHandler(
        updateControlById({
          controlId,
          updatePayload: { irrelevant: isRelevant },
        }),
      );
      if (result) {
        successToast(t(`controls.relevancy.${tKey}.successMsg`));
      }
    },
  });
}

function useDeleteControlAction(controlId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const deleteControl = useDeleteControl();
  if (!deleteControl) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: TrashIcon,
    label: t('buttons.delete'),
    onClick: () => {
      deleteControl(controlId, () => drawer.close());
    },
  });
}
