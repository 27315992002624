import { REQUEST_ID_HEADER } from '@main/shared/utils';
import { NhostClient } from '@nhost/react';
import { Reducer } from '@reduxjs/toolkit';
import * as rtk from '@reduxjs/toolkit/dist/query/react/index.js';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLError } from 'graphql';

// ESM hack
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
const { createApi, retry } = ((rtk as any).default ?? rtk) as typeof rtk;

export const graphqlUrl =
  typeof window !== 'undefined' ? (import.meta.env['VITE_NHOST_GRAPHQL_URL'] as string) : '';

export interface RequiredGraphqlState {
  defaultRole?: 'admin' | 'user' | 'vendor';
  nhostClient: NhostClient;
}

export interface GraphqlStateSlice {
  gql: RequiredGraphqlState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type WithGraphqlReducer = {
  [K in keyof GraphqlStateSlice]: Reducer<GraphqlStateSlice[K]>;
};

type ResponseMeta = {
  response?: {
    errors?: GraphQLError[];
  };
};

const retryWithRequestId = (
  ...args: [args: unknown, api: rtk.BaseQueryApi, extraOptions: object]
) => {
  const requestId = crypto.randomUUID();
  let nhostClient: NhostClient | undefined;

  return retry(
    async (...args) => {
      const result = await graphqlRequestBaseQuery({
        url: graphqlUrl,

        prepareHeaders: (headers, { getState }) => {
          const appState = getState() as GraphqlStateSlice;
          const userDefaultRole = appState.gql.defaultRole;
          const nhostClientInstance = appState.gql.nhostClient;
          const token = nhostClientInstance.auth.getAccessToken();
          nhostClient = nhostClientInstance;

          headers.set(REQUEST_ID_HEADER, requestId);
          if (token) {
            headers.set('authorization', `Bearer ${token}`);
            userDefaultRole && headers.set('x-hasura-role', userDefaultRole);
          }
          return headers;
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      })(...args);

      const errors = (result.meta as ResponseMeta)?.response?.errors;

      /**
       * In a rare event where the access token has expited because of revisiting
       * an inactive tab and token rotation from nhost sdk still didn't happen.
       * - We try to refresh the session when requests are retried because of invalid-jwt
       * - If we don't have the client, we reload the page
       *  */
      if (errors && errors?.[0]?.extensions?.code === 'invalid-jwt') {
        if (nhostClient) {
          await nhostClient?.auth.refreshSession();
        } else {
          window.location.reload();
        }

        return result; // this would cause the graphql client to retry
      }

      // bail out of re-tries if not unexpected error
      if (errors && errors?.[0]?.extensions?.code !== 'unexpected') {
        retry.fail(errors);
      }

      return result;
    },
    {
      maxRetries: 3,
      /**
       * backoff code is taken from here, we just wanted to use datadog logger to send log
       * when the error is UnhandledError like network etc in rtk-query it's keeps retrying and this is best place to log
       * https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/retry.ts
       *  */
      backoff: async (attempt, maxRetries) => {
        const attempts = Math.min(attempt, maxRetries);

        const timeout = ~~((Math.random() + 0.4) * (300 << attempts));
        await new Promise((resolve) => setTimeout((res: unknown) => resolve(res), timeout));
      },
    },
  )(...args);
};

export const api = createApi({
  baseQuery: retryWithRequestId,
  refetchOnMountOrArgChange: 20,
  endpoints: () => ({}),
});
