/* eslint-disable react-hooks/rules-of-hooks */
import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  List,
  ListItem,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  OptionsFieldConfig,
  PickDynamicFormFieldConfig,
  QuestionnaireComponentKind,
} from '@main/dynamic-form';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import { useStableCallback } from '@main/shared/utils';
import { EditableTag } from '@main/ui';
import { useEffect, useMemo, useState } from 'react';
import { Controller, DeepPartial, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { Toggle } from '../../shared/toggle';
import { isOptionsChanged } from './conditions';
import {
  baseFieldSchema,
  fieldValidationRefinement,
  FormFieldConfigurator,
  FormFieldConfiguratorProps,
  useRegisterFormFieldConfigurator,
} from './field-configurator';

const formSchema = baseFieldSchema
  .merge(
    z.object({
      options: z.array(z.object({ label: z.string().trim().min(1) })),
      isMultiOptions: z.boolean(),
    }),
  )
  .superRefine(fieldValidationRefinement);

export type OptionsFieldForm = z.infer<typeof formSchema>;

export class OptionsFieldConfigurator implements FormFieldConfigurator<OptionsFieldForm> {
  static instance = new OptionsFieldConfigurator();

  static Register() {
    useRegisterFormFieldConfigurator(
      QuestionnaireComponentKind.Options,
      OptionsFieldConfigurator.instance,
    );

    return null;
  }

  kind = QuestionnaireComponentKind.Options;
  form!: UseFormReturn<OptionsFieldForm>;

  formDataToConfig?(
    data: OptionsFieldForm,
    config: QuestionnaireFormFieldConfig,
  ): Partial<QuestionnaireFormFieldConfig> {
    const options =
      config?.kind === QuestionnaireComponentKind.Options
        ? new Map(config.options?.map((option) => [option.label, option.value]))
        : new Map<string, string>();

    return {
      ...data,
      options: data.options?.map((option) => ({
        value: options.get(option.label) ?? crypto.randomUUID(),
        label: option?.label ?? '',
      })),
    };
  }

  useForm(config?: QuestionnaireFormFieldConfig): UseFormReturn<OptionsFieldForm> {
    this.form = useForm({
      resolver: zodResolver(formSchema),
      defaultValues: {
        ...config,
        label: config?.label ?? '',
        isMultiOptions: (config as OptionsFieldConfig)?.isMultiOptions ?? false,
        options: (config as OptionsFieldConfig)?.options ?? [
          { label: 'Yes' },
          { label: 'No' },
          { label: 'N/A' },
        ],
      } as OptionsFieldForm,
    });

    return this.form;
  }

  renderName() {
    const { t } = useTranslation();
    return t('form.fields.type.values.select');
  }

  renderForm({ configurator }: FormFieldConfiguratorProps<OptionsFieldForm>) {
    const { form } = configurator;
    const { t } = useTranslation();

    return (
      <>
        <FormControl isInvalid={!!form.formState.errors.options}>
          <FormLabel>{t('form.fields.options.label')}:</FormLabel>
          <Controller
            name="options"
            control={form.control}
            render={({ field }) => (
              <EditableTag
                isMulti
                value={field.value?.map((option) => ({
                  value: option.label,
                  colorScheme: 'purple',
                }))}
                getNewOptionData={(value) => ({ value, colorScheme: 'purple' })}
                onChange={(values) => {
                  field.onChange(values.map(({ value }) => ({ label: value })));
                }}
              />
            )}
          />
          <FormErrorMessage>{form.formState.errors.options?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors.isMultiOptions}>
          <Toggle label={t('form.fields.isMulti.label')} {...form.register('isMultiOptions')} />
          <FormErrorMessage>{form.formState.errors.isMultiOptions?.message}</FormErrorMessage>
        </FormControl>
      </>
    );
  }

  renderAfterForm({
    configurator,
    config,
    affectedConditionalFields,
  }: FormFieldConfiguratorProps<OptionsFieldForm>) {
    const { form } = configurator;
    const { t } = useTranslation();
    const [willResetConditions, setWillResetConditions] = useState(false);

    const options = useMemo(
      () =>
        config?.kind === QuestionnaireComponentKind.Options
          ? new Map(config.options?.map((option) => [option.label, option.value]))
          : new Map<string, string>(),
      [config],
    );

    const getSelectField = useStableCallback(
      (schema: DeepPartial<OptionsFieldForm>): QuestionnaireFormFieldConfig =>
        ({
          ...config,
          kind: QuestionnaireComponentKind.Options,
          name: config?.name ?? crypto.randomUUID(),
          ...schema,
          options: schema.options?.map((option) => ({
            value: options.get(option?.label ?? '') ?? crypto.randomUUID(),
            label: option?.label ?? '',
          })),
        }) as PickDynamicFormFieldConfig<
          QuestionnaireFormFieldConfig,
          QuestionnaireComponentKind.Options
        >,
    );

    useEffect(
      () =>
        form.watch((data) =>
          setWillResetConditions(
            !!affectedConditionalFields?.length &&
              !!config &&
              isOptionsChanged(getSelectField(data), config),
          ),
        ).unsubscribe,
      [config, getSelectField, affectedConditionalFields, form],
    );

    return (
      willResetConditions && (
        <Alert status="warning" variant="left-accent" mb={4}>
          <AlertIcon />
          <Box>
            {t('questionnaires.questions.editQuestionModal.affectedQuestions', {
              count: affectedConditionalFields?.length ?? 0,
            })}
            <List>
              {affectedConditionalFields?.map((field) => (
                <ListItem key={field.name}>- {field.label}</ListItem>
              ))}
            </List>
          </Box>
        </Alert>
      )
    );
  }
}
