/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type AiCreateEntityMutationVariables = Types.Exact<{
  input: Types.Scalars['AiCreateEntityInput']['input'];
}>;

export type AiCreateEntityMutation = {
  __typename?: 'mutation_root';
  ai_create_entity?: {
    __typename?: 'AiCreateEntityOutput';
    entityId: string;
    internalId?: string;
    isGeneratedByAI?: boolean;
  };
};

export const AiCreateEntityDocument = `
    mutation AiCreateEntity($input: AiCreateEntityInput!) {
  ai_create_entity(input: $input) {
    entityId
    internalId
    isGeneratedByAI
  }
}
    ` as string & TypedDocumentNode<AiCreateEntityMutation, AiCreateEntityMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AiCreateEntity: build.mutation<AiCreateEntityMutation, AiCreateEntityMutationVariables>({
      query: (variables) => ({ document: AiCreateEntityDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAiCreateEntityMutation } = injectedRtkApi;
