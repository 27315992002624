import { LockClosedIcon, LockOpenIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  GetEvidenceByIdQuery,
  useGetEvidenceByIdQuery,
} from '@main/graphql/queries/GetEvidenceById.generated';
import { isNonNullable } from '@main/shared/utils';
import { actionHelper, useDrawer, useModal } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteEvidence } from './delete-evidence';
import { EvidenceAccessModal } from './evidence-access-modal';
import { useIsUserAuthorizedToChangeEvidence } from './utils';

type Evidence = GetEvidenceByIdQuery['evidences_by_pk'];

export const useEvidenceDrawerActions = (evidenceId: string) => {
  const evidenceQuery = useGetEvidenceByIdQuery(
    { evidence_id: evidenceId },
    { refetchOnMountOrArgChange: true },
  );
  const evidence = evidenceQuery.data?.evidences_by_pk;

  const manageAccessAction = useManageAccessAction(evidence);
  const deleteAction = useDeleteAction(evidence);

  return actionHelper.drawerToolbarActions({
    iconActions: [manageAccessAction].filter(isNonNullable),
    menuActions: [deleteAction].filter(isNonNullable),
  });
};

function useManageAccessAction(evidence: Evidence) {
  const { t } = useTranslation();
  const { openModal } = useModal();
  if (!evidence) {
    return null;
  }

  return actionHelper.iconAction({
    icon: evidence.is_confidential ? LockClosedIcon : LockOpenIcon,
    tooltip: t('evidences.accessModal.heading'),
    onClick: () =>
      openModal({
        header: t('evidences.accessModal.heading'),
        content: <EvidenceAccessModal evidenceId={evidence.id} />,
      }),
  });
}

function useDeleteAction(evidence: Evidence) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const canDeleteEvidence = useIsUserAuthorizedToChangeEvidence(evidence);
  const deleteEvidence = useDeleteEvidence();
  if (!evidence || !canDeleteEvidence) {
    return null;
  }

  return actionHelper.menuActionItem({
    icon: TrashIcon,
    label: t('buttons.delete'),
    onClick: () => {
      deleteEvidence(evidence, () => drawer.close());
    },
  });
}
