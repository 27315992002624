import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { NAMESPACE } from '@main/shared/utils';
import { useLocalStorageState } from '@main/ui';
import { VisibilityState } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

import { hideCustomFieldColumns } from '../features/custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../features/custom-fields/field-config';
import { getCurrentUserSelectedOrgId } from '../features/user/slice';
import { useAppSelector } from '../hooks/redux-toolkit-hooks';

export function useTableSettings({
  tableId,
  defaultColumnVisibility = {},
}: {
  tableId: string;
  defaultColumnVisibility?: VisibilityState;
}) {
  const storageKey = useStorageKey(tableId);
  const [columnVisibilityState, setColumnVisibilityState] = useLocalStorageState(storageKey, {
    defaultValue: defaultColumnVisibility,
  });

  /**
   *  This piece of code will update column visibility setting for users that disabled "actions" column.
   *
   *  It should be removed when we will be sure that visibility settings was updated for all the users,
   *  removing it will not break anything.
   */
  useEffect(() => {
    if ('actions' in columnVisibilityState) {
      const { actions, ...restColumnVisibility } = columnVisibilityState;
      setColumnVisibilityState(restColumnVisibility);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnVisibilityState['actions']]);

  const columnVisibilityWithDefaults = useMemo(
    () => ({
      ...defaultColumnVisibility,
      ...columnVisibilityState,
    }),
    [columnVisibilityState, defaultColumnVisibility],
  );

  const isColumnVisibilityModified = useMemo(() => {
    return Object.entries(columnVisibilityWithDefaults).some(([columnId, isVisible]) => {
      return isVisible !== (defaultColumnVisibility[columnId] ?? true);
    });
  }, [columnVisibilityWithDefaults, defaultColumnVisibility]);

  return {
    defaultColumnVisibility,
    columnVisibility: columnVisibilityWithDefaults,
    setColumnVisibility: setColumnVisibilityState,
    isColumnVisibilityModified,
  };
}

export function useCustomFieldsTableSettings({
  entityName,
  tableId,
  defaultColumnVisibility,
}: {
  entityName: Field_Entities_Enum;
  tableId: string;
  defaultColumnVisibility: VisibilityState;
}) {
  const storageKey = useStorageKey(tableId);
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const fieldConfigsQuery = useGetFieldConfigsQuery({ orgId, entityName });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  const defaultColumnVisibilityWithCustomFields = useMemo(
    () => ({
      ...defaultColumnVisibility,
      ...hideCustomFieldColumns(fieldConfigs),
    }),
    [defaultColumnVisibility, fieldConfigs],
  );

  const [columnVisibilityState, setColumnVisibilityState] = useLocalStorageState(storageKey, {
    defaultValue: {},
  });

  const columnVisibilityWithDefaults = useMemo(
    () => ({
      ...defaultColumnVisibilityWithCustomFields,
      ...columnVisibilityState,
    }),
    [columnVisibilityState, defaultColumnVisibilityWithCustomFields],
  );

  const isColumnVisibilityModified = useMemo(() => {
    return Object.entries(columnVisibilityWithDefaults).some(([columnId, isVisible]) => {
      return isVisible !== (defaultColumnVisibilityWithCustomFields[columnId] ?? true);
    });
  }, [columnVisibilityWithDefaults, defaultColumnVisibilityWithCustomFields]);

  return {
    columnVisibility: columnVisibilityWithDefaults,
    defaultColumnVisibility: defaultColumnVisibilityWithCustomFields,
    setColumnVisibility: setColumnVisibilityState,
    isColumnVisibilityModified,
  };
}

function useStorageKey(tableId: string) {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  return `${NAMESPACE}${tableId}:org-id-${organizationId}:table:column-visibility-v2`;
}
